import * as Ariakit from '@ariakit/react'
import React from 'react'

import {DialogContent, DialogContentProps} from './Dialog'
import {PhosphorIcon} from '../../icons'
import {cn, VariantsProps} from '../../utils'
import {NextButton} from './Button'
import {cva} from 'class-variance-authority'

export const modalContent = cva('group/modal', {
  variants: {
    variant: {
      default:
        'sm:-translate-x-1/2 sm:-translate-y-1/2 absolute top-0 left-0 flex max-w-dvw translate-y-0 scale-90 flex-col overflow-x-hidden bg-trueWhite opacity-0 transition-all data-[enter]:scale-100 data-[enter]:opacity-100 max-sm:h-full max-sm:w-full max-sm:overflow-y-auto sm:top-1/2 sm:left-1/2 sm:max-h-[calc(100dvh-theme(spacing.8))] sm:rounded sm:shadow-z16',
      leftToRight: [
        '-translate-x-full left-0',
        'fixed top-0 flex h-dvh min-w-80 max-w-screen-md flex-col overflow-hidden bg-natural-100 shadow-z16 transition-transform ease-linear data-[enter]:translate-x-0',
      ],
      rightToLeft: [
        'right-0 translate-x-[100vw]',
        'fixed top-0 flex h-dvh min-w-80 max-w-screen-md flex-col overflow-hidden bg-natural-100 shadow-z16 transition-transform ease-linear data-[enter]:translate-x-0',
      ],
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

// MARK: – ModalContent

export interface ModalContentProps
  extends DialogContentProps,
    VariantsProps<typeof modalContent> {
  backdropClassName?: string
}

export const ModalContent = React.forwardRef<HTMLDivElement, ModalContentProps>(
  (
    {variant, className, backdropClassName, backdrop, ...restProps},
    forwardedRef,
  ) => {
    return (
      <DialogContent
        ref={forwardedRef}
        className={cn(modalContent({variant}), className)}
        backdrop={
          backdrop ?? <ModalBackdropView className={backdropClassName} />
        }
        {...restProps}
      />
    )
  },
)

// MARK: – ModalBackdropView

export const ModalBackdropView = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({className, ...restProps}, forwardedRef) => (
  <div
    ref={forwardedRef}
    className={cn(
      'bg-backdropBackground opacity-0 backdrop-blur-none transition-all [transition-timing-function:cubic-bezier(0.4,0,0.2,1)] data-[enter]:opacity-100 data-[enter]:backdrop-blur-sm',
      className,
    )}
    {...restProps}
  />
))

// MARK: – ModalCloseButton

export interface ModalCloseButtonProps extends Ariakit.DialogDismissProps {}

export const ModalCloseButton = React.forwardRef<
  HTMLButtonElement,
  ModalCloseButtonProps
>(({render, className, children, ...restProps}, forwardedRef) => (
  <Ariakit.DialogDismiss
    ref={forwardedRef}
    className={cn('absolute top-4 right-4 text-4xl', className)}
    render={
      render ?? (
        <NextButton variant="headless" size="headless" roundness="headless" />
      )
    }
    {...restProps}
  >
    {children ?? <PhosphorIcon icon="x" />}
  </Ariakit.DialogDismiss>
))
