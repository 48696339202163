import {
  Disclosure as ReakitDisclosure,
  DisclosureContent as ReakitDisclosureContent,
  DisclosureContentOptions as ReakitDisclosureContentOptions,
  DisclosureInitialState as ReakitDisclosureInitialState,
  DisclosureOptions as ReakitDisclosureOptions,
  DisclosureStateReturn as ReakitDisclosureStateReturn,
  useDisclosureState as useReakitDisclosureState,
} from 'reakit'
import {HTMLMotionProps, motion} from 'framer-motion'
import {
  ForwardRefComponent,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import React, {useContext, useImperativeHandle, useMemo} from 'react'

import {PhosphorIcon} from '../icons'
import {Button} from './Button'
import {Checkbox} from './Checkbox'
import {Stack, StackDirection, StackProps} from './Stack'
import {Switch, SwitchProps} from './Switch'
import {cn} from '../utils'

interface InternalDisclosureContextValue extends ReakitDisclosureStateReturn {
  direction: StackDirection
}

const InternalDisclosureContext = React.createContext(
  {} as InternalDisclosureContextValue,
)

// MARK: - Disclosure

export interface DisclosureInstance extends ReakitDisclosureStateReturn {}
export interface DisclosureProps
  extends ReakitDisclosureInitialState,
    StackProps,
    Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  children:
    | React.ReactNode
    | ((disclosure: ReakitDisclosureStateReturn) => React.ReactNode)
  initialVisible?: boolean
  onVisibleChange?: (newVisible: boolean) => void
  onDidShow?: () => void
  onDidHide?: () => void
}

export const Disclosure = React.forwardRef<DisclosureInstance, DisclosureProps>(
  (
    {
      direction = 'vertical',
      children,
      className,
      initialVisible,
      animated = true,
      onVisibleChange,
      onDidShow,
      onDidHide,
      baseId,
      visible,
      ...restProps
    },
    forwardedRef,
  ) => {
    const disclosure = useReakitDisclosureState({
      baseId,
      visible: visible ?? initialVisible,
      animated,
    })
    const disclosureRef = useLiveRef(disclosure)
    const onVisibleChangeRef = useLiveRef(onVisibleChange)
    const onDidShowRef = useLiveRef(onDidShow)
    const onDidHideRef = useLiveRef(onDidHide)

    useImperativeHandle(forwardedRef, () => disclosure, [disclosure])

    useUpdateEffect(() => {
      if (visible != null) {
        disclosureRef.current.setVisible(visible)
      }
    }, [visible])

    useUpdateEffect(() => {
      if (!disclosure.animating) {
        onVisibleChangeRef.current?.(disclosure.visible)
        if (disclosure.visible) {
          onDidShowRef.current?.()
        } else {
          onDidHideRef.current?.()
        }
      }
    }, [disclosure.visible, disclosure.animating])

    const contextValue: InternalDisclosureContextValue = useMemo(
      () => ({...disclosure, direction}),
      [disclosure, direction],
    )

    return (
      <InternalDisclosureContext.Provider value={contextValue}>
        <Stack
          className={cn('Disclosure', className)}
          direction={direction}
          {...restProps}
        >
          {typeof children === 'function' ? children(disclosure) : children}
        </Stack>
      </InternalDisclosureContext.Provider>
    )
  },
)

// MARK: - DisclosureButton

export interface DisclosureButtonProps
  extends Omit<ReakitDisclosureOptions, keyof ReakitDisclosureStateReturn> {
  arrow?: boolean
  children?:
    | string
    | React.ReactElement
    | ((disclosure: ReakitDisclosureStateReturn) => string | React.ReactElement)
}

export const DisclosureButton = React.forwardRef(
  (
    {as = Button, arrow = true, children, className, ...restProps},
    forwardedRef,
  ) => {
    const {direction, ...disclosure} = useContext(InternalDisclosureContext)
    return (
      <ReakitDisclosure
        ref={forwardedRef}
        as={as}
        className={cn('DisclosureButton', className)}
        iconBefore={
          arrow && (
            <PhosphorIcon
              className={`transition-transform duration-100 ease-linear [[aria-expanded="true"]_>_.Button-iconBefore_>_&]:rotate-180`}
              icon="caret-down"
            />
          )
        }
        {...disclosure}
        {...restProps}
      >
        {typeof children === 'function' ? children(disclosure) : children}
      </ReakitDisclosure>
    )
  },
) as ForwardRefComponent<typeof Button, DisclosureButtonProps>

// MARK: - DisclosureCheckbox

export interface DisclosureCheckboxProps
  extends Omit<ReakitDisclosureOptions, keyof ReakitDisclosureStateReturn> {
  children?:
    | string
    | React.ReactElement
    | ((disclosure: ReakitDisclosureStateReturn) => string | React.ReactElement)
}

export const DisclosureCheckbox = React.forwardRef(
  ({as = Checkbox, children, className, ...restProps}, forwardedRef) => {
    const {direction, ...disclosure} = useContext(InternalDisclosureContext)
    return (
      <ReakitDisclosure
        ref={forwardedRef}
        as={as}
        className={cn('DisclosureCheckbox', className)}
        checked={disclosure.visible}
        {...disclosure}
        {...restProps}
      >
        {typeof children === 'function' ? children(disclosure) : children}
      </ReakitDisclosure>
    )
  },
) as ForwardRefComponent<typeof Checkbox, DisclosureCheckboxProps>

// MARK: – DisclosureSwitch

export interface DisclosureSwitchProps
  extends Omit<ReakitDisclosureOptions, keyof ReakitDisclosureStateReturn>,
    Omit<SwitchProps, 'children'> {
  children?:
    | React.ReactNode
    | ((disclosure: ReakitDisclosureStateReturn) => React.ReactNode)
}

export const DisclosureSwitch = React.forwardRef<
  HTMLInputElement,
  DisclosureSwitchProps
>(({reverse = true, className, children, ...restProps}, forwardedRef) => {
  const {direction, ...disclosure} = useContext(InternalDisclosureContext)
  return (
    <ReakitDisclosure
      ref={forwardedRef}
      className={cn('DisclosureSwitch', className)}
      reverse={reverse}
      as={Switch}
      checked={disclosure.visible}
      {...disclosure}
      {...restProps}
    >
      {typeof children === 'function' ? children(disclosure) : children}
    </ReakitDisclosure>
  )
})

// MARK: - DisclosureContent

export interface DisclosureContentProps
  extends Omit<
    ReakitDisclosureContentOptions,
    keyof ReakitDisclosureStateReturn
  > {
  renderLazily?: boolean
}

export const DisclosureContent = React.forwardRef(
  (
    {renderLazily = true, as: Comp = 'div', children, className, ...restProps},
    forwardedRef,
  ) => {
    const {direction, ...disclosure} = useContext(InternalDisclosureContext)
    return (
      <ReakitDisclosureContent {...disclosure} {...(restProps as any)}>
        {(props: any) =>
          (renderLazily === false ||
            disclosure.visible ||
            disclosure.animating) && (
            <Comp
              ref={forwardedRef}
              className={cn('DisclosureContent', className)}
              {...props}
            >
              <DisclosureContentInnerView className="DisclosureContent-inner">
                {children}
              </DisclosureContentInnerView>
            </Comp>
          )
        }
      </ReakitDisclosureContent>
    )
  },
) as ForwardRefComponent<'div', DisclosureContentProps>

// MARK: – DisclosureContentInnerView

const DisclosureContentInnerView = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>((props, forwardedRef) => {
  const disclosure = useContext(InternalDisclosureContext)
  return disclosure.animated ? (
    <motion.div
      ref={forwardedRef}
      initial={{
        opacity: 0,
        ...{
          horizontal: {width: 0},
          vertical: {height: 0, scale: 0.9},
        }[disclosure.direction],
      }}
      animate={
        disclosure.visible
          ? {
              display: 'block',
              opacity: 1,
              transition: {duration: 0.15},
              ...{
                horizontal: {width: 'auto'},
                vertical: {height: 'auto', scale: 1},
              }[disclosure.direction],
            }
          : {
              opacity: 0,
              transition: {duration: 0.15},
              transitionEnd: {display: 'none'},
              ...{
                horizontal: {width: 0},
                vertical: {height: 0, scale: 0.9},
              }[disclosure.direction],
            }
      }
      onAnimationComplete={() =>
        setTimeout(() => disclosure.stopAnimation(), 0)
      }
      {...(props as HTMLMotionProps<'div'>)}
    />
  ) : (
    <div ref={forwardedRef} {...props} />
  )
})
