import React from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {PhosphorIcon} from '../icons'
import {Button, ButtonProps} from './Button'
import {HStack} from './Stack'
import {cn} from '../utils'

export type PageToolbarVariant = 'default' | 'inline'

export interface PageToolbarProps
  extends React.ComponentPropsWithoutRef<'div'> {
  variant?: PageToolbarVariant
}

export const PageToolbar = React.forwardRef<HTMLDivElement, PageToolbarProps>(
  ({variant = 'default', className, children, ...restProps}, forwardedRef) => (
    <HStack
      ref={forwardedRef}
      className={cn(
        'PageToolbar',
        'sticky bottom-0 h-18 shrink-0 items-center justify-end gap-4 border-t bg-trueWhite px-4',
        variant === 'inline' && 'border-t',
        className,
      )}
      {...restProps}
    >
      {children}
    </HStack>
  ),
)

export interface PageToolbarSubmitButtonProps extends ButtonProps {
  arrow?: boolean
}

export const PageToolbarSubmitButton = React.forwardRef(
  ({arrow = true, className, children, ...restProps}, forwardedRef) => (
    <Button
      ref={forwardedRef}
      className={cn(
        'PageToolbarSubmitButton',
        'min-w-[144px] text-ds-md',
        className,
      )}
      size="large"
      variant="primary"
      iconAfter={arrow && <PhosphorIcon icon="caret-right-bold" width={14} />}
      {...restProps}
    >
      {children}
    </Button>
  ),
) as ForwardRefComponent<'button', PageToolbarSubmitButtonProps>
