import * as Yup from 'yup'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {useFormik, useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import {useRef, useState} from 'react'
import PoweredByStripe from 'src/images/powered-by-stripe.svg'
import CartHelpers from 'src/helpers/CartHelpers'
import {useElements} from '@stripe/react-stripe-js'
import {useAuthToken, useIsAuthed} from 'src/hooks/useAuthToken'

import useCart, {useEnhancedUpdateCartMutation} from '../hooks/useCart'
import {usePayForCart} from '../hooks/usePayForCart'
import {AddressPanel} from './AddressPanel'
import {PaymentMethodTypePanel} from './PaymentMethodTypePanel'
import {PaymentMethodPanel} from './PaymentMethodPanel'
import {CheckoutInvoicePanel} from './CheckoutInvoicePanel'
import {CoverFeePanel} from './CoverFeePanel'
import {ShippingMethodPanel} from './ShippingMethodPanel'
import {
  PayerContentContainerLayout,
  PayerContentLayout,
  PayerMainBlock,
} from '../components/PayerLayouts'
import {CartOverview} from '../components'
import {PayerAuthPanel} from '../components/PayerAuthPanel'
import usePublicCollection from '../hooks/usePublicCollection'

// TODO: make this global
Yup.setLocale({mixed: {required: 'Required'}})

export interface CheckoutValues {
  dripOptIn: boolean
  paymentMethod: {
    method: 'card' | 'echeck' | 'cash'
    useSaved: boolean
    saveSource: boolean
    card: Api.CreditCard | null
    echeck: {
      id: null | string
      routingNumber: string
      accountNumber: string
    }
  }
}

export type CheckoutFormik = ReturnType<typeof useFormik<CheckoutValues>>

export interface CheckoutPageProps {
  paymentIntentClientSecret: string | undefined
  onShouldReload?: () => void
}

const CheckoutPage: React.FC<CheckoutPageProps> = ({
  paymentIntentClientSecret,
  onShouldReload,
}: CheckoutPageProps) => {
  const media = WebUI.useMedia()
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const isDiscountNotAppliedRef = useRef(false)
  const [isStripeElementLoading, setIsStripeElementLoading] = useState(false)
  const [updateCartMutation, updateCartAsync] = useEnhancedUpdateCartMutation()
  const payForCart = usePayForCart(paymentIntentClientSecret)
  const growlActions = WebUI.useGrowlActions()
  const [authToken] = useAuthToken()
  const [initialCheckoutFormValues, updatePersistedCheckoutFormValues] =
    WebUI.useSessionStorage<CheckoutValues>(
      `${cart?.uuid}-${authToken?.id ?? '?'}-checkoutFormValues`,
      {
        dripOptIn: true,
        paymentMethod: {
          method: 'card',
          useSaved: false,
          saveSource: !!cart && cart.recurringTotal > 0,
          card: null,
          echeck: {
            id: null,
            routingNumber: '',
            accountNumber: '',
          },
        },
      },
    )
  const isLoggedIn = useIsAuthed()

  const elements = paymentIntentClientSecret ? useElements() : null

  const formik = useFormik<CheckoutValues>({
    validate: (values) => {
      let paymentMethodSchema = Yup.object({
        method: Yup.string(),
        useSaved: Yup.boolean(),
      })
      if (values.paymentMethod.method === 'echeck') {
        paymentMethodSchema = paymentMethodSchema.shape({
          echeck: values.paymentMethod.useSaved
            ? Yup.object({id: Yup.string().required()})
            : Yup.object({
                routingNumber: Yup.string().required(),
                accountNumber: Yup.string().required(),
              }),
        })
      } else if (
        values.paymentMethod.method === 'card' &&
        values.paymentMethod.useSaved
      ) {
        paymentMethodSchema = paymentMethodSchema.shape({
          card: Yup.object().required(),
        })
      }

      const validationSchema = Yup.object({
        paymentMethod: paymentMethodSchema,
      })

      try {
        validationSchema.validateSync(values, {abortEarly: false})
      } catch (_error: any) {
        if (_error.name !== 'ValidationError') {
          throw _error
        }

        const error: Yup.ValidationError = _error

        return error.inner.reduce<Record<string, string>>(
          (errors, currentError) => {
            if (currentError.path != null) {
              const path = Util.stringToPath(currentError.path) as [string]
              // biome-ignore lint/style/noParameterAssign:
              errors = Util.setAt(errors, path, currentError.message)
            }

            return errors
          },
          {},
        )
      }

      return {}
    },
    initialValues: initialCheckoutFormValues,
    onSubmit: async (values) => {
      growlActions.clear()

      if (isDiscountNotAppliedRef.current) {
        growlActions.show('error', {
          title: 'Caution!',
          body: 'We see you\'ve entered a discount code. Click "Apply" to receive your discount.',
        })
        return
      }

      await payForCart(values, {onShouldReload})
    },
  })

  const cartTotal = CartHelpers.getTotal({cart})
  const elementsRef = useLiveRef(elements)
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useUpdateEffect(() => {
    setIsStripeElementLoading(true)
    elementsRef.current
      ?.fetchUpdates()
      .finally(() => setIsStripeElementLoading(false))
  }, [cartTotal, updateCartMutation.isSuccess])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useUpdateEffect(() => {
    updatePersistedCheckoutFormValues(formik.values)
  }, [formik.values])

  const isQuickCheckout =
    !CartHelpers.isPaymentRequired({cart}) &&
    !publicCollection.shippingOptions.shipToEnabled

  return (
    <PayerContentContainerLayout>
      <PayerContentLayout className="max-w-[1200px]">
        <PayerMainBlock className="flex-[1_0_0px] [&_.CheckoutSteppedPanel[data-active=false][data-completed=false]]:opacity-50">
          <PayerAuthPanel />
          <ShippingMethodPanel />

          <form
            className="flex flex-col gap-4"
            onReset={formik.handleReset}
            onSubmit={formik.handleSubmit}
          >
            <AddressPanel hasPaymentIntent={!!paymentIntentClientSecret} />
            {!!paymentIntentClientSecret && (
              <>
                <PaymentMethodTypePanel formik={formik} />
                <PaymentMethodPanel
                  formik={formik}
                  onStripeElementLoadingChange={(isLoading) =>
                    setIsStripeElementLoading(isLoading)
                  }
                />
                <CoverFeePanel formik={formik} />
              </>
            )}
            {(!isQuickCheckout || isLoggedIn) && (
              <CheckoutInvoicePanel
                className="flex-0 data-[active=false]:pointer-events-none"
                hasPaymentIntent={!!paymentIntentClientSecret}
                formik={formik}
                checkoutDisabled={
                  isStripeElementLoading || updateCartMutation.isPending
                }
                onIsDiscountAppliedChange={(newIsDiscountNotApplied) => {
                  isDiscountNotAppliedRef.current = newIsDiscountNotApplied
                  updateCartAsync({body: {}})
                }}
              />
            )}
          </form>
          {!isQuickCheckout && (
            <div className="flex flex-row justify-center sm:justify-end">
              <img
                className="h-[1.5em]"
                alt="Powered by Stripe"
                src={PoweredByStripe}
              />
            </div>
          )}
        </PayerMainBlock>

        {media.sm && (
          <CartOverview
            className="max-w-[420px] flex-auto"
            paymentMethod={formik.values.paymentMethod.method}
          />
        )}
      </PayerContentLayout>
    </PayerContentContainerLayout>
  )
}

export default CheckoutPage
