import {tokens} from '@cheddarup/tokens'
import type {PresetsConfig} from 'tailwindcss/types/config'
import plugin from 'tailwindcss/plugin'
import animate from 'tailwindcss-animate'

const {
  white,
  gray150,
  gray300,
  accent500,
  accent400,
  accent200,
  aqua,
  aquaLight,
  primary,
  error,
  linkGhostText,
  linkGhostHoverText,
  ...deprecatedColorKeyValuePairs
} = tokens.colors

export default {
  content: [
    '../../apps/web/src/**/*.{js,ts,jsx,tsx,css}',
    '../../apps/web/index.html',
    '../../apps/storybook/.storybook/*.{js,ts,jsx,tsx,css}',
    '../../packages/web-ui/components/__stories__/**/*.{js,ts,jsx,tsx,css}',
    '../../packages/web-ui/components/**/*.{js,ts,jsx,tsx,css}',
    '../../packages/web-ui/icons/**/*.{js,ts,jsx,tsx,css}',
    '../../packages/web-ui/hooks/**/*.{js,ts,jsx,tsx,css}',
  ],
  theme: {
    colors: {
      ...deprecatedColorKeyValuePairs,
      transparent: 'transparent',
      current: 'currentColor',
      inherit: 'inherit',
      trueBlack: '#000000',
      trueWhite: '#FFFFFF',
      backdropBackground: '#33333366',
      'depr-grey': {
        '50': '#FAFAFA',
        '100': '#F4F4F4',
        '200': '#EEEEEE',
        '400': '#CCCCCC',
        '700': '#343330',
      },
      grey: {
        '100': '#F7F7F7',
        '200': '#F0F0F0',
        '300': '#DEDEDE',
        '400': '#C5C5C5',
        '500': '#6F6F6F',
        '600': '#505050',
        '700': '#303030',
        '800': '#241C15',
      },
      'depr-teal': {
        '100': '#EFF8FC',
      },
      teal: {
        '100': '#EAF4F5',
        '200': '#D7E8EA',
        '300': '#ABCDD2',
        '400': '#82AFBC',
        '500': '#5798A7',
        '600': '#257F91',
        '700': '#1F697D',
        '800': '#105061',

        '40': '#1F677A',
        '50': '#257A91',
        '70': '#B0DFE5',
        '80': '#D7EEF1',
        '90': '#E7F6F8',
      },
      orange: {
        '100': '#FFF0EA',
        '200': '#FFD7C6',
        '300': '#F7AD8E',
        '400': '#F58A5E',
        '500': '#F36D36',
        '600': '#E74D0E',
        '700': '#B73D0B',
        '800': '#872D08',
      },
      'depr-aqua': {
        '100': '#D7EFF2',
      },
      aqua: {
        '100': '#E9F2F7',
        '200': '#D2E5EF',
        '300': '#A6CDDF',
        '400': '#79B3CE',
        '500': '#4D9CBE',
        '600': '#2083AE',
        '700': '#1A628B',
        '800': '#134668',
      },
      fuchsia: {
        '100': '#FEF1FF',
        '200': '#E5D5E8',
        '300': '#CDB5D3',
        '400': '#BD9DC5',
        '500': '#AF84B6',
        '600': '#926A8F',
        '700': '#6B4F6D',
        '800': '#463549',
      },
      violet: {
        '100': '#F7F4FF',
        '200': '#DCD8E9',
        '300': '#BCB5D1',
        '400': '#A59CC2',
        '500': '#8F83B3',
        '600': '#72698F',
        '700': '#564F6B',
        '800': '#393448',
      },
      yellow: {
        '100': '#FFF9EA',
        '200': '#FDEFCE',
        '300': '#FCE3A6',
        '400': '#FBD475',
        '500': '#F9C442',
        '600': '#EDB01D',
        '700': '#BE8D17',
        '800': '#8E6A11',
      },
    },
    boxShadow: {
      ...tokens.shadows,
      inner: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
      none: '0 0 #0000',
      '100':
        '0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(0, 0, 0, 0.08)',
      '200':
        '0px 4px 6px -4px rgba(24, 39, 75, 0.12), 0px 8px 8px -4px rgba(0, 0, 0, 0.08)',
      '300':
        '0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(0, 0, 0, 0.08)',
      '400':
        '0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(0, 0, 0, 0.08)',
      '500':
        '0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(0, 0, 0, 0.10)',
      '600':
        '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
      '700':
        '0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(0, 0, 0, 0.12)',
      '800':
        '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(0, 0, 0, 0.14)',
      '801':
        '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 13px 50px -4px rgba(0, 0, 0, 0.30)',
    },

    extend: {
      flex: {
        0: '0 0 auto',
      },
      ringColor: {
        DEFAULT: '#257F91',
      },
      ringOpacity: {
        DEFAULT: '1',
      },
      ringWidth: {
        DEFAULT: '1px',
        3: '3px',
      },
      textColor: tokens.colors,
      fontFamily: tokens.fonts,
      spacing: tokens.space,
      fontSize: {
        // Based on https://alistapart.com/article/more-meaningful-typography/
        '0': '0',
        xs: '10px',
        '3xl': '22px',
        '5xl': '26px',
        '8xl': '38px',

        'ds-xs': ['12px', '150%'],
        'ds-sm': ['14px', '150%'],
        'ds-base': ['16px', '150%'],
        'ds-md': ['18px', '150%'],
        'ds-lg': ['20px', '150%'],
        'ds-xl': ['24px', '150%'],
        'ds-2xl': ['32px', '144%'],
        'ds-3xl': ['36px', '140%'],
        'ds-4xl': ['40px', '135%'],
        'ds-5xl': ['48px', '130%'],
        'ds-6xl': ['64px', '125%'],
        'ds-7xl': ['72px', '120%'],

        'h-8': ['16px', '20px'],
        'h-7': ['24px', '28px'],
        'h-6': ['30px', '33px'],
        'h-5': ['36px', '40px'],
        'h-4': ['40px', '45px'],
        'h-3': ['45px', '48px'],
        'h-2': ['50px', '55px'],
        'h-1': ['60px', '64px'],
      },
      lineHeight: tokens.lineHeights,
      borderRadius: {
        ...tokens.radii,
        DEFAULT: '4px',
        xl: '40px',
      },
      borderWidth: {
        '3': '3px',
      },
      borderColor: {
        DEFAULT: '#DEDEDE',
      },
      backdropBlur: {
        '10': '4px',
        '20': '8px',
        '30': '12px',
        '40': '20px',
      },
      zIndex: tokens.zIndices,
      height: {
        unset: 'unset',
        'input-xs': '34px',
        'input-sm': '38px',
        'input-md': '40px',
        'input-lg': '48px',
        'input-xl': '54px',
      },
      screens: {
        'max-2xl': {max: '1536px'},
        'max-xl': {max: '1280px'},
        'max-lg': {max: '1024px'},
        'max-md': {max: '768px'},
        'max-sm': {max: '640px'},
        xs: {max: '639px'},
      },
      keyframes: {
        fade: {
          '0%': {opacity: '0'},
          '100%': {opacity: '1'},
        },
        'caret-blink': {
          '0%,70%,100%': {opacity: '1'},
          '20%,50%': {opacity: '0'},
        },
        'pulse-alt': {
          '0%': {
            transform: 'scale(1, 1)',
          },
          '100%': {
            transform: 'scale(0.4, 0.4)',
          },
        },
      },
      animation: {
        fade: 'fade 200ms 1 forwards ease-in',
        'pulse-alt': 'pulse infinite 750ms alternate ease-in-out',
        'caret-blink': 'caret-blink 1.2s ease-out infinite',
      },
      aria: {
        'current-page': 'current="page"',
        haspopup: 'haspopup="true"',
        invalid: 'invalid="true"',
        'orientation-horizontal': 'orientation="horizontal"',
        'orientation-vertical': 'orientation="vertical"',
      },
    },
  },
  plugins: [
    plugin(({addUtilities}) => {
      addUtilities({
        '.scrollbar-hide': {
          /* IE and Edge */
          '-ms-overflow-style': 'none',

          /* Firefox */
          'scrollbar-width': 'none',

          /* Safari and Chrome */
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
      })
    }),
    animate,
  ],
  corePlugins: {
    preflight: false,
  },
} satisfies PresetsConfig
