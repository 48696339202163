import UpsellCardLowerFees from 'src/images/Upsell-Card_Lower-Fees.png'
import UpsellCardSignups from 'src/images/Upsell-Card_Sign-Ups.png'
import UpsellCardAffiliate from 'src/images/Upsell-Card_Affiliate.png'
import UpsellCardPos from 'src/images/Upsell-Card_POS.png'
import UpsellCardFlorette from 'src/images/Upsell-Card_Florette.png'
import UpsellCardYellowMan from 'src/images/Upsell-Card_Yellow-Man_Bkgd.png'
import UpsellCardIntegrations from 'src/images/Upsell-Card-Integrations.png'
import UpsellCardGift from 'src/images/Upsell-Card-Gift.png'
import UpsellCardGroupPage from 'src/images/Upsell-Card_Group-Page.png'
import UpsellCardRecurringPayments from 'src/images/Upsell-Card-Recurring-Payments.png'
import UpsellCardManager from 'src/images/Upsell-Card_Manager.png'
import UpsellCardTicketing from 'src/images/Upsell-Card-Ticketing.png'
import UpsellCardTemplateLibrary from 'src/images/Upsell-Card-TemplateLibrary.png'
import UpsellCardCustomReciepts from 'src/images/Upsell-Card-Custom-Reciepts.png'
import UpsellCardFundraising from 'src/images/Upsell-Card-Fundraising.png'
import UpsellCardFundraisingTeam from 'src/images/Upsell-Card-FundraisingTeam.png'
import UpsellCardItemVariations from 'src/images/Upsell-Card-Item-Variations.png'
import UpsellCardAccountWideReporting from 'src/images/Upsell-Card-Account-Wide-Reporting.png'
import UpsellCardShipping from 'src/images/Upsell-Card-Shipping.png'
import UpsellCardBusinessFeatures from 'src/images/Upsell-Card-Business-Features-3.png'
import WomanInBlueWithBadge from 'src/images/WomanInBlueWithBadge.jpg'
import type {UpsellCardProps} from 'src/components/UpsellCard'
import {Link} from 'src/components/Link'
import {PhosphorIcon} from '@cheddarup/web-ui'

export interface UpsellCardContent extends Omit<UpsellCardProps, 'to'> {
  to:
    | {
        pathname: string
        search: Record<string, string>
      }
    | string
}

interface SegmentPlansUpsellType {
  basic: UpsellCardContent[]
  pro: UpsellCardContent[]
  team: UpsellCardContent[]
}

interface UpsellCardsSegments {
  business: SegmentPlansUpsellType
  repeat: SegmentPlansUpsellType
  religious: SegmentPlansUpsellType
  friends: SegmentPlansUpsellType
  plans: SegmentPlansUpsellType
  other: SegmentPlansUpsellType
}

const RELIGIOUS_BASIC_AND_PRO: UpsellCardContent[] = [
  {
    className: 'bg-[#EEEEEE]',
    heading: 'Your group’s very own branded webpage',
    descriptionText: (
      <>
        Your{' '}
        <Link to="/group" variant="primary">
          Group Page
        </Link>{' '}
        gives your group access to your featured collections in ONE link and so
        much more:
      </>
    ),
    tiles: [
      'Add your logo and brand colors',
      'Highlight your mission and team',
      'Shout out your sponsors',
    ],
    imgSrc: UpsellCardGroupPage,
    ctaText: 'Your Group Page',
    to: {
      pathname: '/group',
      search: {
        utm_source: 'cheddarupprod',
        utm_medium: 'sidebar',
        utm_campaign: 'basic',
      },
    },
  },
  {
    className: 'bg-[#E9CDF2]',
    heading: 'Automate dues and donations',
    descriptionText:
      'Never miss a payment again! Give your community hassle-free auto-pay. Set it and forget it.',
    ctaText: 'Add Recurring Payments',
    imgSrc: UpsellCardRecurringPayments,
    to: {
      pathname: 'i/plans',
      search: {
        utm_source: 'cheddarupprod',
        utm_medium: 'sidebar',
        utm_campaign: 'group_page',
      },
    },
  },
  {
    className: 'bg-depr-grey-200',
    heading: 'Invite others to help manage your collections',
    descriptionText:
      'Tired of doing it all? Easily delegate tasks (and protect your data) by adding managers.',
    ctaText: 'Upgrade to Team',
    imgSrc: UpsellCardManager,
    tiles: [
      'Assign manager permissions',
      'Give managers their own login',
      'Control what collections they see',
      'Keep banking data private',
    ],
    to: {
      pathname: 'i/plans',
      search: {
        utm_source: 'cheddarupprod',
        utm_medium: 'sidebar',
        utm_campaign: 'managers',
      },
    },
  },
]

const RELIGIOUS_SEGMENTS: SegmentPlansUpsellType = {
  basic: [
    ...RELIGIOUS_BASIC_AND_PRO,
    {
      className: 'bg-depr-grey-200',
      heading: 'Customize your receipts',
      descriptionText:
        'Use custom receipts to share essential details with your payers.',
      tiles: [
        'Non-profit tax information',
        'Program or location details',
        'Extra note of gratitude',
      ],
      imgSrc: UpsellCardCustomReciepts,
      ctaText: 'Upgrade to Pro',
      to: {
        pathname: 'i/plans',
        search: {
          utm_source: 'cheddarupprod',
          utm_medium: 'sidebar',
          utm_campaign: 'custom_receipt',
        },
      },
    },
    {
      className: 'bg-[##8F81B4]',
      heading: 'Looking to fundraise?',
      descriptionText:
        'Maximize your efforts with Cheddar Up’s fundraising features.',
      ctaText: 'Upgrade',
      tiles: [
        'Recurring donations',
        'Fundraising goal',
        'eChecks (flat $0.59!)',
        'Custom receipts',
      ],
      imgSrc: UpsellCardFundraising,
      variant: 'light',
      linkToLearnMore: ' https://www.cheddarup.com/online-fundraisers',
      to: {
        pathname: 'i/plans',
        search: {
          utm_source: 'cheddarupprod',
          utm_medium: 'sidebar',
          utm_campaign: 'basic_fundraising',
        },
      },
    },
  ],
  pro: [
    ...RELIGIOUS_BASIC_AND_PRO,
    {
      className: 'bg-depr-grey-200',
      heading: 'Looking to fundraise?',
      descriptionText:
        'Maximize your efforts with Cheddar Up’s fundraising features.',
      ctaText: 'Upgrade to Team',
      imgSrc: UpsellCardFundraisingTeam,
      tiles: [
        'Recurring donations',
        'eChecks (flat $0.59!)',
        'Account-wide donor reporting',
      ],
      linkToLearnMore: ' https://www.cheddarup.com/online-fundraisers',
      to: {
        pathname: 'i/plans',
        search: {
          utm_source: 'cheddarupprod',
          utm_medium: 'sidebar',
          utm_campaign: 'basic_fundraising',
        },
      },
    },
  ],
  team: [],
}

const REPEAT_BASIC_AND_PRO: UpsellCardContent[] = [
  {
    className: 'bg-[#E9CDF2]',
    heading: 'Have an event? Try ticketing',
    descriptionText:
      'Seamlessly sell, scan and manage QR code tickets for all your in-person events.',
    linkToLearnMore: 'https://www.cheddarup.com/mobile-ticketing',
    ctaText: 'Upgrade to Team',
    imgSrc: UpsellCardTicketing,
    to: {
      pathname: 'i/plans',
      search: {
        utm_source: 'cheddarupprod',
        utm_medium: 'sidebar',
        utm_campaign: 'tickets',
      },
    },
  },
  {
    className: 'bg-depr-grey-200',
    heading: 'Account-Wide Reporting',
    descriptionText:
      'Visit our Report Center to see account-wide data for any time period.',
    tiles: [
      'See top-trending contributors',
      'Create and save reports',
      'Access a reconciliation report',
    ],
    imgSrc: UpsellCardAccountWideReporting,
    ctaText: 'Upgrade to Team',
    to: {
      pathname: 'i/plans',
      search: {
        utm_source: 'cheddarupprod',
        utm_medium: 'sidebar',
        utm_campaign: 'account_reports',
      },
    },
  },
]

const REPEAT_SEGMENTS: SegmentPlansUpsellType = {
  basic: [
    ...RELIGIOUS_SEGMENTS.basic,
    ...REPEAT_BASIC_AND_PRO,
    {
      heading: 'Looking for more?',
      descriptionText: 'Don’t miss these game-changing features:',
      ctaText: 'Upgrade',
      imgSrc: UpsellCardFlorette,
      tiles: [
        'Multiple Managers ',
        'Group landing page ',
        'QR code tickets ',
        'Waitlists ',
        'Items with variations ',
        'Custom receipts ',
        'Fundraising progress bar',
      ],
      to: {
        pathname: 'i/plans',
        search: {
          utm_source: 'cheddarupprod',
          utm_medium: 'sidebar',
          utm_campaign: 'basic',
        },
      },
    },
    {
      className: 'bg-[#E0C9B3]',
      heading: 'Selling different sizes or colors?',
      descriptionText:
        'Manage merchandise sales like a pro with item variations including pricing, quantity, images, and SKUs for effective inventory tracking.',
      ctaText: 'Upgrade to Pro',
      imgSrc: UpsellCardItemVariations,
      to: {
        pathname: 'i/plans',
        search: {
          utm_source: 'cheddarupprod',
          utm_medium: 'sidebar',
          utm_campaign: 'item_variations',
        },
      },
    },
  ],
  pro: [
    ...RELIGIOUS_SEGMENTS.pro,
    ...REPEAT_BASIC_AND_PRO,
    {
      className: 'bg-depr-grey-200',
      heading: 'Looking for more?',
      descriptionText: 'Don’t miss these game-changing features:',
      ctaText: 'Upgrade to Team',
      imgSrc: UpsellCardYellowMan,
      tiles: [
        'Add managers ',
        'Create a group landing page ',
        'Sell QR code tickets ',
        'Create waitlists ',
        'Access account-wide reporting',
      ],
      to: {
        pathname: 'i/plans',
        search: {
          utm_source: 'cheddarupprod',
          utm_medium: 'sidebar',
          utm_campaign: 'pro',
        },
      },
    },
  ],
  team: [],
}

const ALL_PLANS_UPSELLS: UpsellCardContent[] = [
  {
    className: 'bg-[#80CDDD]',
    heading: 'Need sign ups?',
    descriptionText:
      'Simplify your sign ups! Effortlessly manage snack schedules, volunteer rosters, and more.',
    ctaText: 'Learn More',
    imgSrc: UpsellCardSignups,
    to: 'https://www.cheddarup.com/sign-ups?utm_source=cheddarupprod&utm_medium=sidebar&utm_campaign=signups',
  },
  {
    className: 'bg-[#8F81B4]',
    heading: 'Love Cheddar Up?',
    descriptionText:
      'Get rewarded for sharing Cheddar Up! Earn a 50% referral fee for every subscriber you refer.',
    ctaText: 'Learn More',
    imgSrc: UpsellCardAffiliate,
    variant: 'light',
    to: 'https://www.cheddarup.com/affiliate?utm_source=cheddarupprod&utm_medium=sidebar&utm_campaign=affiliate',
  },
  {
    className: 'bg-depr-grey-200',
    heading: 'In-Person Payments',
    descriptionText:
      'Use our card readers or go hardware-less with Tap on Mobile. Keep all transactions (and funds!) in one spot.',
    ctaText: 'Learn More',
    imgSrc: UpsellCardPos,
    to: 'https://www.cheddarup.com/point-of-sale?utm_source=cheddarupprod&utm_medium=sidebar&utm_campaign=pos',
  },
  {
    className: 'bg-[#F6EBFA]',
    heading: 'Send a Gift Card',
    descriptionText:
      'Spread happiness! Send collected funds instantly with a gift card link. Select amount, add a custom message and we’ll do the rest.',
    ctaText: 'Learn About Gifting',
    imgSrc: UpsellCardGift,
    to: 'https://www.cheddarup.com/group-gifting?utm_source=cheddarupprod&utm_medium=sidebar&utm_campaign=gifting',
  },
  {
    heading: 'Start with a Template',
    descriptionText:
      'Our Template Library offers dozens of templates to make creating your collection even easier. Discover fundraising ideas, tips and more!',
    ctaText: 'Explore the Template Library',
    imgSrc: UpsellCardTemplateLibrary,
    to: '/templates',
  },
  {
    className: 'bg-[#F4F4F4] [&_>_img]:w-auto [&_>_img]:self-center',
    heading: 'Integrations that supercharge your productivity',
    descriptionText:
      'Integrations are like your very own virtual assistant; they automate repetitive tasks between Cheddar Up and other apps—with no coding!',
    ctaText: 'Learn About Integrations',
    imgSrc: UpsellCardIntegrations,
    to: 'https://www.cheddarup.com/integrations?utm_source=cheddarupprod&utm_medium=sidebar&utm_campaign=integrations',
  },
]

const UPSELL_CARDS_CONTENT: UpsellCardsSegments = {
  religious: RELIGIOUS_SEGMENTS,
  repeat: REPEAT_SEGMENTS,
  business: {
    basic: [
      {
        className: 'bg-[#F9C442]',
        heading: 'Add shipping to your online sales!',
        descriptionText:
          'Save time and easily gather shipping information at checkout. Offer shipping or free local pickup. Quickly print USPS labels directly from Cheddar Up!',
        ctaText: 'Upgrade to Pro',
        imgSrc: UpsellCardShipping,
        to: {
          pathname: 'i/plans',
          search: {
            utm_source: 'cheddarupprod',
            utm_medium: 'sidebar',
            utm_campaign: 'shipping',
          },
        },
      },
      {
        className: 'bg-depr-grey-200',
        heading: 'Sell like a pro',
        descriptionText:
          'Don’t miss these features that will make your online sale stand out:',
        tiles: [
          'Shipping at checkout',
          'VIP password ',
          'Start and end times ',
          'Taxes',
          'Discount Codes',
        ],
        imgSrc: UpsellCardBusinessFeatures,
        ctaText: 'Upgrade to Pro',
        to: {
          pathname: 'i/plans',
          search: {
            utm_source: 'cheddarupprod',
            utm_medium: 'sidebar',
            utm_campaign: 'business',
          },
        },
      },
    ],
    pro: [],
    team: [],
  },
  friends: {
    basic: [
      {
        className: 'bg-[#8F81B4]',
        heading: 'Show your goal and raise more',
        descriptionText:
          'Boost your fundraising with a real-time progress bar on your page for easy tracking and contributor engagement.',
        ctaText: 'Upgrade to Pro',
        variant: 'light',
        imgSrc: UpsellCardFundraising,
        to: {
          pathname: 'i/plans',
          search: {
            utm_source: 'cheddarupprod',
            utm_medium: 'sidebar',
            utm_campaign: 'fundraising_goal',
          },
        },
      },
    ],
    pro: [],
    team: [],
  },
  plans: {
    basic: [
      {
        className: 'bg-[#E0C9B3]',
        heading: 'Upgrade for lower fees',
        descriptionText:
          'Upgrade to Pro or Team plans for lower credit card and eCheck fees for your payers!',
        ctaText: 'Upgrade',
        imgSrc: UpsellCardLowerFees,
        to: {
          pathname: 'i/plans',
          search: {
            utm_source: 'cheddarupprod',
            utm_medium: 'sidebar',
            utm_campaign: 'echeck',
          },
        },
      },
      ...ALL_PLANS_UPSELLS,
    ],
    pro: ALL_PLANS_UPSELLS,
    team: ALL_PLANS_UPSELLS,
  },
  other: {pro: [], team: [], basic: []},
}

const VERIFIED_EIN_UPSELL: UpsellCardContent = {
  className:
    'bg-gradient-to-r from-[#01ADF9] via-[#04B0FA] via-[#38BCF9] via-[#3AB8F9] to-[#42BFFB]',
  variant: 'light',
  heading: (
    <div className="flex items-center gap-2">
      <PhosphorIcon color="#F9C442" icon="seal-check-fill" width={30} />
      <span className="font-accentAlt font-normal text-ds-xl">
        You deserve a badge{' '}
      </span>
    </div>
  ),
  descriptionText:
    'As a Verified Non-Profit, you can showcase your status while giving your community an incentive to contribute more:',
  tiles: [
    'Display your badge in a customized footer',
    'Give your payers the ability to deduct donations and pay less in taxes with instant receipts',
  ],
  imgSrc: WomanInBlueWithBadge,
  ctaText: 'Upgrade to Team',
  to: {
    pathname: 'i/plans',
    search: {
      utm_source: 'cheddarupprod',
      utm_medium: 'sidebar',
      utm_campaign: 'ein',
    },
  },
}

export {UPSELL_CARDS_CONTENT, VERIFIED_EIN_UPSELL}
