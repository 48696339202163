import React, {useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import UpsellCardCustomReciepts from 'src/images/Upsell_Card_Custom_Reciepts.png'
import UpsellCardYellowMan from 'src/images/Upsell_Card_Yellow_Man.png'
import {useLocation} from 'react-router-dom'
import * as Util from '@cheddarup/util'
import {UpsellCard} from 'src/components/UpsellCard'

import {ContactListsNav, ContactListsNavInstance} from './ContactListsNav'
import {ContactTableView} from './ContactTableView'
import {LinkButton} from '../../components/LinkButton'
import CollectionsLayout from '../../components/CollectionsLayout'

const AddressBookPage = () => {
  const media = WebUI.useMedia()
  const location = useLocation()
  const contactListsNavRef = useRef<ContactListsNavInstance>(null)
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const {data: session} = api.auth.session.useQuery()

  const contactTableView = (
    <ContactTableView
      className="min-w-0 flex-auto overflow-y-auto"
      onCreateList={() => {
        if (contactListsNavRef.current) {
          return contactListsNavRef.current.createContactList()
        }

        throw new Error('`contactListsNavRef` is nil')
      }}
    />
  )

  return (
    <CollectionsLayout className="max-h-full sm:[&_.CollectionsLayout-contentContainer]:p-0">
      <div className="flex min-h-0 grow flex-col lg:flex-row">
        <div
          className={
            'flex min-w-0 grow flex-col bg-trueWhite sm:flex-row sm:border-r'
          }
        >
          <ContactListsNav
            ref={contactListsNavRef}
            className="min-w-0 shrink-0 grow-0 basis-[264px]"
            openModal={() => modalRef.current?.show()}
          />
          <WebUI.Separator
            variant="primary"
            orientation={media.sm ? 'vertical' : 'horizontal'}
          />
          {media.sm ? (
            contactTableView
          ) : (
            <WebUI.Modal
              ref={modalRef}
              className="[&_>_.ModalContentView]:overflow-hidden"
              aria-label="Contact List"
              preventBodyScroll={false}
              initialVisible={false}
            >
              <WebUI.ModalCloseButton />
              {contactTableView}
            </WebUI.Modal>
          )}
        </div>

        <div
          className={
            'flex w-[min(100%,380px)] xs:w-full flex-col gap-7 p-6 xs:px-0 xs:[&_.Panel]:border-x-0'
          }
        >
          <MessageCenterPanel />
          {session?.capabilities.plan === 'free' && media.lg && (
            <UpsellCard
              className="bg-depr-grey-200"
              heading="Customize your receipts"
              descriptionText="Use custom receipts to share essential details with your payers."
              ctaText="Upgrade to Pro"
              imgSrc={UpsellCardCustomReciepts}
              tiles={[
                'Non-profit tax information',
                'Program or location details',
                'Extra note of gratitude',
              ]}
              to={{
                pathname: 'i/plans',
                search: Util.mergeSearchParams(location.search, {
                  utm_source: 'cheddarupprod',
                  utm_medium: 'sidebar',
                  utm_campaign: 'custom_receipt',
                }),
              }}
            />
          )}
          {session?.capabilities.plan === 'pro' && media.lg && (
            <UpsellCard
              className="bg-depr-grey-200"
              heading="Looking for more?"
              descriptionText="Don’t miss these game-changing features:"
              ctaText="Upgrade to Team"
              imgSrc={UpsellCardYellowMan}
              tiles={[
                'Add managers',
                'Create a group landing page',
                'Sell QR code tickets',
                'Create waitlists',
                'Access account-wide reporting',
              ]}
              to={{
                pathname: 'i/plans',
                search: Util.mergeSearchParams(location.search, {
                  utm_source: 'cheddarupprod',
                  utm_medium: 'sidebar',
                  utm_campaign: 'pro',
                }),
              }}
            />
          )}
        </div>
      </div>
    </CollectionsLayout>
  )
}

// MARK: – MessageCenterPanel

const MessageCenterPanel = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.Panel
    className={WebUI.cn('flex flex-col gap-3 px-8 py-6', className)}
    {...restProps}
  >
    <WebUI.Heading className="text-ds-sm uppercase" as="h2">
      Message Center
    </WebUI.Heading>

    <WebUI.Text className="font-light text-ds-sm">
      Send a message to your payers, schedule followups and track invitations.
    </WebUI.Text>
    <LinkButton className="self-start" variant="default" to="message-center">
      Send Message
    </LinkButton>
  </WebUI.Panel>
)

export default AddressBookPage
