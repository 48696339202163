import React from 'react'
import {cn} from '../../utils'

export interface ColorThumbProps extends React.ComponentPropsWithoutRef<'div'> {
  color?: string
}

export const ColorThumb = React.forwardRef<HTMLDivElement, ColorThumbProps>(
  (
    {
      color = 'transparent',
      role = 'presentation',
      className,
      style,
      ...restProps
    },
    forwardedRef,
  ) => (
    <div
      ref={forwardedRef}
      role={role}
      className={cn(
        'absolute h-5 w-5 rounded-full border-2 border-trueWhite shadow-inner forced-color-adjust-none',
        className,
      )}
      style={{
        backgroundColor: color,
        ...style,
      }}
      {...restProps}
    />
  ),
)
