import {CubeProvider} from '@cubejs-client/react'
import {
  NumberParam,
  NumericArrayParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import * as Util from '@cheddarup/util'
import React, {useMemo, useState} from 'react'
import type {
  Filter as CubeFilter,
  Query as CubeQuery,
  TimeDimensionRanged,
} from '@cubejs-client/core'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {ParammedTabs} from 'src/components/ParammedTabs'
import {fetchAndSave} from 'src/helpers/api-helpers'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'
import {getLocalTimeZone, today} from '@internationalized/date'
import ReconciliationReportImage from 'src/images/Reconciliation-Report.png'

import {
  CubeReportTopCollections,
  CubeReportTopItems,
  CubeReportTopPayers,
  CubeReportWithdrawals,
} from './components/CubeTopLists'
import {
  DateRangeValue,
  ReportOverviewDateRangePicker,
  ReportOverviewHeader,
  ReportTotalCollected,
  ReportTotalPayers,
  ReportTotalPayments,
  getDateRangeFromTimeDimension,
} from './components/ReportOverview'
import {
  CreateCubeReportExportButton,
  CubeReportFormPrompt,
  CubeReportRow,
  CubeReportSummary,
  CubeReportTable,
  reportTypeToCubeTableColumns,
} from './components/CubeReports'
import {
  CollectionDropdownSelect,
  CollectionTagsCloud,
  CollectionsSelect,
  useCollectionsSelect,
  useCollectionsWithPayments,
} from './components/CollectionsSelect'
import {LinkButton} from '../../components/LinkButton'
import CollectionsLayout from '../../components/CollectionsLayout'
import {
  CubeFilterField,
  CubeFilters,
  CubeFiltersProps,
} from '../../components/CubeFilters'
import {PAYMENT_METHOD_LABELS} from '../collection/constants'
import {SearchForm} from '../../components'
import TeamUpgradeOverviewBackgroundImage from '../../images/team-upgrade-overview-background-image.png'
import TeamUpgradePaymentsImage from '../../images/team-upgrade-payments-image.png'
import TeamUpgradePayersImage from '../../images/team-upgrade-payers-image.png'
import TeamUpgradeWithdrawalsImage from '../../images/team-upgrade-withdrawals-image.png'
import TeamUpgradeVisitorsImage from '../../images/team-upgrade-visitors-image.png'
import {
  CubeObject,
  CubeTable,
  CubeTableColumn,
} from '../../components/CubeTable'
import {useCubeApi} from '../../hooks/cube'

const ReportsPage = () => {
  const media = WebUI.useMedia()
  const [managerRoleId] = useManagerRoleId()
  const [selectedCubeReportId, setSelectedCubeReportId] = useQueryParam(
    'reportId',
    NumberParam,
  )
  const [_selectedCollectionIds, setSelectedCollectionIds] = useQueryParam(
    'collectionIds',
    withDefault(NumericArrayParam, [] as Array<number | null>),
  )
  const [accountWideReportLoading, setAccountWideReportLoading] =
    useState(false)
  const [reconciliationReportLoading, setReconciliationReportLoading] =
    useState(false)
  const {data: selectedCubeReport} = api.cube.reportDetail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        reportId: selectedCubeReportId!,
      },
    },
    {
      enabled: !!selectedCubeReportId,
    },
  )
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })
  const cubeApi = useCubeApi()

  const selectedCollectionIds = useMemo(
    () => _selectedCollectionIds.filter((cId) => cId != null),
    [_selectedCollectionIds],
  )

  const growlActions = WebUI.useGrowlActions()
  const isSubscribedToTeam = !!managerRoleId || !!isSubscribedToTeamQuery.data

  return (
    <CollectionsLayout className="max-h-full sm:[&_.CollectionsLayout-contentContainer]:p-0">
      <ParammedTabs
        className="min-h-0 grow"
        orientation={media.sm ? 'vertical' : 'horizontal'}
        variant={media.sm ? 'default' : 'underlined'}
        defaultPaneKey="overview"
        onChangeCurrentId={() => setSelectedCubeReportId(undefined)}
      >
        {(tabs) => (
          <WebUI.HStack className="w-full grow">
            <WebUI.VStack
              className={
                'min-w-0 grow bg-trueWhite sm:flex-row [&_>_.TabPanel]:min-h-0 [&_>_.TabPanel]:min-w-0 [&_>_.TabPanel]:grow [&_>_.TabPanel]:border-r [&_>_.TabPanel_>_*]:max-h-full'
              }
            >
              <WebUI.TabList
                aria-label="Reports navigation"
                className="mx-4 xl:mx-0 xl:min-w-[160px] xl:p-4 xl:[&_>_.Tab]:rounded"
              >
                {media.xl && (
                  <WebUI.Text className="my-4 ml-2 hidden text-ds-sm uppercase">
                    Reports
                  </WebUI.Text>
                )}
                <WebUI.Tab id="overview">Overview</WebUI.Tab>
                <WebUI.Tab id="payments">Payments</WebUI.Tab>
                <WebUI.Tab id="payers">Payers</WebUI.Tab>
                <WebUI.Tab id="withdrawals">Withdrawals</WebUI.Tab>
                <WebUI.Tab id="visitors">Visitors</WebUI.Tab>
              </WebUI.TabList>
              {cubeApi && (
                <CubeProvider cubeApi={cubeApi}>
                  {selectedCubeReport ? (
                    <CubeReportDetailView
                      className="grow border-r"
                      cubeReport={selectedCubeReport}
                      onViewAll={() => setSelectedCubeReportId(undefined)}
                    />
                  ) : (
                    <CollectionsSelect
                      initialSelectedCollectionIds={selectedCollectionIds}
                      onSelectedCollectionIdsChange={(ids) =>
                        setSelectedCollectionIds(ids)
                      }
                    >
                      <WebUI.TabPanel tabId="overview">
                        <ReportOverviewView />
                      </WebUI.TabPanel>
                      <WebUI.TabPanel tabId="payments">
                        <ReportPaymentsView />
                      </WebUI.TabPanel>
                      <WebUI.TabPanel tabId="payers">
                        <ReportPayersView />
                      </WebUI.TabPanel>
                      <WebUI.TabPanel tabId="withdrawals">
                        <ReportWithdrawalsView />
                      </WebUI.TabPanel>
                      <WebUI.TabPanel tabId="visitors">
                        <ReportVisitorsView />
                      </WebUI.TabPanel>
                    </CollectionsSelect>
                  )}
                </CubeProvider>
              )}
            </WebUI.VStack>
            {media.xl && (
              <WebUI.VStack className="min-h-0 min-w-[380px] gap-5 overflow-y-auto *:flex-0 lg:w-[380px] lg:p-6">
                {isSubscribedToTeam ? (
                  <>
                    <AccountReportPanel
                      heading="Account-wide Report"
                      description="Download a spreadsheet summary with aggregated data across your entire account, including all payments, collections, withdrawals and more."
                      onDownload={async (selectedDateRange) => {
                        try {
                          setAccountWideReportLoading(true)
                          await fetchAndSave({
                            url: `users/exports/account.xlsx?interval_start=${Util.convertDateToSeconds(
                              selectedDateRange[0],
                            )}&interval_end=${Util.convertDateToSeconds(
                              selectedDateRange[1],
                            )}`,
                            fileName: 'account.xlsx',
                            config: {
                              headers: {'X-Manager-Account-Id': managerRoleId},
                            },
                          })
                        } catch {
                          // noop
                        } finally {
                          setAccountWideReportLoading(false)
                        }
                      }}
                      loading={accountWideReportLoading}
                    />
                    <AccountReportPanel
                      heading="Reconciliation Report"
                      description="Download this spreadsheet report for a high-level summary of the ins and outs of funds in your account based on the time period you select."
                      validateStartDateAgainstToday
                      onDownload={async (selectedDateRange) => {
                        try {
                          setReconciliationReportLoading(true)
                          await api.exports.reconciliation.fetch({
                            body: {
                              interval_start: Util.convertDateToSeconds(
                                selectedDateRange[0],
                              ),
                              interval_end: Util.convertDateToSeconds(
                                selectedDateRange[1],
                              ),
                            },
                            headers: {
                              'X-Manager-Account-Id': managerRoleId,
                            },
                          })
                          growlActions.show('success', {
                            title: 'Success',
                            body: 'The report has been sent to your inbox. It can take up to 30 minutes.',
                          })
                        } catch {
                          // noop
                        } finally {
                          setReconciliationReportLoading(false)
                        }
                      }}
                      loading={reconciliationReportLoading}
                    />
                    <CubeReportsPanel
                      onReportView={(cubeReport) =>
                        setSelectedCubeReportId(cubeReport.id)
                      }
                    />
                  </>
                ) : (
                  <>
                    <UpsellPanel
                      className={WebUI.cn(
                        'min-w-[320px]',
                        tabs.selectedId === 'overview' && 'bg-cover',
                      )}
                      style={{
                        backgroundImage: `url(${TeamUpgradeOverviewBackgroundImage})`,
                      }}
                      heading={
                        {
                          overview: 'Account-Wide Reporting',
                          payments: 'View payments across multiple collections',
                          payers: 'Find out which payers contribute most',
                          withdrawals:
                            'See a history of all account withdrawals',
                          visitors:
                            'Track visitors: payers and window shoppers',
                        }[tabs.selectedId ?? '']
                      }
                      headingImageSrc={
                        {
                          payments: TeamUpgradePaymentsImage,
                          payers: TeamUpgradePayersImage,
                          withdrawals: TeamUpgradeWithdrawalsImage,
                          visitors: TeamUpgradeVisitorsImage,
                        }[tabs.selectedId ?? '']
                      }
                    />
                    {!['payers', 'visitors'].includes(
                      tabs.selectedId ?? '',
                    ) && (
                      <UpsellPanel
                        className="min-w-[320px]"
                        heading="Access your Reconciliation Report"
                        headingImageSrc={ReconciliationReportImage}
                        descriptionText="Download this spreadsheet report for a high-level summary of the ins and outs of funds in your account based on the time period you select."
                        showTiles={false}
                      />
                    )}
                  </>
                )}
              </WebUI.VStack>
            )}
          </WebUI.HStack>
        )}
      </ParammedTabs>
    </CollectionsLayout>
  )
}

// MARK: – ReportOverviewView

const ReportOverviewView = (props: React.ComponentPropsWithoutRef<'div'>) => {
  const defaultTimeDimension = 'yearToDate'

  const media = WebUI.useMedia()
  const {collections} = useCollectionsWithPayments()
  const {selectedCollections, selectionMode} = useCollectionsSelect()
  const [dateRange, setDateRange] = useState(
    getDateRangeFromTimeDimension(defaultTimeDimension),
  )

  const filters = [
    selectedCollections.length > 0
      ? {
          member: 'Collections.id',
          operator: 'equals' as const,
          values: selectedCollections.map((sc) => String(sc['Collections.id'])),
        }
      : undefined,
  ].filter((f) => !!f)

  return (
    <WebUI.VStack {...props}>
      <ReportOverviewHeader
        className="border-b py-6"
        defaultTimeDimension={defaultTimeDimension}
        dateRange={dateRange}
        onDateRangeChange={(newDateRange) => setDateRange(newDateRange)}
      />
      <WebUI.VStack className="gap-6 overflow-y-auto py-6">
        <WebUI.VStack
          className={
            'mx-5 min-w-[min(100%,470px)] flex-0 shrink-0 basis-auto overflow-x-auto sm:flex-row [&_>_div]:grow [&_>_div]:p-6'
          }
          as={WebUI.Panel}
        >
          <ReportTotalCollected filters={filters} dateRange={dateRange} />
          <WebUI.Separator
            orientation={media.sm ? 'vertical' : 'horizontal'}
            variant="primary"
          />
          <ReportTotalPayments filters={filters} dateRange={dateRange} />
          <WebUI.Separator
            orientation={media.sm ? 'vertical' : 'horizontal'}
            variant="primary"
          />
          <ReportTotalPayers filters={filters} dateRange={dateRange} />
        </WebUI.VStack>
        {selectionMode === 'multiple' && collections.length > 0 && (
          <WebUI.VStack className="mx-5 gap-6 *:min-w-0 *:flex-[1_0] xl:flex-row [&_>_.Stack_>_.Panel]:p-6">
            <WebUI.VStack className="gap-6">
              <CubeReportTopPayers
                filters={filters}
                timeDimensions={[{dimension: 'Payments.createdAt', dateRange}]}
              />
              <CubeReportWithdrawals filters={filters} dateRange={dateRange} />
            </WebUI.VStack>
            <WebUI.VStack className="gap-6">
              {selectedCollections.length !== 1 && (
                <CubeReportTopCollections
                  filters={filters}
                  dateRange={dateRange}
                />
              )}
              <CubeReportTopItems filters={filters} dateRange={dateRange} />
            </WebUI.VStack>
          </WebUI.VStack>
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – ReportPaymentsView

const ReportPaymentsView = (props: React.ComponentPropsWithoutRef<'div'>) => {
  const [managerRoleId] = useManagerRoleId()
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })
  const {selectionMode, selectedCollections} = useCollectionsSelect()
  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState<CubeFilter[]>([])
  const [timeDimensions, setTimeDimensions] = useState<TimeDimensionRanged[]>(
    [],
  )

  const isSubscribedToTeam = !!managerRoleId || !!isSubscribedToTeamQuery.data

  const query: CubeQuery = useMemo(() => {
    const searchValueAsNum = Number.parseFloat(searchValue.replace(/\D/g, ' '))
    return {
      dimensions: [
        'Payments.id',
        'Collections.id',
        'Payments.total',
        'Payments.paymentMethod',
        'Payments.createdAt',
        'Payments.status',
        'Customers.email',
        'Customers.nameSearch',
      ],
      measures: ['Customers.name'],
      filters: [
        selectedCollections.length > 0
          ? {
              dimension: 'Collections.id',
              operator: 'equals',
              values: selectedCollections.map((sc) =>
                String(sc['Collections.id']),
              ),
            }
          : undefined,
        {
          dimension: 'Payments.id',
          operator: 'set',
        },
        ...filters,
        searchValue.length > 0
          ? {
              or: [
                {
                  dimension: 'Customers.nameSearch',
                  operator: 'contains',
                  values: [searchValue],
                },
                {
                  dimension: 'Customers.email',
                  operator: 'contains',
                  values: [searchValue],
                },
                {
                  and: [
                    {
                      dimension: 'Payments.total',
                      operator: 'gte',
                      values: [String(searchValueAsNum - 5)],
                    },
                    {
                      dimension: 'Payments.total',
                      operator: 'lte',
                      values: [String(searchValueAsNum + 5)],
                    },
                  ],
                },
              ],
            }
          : undefined,
      ].filter((f): f is CubeFilter => !!f),
      timeDimensions,
    }
  }, [filters, searchValue, selectedCollections, timeDimensions])

  const initialSortingState = useMemo(
    () => [{id: 'Payments.createdAt', desc: true}],
    [],
  )

  return (
    <WebUI.VStack {...props}>
      <WebUI.VStack className="gap-6 p-6">
        <WebUI.VStack className="gap-0_5">
          <WebUI.Heading as="h2">Payments</WebUI.Heading>
          <WebUI.Text className="font-light text-ds-xs italic">
            It can take up to 24 hours for new activity to be reflected below.
          </WebUI.Text>
        </WebUI.VStack>
        <WebUI.VStack className="items-stretch justify-start gap-3 overflow-auto sm:flex-row sm:items-start sm:justify-between">
          <WebUI.HStack className="gap-3">
            <CollectionDropdownSelect />
            <CubeFiltersPopover
              aria-label="Payment filters"
              filters={filters}
              timeDimensions={timeDimensions}
              onFiltersApply={(newFilters) => {
                setFilters(newFilters.filters)
                setTimeDimensions(newFilters.timeDimensions)
              }}
            >
              {({filters: transientFilters}) => (
                <>
                  <CubeFilterField dimension="Payments.total" />
                  <CubeFilterField dimension="Payments.createdAt" />
                  <CubeFilterField dimension="Payments.paymentMethod" />
                  <CubeFilterField dimension="Payments.status" />
                  {(transientFilters['Payments.paymentMethod']?.values.includes(
                    'card',
                  ) ||
                    transientFilters['Payments.paymentMethod']?.values.includes(
                      'cash',
                    )) && (
                    <CubeFilterField dimension="Payments.isPointOfSale" />
                  )}
                </>
              )}
            </CubeFiltersPopover>
          </WebUI.HStack>

          <WebUI.HStack className="gap-3">
            <SearchForm
              className="h-9 w-[320px]"
              size="compact"
              placeholder="Search by amount, payer name or email"
              onSubmit={(values) => setSearchValue(values.term)}
            />
            {isSubscribedToTeam && (
              <CubeReportFormPrompt
                reportType="payments"
                query={query}
                countMeasure="Payments.count"
              />
            )}
            <CreateCubeReportExportButton
              query={query}
              countMeasure="Payments.count"
            />
          </WebUI.HStack>
        </WebUI.VStack>

        {selectionMode === 'multiple' && selectedCollections.length > 0 && (
          <>
            <WebUI.Separator variant="primary" />
            <CollectionTagsCloud />
          </>
        )}
      </WebUI.VStack>

      <CubeTable
        className={
          'min-h-0 border-t [&_.TableView-headerGroup]:px-3 [&_.TableViewRow]:px-3'
        }
        initialState={{sorting: initialSortingState}}
        query={query}
        countMeasure="Payments.count"
        columns={reportTypeToCubeTableColumns.payments}
        getRowProps={(row) =>
          ({
            as: LinkButton,
            preserveSearch: true,
            className: 'px-0 h-auto [font-size:inherit]',
            to: `collection/${row.original['Collections.id']}/payment/${
              row.original['Payments.id'] ?? -1
            }`,
          }) as any
        }
      />
    </WebUI.VStack>
  )
}

// MARK: – ReportPayersView

const ReportPayersView = (props: React.ComponentPropsWithoutRef<'div'>) => {
  const [managerRoleId] = useManagerRoleId()
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })
  const {selectionMode, selectedCollections} = useCollectionsSelect()
  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState<CubeFilter[]>([])
  const [timeDimensions, setTimeDimensions] = useState<TimeDimensionRanged[]>(
    [],
  )
  const [selectedPayer, setSelectedPayer] = useState<CubeObject | null>(null)

  const isSubscribedToTeam = !!managerRoleId || !!isSubscribedToTeamQuery.data

  const initialSortingState = useMemo(
    () => [{id: 'Customers.lastSeenAt', desc: true}],
    [],
  )

  const query: CubeQuery = useMemo(() => {
    const searchValueAsNum = Number.parseFloat(searchValue.replace(/\D/g, ' '))
    return {
      dimensions: ['Customers.email', 'Customers.nameSearch'],
      measures: [
        'Customers.name',
        'Customers.totalPaid',
        'Customers.paymentCount',
        'Customers.firstSeenAt',
        'Customers.lastSeenAt',
      ],
      filters: [
        selectedCollections.length > 0
          ? {
              dimension: 'Collections.id',
              operator: 'equals',
              values: selectedCollections.map((sc) =>
                String(sc['Collections.id']),
              ),
            }
          : undefined,
        {
          dimension: 'Customers.paymentCountDimension',
          operator: 'gt',
          values: ['0'],
        },
        ...filters,
        // HACK: search won't work for users with number-only emails
        Number.isNaN(searchValueAsNum) && searchValue.length > 0
          ? {
              or: [
                {
                  dimension: 'Customers.nameSearch',
                  operator: 'contains',
                  values: [searchValue],
                },
                {
                  dimension: 'Customers.email',
                  operator: 'contains',
                  values: [searchValue],
                },
              ],
            }
          : undefined,

        Number.isNaN(searchValueAsNum)
          ? undefined
          : {
              or: [
                {
                  dimension: 'Customers.nameSearch',
                  operator: 'contains',
                  values: [searchValue],
                },
                {
                  and: [
                    {
                      dimension: 'Customers.totalPaid',
                      operator: 'gte',
                      values: [String(searchValueAsNum - 5)],
                    },
                    {
                      dimension: 'Customers.totalPaid',
                      operator: 'lte',
                      values: [String(searchValueAsNum + 5)],
                    },
                  ],
                },
              ],
            },
      ].filter((f): f is CubeFilter => !!f),
      timeDimensions,
    }
  }, [filters, timeDimensions, searchValue, selectedCollections])

  if (selectedPayer) {
    return (
      <PayerPaymentsDetailView
        payer={selectedPayer}
        onGoBack={() => setSelectedPayer(null)}
        {...props}
      />
    )
  }

  return (
    <WebUI.VStack {...props}>
      <WebUI.VStack className="gap-6 p-6">
        <WebUI.VStack className="gap-0_5">
          <WebUI.Heading as="h2">Payers</WebUI.Heading>
          <WebUI.Text className="font-light text-ds-xs italic">
            It can take up to 24 hours for new activity to be reflected below.
          </WebUI.Text>
        </WebUI.VStack>
        <WebUI.VStack className="items-stretch justify-start gap-3 overflow-auto sm:flex-row sm:items-start sm:justify-between">
          <WebUI.HStack className="gap-3">
            <CollectionDropdownSelect />
            <CubeFiltersPopover
              aria-label="Payers filters"
              filters={filters}
              timeDimensions={timeDimensions}
              onFiltersApply={(newFilters) => {
                setFilters(newFilters.filters)
                setTimeDimensions(newFilters.timeDimensions)
              }}
            >
              <CubeFilterField dimension="Customers.totalPaid" />
              <CubeFilterField dimension="Customers.paymentCount" />
              <CubeFilterField dimension="Customers.firstVisit" />
              <CubeFilterField dimension="Customers.lastVisit" />
            </CubeFiltersPopover>
          </WebUI.HStack>
          <WebUI.HStack className="gap-3">
            <SearchForm
              className="h-9 w-[320px]"
              size="compact"
              placeholder="Search by amount, payer name or email"
              onSubmit={(values) => setSearchValue(values.term)}
            />
            {isSubscribedToTeam && (
              <CubeReportFormPrompt
                reportType="payers"
                countMeasure="Customers.emailCount"
                query={query}
              />
            )}
            <CreateCubeReportExportButton
              query={query}
              countMeasure="Customers.emailCount"
            />
          </WebUI.HStack>
        </WebUI.VStack>

        {selectionMode === 'multiple' && selectedCollections.length > 0 && (
          <>
            <WebUI.Separator variant="primary" />
            <CollectionTagsCloud />
          </>
        )}
      </WebUI.VStack>
      <CubeTable
        className={
          'min-h-0 border-t [&_.TableView-headerGroup]:px-3 [&_.TableViewRow]:px-3'
        }
        initialState={{sorting: initialSortingState}}
        query={query}
        countMeasure="Customers.emailCount"
        columns={reportTypeToCubeTableColumns.payers}
        getRowProps={(row) =>
          ({
            as: WebUI.Button,
            className: 'px-0 h-auto [font-size:inherit]',
            variant: 'ghost',
            onClick: () => setSelectedPayer(row.original),
          }) as any
        }
      />
    </WebUI.VStack>
  )
}

// MARK: – ReportWithdrawalsView

const ReportWithdrawalsView = (
  props: React.ComponentPropsWithoutRef<'div'>,
) => {
  const [managerRoleId] = useManagerRoleId()
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })
  const {selectionMode, selectedCollections} = useCollectionsSelect()
  const [filters, setFilters] = useState<CubeFilter[]>([])
  const [timeDimensions, setTimeDimensions] = useState<TimeDimensionRanged[]>(
    [],
  )

  const isSubscribedToTeam = !!managerRoleId || !!isSubscribedToTeamQuery.data

  const initialSortingState = useMemo(
    () => [{id: 'Withdrawals.createdAt', desc: true}],
    [],
  )

  const query: CubeQuery = useMemo(
    () => ({
      dimensions: [
        'Collections.id',
        'Users.id',
        'Collections.name',
        'Withdrawals.createdAt',
        'Withdrawals.description',
        'Withdrawals.payoutMethod',
        'TangoCards.recipientName',
        'WithdrawalAccounts.last4',
        'Withdrawals.amount',
        'Withdrawals.status',
      ],
      filters: [
        selectedCollections.length > 0
          ? {
              dimension: 'Collections.id',
              operator: 'equals',
              values: selectedCollections.map((sc) =>
                String(sc['Collections.id']),
              ),
            }
          : undefined,
        ...filters,
      ].filter((f): f is CubeFilter => !!f),
      timeDimensions,
    }),
    [filters, selectedCollections, timeDimensions],
  )

  const columns: CubeTableColumn[] = useMemo(
    () => [
      {path: 'Withdrawals.createdAt', maxWidth: 160},
      {path: 'Collections.name'},
      {path: 'Withdrawals.description'},
      {path: 'Withdrawals.amount', headerTitle: 'Amount', maxWidth: 120},
      {
        path: 'WithdrawalAccounts.last4',
        maxWidth: 160,
        headerTitle: 'Transferred To',
        Cell: ({row, cell}: any) => {
          if (row.original['Withdrawals.payoutMethod'] === 'Reward Link') {
            return (
              <WebUI.Ellipsis className="font-light">
                {row.original['TangoCards.recipientName']}: Gift Card Reward
                Link
              </WebUI.Ellipsis>
            )
          }
          return <>****{cell.value}</>
        },
      },
      {path: 'Withdrawals.status', headerTitle: 'Status', maxWidth: 120},
    ],
    [],
  )

  return (
    <WebUI.VStack {...props}>
      <WebUI.VStack className="gap-6 p-6">
        <WebUI.VStack className="gap-0_5">
          <WebUI.Heading as="h2">Withdrawals</WebUI.Heading>
          <WebUI.Text className="font-light text-ds-xs italic">
            It can take up to 24 hours for new activity to be reflected below.
          </WebUI.Text>
        </WebUI.VStack>
        <WebUI.VStack className="items-stretch justify-start gap-3 sm:flex-row sm:items-start sm:justify-between">
          <WebUI.HStack className="gap-3">
            <CollectionDropdownSelect />
            <CubeFiltersPopover
              aria-label="Withdrawals filters"
              filters={filters}
              timeDimensions={timeDimensions}
              onFiltersApply={(newFilters) => {
                setFilters(newFilters.filters)
                setTimeDimensions(newFilters.timeDimensions)
              }}
            >
              <CubeFilterField dimension="Withdrawals.createdAt" />
              <CubeFilterField dimension="Withdrawals.amount">
                Amount
              </CubeFilterField>
              <CubeFilterField dimension="Withdrawals.status">
                Status
              </CubeFilterField>
            </CubeFiltersPopover>
          </WebUI.HStack>
          <WebUI.HStack className="gap-3">
            {isSubscribedToTeam && (
              <CubeReportFormPrompt
                reportType="withdrawals"
                countMeasure="Withdrawals.count"
                query={query}
              />
            )}
            <CreateCubeReportExportButton
              query={query}
              countMeasure="Withdrawals.count"
            />
          </WebUI.HStack>
        </WebUI.VStack>

        {selectionMode === 'multiple' && selectedCollections.length > 0 && (
          <>
            <WebUI.Separator variant="primary" />
            <CollectionTagsCloud />
          </>
        )}
      </WebUI.VStack>
      <CubeTable
        className={
          'min-h-0 border-t [&_.TableView-headerGroup]:px-3 [&_.TableViewRow]:px-3'
        }
        initialState={{sorting: initialSortingState}}
        query={query}
        countMeasure="Withdrawals.count"
        columns={columns}
        emptyStateView="You have no withdrawals"
        getRowProps={(row) =>
          ({
            as: LinkButton,
            preserveSearch: true,
            className: 'px-0 h-auto [font-size:inherit]',
            to: `i/collection/${row.original['Users.id']}/${row.original['Collections.id']}/summary`,
          }) as any
        }
      />
    </WebUI.VStack>
  )
}

// MARK: – ReportVisitorsView

const ReportVisitorsView = (props: React.ComponentPropsWithoutRef<'div'>) => {
  const [managerRoleId] = useManagerRoleId()
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_team,
  })
  const {selectionMode, selectedCollections} = useCollectionsSelect()
  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState<CubeFilter[]>([])
  const [timeDimensions, setTimeDimensions] = useState<TimeDimensionRanged[]>(
    [],
  )

  const isSubscribedToTeam = !!managerRoleId || !!isSubscribedToTeamQuery.data

  const initialSortingState = useMemo(
    () => [{id: 'Customers.lastSeenAt', desc: true}],
    [],
  )

  const query: CubeQuery = useMemo(
    () => ({
      dimensions: ['Customers.email', 'Customers.nameSearch'],
      measures: [
        'Customers.firstSeenAt',
        'Customers.lastSeenAt',
        'Customers.name',
        'Customers.totalPaid',
        'Customers.visitCount',
      ],
      filters: [
        selectedCollections.length > 0
          ? {
              dimension: 'Collections.id',
              operator: 'equals',
              values: selectedCollections.map((sc) =>
                String(sc['Collections.id']),
              ),
            }
          : undefined,
        {
          dimension: 'Customers.visitCount',
          operator: 'gt',
          values: ['0'],
        },
        ...filters,
        searchValue.length > 0
          ? {
              or: [
                {
                  dimension: 'Customers.nameSearch',
                  operator: 'contains',
                  values: [searchValue],
                },
                {
                  dimension: 'Customers.email',
                  operator: 'contains',
                  values: [searchValue],
                },
              ],
            }
          : undefined,
      ].filter((f): f is CubeFilter => !!f),
      timeDimensions,
    }),
    [filters, timeDimensions, searchValue, selectedCollections],
  )

  return (
    <WebUI.VStack {...props}>
      <WebUI.VStack className="gap-6 p-6">
        <WebUI.VStack className="gap-0_5">
          <WebUI.Heading as="h2">Visitors</WebUI.Heading>
          <WebUI.Text className="font-light text-ds-xs italic">
            It can take up to 24 hours for new activity to be reflected below.
          </WebUI.Text>
        </WebUI.VStack>
        <WebUI.VStack className="justify-start gap-3 overflow-auto sm:flex-row sm:justify-between">
          <WebUI.HStack className="gap-3">
            <CollectionDropdownSelect />
            <CubeFiltersPopover
              aria-label="Visitors filters"
              filters={filters}
              timeDimensions={timeDimensions}
              onFiltersApply={(newFilters) => {
                setFilters(newFilters.filters)
                setTimeDimensions(newFilters.timeDimensions)
              }}
            >
              <CubeFilterField dimension="Customers.totalPaid" />
              <CubeFilterField dimension="Customers.visitCount" />
              <CubeFilterField dimension="Customers.firstVisit" />
              <CubeFilterField dimension="Customers.lastVisit" />
            </CubeFiltersPopover>
          </WebUI.HStack>
          <WebUI.VStack className="gap-3 sm:flex-row">
            <SearchForm
              className="h-9 w-[320px]"
              size="compact"
              placeholder="Search by amount, payer name or email"
              onSubmit={(values) => setSearchValue(values.term)}
            />
            <WebUI.HStack className="gap-3">
              {isSubscribedToTeam && (
                <CubeReportFormPrompt
                  reportType="visitors"
                  query={query}
                  countMeasure="Customers.emailCount"
                />
              )}
              <CreateCubeReportExportButton
                query={query}
                countMeasure="Customers.emailCount"
              />
            </WebUI.HStack>
          </WebUI.VStack>
        </WebUI.VStack>

        {selectionMode === 'multiple' && selectedCollections.length > 0 && (
          <>
            <WebUI.Separator variant="primary" />
            <CollectionTagsCloud />
          </>
        )}
      </WebUI.VStack>
      <CubeTable
        className={
          'min-h-0 border-t [&_.TableView-headerGroup]:px-3 [&_.TableViewRow]:px-3'
        }
        initialState={{sorting: initialSortingState}}
        query={query}
        countMeasure="Customers.emailCount"
        columns={reportTypeToCubeTableColumns.visitors}
      />
    </WebUI.VStack>
  )
}

// MARK: – PayerPaymentsDetailView

interface PayerPaymentsDetailViewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  payer: any
  onGoBack: () => void
}

const PayerPaymentsDetailView = ({
  payer,
  onGoBack,
  className,
  ...restProps
}: PayerPaymentsDetailViewProps) => {
  const [tableData, setTableData] = useState<CubeObject[]>([])

  const query: CubeQuery = useMemo(
    () => ({
      dimensions: [
        'Collections.id',
        'Payments.id',
        'Collections.name',
        'Payments.total',
        'Payments.paymentMethod',
        'Customers.email',
        'Payments.paymentSource',
        'Payments.paymentSourceLast4',
        'Payments.createdAt',
      ],
      filters: [
        {
          dimension: 'Payments.id',
          operator: 'set',
        },
        {
          dimension: 'Customers.email',
          operator: 'equals',
          values: [payer['Customers.email']],
        },
      ],
    }),
    [payer],
  )

  const columns = useMemo(
    () =>
      [
        {path: 'Collections.name'},
        {path: 'Payments.total', headerTitle: 'Payment'},
        {path: 'Payments.paymentMethod', headerTitle: 'Method'},
        {path: 'Payments.createdAt'},
      ] as const,
    [],
  )

  const paymentSourceStrings = Util.unique(
    tableData
      .filter((p) => !!p['Payments.paymentSource'])
      .map(
        (p) =>
          `${
            PAYMENT_METHOD_LABELS[
              // biome-ignore lint/style/noNonNullAssertion:
              p['Payments.paymentSource']! as Api.PaymentMethodType
            ] ?? p['Payments.paymentSource']
          }${
            p['Payments.paymentSourceLast4']
              ? ` ••••${p['Payments.paymentSourceLast4']}`
              : ''
          }`,
      ),
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-7', className)} {...restProps}>
      <WebUI.VStack className="gap-5 px-7 py-6 text-gray800" as={WebUI.Panel}>
        <WebUI.HStack className="justify-between gap-3">
          <WebUI.Button variant="link" onClick={() => onGoBack()}>
            {'< View All'}
          </WebUI.Button>
          <CreateCubeReportExportButton
            query={query}
            countMeasure="Customers.emailCount"
          />
        </WebUI.HStack>

        <WebUI.VStack className="gap-7">
          <WebUI.VStack>
            <WebUI.Heading as="h3">{payer['Customers.name']}</WebUI.Heading>
            <WebUI.Anchor
              className="text-ds-sm"
              rel="noopener noreferrer"
              target="_blank"
              href={`mailto:${payer['Customers.email']}`}
            >
              {payer['Customers.email']}
            </WebUI.Anchor>
          </WebUI.VStack>

          <WebUI.VStack className="gap-1 text-ds-sm">
            <WebUI.HStack className="gap-4">
              <WebUI.Text className="w-[120px]">First Payment</WebUI.Text>
              <WebUI.Text>
                {Util.formatDateAs(
                  payer['Customers.firstSeenAt'],
                  'date_tabular',
                )}
              </WebUI.Text>
            </WebUI.HStack>
            {paymentSourceStrings.length > 0 && (
              <WebUI.HStack className="gap-4">
                <WebUI.Text className="w-[120px]">Payment Methods:</WebUI.Text>
                <WebUI.VStack>
                  {paymentSourceStrings.map((pss) => (
                    <WebUI.Text key={pss}>{pss}</WebUI.Text>
                  ))}
                </WebUI.VStack>
              </WebUI.HStack>
            )}
          </WebUI.VStack>
        </WebUI.VStack>
      </WebUI.VStack>

      <WebUI.VStack className="gap-6 px-7 py-6" as={WebUI.Panel}>
        <WebUI.Heading as="h3">
          Total Payments: {Util.formatAmount(payer['Customers.totalPaid'])}
        </WebUI.Heading>

        <CubeTable
          query={query}
          columns={columns}
          countMeasure="Payments.count"
          onTableDataChange={(newTableData) => setTableData(newTableData)}
          getRowProps={(row) =>
            ({
              as: LinkButton,
              preserveSearch: true,
              className: 'px-0 h-auto [font-size:inherit]',
              to: `collection/${row.original['Collections.id']}/payment/${row.original['Payments.id']}`,
            }) as any
          }
        />
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – CubeReportDetailView

interface CubeReportDetailViewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  cubeReport: Api.CubeReport
  onViewAll: () => void
}

const CubeReportDetailView = ({
  cubeReport,
  onViewAll,
  ...restProps
}: CubeReportDetailViewProps) => (
  <WebUI.VStack {...restProps}>
    <CubeReportSummary
      className="px-7 py-6"
      cubeReport={cubeReport}
      onViewAll={onViewAll}
    />
    <CubeReportTable
      className={
        'min-h-0 border-t [&_.TableView-body]:overflow-y-auto [&_.TableView-headerGroup]:px-3 [&_.TableViewRow]:px-3'
      }
      cubeReport={cubeReport}
    />
  </WebUI.VStack>
)

// MARK: – AccountReportPanel

interface AccountReportPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  heading: string
  description: string
  loading: boolean
  validateStartDateAgainstToday?: boolean
  onDownload: (range: DateRangeValue) => void
}

const AccountReportPanel = ({
  className,
  heading,
  description,
  loading,
  onDownload,
  validateStartDateAgainstToday = false,
  ...restProps
}: AccountReportPanelProps) => {
  const [dateRange, setDateRange] = useState(
    getDateRangeFromTimeDimension('yearToDate'),
  )
  const parsedDate = Util.parseCalendarDate(dateRange[0])
  const invalidDate =
    validateStartDateAgainstToday && parsedDate
      ? parsedDate >= today(getLocalTimeZone())
      : false

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-3 p-6', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.Heading className="text-gray400 uppercase" as="h6">
        {heading}
      </WebUI.Heading>
      <WebUI.Text className="font-light text-ds-sm text-gray800">
        {description}
      </WebUI.Text>
      <WebUI.FormField
        error={invalidDate ? 'Start date must be before today' : null}
      >
        <ReportOverviewDateRangePicker
          className={
            'max-h-[auto] gap-2 border-none *:h-9 *:rounded *:border *:border-grey-300'
          }
          defaultTimeDimension="yearToDate"
          dateRange={dateRange}
          onDateRangeChange={(newDateRange) => setDateRange(newDateRange)}
          showDatePickerForCustomOnly
          as={WebUI.VStack}
        />
      </WebUI.FormField>
      <WebUI.Button
        className="self-start"
        onClick={() => onDownload(dateRange)}
        disabled={loading || invalidDate}
        loading={loading}
      >
        Download Report
      </WebUI.Button>
    </WebUI.VStack>
  )
}

// MARK: – CubeReportsPanel

interface CubeReportsPanelProps extends React.ComponentPropsWithoutRef<'div'> {
  onReportView: (cubeReport: Api.CubeReport) => void
}

const CubeReportsPanel = ({
  onReportView,
  className,
  ...restProps
}: CubeReportsPanelProps) => {
  const cubeReportsQuery = api.cube.reportList.useQuery()

  if (!cubeReportsQuery.data || cubeReportsQuery.data.length === 0) {
    return null
  }

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-7 p-6', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.Heading className="text-gray400 uppercase" as="h6">
        Saved reports
      </WebUI.Heading>
      <WebUI.VStack className="gap-4">
        {cubeReportsQuery.data?.map((cr) => (
          <CubeReportRow
            key={cr.id}
            cubeReport={cr}
            onView={() => onReportView(cr)}
          />
        ))}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – CubeFiltersPopover

export interface CubeFiltersPopoverProps
  extends Omit<WebUI.FiltersPopoverProps, 'children'>,
    Pick<
      CubeFiltersProps,
      'filters' | 'timeDimensions' | 'onFiltersApply' | 'children'
    > {}

export const CubeFiltersPopover = ({
  filters,
  timeDimensions,
  onFiltersApply,
  children,
  ...restProps
}: CubeFiltersPopoverProps) => (
  <WebUI.FiltersPopover size="default" {...restProps}>
    {(popover) => (
      <CubeFilters
        className="min-w-[280px] sm:min-w-[340px]"
        filters={filters}
        timeDimensions={timeDimensions}
        onFiltersApply={(newFilters) => {
          onFiltersApply?.(newFilters)
          popover.hide()
        }}
      >
        {children}
      </CubeFilters>
    )}
  </WebUI.FiltersPopover>
)

// MARK: – UpsellPanel

interface UpsellPanelProps extends React.ComponentPropsWithoutRef<'div'> {
  heading: React.ReactNode
  headingImageSrc?: string
  descriptionText?: string
  showTiles?: boolean
}

const UpsellPanel = ({
  heading,
  headingImageSrc,
  descriptionText,
  showTiles = true,
  className,
  ...restProps
}: UpsellPanelProps) => (
  <WebUI.VStack
    className={WebUI.cn(
      'items-start justify-center gap-6 px-6 py-8',
      className,
    )}
    as={WebUI.Panel}
    {...restProps}
  >
    <WebUI.VStack className="gap-3">
      <WebUI.HStack className="items-center gap-5">
        {headingImageSrc && (
          <img
            className="w-17"
            width={72}
            src={headingImageSrc}
            alt="Team upgrade upsell"
          />
        )}
        <WebUI.Heading className="font-accent font-bold text-ds-md" as="h3">
          {heading}
        </WebUI.Heading>
      </WebUI.HStack>
      <WebUI.Text className="font-light text-ds-sm">
        {descriptionText ?? (
          <>
            Tap into collecting trends{' '}
            <span className="font-bold">across your entire account</span> with
            our Team plan
          </>
        )}
      </WebUI.Text>
    </WebUI.VStack>
    {showTiles && (
      <WebUI.RadioGroup
        className={
          '[&_>_.Radio_>_.Radio-icon]:mt-1 [&_>_.Radio_>_.Radio-icon]:h-[1em] [&_>_.Radio_>_.Radio-icon]:w-[1em] [&_>_.Radio_>_.Radio-icon]:self-start [&_>_.Radio_>_.Radio-icon_>_.Radio-checkIcon]:text-[0.75em] [&_>_.Radio_>_.Radio-text]:w-[178px]'
        }
        size="compact"
      >
        <WebUI.Radio checked>
          See top trending payers and collections
        </WebUI.Radio>
        <WebUI.Radio checked>
          Access reporting across all collections
        </WebUI.Radio>
        <WebUI.Radio checked>
          Create and save reports with custom filters
        </WebUI.Radio>
      </WebUI.RadioGroup>
    )}
    <LinkButton variant="primary" preserveSearch to="i/plans">
      Upgrade to Team
    </LinkButton>
  </WebUI.VStack>
)

export default ReportsPage
