import * as Util from '@cheddarup/util'
import {Element as ScrollAnchor, scroller} from 'react-scroll'
import {useNavigate, useParams} from 'react-router-dom'
import {useQueryParam} from 'use-query-params'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useAddObjectsFromCatalogMutation} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {SearchForm} from 'src/components'
import {SharpImage} from 'src/components/SharpImage'
import ImagesUtils from 'src/helpers/ImagesUtils'
import {UpgradeRequiredAlert} from 'src/components/UpgradeRequiredAlert'

const search = (items: any[], value: string) => {
  const valueLower = value.toLowerCase()
  return items.filter((i) => i.name.toLowerCase().includes(valueLower))
}

const CatalogItemsPage = () => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const collectionId = Number(urlParams.collection)
  const catalogId = Number(urlParams.catalog)

  const itemCatalogQuery = api.itemCatalogs.detail.useQuery(
    {
      pathParams: {
        catalogId,
      },
    },
    {
      select: (itemCatalog) => itemCatalog.catalog,
    },
  )
  const itemsQuery = api.tabItems.list.useQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [initialItem] = useQueryParam('item')

  const categories = useMemo(
    () =>
      Util.sort(
        Util.uniqueBy(
          (itemCatalogQuery.data?.active_items ?? [])
            .map((i) => i.category)
            .filter((c) => !!c),
          (i) => i.id,
        ),
      ).asc((c) => c.position),
    [itemCatalogQuery.data],
  )
  const items = useMemo(
    () =>
      Util.sort(itemCatalogQuery.data?.active_items ?? []).asc(
        (item) => item.position,
      ),
    [itemCatalogQuery.data],
  )

  const searchItemsNoCategory = useMemo(() => {
    const itemsNoCategory = items.filter((item) => !item.category)

    return searchValue.length > 0
      ? search(itemsNoCategory, searchValue)
      : itemsNoCategory
  }, [items, searchValue])
  const searchitemsWithCategory = useMemo(() => {
    const itemsWithCategory = items.filter((item) => item.category)

    return searchValue.length === 0
      ? itemsWithCategory
      : search(itemsWithCategory, searchValue)
  }, [items, searchValue])

  const itemAdded = (itemId: number) =>
    itemsQuery.data?.some((ci) => ci.catalog_object_id === itemId) ?? false

  const itemSelected = useCallback(
    (itemId: number) => selectedItems.includes(itemId),
    [selectedItems],
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const handleItemSelectedToggle = useCallback(
    (itemId: number) => {
      setSelectedItems((prevSelectedItems) =>
        itemSelected(itemId)
          ? prevSelectedItems.filter((id) => id !== itemId)
          : [...prevSelectedItems, itemId],
      )
    },
    [itemSelected, setSelectedItems],
  )

  useEffect(() => {
    if (initialItem) {
      scroller.scrollTo(`item-${initialItem}`, {
        containerId: 'catalog-items-list-container',
        smooth: true,
        duration: 300,
      })
    }
  }, [initialItem])

  return (
    <WebUI.Modal
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
      onDidHide={() => navigate('..')}
    >
      <WebUI.ModalCloseButton />

      <WebUI.ModalHeader>
        <WebUI.Heading as="h2">{itemCatalogQuery.data?.name}</WebUI.Heading>
        <span>Select items to add to your collection.</span>
      </WebUI.ModalHeader>

      <div className="flex flex-row items-center justify-between gap-3 p-4 sm:px-8">
        <WebUI.Checkbox
          className="text-ds-base"
          size="compact"
          checked={items.length > 0 && selectedItems.length === items.length}
          onChange={(event) =>
            setSelectedItems(event.target.checked ? items.map((i) => i.id) : [])
          }
        >
          Select all
        </WebUI.Checkbox>
        <div className="flex max-w-3/5 flex-row gap-4">
          {categories.length > 0 && (
            <WebUI.DropdownSelect<number>
              size="compact"
              placeholder="All"
              onValueChange={(categoryId) => {
                if (categoryId != null) {
                  scroller.scrollTo(String(categoryId), {
                    containerId: 'catalog-items-list-container',
                    smooth: true,
                    duration: 300,
                    offset: -240,
                  })
                }
              }}
            >
              {categories.map((c) => (
                <WebUI.DropdownSelectOption key={c.id} value={c.id}>
                  {c.name}
                </WebUI.DropdownSelectOption>
              ))}
            </WebUI.DropdownSelect>
          )}

          <SearchForm
            size="compact"
            placeholder="Search"
            onTermChange={(newSearchValue) => setSearchValue(newSearchValue)}
          />
        </div>
      </div>

      <WebUI.Separator variant="primary" />

      <div id="catalog-items-list-container" className="grow overflow-y-auto">
        <ScrollAnchor name="all" />

        {searchItemsNoCategory.map((item) => (
          <Item
            key={item.id}
            added={itemAdded(item.id)}
            item={item}
            selected={itemSelected(item.id)}
            onToggleSelected={() => handleItemSelectedToggle(item.id)}
          />
        ))}

        {categories.map((category) => {
          const categoryItems = searchitemsWithCategory.filter(
            (i) => i.category.id === category.id,
          )

          return (
            categoryItems.length > 0 && (
              <div key={category.id}>
                <ScrollAnchor name={String(category.id)}>
                  <div className="bg-gray100 px-8 py-4 text-ds-base">
                    {category.name ?? ''}
                  </div>
                  {categoryItems.map((item) => (
                    <ScrollAnchor key={item.id} name={`item-${item.id}`}>
                      <Item
                        added={itemAdded(item.id)}
                        item={item}
                        selected={itemSelected(item.id)}
                        onToggleSelected={() =>
                          handleItemSelectedToggle(item.id)
                        }
                      />
                    </ScrollAnchor>
                  ))}
                </ScrollAnchor>
              </div>
            )
          )
        })}
      </div>

      <CatalogItemsFooterContainer
        collectionItems={itemsQuery.data ?? []}
        selectedItems={selectedItems}
      />
    </WebUI.Modal>
  )
}

// MARK: – Item

interface ItemProps {
  added?: boolean
  item: Api.ItemCatalogItem
  selected?: boolean
  onToggleSelected: React.ChangeEventHandler<HTMLInputElement>
}

const Item = React.memo(
  ({added, item, selected, onToggleSelected}: ItemProps) => (
    <div className="flex flex-row items-center gap-4 border-b px-4 py-8">
      <WebUI.Checkbox
        size="compact"
        checked={selected || added}
        disabled={added}
        onChange={onToggleSelected}
      />
      <SharpImage
        width={80}
        height={80}
        alt="Catalog item"
        image={ImagesUtils.getMainImage(item.images ?? [])}
      />
      <div className="flex flex-col gap-0_5">
        <div className="font-normal text-ds-base">{item.name || ''}</div>
        <div className="text-ds-sm">
          {Util.formatAmount(
            (() => {
              const lowestAmountListing = Util.firstBy(
                item.variants?.enabled ? (item.variants.listings ?? []) : [],
                (l) => l.amount,
              )
              return (lowestAmountListing?.amount ?? item.amount) || 0
            })(),
          )}
        </div>
      </div>
    </div>
  ),
)

// MARK: – CatalogItemsFooterContainer

interface CatalogItemsFooterContainerProps {
  collectionItems: Api.TabItem[]
  selectedItems: number[]
}

const CatalogItemsFooterContainer = ({
  collectionItems,
  selectedItems,
}: CatalogItemsFooterContainerProps) => {
  const navigate = useNavigate()
  const urlParams = useParams()
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })
  const addObjectsFromCatalogMutation = useAddObjectsFromCatalogMutation()
  const upgradeRequiredAlertRef = useRef<WebUI.DialogInstance>(null)

  const collectionId = Number(urlParams.collection)
  const catalogId = Number(urlParams.catalog)

  return (
    <div className="flex flex-row justify-end border-t bg-trueWhite p-4">
      <div className="flex flex-row gap-3">
        <LinkButton size="large" variant="secondary" to="..">
          Back
        </LinkButton>
        <WebUI.Button
          size="large"
          variant="primary"
          loading={addObjectsFromCatalogMutation.isPending}
          onClick={async () => {
            if (selectedItems.length === 0) {
              return
            }

            if (
              collection &&
              !collection.is_pro &&
              collection.status !== 'draft' &&
              collectionItems.length + selectedItems.length >
                (collection.itemLimit ?? 15)
            ) {
              upgradeRequiredAlertRef.current?.show()
            } else {
              await addObjectsFromCatalogMutation.mutateAsync({
                pathParams: {
                  tabId: collectionId,
                },
                body: {
                  catalog: {
                    id: catalogId,
                    items: selectedItems,
                  },
                },
              })

              navigate('../..')
            }
          }}
        >
          Add
        </WebUI.Button>
      </div>
      <UpgradeRequiredAlert ref={upgradeRequiredAlertRef} />
    </div>
  )
}

export default CatalogItemsPage
