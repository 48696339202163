import {Link, To} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import {AppHeader, AppHeaderMobile} from 'src/components/AppHeader'
import {CollectionsSidebar} from 'src/components/CollectionsMobileLayout/CollectionsSidebar'

export interface UserBasePageProps
  extends React.ComponentPropsWithoutRef<'div'> {
  currentUrl?: string
  heading: React.ReactNode
  error?: React.ReactNode
}

const UserBasePage = ({
  currentUrl,
  error,
  heading,
  className,
  children,
  ...restProps
}: UserBasePageProps) => {
  const media = WebUI.useMedia()

  return (
    <>
      {media.sm ? (
        <AppHeader />
      ) : (
        <AppHeaderMobile left={<CollectionsSidebar />} />
      )}
      <WebUI.HStack className="max-w-full bg-depr-grey-50">
        <WebUI.HStack className="relative min-w-0">
          <SecondarySidebar className="hidden flex-[0_0_240px] lg:block">
            <UserSettingsNav currentUrl={currentUrl} />
          </SecondarySidebar>
          <div
            className={WebUI.cn('relative max-w-full grow', className)}
            {...restProps}
          >
            <div className="mx-auto w-full max-w-5xl px-8 pb-16">
              {!!error && (
                <p className="mt-8 flex items-center">
                  <WebUI.PhosphorIcon
                    className="mr-2 h-8 text-orange-500"
                    icon="warning-circle"
                  />
                  <span className="relative top-px">{error}</span>
                </p>
              )}
              <h1 className="mt-8 text-gray800">{heading}</h1>
              <div className="mt-8">{children}</div>
            </div>
          </div>

          <WebUI.Drawer
            className="[&_>_.SideSheetContentView]:bg-gray100"
            disclosureClassName="block lg:hidden"
          >
            <UserSettingsNav currentUrl={currentUrl} />
          </WebUI.Drawer>
        </WebUI.HStack>
      </WebUI.HStack>
    </>
  )
}

// MARK: – SecondarySidebar

export interface SecondarySidebarProps {
  className?: string
  children: React.ReactNode
}

export const SecondarySidebar = ({
  className,
  children,
}: SecondarySidebarProps) => (
  <div
    className={WebUI.cn(
      'h-full min-h-[88vh] bg-teal-80 sm:min-h-screen',
      className,
    )}
  >
    {children}
  </div>
)

// MARK: – UserSettingsNav

interface UserSettingsNavProps extends React.ComponentPropsWithoutRef<'div'> {
  currentUrl?: string
}

const UserSettingsNav = ({currentUrl, ...restProps}: UserSettingsNavProps) => {
  const pathMap = {
    inviteMembers: '/organizations/invite-members',
    members: '/organizations/members',
  }

  return (
    <WebUI.VStack {...restProps}>
      <SidebarNavSection label="Partner Tools">
        <SidebarNavItem
          active={
            !!currentUrl && currentUrl.indexOf(pathMap.inviteMembers) === 0
          }
          label="Invite Members"
          to={pathMap.inviteMembers}
        />
        <SidebarNavItem
          active={!!currentUrl && currentUrl.indexOf(pathMap.members) === 0}
          label="Member Listing"
          to={pathMap.members}
        />
      </SidebarNavSection>
    </WebUI.VStack>
  )
}

// MARK: – SidebarNavSection

interface SidebarNavSectionProps extends React.ComponentPropsWithoutRef<'div'> {
  label: React.ReactNode
}

const SidebarNavSection = ({
  label,
  children,
  ...restProps
}: SidebarNavSectionProps) => (
  <div {...restProps}>
    <div className="relative bg-tint py-2 pl-2 font-light text-ds-sm">
      <div className="relative flex items-center px-4 py-2 text-trueWhite uppercase">
        {label}
      </div>
    </div>
    {children}
  </div>
)

// MARK: – SidebarNavItem

interface SidebarNavItemProps extends React.ComponentPropsWithoutRef<'div'> {
  active?: boolean
  to: To
  label: React.ReactNode
}

const SidebarNavItem = ({
  active = false,
  to,
  label,
  className,
  ...restProps
}: SidebarNavItemProps) => (
  <div
    className={WebUI.cn('relative font-normal text-ds-sm', className)}
    {...restProps}
  >
    {active && (
      <i className="absolute top-0 right-0 bottom-0 left-0 bg-trueWhite opacity-60" />
    )}
    <Link className="block overflow-hidden whitespace-nowrap py-2 pl-2" to={to}>
      <div className="relative flex items-center px-4 py-2 text-gray800">
        {label}
      </div>
    </Link>
  </div>
)

export default UserBasePage
