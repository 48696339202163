import * as WebUI from '@cheddarup/web-ui'
import React from 'react'

export interface ManagerSummaryModalProps
  extends WebUI.DialogDisclosureProps,
    WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  managerRole: Api.ManagerRole
}

export const ManagerSummaryModal = React.forwardRef<
  HTMLButtonElement,
  ManagerSummaryModalProps
>(({managerRole, ...restProps}, forwardedRef) => (
  <WebUI.Prompt
    aria-label="Manager summary"
    disclosure={
      <WebUI.DialogDisclosure
        ref={forwardedRef}
        className="font-light"
        variant="link"
        size="compact"
        {...restProps}
      />
    }
  >
    <WebUI.VStack className="gap-6">
      <WebUI.VStack>
        <WebUI.Text className="text-ds-base">
          You are a manager on this account.
        </WebUI.Text>
        {managerRole && (
          <WebUI.Text className="mt-2 text-ds-sm">
            Manager Role:{' '}
            <span className="text-orange-500 capitalize">
              {managerRole?.permissions?.role}
            </span>
          </WebUI.Text>
        )}
        <WebUI.Anchor
          className="font-light text-ds-sm"
          href="https://support.cheddarup.com/hc/en-us/sections/360007160431-Managers"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more about managing an account.
        </WebUI.Anchor>
      </WebUI.VStack>
      <WebUI.VStack className="text-ds-sm">
        <WebUI.Text>
          Account: <span className="font-light">{managerRole.name}</span>
        </WebUI.Text>
        <WebUI.Text>
          Contact:{' '}
          <span className="font-light">
            {managerRole.full_name},{' '}
            <WebUI.Anchor href={`mailto:${managerRole.email}`}>
              {managerRole.email}
            </WebUI.Anchor>
          </span>
        </WebUI.Text>
      </WebUI.VStack>
    </WebUI.VStack>
  </WebUI.Prompt>
))
