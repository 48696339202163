import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {useIsAuthed} from 'src/hooks/useAuthToken'

import usePublicCollection from '../../hooks/usePublicCollection'

export interface PayerBandsProps {}

export const PayerBands: React.FC<PayerBandsProps> = () => {
  const {publicCollection} = usePublicCollection()
  const isAuthed = useIsAuthed()
  const {data: session} = api.auth.session.useQuery(undefined, {
    enabled: isAuthed,
  })
  const {data: tab} = api.tabs.detail.useQuery(
    {
      pathParams: {
        tabId: publicCollection.id,
      },
    },
    {
      enabled: isAuthed && !!publicCollection.userManagesCollection,
    },
  )

  return (
    <>
      {publicCollection.userManagesCollection &&
        publicCollection.forms.length === 0 &&
        publicCollection.signups.length === 0 &&
        publicCollection.items.length === 0 && (
          <WebUI.HStack className="justify-center bg-orange-500">
            <WebUI.HStack className="max-w-screen-xl gap-2 p-2 text-center text-trueWhite">
              <WebUI.PhosphorIcon icon="warning-circle-fill" width={24} />
              <span>
                Your payers will not be able to check out until you add an item
                or form to your collection
              </span>
            </WebUI.HStack>
          </WebUI.HStack>
        )}
      {publicCollection.userManagesCollection &&
        tab &&
        (tab.statement_descriptor === session?.user?.full_name ||
          tab.statement_descriptor === 'Cheddar Up') && (
          <StatementDescriptorWarningBand tab={tab} />
        )}

      {!!publicCollection.published_at &&
        publicCollection.type !== 'Template' && <CollectionUnavailableBanner />}
    </>
  )
}

// MARK: – StatementDescriptorWarningBand

interface StatementDescriptorWarningBandProps
  extends React.ComponentPropsWithoutRef<'div'> {
  tab: Api.Tab
}

const StatementDescriptorWarningBand: React.FC<
  StatementDescriptorWarningBandProps
> = ({tab, className, ...restProps}) => (
  <WebUI.HStack
    className={WebUI.cn('justify-center bg-orange-500', className)}
    {...restProps}
  >
    <WebUI.HStack className="max-w-screen-xl items-center justify-center gap-2 p-2 text-trueWhite">
      <WebUI.PhosphorIcon
        className="flex-0 text-trueWhite"
        icon="warning-circle-fill"
        width={24}
      />
      <span>
        Payers will see "{tab.statement_descriptor}" on their credit card
        statements.{' '}
        <Link
          className="text-trueWhite underline"
          to={`/collection/${tab.id}/details/settings#statement_descriptor`}
        >
          Update to avoid disputes
        </Link>
      </span>
    </WebUI.HStack>
  </WebUI.HStack>
)

// MARK: – CollectionUnavailableBanner

const CollectionUnavailableBanner: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const {publicCollection} = usePublicCollection()

  let reasonText: string | null
  if (
    publicCollection.userManagesCollection &&
    !publicCollection.allowPayments
  ) {
    reasonText = ' inactive. Complete your profile to reactivate.'
  } else if (
    publicCollection.userManagesCollection &&
    publicCollection.unavailable === 'requires_pro'
  ) {
    reasonText = ' inactive. Upgrade to reactivate.'
  } else if (
    !publicCollection.allowPayments ||
    publicCollection.unavailable === 'requires_pro' ||
    publicCollection.unavailable === 'payments_disabled'
  ) {
    reasonText = ' inactive. Please contact the organizer.'
  } else if (publicCollection.unavailable === 'closed') {
    reasonText = ' closed.'
  } else {
    reasonText = null
  }

  if (reasonText == null) {
    return null
  }

  return (
    <WebUI.HStack
      className={WebUI.cn('justify-center bg-tint', className)}
      {...restProps}
    >
      <div
        className="max-w-screen-xl px-4 py-2 text-center text-ds-base text-trueWhite"
        {...restProps}
      >
        {publicCollection.userManagesCollection ? 'Your' : 'This'} collection is
        {`${reasonText}`}
      </div>
    </WebUI.HStack>
  )
}
