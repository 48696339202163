import * as WebUI from '@cheddarup/web-ui'
import {tailwindConfig} from '@cheddarup/tailwind-config'
import {useLocation, useNavigate} from 'react-router-dom'
import {useMemo, useState} from 'react'
import {
  api,
  useDeleteOrgMemberInviteMutation,
  useResendOrgMemberInviteMutation,
} from '@cheddarup/api-client'
import {SearchForm} from 'src/components'
import {Elements} from 'src/components/Stripe'
import {fetchAndSave} from 'src/helpers/api-helpers'

import {AddMemberForm} from './components'
import {UploadMemberInvitesCsvContainer} from './containers'
import {UserBasePage} from '../../components'

const InviteMembersPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [addMemberInviteFormExpanded, setAddMemberInviteFormExpanded] =
    useState(false)
  const {data: session} = api.auth.session.useSuspenseQuery()

  return (
    <UserBasePage currentUrl={location.pathname} heading="Invite Members">
      {session.partner_admin?.org?.id && (
        <>
          <h2 className="mt-4 text-ds-xl">Invite New Members</h2>
          <h5 className="mt-2">
            When you invite new members to your organization, Cheddar Up will
            create a new account for them using the
            <br />
            underwriting information you include and will send them a welcome
            email prompting them to create their password and
            <br />
            get started. You can add new members in bulk via an Excel upload or
            one member at a time.
          </h5>
          <div className="mt-4">
            <Elements>
              <UploadMemberInvitesCsvContainer />
            </Elements>
          </div>
          <div
            className="mt-4 flex cursor-pointer items-center"
            onClick={() => setAddMemberInviteFormExpanded(true)}
          >
            Add Individual Member{' '}
            <WebUI.PhosphorIcon
              className="ml-2"
              icon="plus-circle-fill"
              color={tailwindConfig.theme.colors.tint}
            />
          </div>
          {addMemberInviteFormExpanded && (
            <Elements>
              <AddMemberForm
                className="mt-4 bg-gray100 p-4"
                onClose={() => setAddMemberInviteFormExpanded(false)}
              />
            </Elements>
          )}
          <div className="mt-16 mb-8 border-t" />
          <h3 className="mb-8">Members You&apos;ve Invited</h3>
          <MemberInviteTable
            onResendToAll={(memberInvitesQuery) =>
              navigate('resend-all-confirm', {
                state: {
                  resendAllPayload: {
                    query: memberInvitesQuery,
                  },
                },
              })
            }
          />
        </>
      )}
    </UserBasePage>
  )
}

// MARK: – MemberInviteTable

const MEMBER_INVITES_PER_PAGE = 50

interface MemberInviteTableProps {
  onResendToAll: (queryConfig: any) => void
}

const MemberInviteTable = ({onResendToAll}: MemberInviteTableProps) => {
  const [resentInvitations, setResentInvitations] = useState<any[]>([])
  const [queryConfig, setQueryConfig] = useState<Record<string, any>>({page: 1})
  const {data} = api.orgMemberInvites.list.useSuspenseQuery({
    queryParams: {
      sort: 'created_at',
      direction: 'asc',
      ...queryConfig,
    },
  })
  const resendMemberInviteMutation = useResendOrgMemberInviteMutation()
  const deleteMemberInviteMutation = useDeleteOrgMemberInviteMutation()
  const growlActions = WebUI.useGrowlActions()

  const {data: memberInvites, pagination} = data
  const resendMemberInvite = resendMemberInviteMutation.mutateAsync
  const deleteMemberInvite = deleteMemberInviteMutation.mutate

  const columns = useMemo(
    () => [
      {
        id: 'profile.first_name',
        Header: 'First Name',
        accessor: 'profile.first_name',
      },
      {
        id: 'profile.last_name',
        Header: 'Last Name',
        accessor: 'profile.last_name',
      },
      {
        id: 'profile.email',
        Header: 'Email',
        accessor: 'profile.email',
      },
      {
        id: 'created_user_id',
        Header: 'Active',
        accessor: (memberInvite: any) =>
          memberInvite.created_user_id ? 'Yes' : '-',
      },
      {
        id: 'status',
        Header: 'Email Status',
        accessor: (memberInvite: any) => getFriendlyStatus(memberInvite),
      },
      {
        id: 'resend',
        Header: 'Resend',
        Cell: ({row: {original: memberInvite}}: any) =>
          memberInvite.created_user_id ||
          resentInvitations.includes(memberInvite.id) ? (
            '-'
          ) : (
            <WebUI.Button
              variant="link"
              onClick={async () => {
                try {
                  await resendMemberInvite({
                    pathParams: {inviteId: memberInvite.id},
                  })
                  setResentInvitations((prevResentInvitations) => [
                    ...prevResentInvitations,
                    memberInvite.id,
                  ])
                  growlActions.show('success', {
                    title: 'Success!',
                    body: 'Invitation re-sent',
                  })
                } catch {
                  // noop
                }
              }}
            >
              Resend
            </WebUI.Button>
          ),
      },
      {
        id: 'remove',
        Header: 'Remove',
        Cell: ({row: {original: memberInvite}}) =>
          memberInvite.created_user_id ? (
            <WebUI.IconButton
              className="text-gray400"
              onClick={() =>
                deleteMemberInvite({pathParams: {inviteId: memberInvite.id}})
              }
            >
              <WebUI.PhosphorIcon icon="trash-fill" />
            </WebUI.IconButton>
          ) : (
            '-'
          ),
      },
    ],
    [deleteMemberInvite, growlActions, resendMemberInvite, resentInvitations],
  )

  const paginationState = useMemo(
    () => ({
      pageSize: MEMBER_INVITES_PER_PAGE,
      pageIndex: queryConfig.page - 1,
    }),
    [queryConfig.page],
  )
  const sortingState = useMemo(
    () => [
      {
        id: queryConfig.sort,
        desc: queryConfig.direction === 'desc',
      },
    ],
    [queryConfig.sort, queryConfig.direction],
  )

  const pageCount = pagination
    ? Math.ceil(pagination.total / MEMBER_INVITES_PER_PAGE)
    : undefined

  return (
    <WebUI.VStack className="gap-2">
      <WebUI.HStack className="justify-between">
        <WebUI.HStack className="items-center justify-between gap-2">
          <SearchForm
            onSubmit={(values) => {
              if (values.term !== queryConfig.search) {
                setQueryConfig((prevQuery) => ({
                  ...prevQuery,
                  search: values.term,
                }))
              }
            }}
          />
          <WebUI.Button
            type="submit"
            size="compact"
            disabled={resentInvitations.length > 0}
            onClick={() => onResendToAll(queryConfig)}
          >
            Resend To All
          </WebUI.Button>
        </WebUI.HStack>

        <WebUI.IconButton
          className="text-ds-xl text-trueWhite"
          size="default_alt"
          variant="default"
          onClick={() =>
            fetchAndSave({
              url: 'users/org_member_invites/invites.xlsx',
              fileName: 'invites.xlsx',
            })
          }
        >
          <WebUI.PhosphorIcon icon="download-simple" />
        </WebUI.IconButton>
      </WebUI.HStack>

      <WebUI.TableView
        className="[&_.TableViewRow]:py-0"
        columns={columns as any}
        data={memberInvites}
        state={{
          pagination: paginationState,
          sorting: sortingState,
        }}
        pageCount={pageCount}
        manualPagination
        onPaginationChange={(updater) => {
          const newPagination =
            typeof updater === 'function' ? updater(paginationState) : updater

          setQueryConfig((prevQueryConfig) => ({
            ...prevQueryConfig,
            page: newPagination.pageIndex + 1,
          }))
        }}
        sortable
        sortByTogglesVisible
        manualSortBy
        disableSortRemove
        onSortingChange={(updater) => {
          const [newSorting] =
            typeof updater === 'function' ? updater(sortingState) : updater

          setQueryConfig((prevQueryConfig) => ({
            ...prevQueryConfig,
            sort: newSorting?.id,
            direction: newSorting?.desc ? 'desc' : 'asc',
          }))
        }}
      >
        {pageCount && pageCount > 1 && (
          <WebUI.HStack className="justify-end px-8 py-4">
            <WebUI.TablePaginator />
          </WebUI.HStack>
        )}
      </WebUI.TableView>
    </WebUI.VStack>
  )
}

// MARK: – Helpers

const getFriendlyStatus = (invite: any) => {
  const statusMap = {
    open: 'Opened',
    delivered: 'Delivered',
    bounce: 'Bounced',
    sent: 'Sending',
    processed: 'Sent',
    click: 'Opened',
    dropped: 'Failed',
  } as any

  return invite.status ? statusMap[invite.status] || 'Unknown' : 'Unknown'
}

export default InviteMembersPage
