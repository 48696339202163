import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useDeleteUserMutation,
  useUpdateSubscriptionMutation,
} from '@cheddarup/api-client'
import {useNavigate} from 'react-router-dom'
import config from 'src/config'
import {Link} from 'src/components/Link'

// MARK: – DeleteAccountConfirmationAlert

export const DeleteAccountConfirmationAlert = React.forwardRef<
  WebUI.DialogInstance,
  WebUI.AlertProps
>(({...restProps}, forwardedRef) => {
  const sessionQuery = api.auth.session.useSuspenseQuery()
  const body = `Please confirm your existing account is under ${sessionQuery.data.user.email} and share with us the partner affiliation (and tools) you would you like to add to your account.`
  const changePartnerAffiliationUrl = `mailto:${config.supportEmail}?subject=Wrong partner affiliation&body=${body}`

  return (
    <WebUI.Alert
      aria-label="Delete account confirmation"
      ref={forwardedRef}
      {...restProps}
    >
      <WebUI.AlertHeader>Delete Account Confirmation</WebUI.AlertHeader>
      <WebUI.AlertContentView
        text={
          <div className="flex flex-col gap-3">
            <span>
              Since this action cannot be undone, we want to make sure we
              understand your goals.
            </span>
            <div className="font-bold text-ds-base text-gray800">
              Want to cancel a paid subscription?
            </div>
            <Link
              className="font-light"
              variant="primary"
              to="../../plan-billing"
            >
              Please go here to downgrade.
            </Link>
            <div className="font-bold text-ds-base text-gray800">
              Sign up for the wrong partner affiliation?
            </div>
            <a className="font-light" href={changePartnerAffiliationUrl}>
              Please contact us and our Support team can help.
            </a>
          </div>
        }
        actions={
          <>
            <WebUI.AlertCancelButton>Keep my account</WebUI.AlertCancelButton>
            <DeleteAccountAlert
              disclosure={
                <WebUI.DialogDisclosure as={WebUI.AlertActionButton}>
                  Delete my account
                </WebUI.DialogDisclosure>
              }
            />
          </>
        }
      />
    </WebUI.Alert>
  )
})

export const DeleteAccountAlert = React.forwardRef<
  WebUI.DialogInstance,
  WebUI.AlertProps
>((props, forwardedRef) => {
  const navigate = useNavigate()
  const [value, setValue] = useState('')
  const updateSubscriptionMutation = useUpdateSubscriptionMutation()
  const deleteUserMutation = useDeleteUserMutation()
  const growlActions = WebUI.useGrowlActions()

  return (
    <WebUI.Alert aria-label="Delete account" ref={forwardedRef} {...props}>
      {(dialog) => (
        <>
          <WebUI.AlertHeader className="text-orange-500">
            Delete Account
          </WebUI.AlertHeader>

          <div className="flex flex-col gap-4 p-6">
            <WebUI.Text className="font-light text-gray800">
              Since this action cannot be undone, we want to make sure we
              understand your goals.
            </WebUI.Text>

            <WebUI.FormField label="Type DELETE to confirm">
              <WebUI.Input
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </WebUI.FormField>

            <div className="flex flex-row gap-3">
              <WebUI.Button variant="secondary" onClick={() => dialog.hide()}>
                Keep my account
              </WebUI.Button>
              <WebUI.Button
                variant="primary"
                disabled={value !== 'DELETE'}
                onClick={async () => {
                  try {
                    await updateSubscriptionMutation.mutateAsync({
                      body: {plan: 'free', force_update: true, source_id: ''},
                    })
                    await deleteUserMutation.mutateAsync()
                    navigate('/thankyou')
                  } catch {
                    growlActions.show('error', {
                      title: 'Sorry!',
                      body: 'We are not able to delete your account now. Please try again later.',
                    })
                  }
                }}
              >
                Delete my account
              </WebUI.Button>
            </div>
          </div>
        </>
      )}
    </WebUI.Alert>
  )
})
