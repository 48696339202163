import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {
  Dialog,
  DialogDisclosure,
  ModalContent,
  NextButton,
  NextHeading,
} from '@cheddarup/web-ui/next'
import {useEffect, useMemo, useRef} from 'react'
import {useParams} from 'react-router-dom'
import {SearchForm} from 'src/components'
import {NextLink} from 'src/components/__next/Link'
import FooterBig from 'src/components/FooterBig'
import {LinkButton} from 'src/components/LinkButton'
import {NavListItemNavLink} from 'src/components/NavListItemNavLink'
import {StringParam, useQueryParam, withDefault} from 'use-query-params'

export interface TemplatesLayoutProps
  extends React.ComponentPropsWithoutRef<'div'> {}

export const TemplatesLayout: React.FC<TemplatesLayoutProps> = ({
  className,
  children,
  ...restProps
}) => {
  const media = WebUI.useMedia()

  useEffect(() => {
    window.zE?.('webWidget', 'hide')

    return () => {
      window.zE?.('webWidget', 'show')
    }
  }, [])

  const [searchTerm, setSearchTerm] = useQueryParam(
    'q',
    withDefault(StringParam, ''),
  )
  const templatesQuery = api.templates.list.useQuery({
    queryParams: {
      orderBy: {
        key: 'categories',
      },
    },
  })

  const groupTypes = Util.unique(
    templatesQuery.data?.flatMap((template) =>
      (template?.options?.template?.groups ?? []).map((g) => g.type),
    ) ?? [],
  )
  const categories = Util.unique(
    templatesQuery.data?.flatMap((template) =>
      (template?.options?.template?.categories ?? []).map((c) => c.type),
    ) ?? [],
  )

  return (
    <>
      <div
        className={WebUI.cn(
          'flex shrink-0 grow basis-auto flex-col bg-depr-grey-200',
          className,
        )}
        {...restProps}
      >
        <div
          className={
            'flex w-full max-w-[calc(1080px+2*theme(spacing.4))] flex-col items-center gap-9 self-center py-16 *:px-8 sm:*:px-4'
          }
        >
          <div className="flex flex-col items-center gap-2 text-center">
            <NextHeading className="text-h-1">
              Explore our Template Library
            </NextHeading>
            <WebUI.Text className="max-w-[720px] text-balance text-ds-lg">
              Tap into some inspiration with our collection templates from
              Cheddar Up as well as other group organizers. See something you
              like? Make it your own!
            </WebUI.Text>
          </div>

          <div className="flex w-full flex-col gap-8">
            <SearchForm
              containerClassName="max-w-[800px] w-full"
              className={
                'w-full [&_.Input]:rounded-xl [&_.Input]:bg-trueWhite [&_.Input]:px-7 [&_.Search-cancelButton]:right-6'
              }
              placeholder="Search by keyword"
              values={useMemo(() => ({term: searchTerm}), [searchTerm])}
              onTermChange={(newSearchTerm) => setSearchTerm(newSearchTerm)}
            />

            {(groupTypes.length > 0 || categories.length > 0) &&
              (media.sm ? (
                <TabTemplatesFilters
                  className="self-stretch"
                  groupTypes={groupTypes}
                  categories={categories}
                />
              ) : (
                <TabTemplatesFiltersCompact
                  groupTypes={groupTypes}
                  categories={categories}
                />
              ))}
          </div>
        </div>
        {children}
      </div>
      <FooterBig />
    </>
  )
}

// MARK: – TabTemplatesFilter

interface TabTemplatesFiltersProps
  extends React.ComponentPropsWithoutRef<'div'> {
  groupTypes: Api.TabTemplateGroupType[]
  categories: Api.TemplateCollectionCategory[]
}

const TabTemplatesFilters: React.FC<TabTemplatesFiltersProps> = ({
  groupTypes,
  categories,
  className,
  ...restProps
}) => {
  const urlParams = useParams()
  const categoriesContainerRef = useRef<HTMLDivElement>(null)
  const visibleCategoriesCount = WebUI.useSizeSelector(
    categoriesContainerRef,
    ([width]) => Math.round(width / 150) - 1,
  )

  const selectedCategory =
    urlParams.filterType === 'category' ? urlParams.filterValue : null
  const selectedGroupType =
    urlParams.filterType === 'group-type' ? urlParams.filterValue : null

  const categoriesOrdered = Util.sort(categories).asc((cat) => {
    const index = templateCategoriesPickerOrder.indexOf(cat)
    return index > -1 ? index : Number.MAX_SAFE_INTEGER
  })
  const visibleCategories = categoriesOrdered.slice(0, visibleCategoriesCount)
  const collapsedCategories = categoriesOrdered.slice(visibleCategoriesCount)

  const isCollapsedCategorySelected =
    selectedCategory &&
    (collapsedCategories as string[]).includes(selectedCategory)

  return (
    <div
      className={WebUI.cn('flex max-w-full flex-row gap-3', className)}
      {...restProps}
    >
      {groupTypes.length > 0 && (
        <>
          <WebUI.DropdownSelect
            popoverClassName="[&_.PopoverContent-body]:rounded-[10px] [&_.DropdownSelectOption]:rounded [&_.DropdownSelectOption]:px-4"
            size="compact"
            placeholder="Group Type"
            value={selectedGroupType}
            disclosure={
              <WebUI.DropdownSelectButton
                as={WebUI.RoundedButton}
                size="default"
                variant={
                  selectedGroupType == null ? 'outlined' : 'secondaryAlt'
                }
                iconAfter={<WebUI.PhosphorIcon icon="caret-down-fill" />}
              />
            }
          >
            {groupTypes.map((groupType) => (
              <WebUI.DropdownSelectOption
                key={groupType}
                value={groupType}
                as={LinkButton}
                variant={
                  groupType === selectedGroupType ? 'secondaryAlt' : 'text'
                }
                to={`group-type/${groupType}`}
              >
                {groupType || 'Group Type'}
              </WebUI.DropdownSelectOption>
            ))}
          </WebUI.DropdownSelect>

          <WebUI.Separator orientation="vertical" variant="black" />
        </>
      )}

      <div
        ref={categoriesContainerRef}
        className="flex flex-auto flex-row gap-3 overflow-hidden"
      >
        {visibleCategories.map((categoryTitle, idx) => {
          const isSelected = categoryTitle === selectedCategory

          return (
            <WebUI.RoundedButton
              key={`${categoryTitle}-${idx}`}
              className={
                'w-[140px] transition-colors duration-100 ease-in-out [&_.Button-content]:ml-0 [&_.Checkbox-icon]:hidden'
              }
              size="default"
              as={LinkButton}
              variant={isSelected ? 'secondaryAlt' : 'outlined'}
              to={isSelected ? '.' : `category/${categoryTitle}`}
            >
              {categoryTitle}
            </WebUI.RoundedButton>
          )
        })}

        {collapsedCategories.length > 0 && (
          <WebUI.DropdownSelect
            popoverClassName="[&_.PopoverContent-body]:rounded-[10px] [&_.DropdownSelectOption]:rounded [&_.DropdownSelectOption]:px-4"
            size="compact"
            placeholder="More"
            value={selectedCategory}
            disclosure={
              <WebUI.DropdownSelectButton
                as={WebUI.RoundedButton}
                size="default"
                variant={
                  isCollapsedCategorySelected ? 'secondaryAlt' : 'outlined'
                }
                iconAfter={<WebUI.PhosphorIcon icon="caret-down-fill" />}
              />
            }
          >
            {collapsedCategories.map((categoryTitle) => (
              <WebUI.DropdownSelectOption
                key={categoryTitle}
                value={categoryTitle}
                as={LinkButton}
                variant={
                  categoryTitle === selectedCategory ? 'secondaryAlt' : 'text'
                }
                to={`category/${categoryTitle}`}
              >
                {categoryTitle}
              </WebUI.DropdownSelectOption>
            ))}
          </WebUI.DropdownSelect>
        )}
      </div>
    </div>
  )
}

// MARK: – TabTemplatesFiltersCompact

interface TabTemplatesFiltersCompactProps
  extends React.ComponentPropsWithoutRef<'div'> {
  groupTypes: Api.TabTemplateGroupType[]
  categories: Api.TemplateCollectionCategory[]
}

const TabTemplatesFiltersCompact: React.FC<TabTemplatesFiltersCompactProps> = ({
  groupTypes,
  categories,
  className,
  ...restProps
}) => {
  const categoriesOrdered = Util.sort(categories).asc((cat) => {
    const index = templateCategoriesPickerOrder.indexOf(cat)
    return index > -1 ? index : Number.MAX_SAFE_INTEGER
  })

  return (
    <Dialog
      disclosure={
        <DialogDisclosure render={<NextButton size="md" roundness="pill" />}>
          <div />
          Browse by Category
          <WebUI.PhosphorIcon
            className="-translate-y-1/2 absolute top-1/2 right-5 text-ds-xl"
            icon="faders-horizontal-bold"
          />
        </DialogDisclosure>
      }
    >
      {(dialog) => (
        <ModalContent
          className={WebUI.cn('bg-trueWhite px-8', className)}
          variant="rightToLeft"
          {...restProps}
        >
          <div className="flex min-h-0 grow flex-col divide-y">
            <div className="flex flex-col gap-2 py-6 pt-12">
              <NextHeading className="font-body font-bold text-ds-lg" as="h2">
                Template Categories
              </NextHeading>
              <NextLink
                className="font-normal"
                to="/templates"
                onClick={() => dialog.hide()}
              >
                View All Templates
              </NextLink>
            </div>

            <WebUI.NavList
              className="max-h-full grow overflow-y-auto py-6"
              render={<div />}
            >
              <WebUI.NavListContent>
                {categoriesOrdered.length > 0 && (
                  <WebUI.NavListSub
                    defaultVisible
                    disclosure={
                      <WebUI.NavListItem>
                        <WebUI.NavListSubDisclosure>
                          Use Case
                        </WebUI.NavListSubDisclosure>
                      </WebUI.NavListItem>
                    }
                  >
                    {categoriesOrdered.map((category) => (
                      <WebUI.NavListItem key={category}>
                        <NavListItemNavLink
                          to={`category/${category}`}
                          onClick={() => dialog.hide()}
                        >
                          {category}
                        </NavListItemNavLink>
                      </WebUI.NavListItem>
                    ))}
                  </WebUI.NavListSub>
                )}

                {groupTypes.length > 0 && (
                  <WebUI.NavListSub
                    defaultVisible={categoriesOrdered.length === 0}
                    disclosure={
                      <WebUI.NavListItem>
                        <WebUI.NavListSubDisclosure>
                          Group Type
                        </WebUI.NavListSubDisclosure>
                      </WebUI.NavListItem>
                    }
                  >
                    {groupTypes.map((groupType) => (
                      <WebUI.NavListItem key={groupType}>
                        <NavListItemNavLink
                          to={`group-type/${groupType}`}
                          onClick={() => dialog.hide()}
                        >
                          {groupType}
                        </NavListItemNavLink>
                      </WebUI.NavListItem>
                    ))}
                  </WebUI.NavListSub>
                )}
              </WebUI.NavListContent>
            </WebUI.NavList>
          </div>
        </ModalContent>
      )}
    </Dialog>
  )
}

// MARK: – Helpers

export const templateCategoriesPickerOrder: Api.TemplateCollectionCategory[] = [
  'Fundraisers',
  'Events',
  'Online Stores',
  'Dues & Fees',
  'Registrations',
  'Sign Ups',
  'Camps & Classes',
  'Group Gifts',
  'Group Travel',
  'Staff Picks',
  'Seasonal Collections',
]
