import {forwardRef} from 'react'

import {PhosphorIcon} from '../icons'
import {DialogDisclosure, DialogInstance} from './Dialog'
import {IconButton} from './IconButton'
import {Modal, ModalProps} from './Modal'
import {cn} from '../utils'

export interface DrawerProps extends ModalProps {
  disclosureClassName?: string
}

export const Drawer = forwardRef<DialogInstance, DrawerProps>(
  ({disclosureClassName, className, ...restProps}, forwardedRef) => (
    <Modal
      ref={forwardedRef}
      aria-label="Navigation menu"
      className={cn('Drawer', className)}
      initialVisible={false}
      contentViewAppearance="leftToRight"
      disclosure={
        <DialogDisclosure
          className={cn(
            'absolute top-0 left-0 rounded-t-none rounded-l-none p-2 text-depr-aqua-100 shadow-[2px_2px_8px_0px_rgba(0,0,0,0.2)]',
            disclosureClassName,
          )}
          as={IconButton}
          variant="default"
        >
          <PhosphorIcon
            className="Drawer-disclosureIcon"
            icon="caret-right-fill"
          />
        </DialogDisclosure>
      }
      {...restProps}
    />
  ),
)
