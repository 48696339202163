import {useLocation, useNavigate} from 'react-router-dom'
import React from 'react'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {Elements} from 'src/components/Stripe'
import {WithSetupIntent} from 'src/components/WithSetupIntent'

import {FieldPanel} from './UpgradePlanForm/UpgradePlanForm'
import UpgradePlanForm from './UpgradePlanForm'

export interface UpgradePlanContainerProps {
  fieldPanels: FieldPanel[]
  heading: React.ReactNode
  subheading: React.ReactNode
}

const UpgradePlanContainer = ({
  fieldPanels,
  heading,
  subheading,
}: UpgradePlanContainerProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <WebUI.Modal
      aria-label="Plan select"
      className={
        '[&_>_.ModalContentView]:max-w-screen-md [&_>_.ModalContentView]:overflow-auto [&_>_.ModalContentView]:bg-trueWhite [&_>_.ModalContentView]:px-4 [&_>_.ModalContentView]:py-8 lg:[&_>_.ModalContentView]:px-8 lg:[&_>_.ModalContentView]:py-10'
      }
      onDidHide={() => {
        navigate(
          {
            pathname: '..',
            // hack for react-native WebView to detect url change
            hash: Util.makeShortId(),
          },
          {state: location.state},
        )
      }}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <WebUI.VStack className="gap-8">
            <WebUI.VStack className="gap-2">
              <WebUI.Heading className="text-ds-2xl" as="h1">
                {heading}
              </WebUI.Heading>
              <p>{subheading}</p>
            </WebUI.VStack>
            <WithSetupIntent>
              {({clientSecret}) => (
                <Elements options={{clientSecret}}>
                  <UpgradePlanForm
                    fieldPanels={fieldPanels}
                    onDidUpdateSubscription={() => dialog.hide()}
                  />
                </Elements>
              )}
            </WithSetupIntent>
          </WebUI.VStack>
        </>
      )}
    </WebUI.Modal>
  )
}

export default UpgradePlanContainer
