import {useEffect, useRef} from 'react'
import {NumberParam, useQueryParam, withDefault} from 'use-query-params'
import SignupLady from 'src/images/SignupLady.png'
import FromTypeStar from 'src/images/FormTypeStar.png'
import * as WebUI from '@cheddarup/web-ui'
import {ActionButton} from 'src/components/ActionButton'
import {LinkButton} from 'src/components/LinkButton'
import {TemplatesSuggestionsModal} from 'src/components/TemplatesSuggestionsModal'

const WelcomeModal = () => {
  const [newSignup, setNewSignup] = WebUI.useLocalStorage(
    'cu-new-signup',
    false,
  )
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const [convertedTabId] = useQueryParam(
    'convertedTabId',
    withDefault(NumberParam, null),
  )

  useEffect(() => {
    if (convertedTabId) {
      modalRef.current?.hide()
    }
  }, [convertedTabId])

  return (
    <WebUI.Modal
      aria-label="Welcome Modal"
      className="sm:[&_>_.ModalContentView]:max-h-[600px] sm:[&_>_.ModalContentView]:max-w-[915px] sm:[&_>_.ModalContentView]:rounded-extended"
      role="dialog"
      initialVisible={newSignup}
      onDidHide={() => setNewSignup(false)}
      ref={modalRef}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <WebUI.VStack className="sm:flex-row sm:gap-7">
            <WebUI.VStack className="gap-8 px-8 py-12 sm:px-11 sm:py-20">
              <WebUI.VStack>
                <WebUI.Heading className="font-accentAlt text-ds-3xl text-trueBlack leading-none">
                  Welcome to Cheddar Up!
                </WebUI.Heading>
                <WebUI.Heading as="h3" className="font-extrabold">
                  What would you like to collect for?
                </WebUI.Heading>
              </WebUI.VStack>
              <WebUI.Text className="text-ds-md">
                Whether you’re collecting dues and fees, fundraising, managing
                an event, creating a sign up, or selling something online or
                in-person, you can do it with a Cheddar Up collection.
              </WebUI.Text>
              <WebUI.HStack className="gap-5 sm:gap-8">
                <ActionButton
                  iconBefore={
                    <WebUI.PhosphorIcon
                      icon="plus"
                      color="#f36d36"
                      height={70}
                      width={70}
                    />
                  }
                  title="Start from Scratch"
                  description="Add details, items and forms. Easy!"
                  onClick={() => dialog.hide()}
                  as={LinkButton}
                  to="."
                />
                <TemplatesSuggestionsModal
                  initialVisible={false}
                  onDidShow={() => setNewSignup(false)}
                  disclosure={
                    <WebUI.DialogDisclosure
                      as={ActionButton}
                      iconBefore={
                        <WebUI.Image
                          src={FromTypeStar}
                          height={75}
                          width="auto"
                          alt=""
                        />
                      }
                      title="Use a Template"
                      description="Choose from over 100 premade templates"
                    />
                  }
                />
              </WebUI.HStack>
            </WebUI.VStack>
            <WebUI.VStack className="gap-6 bg-[#F9C442] px-7 pt-12 sm:pt-20">
              <WebUI.Heading as="h3" className="max-w-56 font-extrabold">
                Did you know you can add sign ups to your collections?
              </WebUI.Heading>
              <WebUI.Text>
                From snack schedules to volunteer rosters, we offer a beautiful
                sign up experience without the clutter! Add sign ups in the form
                step when creating your collection.
              </WebUI.Text>
              <WebUI.Image src={SignupLady} height="auto" width="auto" alt="" />
            </WebUI.VStack>
          </WebUI.VStack>
        </>
      )}
    </WebUI.Modal>
  )
}

export default WelcomeModal
