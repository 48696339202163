import * as Yup from 'yup'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {CalendarDate, getLocalTimeZone} from '@internationalized/date'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCreateItemMutation,
  useUpdateItemMutation,
} from '@cheddarup/api-client'
import ImagesUtils from 'src/helpers/ImagesUtils'
import {saveItemImages} from 'src/helpers/item-helpers'
import {useSaveFields} from 'src/hooks/fields'
import * as Util from '@cheddarup/util'
import {readApiError} from 'src/helpers/error-formatting'
import type {FieldsEditValue} from '@cheddarup/core'

import {
  ItemFormImagesAndDescription,
  ItemFormItemFields,
} from '../../components'
import RecurringItemFormDetails from './RecurringItemFormDetails'
import {ItemFormValues, getOrderedImages} from '../FixedItemForm/FixedItemForm'
import RecurringItemFormSettings from './RecurringItemFormSettings'
import {useFormik} from '@cheddarup/react-util'

export interface RecurringItemFormValues extends ItemFormValues {
  amount_type: Api.TabAmountType
  options: {
    subcategoryId: string | null
    makeAvailableQuantityPublic: boolean
    recurring: {
      enabled: boolean
      options: {
        start: {
          type: 'first_payment' | 'date'
          date?: CalendarDate
        }
        ends: {
          type: 'payment_count' | 'never'
          payment_count: string
        }
        repeatInterval: string
      }
    }
    waitlist: {
      enabled: boolean
      customMessage: string
    }
  }
}

export type RecurringItemFormFormik = ReturnType<
  typeof useFormik<RecurringItemFormValues>
>

Yup.setLocale({mixed: {notType: 'Must be a number'}})

export interface RecurringItemFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onSubmit' | 'onReset'> {
  collectionId: number
  item?: Api.TabItem
  fields?: Api.TabObjectField[]
  onDismiss: () => void
  onDirtyChange?: (value: boolean) => void
}

const RecurringItemForm: React.FC<RecurringItemFormProps> = ({
  collectionId,
  item,
  fields,
  onDismiss,
  onDirtyChange,
  className,
  ...restProps
}) => {
  const [selectedTabId, setSelectedTabId] = useState('details')
  const {data: availableQuantityOneByDefault} = api.auth.session.useQuery(
    undefined,
    {
      select: (session) => session.user.availableQuantityOneByDefault,
    },
  )
  const createItemMutation = useCreateItemMutation()
  const updateItemMutation = useUpdateItemMutation()
  const saveFields = useSaveFields()
  const fieldsEditValueRef = useRef<FieldsEditValue[]>([])
  const tabsRef = useRef<WebUI.TabsInstance>(null)
  const growlActions = WebUI.useGrowlActions()

  const ownAvailableQuantity = item?.inventory_items.find(
    (ii) => ii.variant_uuid === 'NONE',
  )?.available_quantity

  const formik = useFormik<RecurringItemFormValues>({
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Required')
        .max(75, 'Must be 75 characters or less'),
      description: Yup.string().max(4000, 'Must be 4000 characters or less'),
      amount_type: Yup.string().oneOf(['open', 'fixed']).required('Required'),
      amount: Yup.number().when(
        ['amount_type', 'options.variants.enabled'],
        ([amountType, variantsEnabled], schema) =>
          amountType === 'fixed' && !variantsEnabled
            ? schema
                .required('Required')
                .max(999999, 'Price must be less than 1,000,000.00')
            : schema,
      ),
      options: Yup.object().shape({
        recurring: Yup.object().shape({
          enabled: Yup.boolean(),
          options: Yup.object().shape({
            ends: Yup.object().shape({
              type: Yup.string(),
              payment_count: Yup.number().when('type', {
                is: 'payment_count',
                // biome-ignore lint/suspicious/noThenProperty:
                then: (schema) => schema.required(),
              }),
            }),
          }),
        }),
        waitlist: Yup.object().shape({
          enabled: Yup.boolean(),
          customMessage: Yup.string(),
        }),
      }),
      available_quantity_enabled: Yup.boolean(),
      available_quantity: Yup.number().when('available_quantity_enabled', {
        is: true,
        // biome-ignore lint/suspicious/noThenProperty:
        then: (schema) =>
          schema
            .transform((v) => (Number.isNaN(v) ? undefined : v))
            .required('Required'),
      }),
      limit_per_person_quantity_enabled: Yup.boolean(),
      limit_per_person_quantity: Yup.number().when(
        'limit_per_person_quantity_enabled',
        {
          is: true,
          // biome-ignore lint/suspicious/noThenProperty:
          then: (schema) =>
            schema
              .transform((v) => (Number.isNaN(v) ? undefined : v))
              .required('Required'),
        },
      ),
    }),
    initialValues: {
      name: item?.name ?? '',
      parent_id: item?.parent_id ?? null,
      amount_type: item?.amount_type ?? 'fixed',
      amount: item?.amount == null ? '' : String(item.amount.toFixed(2)),
      limit_per_person_quantity_enabled:
        item?.options?.perPersonMaxQuantity?.enabled ?? false,
      limit_per_person_quantity: item?.options?.perPersonMaxQuantity?.value
        ? String(item?.options?.perPersonMaxQuantity?.value)
        : '',
      options: {
        subcategoryId: item?.options?.subcategoryId ?? null,
        makeAvailableQuantityPublic:
          item?.options?.makeAvailableQuantityPublic ?? false,
        recurring: {
          enabled: true,
          options: {
            start: {
              type:
                item?.options?.recurring?.options?.start?.type ??
                'first_payment',
              date:
                Util.parseCalendarDate(
                  item?.options?.recurring?.options?.start?.date ?? '',
                ) ?? undefined,
            },
            ends: {
              type: item?.options?.recurring?.options?.ends?.type ?? 'never',
              payment_count:
                item?.options?.recurring?.options?.ends?.payment_count ?? '',
            },
            repeatInterval:
              item?.options?.recurring?.options?.repeatInterval ?? 'P1M',
          },
        },
        waitlist: {
          enabled: item?.options?.waitlist?.enabled ?? false,
          customMessage: item?.options?.waitlist?.customMessage ?? '',
        },
      },
      allow_quantity:
        item?.allow_quantity ?? item?.options?.variants?.enabled ?? false,
      available_quantity_enabled:
        (!item && !!availableQuantityOneByDefault) || item
          ? ownAvailableQuantity != null
          : false,
      available_quantity: String(
        !item && !!availableQuantityOneByDefault
          ? 1
          : (ownAvailableQuantity ?? ''),
      ),
      description: item?.description ?? '',
      required: item?.required ?? false,
      images: getOrderedImages(item?.images ?? []).map((itemImage) => ({
        // biome-ignore lint/style/noNonNullAssertion:
        id: itemImage.id!,
        contentType: itemImage.metadata.contentType ?? 'image/jpeg',
        thumbnailCrop:
          Object.keys(itemImage.metadata.thumbnail.cropDetails ?? {}).length > 0
            ? itemImage.metadata.thumbnail.cropDetails
            : null,
        image: {
          ...itemImage,
          preview: ImagesUtils.getImageUrl(itemImage),
        },
      })) as any[],
    },
    onSubmit: async ({images, ...values}) => {
      const payload = {
        name: values.name,
        description: values.description,
        required: values.required,
        parent_id: values.parent_id,
        amount: Number(values.amount),
        amount_type: values.amount_type,
        available_quantity_enabled: values.available_quantity_enabled,
        allow_quantity: values.allow_quantity,
        available_quantity: values.available_quantity_enabled
          ? Number(values.available_quantity)
          : null,
        options: {
          subcategoryId: values.options.subcategoryId,
          itemSubType: 'recurring' as const,
          makeAvailableQuantityPublic:
            values.options.makeAvailableQuantityPublic,
          recurring: {
            enabled: true,
            options: {
              start: {
                type: values.options.recurring.options.start.type,
                date: values.options.recurring.options.start.date
                  ?.toDate(getLocalTimeZone())
                  .toISOString(),
              },
              ends: {
                type: values.options.recurring.options.ends.type,
                payment_count: values.options.recurring.options.ends
                  .payment_count
                  ? Number(values.options.recurring.options.ends.payment_count)
                  : undefined,
              },
              repeatInterval: values.options.recurring.options.repeatInterval,
            },
          },
          waitlist: {
            enabled:
              values.available_quantity_enabled &&
              values.options.waitlist.enabled,
            customMessage: values.options.waitlist.customMessage,
          },
          perPersonMaxQuantity: {
            enabled: values.limit_per_person_quantity_enabled,
            value: values.limit_per_person_quantity_enabled
              ? Number(values.limit_per_person_quantity)
              : 0,
          },
          fieldSets: fieldsEditValueRef.current.map((fev) => fev.fieldSet),
        },
      }

      const localFields = fieldsEditValueRef.current.flatMap(
        (fev) => fev.fields,
      )

      const someCheckboxOrMultipleChoiceFieldsEmpty = localFields
        .filter(
          (f) =>
            f.field_type === 'checkbox' || f.field_type === 'multiple_choice',
        )
        .some((f) => 'values' in f && f.values?.length === 0)
      if (someCheckboxOrMultipleChoiceFieldsEmpty) {
        growlActions.show('error', {
          title: 'Error',
          body: 'Checkbox and dropdown questions require at least one option',
        })
        tabsRef.current?.select('questions')
        return
      }

      let savedItem = item ?? null

      try {
        if (item) {
          savedItem = await updateItemMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
              itemId: item.id,
            },
            body: payload,
          })
        } else {
          savedItem = await createItemMutation.mutateAsync({
            pathParams: {
              tabId: collectionId,
            },
            body: payload,
          })
        }

        await saveItemImages({
          tabId: collectionId,
          item: savedItem,
          images: images.filter((image) => image != null),
        })

        if (savedItem) {
          await saveFields({
            tabId: collectionId,
            tabObjectId: savedItem.id,
            tabObjectType: 'item',
            existingFields: fields ?? [],
            newFields: localFields,
          })
        }
        onDismiss()
      } catch (err) {
        const errMessage = readApiError(err, {
          recurring_contracts_active: () =>
            'This item has active recurring payers and cannot be updated. If you wish to make changes, please clone the item and hide this version.',
        })
        if (errMessage) {
          growlActions.clear()
          growlActions.show('error', {body: errMessage})
        }
      }
    },
  })

  const itemImages = useMemo(() => item?.images ?? [], [item?.images])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    onDirtyChange?.(formik.dirty)
  }, [formik.dirty])

  return (
    <form
      className={WebUI.cn('flex min-h-0 flex-col', className)}
      noValidate
      onSubmit={async (event) => {
        const errors = await formik.validateForm()

        if (Object.keys(errors).length > 0) {
          if (
            formik.errors.available_quantity_enabled ||
            formik.errors.available_quantity
          ) {
            tabsRef.current?.select('settings')
          } else if (Object.keys(formik.errors).length > 0) {
            tabsRef.current?.select('details')
          }
        }

        formik.handleSubmit(event)
      }}
      onReset={formik.handleReset}
      {...restProps}
    >
      <WebUI.Tabs
        ref={tabsRef}
        className="min-h-0 grow [&_>_.TabPanel:not([id=questions])]:overflow-y-auto [&_>_.TabPanel:not([id=questions])]:p-6 sm:[&_>_.TabPanel:not([id=questions])]:px-13 sm:[&_>_.TabPanel:not([id=questions])]:py-6 [&_>_.TabPanel[id=questions]]:overflow-y-hidden [&_>_.TabPanel]:grow"
        variant="underlined"
        onChangeSelectedId={(newSelectedId) => {
          if (newSelectedId != null) {
            setSelectedTabId(newSelectedId)
          }
        }}
      >
        <WebUI.TabList
          aria-label="Item form navigation"
          className="mx-6 flex-0 border-b-0 sm:mx-13 [&_>_.TabList-underline]:bg-orange-500 [&_>_.Tab_>_.Button-content]:font-normal [&_>_.Tab_>_.Button-content]:text-ds-sm sm:[&_>_.Tab_>_.Button-content]:text-ds-md"
        >
          <WebUI.Tab id="details">Details</WebUI.Tab>
          <WebUI.Tab id="description">Description</WebUI.Tab>
          <WebUI.Tab id="settings">Settings</WebUI.Tab>
          <WebUI.Tab id="questions">Questions</WebUI.Tab>
        </WebUI.TabList>

        <WebUI.Separator variant="primary" />

        <WebUI.TabPanel id="details">
          <RecurringItemFormDetails
            formik={formik}
            collectionId={collectionId}
            itemImages={itemImages}
          />
        </WebUI.TabPanel>
        <WebUI.TabPanel id="description">
          <ItemFormImagesAndDescription
            collectionId={collectionId}
            formik={formik}
          />
        </WebUI.TabPanel>
        <WebUI.TabPanel id="settings">
          <RecurringItemFormSettings
            formik={formik}
            tabId={collectionId}
            item={item}
          />
        </WebUI.TabPanel>
        <WebUI.TabPanel id="questions">
          <ItemFormItemFields
            className="max-h-full"
            label="Need to collect information from your payers about this recurring plan?"
            text=""
            initialFieldSets={item?.options.fieldSets ?? undefined}
            initialFields={fields}
            onInit={(initialFieldsEditValue) => {
              fieldsEditValueRef.current = initialFieldsEditValue
            }}
            onChange={(newFieldsEditValue) => {
              const localFieldSets = newFieldsEditValue.map(
                (fev) => fev.fieldSet,
              )
              const localFields = newFieldsEditValue.flatMap(
                (fev) => fev.fields,
              )

              onDirtyChange?.(
                formik.dirty ||
                  !Util.deepEqual(localFieldSets, item?.options.fieldSets) ||
                  !Util.deepEqual(localFields, fields),
              )

              fieldsEditValueRef.current = newFieldsEditValue
            }}
          />
        </WebUI.TabPanel>
      </WebUI.Tabs>

      <WebUI.Separator />
      <WebUI.HStack className="justify-end bg-trueWhite px-4 py-5">
        {!item && selectedTabId !== 'questions' ? (
          <WebUI.Button
            variant="default"
            size="large"
            onClick={() => tabsRef.current?.next()}
          >
            Continue
          </WebUI.Button>
        ) : (
          <WebUI.Button
            type="submit"
            variant="primary"
            size="large"
            loading={formik.isSubmitting}
          >
            Save
          </WebUI.Button>
        )}
      </WebUI.HStack>
    </form>
  )
}

export default RecurringItemForm
