import UpgradePlanContainer from './components/UpgradePlanContainer'

const fieldPanels = [
  {
    title: 'Team Annual',
    description: '$360 charged anually (equal to only $30/month)',
    value: 'team_annual',
    price: 360,
    discountBadge: (
      <div className="text-ds-sm">
        <span className="font-bold text-orange-500">UPGRADE NOW!</span> Price
        will change to $420/year as of January 1, 2025.
      </div>
    ),
  },
  {
    description: '$36 charged monthly',
    title: 'Team Monthly',
    value: 'team_monthly',
    price: 36,
    discountBadge: (
      <div className="text-ds-sm">
        <span className="font-bold text-orange-500">UPGRADE NOW!</span> Price
        will change to $48/month as of January 1, 2025.
      </div>
    ),
  },
]

const TeamUpgradePage = () => (
  <UpgradePlanContainer
    fieldPanels={fieldPanels}
    heading="The Team Plan"
    subheading={
      <>
        The Cheddar Up Team plan is paid yearly or monthly. We make it simple to
        adjust your plan at any time from your My Account page.
      </>
    }
  />
)

export default TeamUpgradePage
