import React from 'react'
import {api} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {LinkButton} from 'src/components/LinkButton'
import ManagersLogoIcon from 'src/images/ManagersLogoIcon.svg'

import {ManagerFormModal} from './ManagerFormModal'
import {useManagerRole} from 'src/components/ManageRoleProvider'

export const ManagersEmptyStateView = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const [managerRole] = useManagerRole()
  const isSubscribedToTeamQuery = api.auth.session.useQuery(undefined, {
    enabled: !managerRole,
    select: (session) => session.capabilities.subscribed_to_team,
  })

  return (
    <div className={WebUI.cn('flex flex-col gap-8', className)} {...restProps}>
      <div className="mt-12 flex justify-center">
        <img src={ManagersLogoIcon} alt="" className="w-[120px]" />
      </div>
      <WebUI.Text className="text-center font-bold text-ds-md">
        You haven’t invited any managers
        <br />
        <span className="font-normal text-gray400">
          Managers will appear here.{' '}
          <WebUI.Anchor
            rel="noopener noreferrer"
            href="https://www.cheddarup.com/user-management/"
            target="_blank"
          >
            Learn more
          </WebUI.Anchor>
        </span>
      </WebUI.Text>
      {(managerRole || isSubscribedToTeamQuery.data === true) && (
        <ManagerFormModal
          disclosure={
            <WebUI.DialogDisclosure className="self-center" variant="primary">
              Add Managers
            </WebUI.DialogDisclosure>
          }
        />
      )}
      {isSubscribedToTeamQuery.data === false && !managerRole && (
        <LinkButton
          className="self-center"
          variant="primary"
          to="/managers/i/plans"
        >
          Upgrade to Team
        </LinkButton>
      )}
    </div>
  )
}
