import {forwardRef} from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'
import {cva} from 'class-variance-authority'

import {VariantsProps, cn} from '../utils'

export const panel = cva('overflow-hidden bg-trueWhite focus:outline-none', {
  variants: {
    variant: {
      default: 'border sm:rounded',
      capsule: 'sm:rounded-extended',
      shadowed: 'shadow-z9',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export interface PanelProps extends VariantsProps<typeof panel> {}

export const Panel = forwardRef(
  (
    {className, as: Comp = 'div', variant = 'default', ...restProps},
    forwardedRef,
  ) => (
    <Comp
      ref={forwardedRef}
      className={cn('Panel', panel({variant}), className)}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'div', PanelProps>
