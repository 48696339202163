import React from 'react'
import {
  BooleanParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import {Navigate, useNavigate} from 'react-router-dom'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'
import {PersonaButton} from 'src/components/PersonaButton'
import {
  useManagerRole,
  useManagerRoleQuery,
} from 'src/components/ManageRoleProvider'
import {MoneyTransferOptionCta} from 'src/views/collection/manage/components'

const WithdrawPageContentContainer = () => {
  const [managerRoleQuery] = useManagerRoleQuery()
  const [identity] = useQueryParam('identity', withDefault(BooleanParam, false))
  const [redirectTo] = useQueryParam('redirectTo', StringParam)
  const {data: isExternalAccountsEmpty} = api.externalAccounts.list.useQuery(
    undefined,
    {
      enabled:
        !managerRoleQuery.isPending &&
        (!managerRoleQuery.data ||
          managerRoleQuery.data.permissions.role === 'admin'),
      select: (externalAccounts) =>
        externalAccounts.banks.length === 0 &&
        externalAccounts.cards.length === 0,
    },
  )
  const sessionQuery = api.auth.session.useQuery(undefined, {
    enabled: !identity,
  })
  const personaIdentityQuery = api.users.personaInquiry.useQuery(undefined, {
    enabled: !!identity,
    refetchInterval: 2500,
  })
  const {data: collections} = api.tabs.list.useQuery()

  const session = personaIdentityQuery.data || sessionQuery.data

  const pendingCollections = (collections ?? []).filter(
    (c) => c.online_pending_total > 0,
  )
  const collectionsWithBalance = (collections ?? []).filter(
    (c) =>
      (managerRoleQuery.data?.permissions.role === 'admin' ||
        !!c?.access?.owner) &&
      c.withdrawal_balance_available > 0,
  )

  const totalWithdrawableAmount = Util.sum(
    (collections ?? []).map((c) => c.withdrawal_balance_available),
  )

  if (
    !managerRoleQuery.data &&
    session?.user.profile?.persona?.required &&
    !session?.user.profile?.persona?.completed
  ) {
    return <PersonaCta />
  }

  if (isExternalAccountsEmpty) {
    return <BankAccountCta />
  }

  if (totalWithdrawableAmount === 0 && redirectTo) {
    // TODO: handle the case when user click back button after redirect
    return <Navigate to={redirectTo} />
  }

  if (collectionsWithBalance.length === 0 && pendingCollections.length === 0) {
    return <CreateCollectionCTA />
  }

  if (
    !managerRoleQuery.data &&
    session?.user?.disabled_reason === 'requirements.pending_verification'
  ) {
    return <VerificationPendingCta />
  }

  if (
    !managerRoleQuery.data &&
    (!session?.stripe_data.payoutsEnabled ||
      (session?.stripe_data?.fieldsNeeded?.length || 0) > 0)
  ) {
    return <MoreInformationCta />
  }

  if (!managerRoleQuery.data && !session?.user.profile?.phone?.verified) {
    return <SetupTwoFactorCta />
  }

  if (collectionsWithBalance.length === 0 && pendingCollections.length > 0) {
    return (
      <PendingCollectionsOnlyCta
        total={Util.sumBy(pendingCollections, (c) => c.online_pending_total)}
      />
    )
  }

  if (!managerRoleQuery.data && identity) {
    return <Navigate to="/withdraw" />
  }

  return (
    <>
      {collectionsWithBalance.map((collection) => (
        <WithdrawPanel
          key={collection.id}
          className="mb-4 bg-gray100"
          collection={collection}
          totalWithdrawableAmount={totalWithdrawableAmount}
          canSendGiftCard={session?.user.currency === 'usd'}
        />
      ))}
    </>
  )
}

// MARK: – WithdrawPanel

interface WithdrawPanelProps extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
  totalWithdrawableAmount: number
  canSendGiftCard: boolean
}

const WithdrawPanel = React.memo<WithdrawPanelProps>(
  ({
    collection,
    totalWithdrawableAmount,
    canSendGiftCard,
    className,
    children,
    ...restProps
  }) => (
    <WebUI.Panel
      className={WebUI.cn(
        'flex max-w-full flex-col gap-4 p-6 sm:w-[400px] sm:rounded',
        className,
      )}
      {...restProps}
    >
      <WebUI.Ellipsis className="text-black" as="h4">
        {collection.name}
      </WebUI.Ellipsis>
      <div className="flex flex-col gap-1">
        <WebUI.Text className="font-light text-ds-sm text-gray800">
          {Util.formatAmount(
            Math.min(
              Math.max(totalWithdrawableAmount, 0),
              collection.withdrawal_balance_available,
            ),
          )}{' '}
          Available
        </WebUI.Text>
        <Link
          className="text-ds-xs"
          variant="primary"
          to={`i/collection/${collection.id}/summary`}
        >
          Balance Summary
        </Link>
      </div>
      <MoneyTransferOptionCta
        className="h-auto max-w-full bg-trueWhite"
        iconBefore={
          <TransferIconWrap className="bg-teal-40">
            <WebUI.PhosphorIcon
              icon="bank"
              width={25}
              className="text-depr-grey-50"
            />
          </TransferIconWrap>
        }
        preserveSearch
        to={{
          pathname: `i/collection/${collection.id}/summary`,
          search: 'selectedTabId=bank-transfer',
        }}
      >
        Transfer to bank account
      </MoneyTransferOptionCta>
      {canSendGiftCard && (
        <MoneyTransferOptionCta
          className="h-auto max-w-full bg-trueWhite"
          iconBefore={
            <TransferIconWrap className="bg-yellow-600">
              <WebUI.PhosphorIcon
                icon="gift"
                width={25}
                className="text-depr-grey-50"
              />
            </TransferIconWrap>
          }
          preserveSearch
          to={{
            pathname: `i/collection/${collection.id}/summary`,
            search: 'selectedTabId=send-gift',
          }}
        >
          Transfer to gift card or prepaid Mastercard
        </MoneyTransferOptionCta>
      )}
    </WebUI.Panel>
  ),
)

// MARK: – BankAccountCta

const BankAccountCta = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const [managerRole] = useManagerRole()
  return (
    <WebUI.VStack
      className={WebUI.cn(
        'max-w-full gap-4 rounded bg-gray100 px-6 py-7 text-gray800 sm:w-[360px]',
        className,
      )}
      {...restProps}
    >
      <WebUI.HStack className="gap-2 [&_>_.TransferIconWrap]:box-content [&_>_.TransferIconWrap]:border-4 [&_>_.TransferIconWrap]:border-trueWhite">
        <TransferIconWrap className="bg-teal-70">
          <WebUI.PhosphorIcon icon="bank" width={25} />
        </TransferIconWrap>
        <TransferIconWrap className="bg-[#f9c442]">
          <WebUI.PhosphorIcon icon="gift" width={25} />
        </TransferIconWrap>
      </WebUI.HStack>
      <WebUI.Heading as="h4">
        {managerRole
          ? 'No bank account linked.'
          : 'Where should we deposit your money?'}
      </WebUI.Heading>
      <WebUI.Text className="font-light text-ds-sm">
        {managerRole
          ? "Contact the account owner and let them know they'll need to link a bank account before withdrawals can be initiated."
          : "Please add your banking information so we know where to deposit the money you've collected."}
      </WebUI.Text>
      {managerRole ? (
        <WebUI.VStack className="[&_>_.Text]:font-light [&_>_.Text_span]:font-normal">
          <WebUI.Text>
            <span>Account:</span> {managerRole.name}
          </WebUI.Text>
          <WebUI.Text>
            <span>Contact:</span> {managerRole.full_name},{' '}
            <WebUI.Anchor
              href={`mailto:${managerRole.email}?subject=Link a bank account to initiate withdrawals`}
            >
              {managerRole.email}
            </WebUI.Anchor>
          </WebUI.Text>
        </WebUI.VStack>
      ) : (
        <LinkButton
          className="self-start"
          variant="primary"
          to="my-account/withdrawal-settings"
        >
          Add a Bank Account
        </LinkButton>
      )}
    </WebUI.VStack>
  )
}

// MARK: - TransferIconWrap

export const TransferIconWrap: React.FC<
  React.ComponentPropsWithoutRef<'span'>
> = ({className, ...restProps}) => (
  <span
    className={WebUI.cn(
      'TransferIconWrap',
      'flex h-[48px] w-[48px] items-center justify-center rounded-full',
      className,
    )}
    {...restProps}
  />
)

// MARK: – CreateCollectionCTA

const CreateCollectionCTA = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.VStack
    className={WebUI.cn(
      'max-w-screen-md gap-3 rounded bg-teal-80 px-8 py-6 text-gray800',
      className,
    )}
    {...restProps}
  >
    <WebUI.Heading as="h4">Let&apos;s get you some cheddar</WebUI.Heading>
    <WebUI.Text className="font-light text-ds-sm">
      Create a collection and start collecting. You can withdraw $ directly into
      your bank account.
    </WebUI.Text>
    <LinkButton className="self-start" variant="primary" to="/collections">
      Create a Collection
    </LinkButton>
  </WebUI.VStack>
)

// MARK: – MoreInformationCta

const MoreInformationCta = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.VStack
    className={WebUI.cn(
      'max-w-screen-md gap-3 rounded bg-teal-80 px-8 py-6 text-gray800',
      className,
    )}
    {...restProps}
  >
    <WebUI.Heading as="h4">More information, please.</WebUI.Heading>
    <WebUI.Text className="font-light text-ds-sm">
      Looks like we need some more information before we can enable withdrawals.
    </WebUI.Text>
    <LinkButton
      className="self-start"
      variant="primary"
      to="my-account/account-details"
    >
      Go to Account Settings
    </LinkButton>
  </WebUI.VStack>
)

// MARK: – VerificationPendingCta

const VerificationPendingCta = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.VStack
    className={WebUI.cn(
      'max-w-screen-md gap-3 rounded bg-teal-80 px-8 py-6 text-gray800',
      className,
    )}
    {...restProps}
  >
    <WebUI.Heading as="h4">You have information in review</WebUI.Heading>
    <WebUI.Text className="font-light text-ds-sm">
      Your account is pending verification. Please check back in 24 hours.
    </WebUI.Text>
  </WebUI.VStack>
)

// MARK: - PersonaCta

const PersonaCta = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const navigate = useNavigate()
  return (
    <WebUI.VStack
      className={WebUI.cn(
        'max-w-screen-md gap-3 rounded bg-teal-80 px-8 py-6 text-gray800',
        className,
      )}
      {...restProps}
    >
      <WebUI.Heading className="font-bold" as="h4">
        Verify your identity to expedite withdrawals
      </WebUI.Heading>
      <WebUI.Text className="font-light text-ds-sm">
        Upload a government ID in combination with a live photo. You will only
        need to do this one time.
      </WebUI.Text>
      <PersonaButton
        className="self-start"
        variant="primary"
        onComplete={() => setTimeout(() => navigate('/withdraw?identity=1'), 0)}
      />
    </WebUI.VStack>
  )
}

// MARK: – SetupTwoFactorCta

const SetupTwoFactorCta = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <WebUI.VStack
    className={WebUI.cn(
      'max-w-screen-md gap-3 rounded bg-teal-80 px-8 py-6 text-gray800',
      className,
    )}
    {...restProps}
  >
    <WebUI.Heading as="h4">
      Time to set up two-factor authentication
    </WebUI.Heading>
    <WebUI.Text className="font-light text-ds-sm">
      Two-factor authentication helps ensure you&apos;re the only person who can
      access your account. Once you verify your phone number, you&apos;ll be
      able to withdraw.
    </WebUI.Text>
    <LinkButton
      className="self-start"
      variant="primary"
      to="my-account/security"
    >
      Go to Account Settings
    </LinkButton>
  </WebUI.VStack>
)

// MARK: – PendingCollectionsOnlyCta

interface PendingCollectionsOnlyCtaProps
  extends React.ComponentPropsWithoutRef<'div'> {
  total: number
}

const PendingCollectionsOnlyCta = ({
  total,
  className,
  ...restProps
}: PendingCollectionsOnlyCtaProps) => (
  <WebUI.VStack
    className={WebUI.cn(
      'max-w-screen-md gap-3 rounded bg-teal-80 px-8 py-6 text-gray800',
      className,
    )}
    {...restProps}
  >
    <WebUI.Heading as="h4">No Funds Available For Withdrawal</WebUI.Heading>
    <WebUI.Text className="font-light text-ds-sm">
      You have {Util.formatAmount(total)} in pending payments. Check back here
      in 3-5 business days to withdraw!
    </WebUI.Text>
  </WebUI.VStack>
)

export default WithdrawPageContentContainer
