import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {useFormik, useLiveRef} from '@cheddarup/react-util'
import {FormikHandlers, FormikHelpers, FormikState} from 'formik'
import React, {useEffect, useRef} from 'react'
import {api, useUpdateTabMutation} from '@cheddarup/api-client'
import {SearchAndSelectableItems} from 'src/components'

// MARK: – TaxItem

export interface TaxItemProps extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  index: number
  tax: Api.TabTax
  onDelete: (index: number) => void
}

export const TaxItem = ({
  collectionId,
  tax,
  index,
  onDelete,
  className,
  ...restProps
}: TaxItemProps) => (
  <WebUI.HStack
    className={WebUI.cn(
      'items-center gap-4 border px-4 py-3 *:flex-[1_0_0px]',
      className,
    )}
    {...restProps}
  >
    <TaxFormModal
      disclosure={
        <WebUI.DialogDisclosure className="font-light text-tint" variant="link">
          {tax?.name ?? ''}: {((tax?.rate ?? 0) * 100).toFixed(3)}%
        </WebUI.DialogDisclosure>
      }
      collectionId={collectionId}
      tax={tax}
      taxIdx={index}
    />
    <WebUI.Text className="text-right font-light text-ds-sm text-gray400">
      {tax.applied_to === 'all'
        ? 'All items'
        : `${tax.applicable_items?.length} item(s)`}
    </WebUI.Text>
    <WebUI.HStack className="justify-end">
      <WebUI.DeprecatedTooltip
        as={WebUI.IconButton}
        label="Delete"
        onClick={() => onDelete(index)}
      >
        <WebUI.PhosphorIcon icon="x" />
      </WebUI.DeprecatedTooltip>
    </WebUI.HStack>
  </WebUI.HStack>
)

// MARK: – TaxFormModal

export interface TaxFormValues {
  applicable_items: number[]
  applied_to: Api.TabTaxAppliedToType
  name: string
  percent: string
}

export type TaxFormFormik = FormikState<TaxFormValues> &
  FormikHelpers<TaxFormValues> &
  FormikHandlers

export interface TaxFormModalProps extends WebUI.ModalProps {
  collectionId: number
  tax?: Api.TabTax
  taxIdx?: number
}

export const TaxFormModal = ({
  collectionId,
  tax,
  taxIdx,
  ...restProps
}: TaxFormModalProps) => {
  const ownRef = useRef<WebUI.DialogInstance>(null)
  const {data: items} = api.tabItems.list.useSuspenseQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const {data: collection} = api.tabs.detail.useSuspenseQuery({
    pathParams: {
      tabId: collectionId,
    },
  })
  const updateCollectionMutation = useUpdateTabMutation()

  const growlActions = WebUI.useGrowlActions()

  const taxableItems = items.filter(
    (item) =>
      !item.options?.recurring?.enabled ||
      (item.options?.recurring?.enabled &&
        item.options?.recurring?.options?.start?.type === 'first_payment'),
  )

  const formik = useFormik<TaxFormValues>({
    validateOnMount: false,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required'),
      applicable_items: Yup.array().required('Required'),
      applied_to: Yup.string().required('Required'),
      percent: Yup.string().required('Required'),
    }),
    initialValues: {
      applicable_items: tax?.applicable_items ?? [],
      applied_to: tax?.applied_to ?? 'some',
      name: tax?.name ?? '',
      percent: tax?.rate == null ? '' : String(tax.rate * 100),
    },
    onSubmit: async (values) => {
      const newTax = {
        applicable_items: values.applicable_items,
        applied_to: values.applied_to,
        name: values.name,
        rate: Number(values.percent) / 100,
      }

      const existingTaxes = Array.isArray(collection.taxes)
        ? collection.taxes
        : []

      await updateCollectionMutation.mutateAsync({
        pathParams: {
          tabId: collectionId,
        },
        body: {
          taxes:
            tax && typeof taxIdx === 'number' && Array.isArray(collection.taxes)
              ? collection.taxes.map((t, idx) => (idx === taxIdx ? newTax : t))
              : [...existingTaxes, newTax],
        },
      })

      ownRef.current?.hide()
    },
  })
  const formikRef = useLiveRef(formik)

  useEffect(() => {
    if (formik.values.applied_to === 'all') {
      formikRef.current.setFieldValue(
        'applicable_items',
        items.map((i) => i.id) ?? [],
        false,
      )
    }
  }, [formik.values.applied_to, items])

  const allItemsSelected =
    items.length === formik.values.applicable_items.length
  useEffect(() => {
    if (!allItemsSelected) {
      formikRef.current.setFieldValue('applied_to', 'some', false)
    }
  }, [allItemsSelected])

  return (
    <WebUI.Modal
      ref={ownRef}
      className={
        '[&_>_.ModalContentView]:h-[calc(100%-theme(spacing.16))] [&_>_.ModalContentView]:max-w-screen-lg'
      }
      initialVisible={false}
      aria-label="Tax form"
      {...restProps}
    >
      <WebUI.ModalCloseButton />
      <WebUI.ModalHeader variant="compact">Add Tax</WebUI.ModalHeader>
      <WebUI.Form
        className={
          'mt-4 flex grow flex-col overflow-hidden [&_>_.Form-inner]:max-h-full [&_>_.Form-inner]:grow [&_>_.Form-inner]:gap-0'
        }
        onSubmit={(e) => {
          e.preventDefault()
          if (
            formik.values.applied_to !== 'all' &&
            formik.values.applicable_items.length === 0
          ) {
            return growlActions.show('error', {
              body: 'Please select items to be taxed',
              title: 'Error',
            })
          }
          formik.handleSubmit(e)
        }}
      >
        <WebUI.VStack className="grow gap-4 overflow-hidden">
          <WebUI.VStack className="max-w-[720px] gap-4 px-8">
            <WebUI.FormFieldGroup>
              <WebUI.FormField label="Name" error={formik.errors.name} required>
                <WebUI.Input
                  name="name"
                  placeholder="Tax Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
              <WebUI.FormField label="Percent" error={formik.errors.percent}>
                <WebUI.PercentInput
                  className="w-[140px]"
                  name="percent"
                  allowDecimals
                  placeholder="Percentage"
                  decimalsLimit={3}
                  value={formik.values.percent}
                  onValueChange={(newPercent) =>
                    formik.setFieldValue('percent', newPercent)
                  }
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            </WebUI.FormFieldGroup>

            <WebUI.FormField error={formik.errors.applied_to}>
              <WebUI.RadioGroup
                name="applied_to"
                size="compact"
                aria-label="Tax apply strategy"
                state={formik.values.applied_to}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <WebUI.Radio value="all">
                  Apply to all items in collection
                </WebUI.Radio>
                <WebUI.Radio value="some">Apply to specific items</WebUI.Radio>
              </WebUI.RadioGroup>
            </WebUI.FormField>
          </WebUI.VStack>

          <WebUI.Separator />

          <SearchAndSelectableItems
            className="mx-8 overflow-y-auto pb-4"
            items={taxableItems}
            selectedItemIds={formik.values.applicable_items}
            onChangeSelectedItemsIds={(newItemIds) =>
              formik.setFieldValue('applicable_items', newItemIds)
            }
          />
        </WebUI.VStack>

        <WebUI.HStack
          className={'h-18 items-center justify-end border-t bg-trueWhite px-4'}
        >
          <WebUI.Button
            type="submit"
            variant="primary"
            size="large"
            loading={formik.isSubmitting}
          >
            {`Apply tax to ${
              formik.values.applied_to === 'all'
                ? 'all'
                : formik.values.applicable_items.length
            } item(s)`}
          </WebUI.Button>
        </WebUI.HStack>
      </WebUI.Form>
    </WebUI.Modal>
  )
}
