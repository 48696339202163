import * as WebUI from '@cheddarup/web-ui'
import {
  ForwardRefComponent,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import {
  BinaryFilter,
  Filter as CubeFilter,
  TimeDimension,
  UnaryFilter,
} from '@cubejs-client/core'
import * as Util from '@cheddarup/util'
import {useQueryParam} from 'use-query-params'
import React, {useState} from 'react'
import {UseCubeQueryResult} from '@cubejs-client/react'
import {useCubeQuery} from 'src/hooks/cube'
import {CubeObject} from 'src/components/CubeTable'
import {
  CalendarDate,
  getLocalTimeZone,
  startOfMonth,
  startOfYear,
  today,
} from '@internationalized/date'

import {
  CollectionDropdownSelect,
  CollectionTagsCloud,
  useCollectionsSelect,
  useCollectionsWithPayments,
} from './CollectionsSelect'

// MARK: – ReportOverviewHeader

interface ReportOverviewHeaderProps
  extends React.ComponentPropsWithoutRef<'div'> {
  defaultTimeDimension: string
  dateRange: DateRangeValue
  onDateRangeChange: (dateRange: DateRangeValue) => void
}

export const ReportOverviewHeader = ({
  defaultTimeDimension,
  dateRange,
  onDateRangeChange,
  className,
  ...restProps
}: ReportOverviewHeaderProps) => {
  const {collections} = useCollectionsWithPayments()
  const {selectionMode, selectedCollections} = useCollectionsSelect()
  return (
    <WebUI.VStack className={WebUI.cn('gap-6 px-6', className)} {...restProps}>
      <WebUI.VStack className="gap-2">
        <WebUI.Heading as="h2">Reporting Overview</WebUI.Heading>
        <WebUI.Text className="font-light text-ds-base">
          Our report center lets you look at data and reporting across all your
          Cheddar Up collections in aggregate.{' '}
          <WebUI.Anchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cheddarup.com/payment-report/"
          >
            Learn more
          </WebUI.Anchor>
        </WebUI.Text>
        <WebUI.Text className="font-light text-ds-xs italic">
          It can take up to 24 hours for new activity to be reflected below.
        </WebUI.Text>
      </WebUI.VStack>
      {collections?.length > 0 ? (
        <>
          <WebUI.VStack className="gap-3 overflow-x-auto overflow-y-hidden sm:flex-row sm:overflow-x-hidden">
            <CollectionDropdownSelect />
            <ReportOverviewDateRangePicker
              defaultTimeDimension={defaultTimeDimension}
              dateRange={dateRange}
              onDateRangeChange={onDateRangeChange}
            />
          </WebUI.VStack>
          {selectionMode === 'multiple' && selectedCollections.length > 0 && (
            <>
              <WebUI.Separator orientation="horizontal" variant="primary" />
              <CollectionTagsCloud />
            </>
          )}
        </>
      ) : (
        <WebUI.Text className="font-light">
          Once you start collecting payments, you’ll find a snapshot of your
          payers, payments and withdrawals here.
        </WebUI.Text>
      )}
    </WebUI.VStack>
  )
}

// MARK: – ReportTotalCollected

export interface ReportTotalCollectedProps
  extends Omit<
    ReportOverviewAggregationBlockProps,
    'measure' | 'timeDimensions' | 'children'
  > {
  dateRange: DateRangeValue
}

export const ReportTotalCollected = ({
  filters,
  dateRange,
  ...restProps
}: ReportTotalCollectedProps) => {
  const [, setPaneKey] = useQueryParam('p')
  const {collections} = useCollectionsWithPayments()
  const [method, setMethod] = useState('')
  return (
    <ReportOverviewAggregationBlock
      measure="Payments.totalPaid"
      filters={[
        method
          ? {
              member: 'Payments.paymentMethod',
              operator: 'equals' as const,
              values: [method],
            }
          : undefined,
        ...filters,
      ].filter(
        (f): f is BinaryFilter | UnaryFilter =>
          !!f && 'values' in f && !!f.values && f.values.length > 0,
      )}
      timeDimensions={[{dimension: 'Payments.createdAt', dateRange}]}
      {...restProps}
    >
      {({measureDataPoint}) => (
        <>
          <HeadingWithIcon iconName="bank">Total Collected</HeadingWithIcon>

          <WebUI.VStack className="gap-2">
            <WebUI.HStack>
              <WebUI.DropdownSelectText
                className="self-start"
                value={method}
                onValueChange={(newMethod) => {
                  if (newMethod) {
                    setMethod(newMethod)
                  }
                }}
              >
                <WebUI.DropdownSelectOption value="">
                  All methods
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="card">
                  Credit Card
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="echeck">
                  eCheck
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="offline">
                  Offline (Cash/Check)
                </WebUI.DropdownSelectOption>
              </WebUI.DropdownSelectText>
            </WebUI.HStack>
            <WebUI.Text className="text-ds-xl">
              {Util.formatAmount(
                typeof measureDataPoint === 'number' ||
                  typeof measureDataPoint === 'string'
                  ? measureDataPoint
                  : 0,
              )}
            </WebUI.Text>
            <WebUI.Button
              className="text-ds-sm disabled:text-buttonGhostText"
              variant="link"
              disabled={!collections?.length}
              onClick={() => setPaneKey('withdrawals')}
            >
              Run Withdrawals Report
            </WebUI.Button>
          </WebUI.VStack>
        </>
      )}
    </ReportOverviewAggregationBlock>
  )
}

// MARK: – ReportTotalPayments

export interface ReportTotalPaymentsProps
  extends Omit<
    ReportOverviewAggregationBlockProps,
    'measure' | 'timeDimensions' | 'children'
  > {
  dateRange: DateRangeValue
}

export const ReportTotalPayments = ({
  filters,
  dateRange,
  ...restProps
}: ReportTotalPaymentsProps) => {
  const [, setPaneKey] = useQueryParam('p')
  const {collections} = useCollectionsWithPayments()
  const [status, setStatus] = useState('available')
  return (
    <ReportOverviewAggregationBlock
      measure="Payments.paidCount"
      filters={[
        {
          dimension: 'Payments.status',
          operator: 'equals' as const,
          values: [status],
        },
        ...filters,
      ].filter((f) => !!f && 'values' in f && f.values && f.values.length > 0)}
      timeDimensions={[{dimension: 'Payments.createdAt', dateRange}]}
      {...restProps}
    >
      {({measureDataPoint}) => (
        <>
          <HeadingWithIcon iconName="currency-circle-dollar">
            Total Payments
          </HeadingWithIcon>

          <WebUI.VStack className="gap-2">
            <WebUI.HStack>
              <WebUI.DropdownSelectText
                className="self-start"
                value={status}
                onValueChange={(newStatus) => {
                  if (newStatus) {
                    setStatus(newStatus)
                  }
                }}
              >
                <WebUI.DropdownSelectOption value="available">
                  Status: Cleared
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="pending">
                  Status: Pending
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="failed">
                  Status: Failed
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="refunded">
                  Status: Refunded
                </WebUI.DropdownSelectOption>
              </WebUI.DropdownSelectText>
            </WebUI.HStack>
            <WebUI.Text className="text-ds-xl">
              {Number(measureDataPoint ?? '0').toLocaleString()}
            </WebUI.Text>
            <WebUI.Button
              className="text-ds-sm disabled:text-buttonGhostText"
              variant="link"
              disabled={!collections.length}
              onClick={() => setPaneKey('payments')}
            >
              Run Payments Report
            </WebUI.Button>
          </WebUI.VStack>
        </>
      )}
    </ReportOverviewAggregationBlock>
  )
}

// MARK: – ReportTotalPayers

export interface ReportTotalPayersProps
  extends Omit<
    ReportOverviewAggregationBlockProps,
    'measure' | 'timeDimensions' | 'children'
  > {
  dateRange: DateRangeValue
}

export const ReportTotalPayers = ({
  filters,
  dateRange,
  ...restProps
}: ReportTotalPayersProps) => {
  const [, setPaneKey] = useQueryParam('p')
  const {collections} = useCollectionsWithPayments()
  return (
    <ReportOverviewAggregationBlock
      measure="Payments.uniqueCustomerCount"
      filters={filters.filter(
        (f) => !!f && 'values' in f && f.values && f.values.length > 0,
      )}
      timeDimensions={[{dimension: 'Payments.createdAt', dateRange}]}
      {...restProps}
    >
      {({measureDataPoint}) => (
        <>
          <HeadingWithIcon iconName="user-circle">Total Payers</HeadingWithIcon>

          <WebUI.VStack className="gap-2">
            <WebUI.Text className="text-ds-sm leading-[24px]">
              All unique payers
            </WebUI.Text>
            <WebUI.Text className="text-ds-xl">
              {Number(measureDataPoint ?? '0').toLocaleString()}
            </WebUI.Text>
            <WebUI.Button
              className="text-ds-sm disabled:text-buttonGhostText"
              variant="link"
              disabled={!collections.length}
              onClick={() => setPaneKey('payers')}
            >
              Run Payers Report
            </WebUI.Button>
          </WebUI.VStack>
        </>
      )}
    </ReportOverviewAggregationBlock>
  )
}

// MARK: – ReportOverviewAggregationBlock

interface ReportOverviewAggregationBlockProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  measure: string
  filters: CubeFilter[]
  timeDimensions: TimeDimension[]
  children:
    | React.ReactNode
    | ((options: {
        dataQuery: UseCubeQueryResult<any, CubeObject>
        measureDataPoint: string | number | boolean | null
      }) => React.ReactNode)
}

const ReportOverviewAggregationBlock = ({
  measure,
  filters,
  timeDimensions,
  children,
  className,
  ...restProps
}: ReportOverviewAggregationBlockProps) => {
  const dataQuery = useCubeQuery<CubeObject>({
    measures: [measure],
    filters,
    timeDimensions,
  })
  const measureDataPoint =
    dataQuery.resultSet?.tablePivot()[0]?.[measure] ?? null

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      {typeof children === 'function'
        ? children({dataQuery, measureDataPoint})
        : children}
    </WebUI.VStack>
  )
}

// MARK: HeadingWithIcon

interface HeadingWithIconProps extends React.ComponentPropsWithoutRef<'h3'> {
  iconName: WebUI.PhosphorIconName
}

const HeadingWithIcon = React.forwardRef<
  HTMLHeadingElement,
  HeadingWithIconProps
>(({iconName, className, children, ...restProps}, forwardedRef) => (
  <WebUI.Heading
    ref={forwardedRef}
    className={WebUI.cn('text-ds-md', className)}
    as="h3"
    {...restProps}
  >
    <WebUI.PhosphorIcon
      className="align-text-bottom text-ds-xl"
      icon={iconName}
    />{' '}
    {children}
  </WebUI.Heading>
))

// TODO: move this out, it's used in reporting
// MARK: – ReportOverviewDateRangePicker

export type DateRangeValue = [string, string]

interface ReportOverviewDateRangePickerProps
  extends React.ComponentPropsWithoutRef<'div'> {
  defaultTimeDimension: string
  allTimeExcluded?: boolean
  showDatePickerForCustomOnly?: boolean
  dateRange: DateRangeValue
  onDateRangeChange: (dateRange: DateRangeValue) => void
}

export const ReportOverviewDateRangePicker = React.forwardRef(
  (
    {
      defaultTimeDimension,
      allTimeExcluded = false,
      showDatePickerForCustomOnly = false,
      dateRange,
      onDateRangeChange,
      className,
      as: Comp = WebUI.HStack,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [timeDimension, setTimeDimension] = useState(defaultTimeDimension)
    const onDateRangeChangeRef = useLiveRef(onDateRangeChange)

    useUpdateEffect(() => {
      if (timeDimension !== 'custom') {
        const newDateRange = getDateRangeFromTimeDimension(timeDimension)
        if (newDateRange) {
          onDateRangeChangeRef.current?.(newDateRange)
        }
      }
    }, [timeDimension])

    return (
      <Comp
        ref={forwardedRef}
        className={WebUI.cn(
          'max-h-9 max-w-[380px] border border-grey-300 *:max-h-9 *:overflow-hidden',
          className,
        )}
        {...restProps}
      >
        <WebUI.DropdownSelect
          className="[&_>_.Select-select]:min-h-[auto] [&_>_.Select-select]:rounded-r-none"
          size="compact"
          variant="ghost"
          value={timeDimension}
          onValueChange={(newTimeDimension) => {
            if (typeof newTimeDimension === 'string') {
              setTimeDimension(newTimeDimension)
            }
          }}
        >
          <WebUI.DropdownSelectOption value="yearToDate">
            Year to date
          </WebUI.DropdownSelectOption>
          <WebUI.DropdownSelectOption value="monthToDate">
            Month to date
          </WebUI.DropdownSelectOption>
          <WebUI.DropdownSelectOption value="last7Days">
            Last 7 days
          </WebUI.DropdownSelectOption>
          {!allTimeExcluded && (
            <WebUI.DropdownSelectOption value="allTime">
              All time
            </WebUI.DropdownSelectOption>
          )}
          <WebUI.DropdownSelectOption value="custom">
            Custom
          </WebUI.DropdownSelectOption>
        </WebUI.DropdownSelect>
        {((!showDatePickerForCustomOnly && timeDimension !== 'allTime') ||
          timeDimension === 'custom') && (
          <WebUI.DateRangePicker
            className={
              'h-full max-h-full bg-depr-grey-200 [&_.DateSegmentBox]:min-h-[auto] [&_>_.DateRangePicker-inputsContainer]:h-full'
            }
            size="compact"
            disabled={timeDimension !== 'custom'}
            maxValue={today(getLocalTimeZone())}
            value={{
              // biome-ignore lint/style/noNonNullAssertion:
              start: Util.parseCalendarDate(dateRange[0])!,
              // biome-ignore lint/style/noNonNullAssertion:
              end: Util.parseCalendarDate(dateRange[1])!,
            }}
            onValueChange={(newDateRange) => {
              onDateRangeChange([
                newDateRange?.start.toDate(getLocalTimeZone()).toISOString() ??
                  '',
                newDateRange?.end.toDate(getLocalTimeZone()).toISOString() ??
                  '',
              ])
              setTimeDimension('custom')
            }}
          />
        )}
      </Comp>
    )
  },
) as ForwardRefComponent<
  typeof WebUI.HStack,
  ReportOverviewDateRangePickerProps
>

// MARK: – Helpers

export const getDateRangeFromTimeDimension = (timeDimension: string) => {
  const nowDate = today(getLocalTimeZone())

  const timeDimensionToDateRangeMap: Record<
    string,
    [CalendarDate, CalendarDate]
  > = {
    yearToDate: [startOfYear(nowDate), nowDate],
    monthToDate: [startOfMonth(nowDate), nowDate],
    last7Days: [nowDate.subtract({days: 7}), nowDate],
    today: [nowDate, nowDate],
    allTime: [new CalendarDate(1970, 1, 31), nowDate], // 1970 must be good enough
  }

  return (
    timeDimensionToDateRangeMap[timeDimension] ??
    // biome-ignore lint/style/noNonNullAssertion:
    timeDimensionToDateRangeMap.yearToDate!
  ).map((calendarDate) =>
    calendarDate.toDate(getLocalTimeZone()).toISOString(),
  ) as DateRangeValue
}
