import {StringParam, useQueryParam, withDefault} from 'use-query-params'
import React from 'react'
import {useParams} from 'react-router-dom'
import Papa from 'papaparse'
import * as WebUI from '@cheddarup/web-ui'
import {InferResponse, api, endpoints} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {LinkButton} from 'src/components/LinkButton'
import {useResponsesCsvDataQuery} from 'src/hooks/useResponsesCsvDataQuery'
import {FieldViewsList} from 'src/components/FieldViewsList'

const FormSpecificPage = () => {
  const urlParams = useParams()
  const [viewBy] = useQueryParam(
    'viewBy',
    withDefault(StringParam, 'respondents'),
  )

  const collectionId = Number(urlParams.collection)
  const formId = Number(urlParams.form)

  const {data: respondentsCount} = api.tabPayments.formsAndSignups.useQuery(
    {
      pathParams: {
        tabId: collectionId,
      },
      queryParams: {
        type: 'TabForm',
      },
    },
    {
      select: (tabFormsStats) =>
        tabFormsStats.find((tfs) => tfs.id === formId)?.respondents ?? 0,
    },
  )

  let form: InferResponse<typeof endpoints.tabForms.detail> | undefined
  if (formId != null) {
    form = api.tabForms.detail.useSuspenseQuery({
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        formId,
      },
    }).data
  }

  return (
    <WebUI.VStack className="gap-5">
      {form && (
        <WebUI.Panel className="gap-4 py-6" as={WebUI.VStack}>
          <WebUI.VStack className="px-6">
            <div>
              <Link
                className="text-ds-sm"
                variant="primary"
                iconBefore={<WebUI.PhosphorIcon icon="arrow-left" />}
                to="../forms-signups"
              >
                Back to all forms and sign ups
              </Link>
            </div>
            <WebUI.VStack className="mt-2">
              <WebUI.HStack className="items-center gap-3">
                <WebUI.PhosphorIcon icon="clipboard-text" />
                <WebUI.Text>{form.name}</WebUI.Text>
              </WebUI.HStack>
              <WebUI.VStack className="mt-1 ml-7">
                <WebUI.Text className="text-ds-sm">
                  Respondents: {respondentsCount}
                </WebUI.Text>
              </WebUI.VStack>
            </WebUI.VStack>
          </WebUI.VStack>
          <FormResponsesDisplayOptions
            collectionId={collectionId}
            formName={form.name}
          />
        </WebUI.Panel>
      )}
      <FieldViewsList
        collectionId={collectionId}
        tabObjectId={formId}
        viewBy={viewBy}
        tabObjectType="form"
      />
    </WebUI.VStack>
  )
}

// MARK: – FormResponsesDisplayOptions

interface FormResponsesDisplayOptionsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  formName: string
}

const FormResponsesDisplayOptions: React.FC<
  FormResponsesDisplayOptionsProps
> = ({collectionId, formName, className, ...restProps}) => {
  const urlParams = useParams()
  const selectedFormId = Number(urlParams.form)
  const [viewBy, setViewBy] = useQueryParam(
    'viewBy',
    withDefault(StringParam, 'respondents'),
  )
  const {data: hasFilledInFields} = api.tabPayments.formsAndSignups.useQuery(
    {
      pathParams: {
        tabId: collectionId,
      },
      queryParams: {
        type: 'TabForm',
      },
    },
    {
      select: (tabFormsStats) =>
        tabFormsStats.some((tfs) => tfs.item_field_views_count > 0),
    },
  )
  const fieldSetsQuery = api.tabForms.detail.useQuery(
    {
      pathParams: {
        tabId: collectionId,
        formId: selectedFormId,
      },
    },
    {
      select: (form) => form.options.fieldSets,
    },
  )
  const responsesCsvDataQuery = useResponsesCsvDataQuery(
    collectionId,
    selectedFormId,
    fieldSetsQuery.data,
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.Separator variant="primary" />
      <WebUI.VStack className="flex-[1] items-stretch justify-end gap-4 px-6 md:flex-row md:items-end md:justify-between">
        <WebUI.FormField className="max-w-[240px] grow">
          <WebUI.DropdownSelect<string | null>
            size="compact"
            placeholder="Select View"
            value={viewBy}
            onValueChange={(newViewBy) => setViewBy(newViewBy)}
          >
            <WebUI.DropdownSelectOption value="respondents">
              View by Respondents
            </WebUI.DropdownSelectOption>
            <WebUI.DropdownSelectOption value="questions">
              View by Questions
            </WebUI.DropdownSelectOption>
          </WebUI.DropdownSelect>
        </WebUI.FormField>
        {!!hasFilledInFields && viewBy != null && (
          <WebUI.VStack className="gap-4 sm:flex-row">
            <WebUI.DeprecatedTooltip label="CSV file of all responses">
              <WebUI.Button
                variant="secondary"
                iconAfter={
                  <WebUI.PhosphorIcon icon="download-simple" width={20} />
                }
                onClick={() => {
                  const csv = Papa.unparse({
                    fields: [
                      'Respondent',
                      'Email',
                      'Date',
                      ...(responsesCsvDataQuery.data?.possibleFieldNames ?? []),
                    ],
                    data: responsesCsvDataQuery.data?.csvData ?? [],
                  })

                  WebUI.downloadFile(
                    new Blob([csv], {
                      type: 'data:text/csv;charset=utf-8',
                    }),
                    `${formName}.csv`,
                  )
                }}
              >
                Download Responses
              </WebUI.Button>
            </WebUI.DeprecatedTooltip>
            <WebUI.DeprecatedTooltip label="Download pdf to print">
              <LinkButton
                variant="secondary"
                iconAfter={<WebUI.PhosphorIcon icon="printer" width={20} />}
                target="_blank"
                to={
                  viewBy === 'respondents'
                    ? `/pdf/collection/${collectionId}/form/${selectedFormId}/field-views-report`
                    : `/pdf/collection/${collectionId}/tab-object/${selectedFormId}/fields`
                }
              >
                Print Responses
              </LinkButton>
            </WebUI.DeprecatedTooltip>
          </WebUI.VStack>
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

export default FormSpecificPage
