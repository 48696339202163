import React, {useContext, useEffect, useMemo, useState} from 'react'
import useEmblaCarousel, {type UseEmblaCarouselType} from 'embla-carousel-react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {IconButton, IconButtonProps} from './IconButton'
import {cn} from '../utils'
import {PhosphorIcon} from '../icons'

export type CarouselApi = NonNullable<UseEmblaCarouselType[1]>
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>
type CarouselOptions = UseCarouselParameters[0]
type CarouselPlugin = Required<UseCarouselParameters>[1][number]

interface CarouselContextProps extends CarouselProps {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0]
  api: ReturnType<typeof useEmblaCarousel>[1]
  scrollPrev: () => void
  scrollNext: () => void
  canScrollPrev: boolean
  canScrollNext: boolean
}

const CarouselContext = React.createContext({} as CarouselContextProps)

// MARK: – Carousel

export interface CarouselProps {
  options?: CarouselOptions
  plugins?: CarouselPlugin[]
  orientation?: 'horizontal' | 'vertical'
  setApi?: (api: CarouselApi) => void
  hideArrowsAtEdges?: boolean
}

export const Carousel = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'> & CarouselProps
>(
  (
    {
      orientation = 'horizontal',
      options,
      setApi,
      plugins,
      role = 'region',
      'aria-roledescription': ariaRoledescription = 'carousel',
      tabIndex = 0,
      className,
      onKeyDownCapture,
      hideArrowsAtEdges,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...options,
        axis: orientation === 'horizontal' ? 'x' : 'y',
      },
      plugins,
    )
    const [canScrollPrev, setCanScrollPrev] = useState(false)
    const [canScrollNext, setCanScrollNext] = useState(false)

    useEffect(() => {
      if (!api || !setApi) {
        return
      }

      setApi(api)
    }, [api, setApi])

    useEffect(() => {
      if (!api) {
        return
      }

      const handleSelect = (carouselApi: CarouselApi) => {
        if (!carouselApi) {
          return
        }
        setCanScrollPrev(carouselApi.canScrollPrev())
        setCanScrollNext(carouselApi.canScrollNext())
      }

      handleSelect(api)
      api.on('reInit', handleSelect)
      api.on('select', handleSelect)

      return () => {
        api?.off('select', handleSelect)
      }
    }, [api])

    const value = useMemo(
      () => ({
        carouselRef,
        api,
        options,
        orientation:
          orientation || (options?.axis === 'y' ? 'vertical' : 'horizontal'),
        scrollPrev: () => api?.scrollPrev(),
        scrollNext: () => api?.scrollNext(),
        hideArrowsAtEdges,
        canScrollPrev,
        canScrollNext,
      }),
      [
        carouselRef,
        api,
        options,
        orientation,
        canScrollNext,
        hideArrowsAtEdges,
        canScrollPrev,
      ],
    )

    return (
      <CarouselContext.Provider value={value}>
        <div
          ref={forwardedRef}
          role={role}
          aria-roledescription={ariaRoledescription}
          className={cn('Carousel', 'relative', className)}
          tabIndex={tabIndex}
          onKeyDownCapture={(event) => {
            onKeyDownCapture?.(event)

            if (event.defaultPrevented) {
              return
            }

            if (event.key === 'ArrowLeft') {
              event.preventDefault()
              api?.scrollPrev()
            } else if (event.key === 'ArrowRight') {
              event.preventDefault()
              api?.scrollNext()
            }
          }}
          {...restProps}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    )
  },
)

// MARK: – CarouselContent

export const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({className, ...restProps}, forwardedRef) => {
  const carousel = useContext(CarouselContext)

  return (
    <div ref={carousel.carouselRef} className="CarouselContent overflow-hidden">
      <div
        ref={forwardedRef}
        className={cn(
          'CarouselContent-inner',
          'flex gap-3',
          carousel.orientation === 'horizontal' ? 'flex-row' : 'flex-col',
          className,
        )}
        {...restProps}
      />
    </div>
  )
})

// MARK: – CarouselItem

export const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({className, ...restProps}, forwardedRef) => (
  <div
    ref={forwardedRef}
    role="group"
    aria-roledescription="slide"
    className={cn(
      'CarouselItem',
      'relative min-w-0 shrink-0 grow-0 basis-full',
      className,
    )}
    {...restProps}
  />
))

// MARK: – CarouselArrowButtons

interface CarouselArrowButtonProps extends IconButtonProps {}

const CarouselArrowButton = React.forwardRef(
  ({className, ...restProps}, forwardedRef) => (
    <IconButton
      ref={forwardedRef}
      className={cn(
        'Carousel-arrowBtn',
        '-translate-y-1/2 aria-orientation-vertical:-translate-x-1/2 absolute h-[45px] w-[45px] rounded-full aria-orientation-vertical:rotate-90',
        className,
      )}
      {...restProps}
    />
  ),
) as ForwardRefComponent<'button', CarouselArrowButtonProps>

export const CarouselPrevious = React.forwardRef(
  (
    {
      className,
      children = <PhosphorIcon icon="arrow-circle-left" />,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {orientation, canScrollPrev, scrollPrev, hideArrowsAtEdges, options} =
      useContext(CarouselContext)
    const hideButton = hideArrowsAtEdges && !canScrollPrev && !options?.loop

    return (
      <CarouselArrowButton
        ref={forwardedRef}
        orientation={orientation}
        className={cn(
          'Carousel-prevBtn',
          'top-1/2 left-0 aria-orientation-vertical:top-0 aria-orientation-vertical:left-1/2',
          hideButton && 'hidden',
          className,
        )}
        disabled={!canScrollPrev}
        onClick={scrollPrev}
        {...restProps}
      >
        {children}
      </CarouselArrowButton>
    )
  },
) as ForwardRefComponent<'button', CarouselArrowButtonProps>

export const CarouselNext = React.forwardRef(
  (
    {
      className,
      children = <PhosphorIcon icon="arrow-circle-right" />,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {orientation, canScrollNext, scrollNext, hideArrowsAtEdges, options} =
      useContext(CarouselContext)
    const hideButton = hideArrowsAtEdges && !canScrollNext && !options?.loop
    return (
      <CarouselArrowButton
        ref={forwardedRef}
        orientation={orientation}
        className={cn(
          'Carousel-nextBtn',
          'top-1/2 right-0 aria-orientation-vertical:bottom-0 aria-orientation-vertical:left-1/2',
          hideButton && 'hidden',
          className,
        )}
        disabled={!canScrollNext}
        onClick={scrollNext}
        {...restProps}
      >
        {children}
      </CarouselArrowButton>
    )
  },
) as ForwardRefComponent<'button', CarouselArrowButtonProps>

// MARK: – CarouselStepper

export interface CarouselStepperProps
  extends React.ComponentPropsWithoutRef<'div'> {}

export const CarouselStepper = React.forwardRef<
  HTMLDivElement,
  CarouselStepperProps
>(({className, ...restProps}, forwardedRef) => {
  const carousel = useContext(CarouselContext)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    const handleInit = (api: CarouselApi) => {
      setScrollSnaps(api.scrollSnapList())
      setSelectedIndex(api.selectedScrollSnap())
    }

    const handleSelect = (api: CarouselApi) => {
      setSelectedIndex(api.selectedScrollSnap())
    }

    if (carousel.api) {
      handleInit(carousel.api)
      handleSelect(carousel.api)
    }

    carousel.api?.on('reInit', handleInit).on('select', handleSelect)

    return () => {
      carousel.api?.off('reInit', handleInit).off('select', handleSelect)
    }
  }, [carousel.api])

  return (
    <div
      ref={forwardedRef}
      className={cn(
        'CarouselStepper',
        'absolute inset-x-3 bottom-3 flex h-2 flex-row justify-center gap-3',
        className,
      )}
      {...restProps}
    >
      {scrollSnaps.map((_, idx) => (
        <button
          key={idx}
          aria-selected={selectedIndex === idx}
          className="CarouselStep h-full min-w-10 max-w-16 flex-1 cursor-pointer rounded-large bg-trueWhite opacity-50 transition-opacity hover:bg-depr-grey-400 aria-selected:opacity-100"
          type="button"
          onClick={() => carousel.api?.scrollTo(idx)}
        />
      ))}
    </div>
  )
})
