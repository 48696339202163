import React, {useMemo, useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {
  api,
  useResendReceiptPaymentToOthersMutation,
  useRetryScheduledPaymentToOthersMutation,
} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import CollectionsLayout from 'src/components/CollectionsLayout'
import {PaymentMethodSelectDropdown} from 'src/components/OrderSummaryTermsHistory'
import {Link} from 'src/components/Link'

const PaymentsPage: React.FC = () => (
  <CollectionsLayout className="max-h-full sm:[&_.CollectionsLayout-contentContainer]:p-0">
    <WebUI.VStack className="min-h-0 w-full max-w-[1200px] grow gap-6">
      <PaymentsToOthersView />
    </WebUI.VStack>
  </CollectionsLayout>
)

// MARK: – PaymentsToOthersView

export interface PaymentsToOthersViewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  heading?: React.ReactNode
}

export const PaymentsToOthersView: React.FC<PaymentsToOthersViewProps> = ({
  heading = (
    <WebUI.Heading className="leading-compact" as="h2">
      Order History
    </WebUI.Heading>
  ),
  className,
  ...restProps
}) => {
  const media = WebUI.useMedia()
  const userPaymentsQuery = api.payments.list.useQuery(undefined, {
    select: (payments) => payments.filter((payment) => !!payment.tab),
  })

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.PaymentToOthers>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((p) => p.tab.name, {
        id: 'collectionSummary',
        size: 160,
        header: 'Collection',
        cell: (cellProps) => (
          <WebUI.VStack className="max-w-full gap-3">
            <WebUI.VStack>
              <WebUI.Ellipsis>{cellProps.getValue()}</WebUI.Ellipsis>
            </WebUI.VStack>
            {(cellProps.row.original.recurring_payment_invoice ||
              cellProps.row.original.scheduled_invoices.length > 0) && (
              <Link
                variant="primary"
                className="text-ds-sm"
                to={`${cellProps.row.original.id}/details?termsAndHistory=1`}
                iconBefore={<WebUI.PhosphorIcon icon="arrows-clockwise" />}
              >
                Includes recurring payment(s)
              </Link>
            )}
          </WebUI.VStack>
        ),
      }),
      columnHelper.accessor((p) => p.tab.user.name, {
        id: 'organizer',
        size: 120,
        header: 'Organizer',
        cell: (cellProps) => (
          <WebUI.Anchor
            className="max-w-full overflow-hidden text-ellipsis"
            href={`mailto:${
              cellProps.row.original.tab.user.email
            }?subject=${encodeURIComponent(cellProps.getValue())}`}
          >
            {cellProps.cell.getValue()}
          </WebUI.Anchor>
        ),
      }),
      columnHelper.accessor((p) => new Date(p.created_at), {
        id: 'created_at',
        size: 96,
        header: 'Date',
        sortingFn: 'datetime',
        sortDescFirst: true,
        cell: (cellProps) => <>{Util.formatDateAs(cellProps.getValue())}</>,
      }),
      columnHelper.accessor(
        (payment) =>
          payment.total + payment.member_fee - payment.member_fee_refund,
        {
          id: 'total',
          size: 80,
          sortDescFirst: true,
          header: 'Amount Paid',
          cell: (cellProps) => (
            <WebUI.VStack className="gap-2">
              <WebUI.HStack className="items-center gap-2">
                <span>{Util.formatAmount(cellProps.getValue())}</span>
                <span
                  className={WebUI.cn(
                    'text-ds-sm',
                    cellProps.row.original.recurring_payment_invoice?.status ===
                      'failed' || cellProps.row.original.status === 'failed'
                      ? 'text-orange-500'
                      : 'text-teal-50',
                  )}
                >
                  {cellProps.row.original.recurring_payment_invoice?.status ===
                  'failed'
                    ? 'Failed'
                    : cellProps.row.original.recurring_payment_invoice
                          ?.status === 'scheduled'
                      ? 'Scheduled'
                      : (cellProps.row.original.total_refund || 0) > 0
                        ? cellProps.row.original.fully_refunded
                          ? 'Refunded'
                          : 'Partial Refund'
                        : cellProps.row.original.status === 'failed'
                          ? 'Failed'
                          : null}
                </span>
              </WebUI.HStack>
              {cellProps.row.original.recurring_payment_invoice?.status ===
                'failed' && (
                <RetryRecurringPaymentInvoice
                  payment={cellProps.row.original}
                />
              )}
            </WebUI.VStack>
          ),
        },
      ),
      columnHelper.display({
        id: 'view-items',
        size: 100,
        meta: {
          align: 'right',
        },
        header: 'Order includes:',
        cell: ({row: {original: payment}}) => (
          <WebUI.HStack className="gap-2 sm:text-ds-xl">
            {media.sm && hasItems(payment) && (
              <WebUI.DeprecatedTooltip label="View Items">
                <WebUI.IconButton
                  size="default_alt"
                  variant="link"
                  as={Link}
                  to={`${payment.id}/details`}
                >
                  <WebUI.PhosphorIcon icon="tag" />
                </WebUI.IconButton>
              </WebUI.DeprecatedTooltip>
            )}
            {hasTickets(payment) &&
              (media.sm ? (
                <WebUI.DeprecatedTooltip label="Download Tickets">
                  <WebUI.IconButton
                    size="default_alt"
                    variant="link"
                    as={Link}
                    to={`/pdf/payments/${payment.id}/tickets`}
                    target="_blank"
                  >
                    <WebUI.PhosphorIcon icon="ticket" />
                  </WebUI.IconButton>
                </WebUI.DeprecatedTooltip>
              ) : (
                <Link
                  variant="primary"
                  to={`/pdf/payments/${payment.id}/tickets`}
                  target="_blank"
                >
                  Download Ticket(s)
                </Link>
              ))}
            {media.sm && hasForms(payment) && (
              <WebUI.DeprecatedTooltip label="View Forms">
                <WebUI.IconButton
                  size="default_alt"
                  variant="link"
                  as={Link}
                  to={`${payment.id}/details`}
                >
                  <WebUI.PhosphorIcon icon="clipboard-text" />
                </WebUI.IconButton>
              </WebUI.DeprecatedTooltip>
            )}
            {media.sm && hasWaviers(payment) && (
              <WebUI.DeprecatedTooltip label="View Waviers">
                <WebUI.IconButton
                  size="default_alt"
                  variant="link"
                  as={Link}
                  to={`${payment.id}/details`}
                >
                  <WebUI.PhosphorIcon icon="pen" />
                </WebUI.IconButton>
              </WebUI.DeprecatedTooltip>
            )}
            {hasSignups(payment) &&
              (media.sm ? (
                <WebUI.DeprecatedTooltip label="View Sign Ups">
                  <WebUI.IconButton
                    size="default_alt"
                    variant="link"
                    as={Link}
                    to={`${payment.id}/details`}
                  >
                    <WebUI.PhosphorIcon icon="users-four" />
                  </WebUI.IconButton>
                </WebUI.DeprecatedTooltip>
              ) : (
                <Link variant="primary" to={`${payment.id}/details`}>
                  View Sign Up(s)
                </Link>
              ))}
          </WebUI.HStack>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        size: 20,
        meta: {
          align: 'right',
        },
        cell: ({row}) => <PaymentActionsDropdown payment={row.original} />,
      }),
    ],
    [columnHelper, media.sm],
  )

  const initialSortingState = useMemo(
    () => [{id: 'created_at', desc: true} as const],
    [],
  )

  const tableColumnsToRowSlots = useMemo(
    () =>
      WebUI.makeTableColumnsToRowSlotsConverter(
        columns as any,
        [
          {
            slotId: 'payment_overview',
            colIds: ['collectionSummary', 'total', 'created_at'],
          },
          {
            slotId: 'misc',
            colIds: ['view-items'],
          },
        ],
        {
          action: {
            colId: 'actions',
          },
        },
      ),
    [columns],
  )

  const PaymentListDataRow: WebUI.DataRowComponentType<Api.PaymentToOthers> =
    useMemo(
      () =>
        React.forwardRef(
          ({row, setSelected, ...rowRestProps}, forwardedRef) => (
            <WebUI.SlottedRow
              ref={forwardedRef}
              slots={row?.original ? tableColumnsToRowSlots(row.original) : []}
              {...rowRestProps}
            />
          ),
        ),
      [tableColumnsToRowSlots],
    )

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'grow border-t-0 border-b-0 sm:rounded-none',
        className,
      )}
      as={WebUI.Panel}
      {...restProps}
    >
      {media.sm ? (
        <WebUI.TableView<Api.PaymentToOthers>
          className={
            'flex-[0_1_auto] [&_.TableView-headerGroup]:border-t [&_.TableView-headerGroup]:border-b [&_.TableView-headerGroup]:px-6 [&_.TableViewRow]:px-6 [@media(hover:hover){&_.TableViewRow:hover_.PaymentAction}]:visible [@media(hover:hover){&_.TableViewRow:hover_.PaymentAction}]:opacity-100'
          }
          loading={userPaymentsQuery.isPending}
          sortable
          sortByTogglesVisible
          disableSortRemove
          initialState={{sorting: initialSortingState}}
          columns={columns}
          data={userPaymentsQuery.data ?? []}
        >
          {(table) => (
            <WebUI.HStack className="-order-1 min-h-[84px] flex-0 items-center justify-between p-6">
              {heading}
              <div className="relative h-[30px]">
                <WebUI.Input
                  className="h-full min-h-[auto] placeholder:text-transparent sm:w-[320px] sm:placeholder:text-inputPlaceholder"
                  size="small"
                  placeholder="Search by collection name or organizer"
                  onChange={(event) =>
                    table.setGlobalFilter(event.target.value)
                  }
                />
                <WebUI.IconButton
                  className="-translate-y-1/2 absolute top-1/2 right-2 text-gray400"
                  size="default_alt"
                  onClick={() => table.setGlobalFilter('')}
                >
                  {table.getState().globalFilter ? (
                    <WebUI.PhosphorIcon icon="x" />
                  ) : (
                    <WebUI.PhosphorIcon icon="magnifying-glass" />
                  )}
                </WebUI.IconButton>
              </div>
            </WebUI.HStack>
          )}
        </WebUI.TableView>
      ) : (
        <>
          <div className="p-6">{heading}</div>
          <WebUI.DataList
            className={'border-t [&_.List-item]:border-b'}
            data={userPaymentsQuery.data ?? []}
            initialState={{focusedItemId: null, selectedItemIdsMap: {}}}
            DataRowComponent={PaymentListDataRow}
          />
        </>
      )}
      {userPaymentsQuery.data && userPaymentsQuery.data?.length === 0 && (
        <WebUI.Text className="px-8 py-[18px] font-light text-ds-sm text-gray800">
          Looks like you haven't made any payments yet.
        </WebUI.Text>
      )}
    </WebUI.VStack>
  )
}

// MARK: – RetryRecurringPaymentInvoice

interface RetryRecurringPaymentInvoiceProps {
  payment: Api.PaymentToOthers
}

const RetryRecurringPaymentInvoice = ({
  payment,
}: RetryRecurringPaymentInvoiceProps) => {
  const retryPaymentAlertRef = useRef<WebUI.DialogInstance>(null)
  const retryScheduledPaymentMutation =
    useRetryScheduledPaymentToOthersMutation()
  const growlActions = WebUI.useGrowlActions()

  const scheduledInvoice = payment?.scheduled_invoices[0]
  const recurringPaymentContractId =
    payment?.recurring_payment_invoice?.recurring_payment_contract?.id ??
    scheduledInvoice?.recurring_payment_contract_id ??
    null

  const {data: recurringContract} =
    api.recurringPaymentContracts.detail.useQuery(
      {
        pathParams: {
          // biome-ignore lint/style/noNonNullAssertion:
          contractId: recurringPaymentContractId!,
        },
      },
      {
        enabled: recurringPaymentContractId != null,
      },
    )
  const {data: recurringPaymentContracts} =
    api.recurringPaymentContracts.list.useQuery()

  return (
    <>
      {retryScheduledPaymentMutation.data?.status === 'scheduled' ? (
        <span>Scheduled</span>
      ) : (
        <WebUI.Button
          variant="link"
          className="font-normal text-ds-sm"
          onClick={() => retryPaymentAlertRef.current?.show()}
        >
          Retry this payment
        </WebUI.Button>
      )}

      <WebUI.Alert
        ref={retryPaymentAlertRef}
        aria-label="Cancel future payments confirmation"
      >
        <WebUI.AlertHeader>Retry Payment</WebUI.AlertHeader>
        <WebUI.AlertContentView
          text={
            <>
              <div>
                For failed payments, consider adding a new payment method or
                ensuring sufficient funds.
              </div>
              <div className="mt-2">
                Retry payment using the following methods:
              </div>
              <PaymentMethodSelectDropdown
                recurringContract={recurringContract}
                banks={recurringPaymentContracts?.banks}
                cards={recurringPaymentContracts?.cards}
              />
            </>
          }
          actions={
            <>
              <WebUI.AlertActionButton
                execute={async () => {
                  if (!payment.recurring_payment_invoice) {
                    return
                  }

                  await retryScheduledPaymentMutation.mutateAsync({
                    pathParams: {
                      invoiceId: payment.recurring_payment_invoice.id,
                    },
                  })
                  growlActions.show('success', {
                    title: "We've scheduled your payment!",
                    body: 'Please allow 30 minutes to process.',
                  })
                  retryPaymentAlertRef.current?.hide()
                }}
              >
                Retry Payment
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton>Cancel</WebUI.AlertCancelButton>
            </>
          }
        />
      </WebUI.Alert>
    </>
  )
}

// MARK: – PaymentActionsDropdown

interface PaymentActionsDropdownProps
  extends React.ComponentPropsWithoutRef<'button'> {
  payment: Api.PaymentToOthers
}

const PaymentActionsDropdown = ({
  payment,
  className,
  ...restProps
}: PaymentActionsDropdownProps) => {
  const resendReceiptMutation = useResendReceiptPaymentToOthersMutation()
  const growlActions = WebUI.useGrowlActions()
  return (
    <WebUI.Menu>
      <WebUI.MenuButton
        className={WebUI.cn(
          'PaymentAction rounded-[8px] border bg-trueWhite p-1 text-ds-lg transition-opacity duration-100 ease-in-out aria-expanded:visible aria-expanded:opacity-100 [@media(hover:hover){&}]:invisible [@media(hover:hover){&}]:opacity-0',
          className,
        )}
        as={WebUI.IconButton}
        size="default_alt"
        variant="secondary"
        {...restProps}
      >
        <WebUI.PhosphorIcon icon="dots-three-outline-fill" />
      </WebUI.MenuButton>

      <WebUI.MenuList>
        <WebUI.MenuItem as={LinkButton} to={`${payment.id}/details`}>
          Order Summary
        </WebUI.MenuItem>
        {hasTickets(payment) && (
          <WebUI.MenuItem
            as={LinkButton}
            to={`/pdf/payments/${payment.id}/tickets`}
            target="_blank"
          >
            Download Tickets
          </WebUI.MenuItem>
        )}
        <WebUI.MenuItem
          as={WebUI.AnchorButton}
          href={`mailto:${payment.tab.user.email}?subject=${payment.tab.name}`}
        >
          Contact Organizer
        </WebUI.MenuItem>
        <WebUI.MenuItem
          onClick={async () => {
            try {
              await resendReceiptMutation.mutateAsync({
                pathParams: {
                  paymentId: payment.id,
                },
              })
              growlActions.show('success', {
                title: 'Receipt has been sent',
                body: `You will receive an email confirmation shortly at ${payment.tab_member.email}.`,
              })
            } catch (err: any) {
              growlActions.show('error', {
                title: 'Error',
                body: `${err.message}`,
              })
            }
          }}
        >
          Resend Receipt
        </WebUI.MenuItem>
      </WebUI.MenuList>
    </WebUI.Menu>
  )
}

// MARK: – Helpers

const hasTickets = (payment: Api.PaymentToOthers) =>
  payment.payment_items.some(
    (pi) =>
      pi.detail && 'itemType' in pi.detail && pi.detail.itemType === 'ticket',
  )

const hasItems = (payment: Api.PaymentToOthers) =>
  payment.payment_items.some(
    (pi) =>
      pi.detail &&
      'itemType' in pi.detail &&
      pi.detail.itemType != null &&
      pi.detail.itemType !== 'ticket',
  )

const hasWaviers = (payment: Api.PaymentToOthers) =>
  payment.payment_items.some((pi) => pi.tab_form?.options.isWaiver)

const hasForms = (payment: Api.PaymentToOthers) =>
  payment.payment_items.some(
    (pi) => pi.tab_form && !pi.tab_form.options.isWaiver,
  )

const hasSignups = (payment: Api.PaymentToOthers) =>
  payment.payment_items.some((pi) => pi.time_slot)

export default PaymentsPage
