import * as WebUI from '@cheddarup/web-ui'
import {
  Filter as CubeFilter,
  Query as CubeQuery,
  TimeDimension,
} from '@cubejs-client/core'
import {NumericArrayParam, useQueryParam} from 'use-query-params'
import React, {useState} from 'react'
import * as Util from '@cheddarup/util'
import {api} from '@cheddarup/api-client'
import {CubeObject} from 'src/components/CubeTable'
import {CollectionListItemImage} from 'src/components'
import {SharpImage, SharpImageProps} from 'src/components/SharpImage'
import {useCubeQuery} from 'src/hooks/cube'

// MARK: – CubeReportTopCollections

interface CubeReportTopCollectionsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  filters: CubeFilter[]
  dateRange: [string, string]
}

export const CubeReportTopCollections = ({
  filters,
  dateRange,
  className,
  ...restProps
}: CubeReportTopCollectionsProps) => {
  const [orderBy, setOrderBy] = useState('Collections.paymentsTotal')
  return (
    <WebUI.VStack
      className={WebUI.cn('gap-4', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.VStack className="gap-2">
        <WebUI.Heading className="text-ds-md" as="h5">
          Top Collections
        </WebUI.Heading>
        <WebUI.DropdownSelectText
          className="self-start"
          value={orderBy}
          onValueChange={(newOrderBy) => {
            if (newOrderBy) {
              setOrderBy(newOrderBy)
            }
          }}
        >
          <WebUI.DropdownSelectOption value="Collections.paymentsTotal">
            By total amount
          </WebUI.DropdownSelectOption>
          <WebUI.DropdownSelectOption value="Collections.paymentsCount">
            By total payments
          </WebUI.DropdownSelectOption>
        </WebUI.DropdownSelectText>
      </WebUI.VStack>
      <CubeTopList
        className="gap-4"
        emptyStateView="There are no collections that received funds during this time period."
        query={{
          dimensions: [
            'Collections.id',
            'Collections.name',
            'Collections.paymentsCount',
            'Collections.paymentsTotal',
          ],
          measures: ['Collections.count'],
          limit: 3,
          order: [[orderBy, 'desc']],
          filters,
          timeDimensions: [{dimension: 'Payments.createdAt', dateRange}],
        }}
        renderItem={(collection) => (
          <CollectionListItem
            key={String(collection['Collections.id'])}
            collection={collection}
            isByAmount={orderBy === 'Collections.paymentsTotal'}
          />
        )}
      />
    </WebUI.VStack>
  )
}

// MARK: – CollectionListItem

interface CollectionListItemProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: CubeObject
  isByAmount?: boolean
}

const CollectionListItem = ({
  collection,
  isByAmount,
  className,
  ...restProps
}: CollectionListItemProps) => {
  const {data: collectionResource} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: collection['Collections.id']!,
    },
  })
  const [, setSelectedCollectionIds] = useQueryParam(
    'collectionIds',
    NumericArrayParam,
  )
  return (
    <WebUI.VStack
      className={WebUI.cn(
        'items-stretch justify-center gap-6 sm:flex-row sm:items-center sm:justify-start',
        className,
      )}
      {...restProps}
    >
      <WebUI.HStack className="items-center justify-between gap-3">
        {/* TODO: add image path when added to query */}
        <CollectionListItemImage
          width={110}
          height={110}
          image={collectionResource?.featured_image}
        />
      </WebUI.HStack>
      <WebUI.VStack className="min-w-0 grow gap-2">
        <WebUI.Text className="text-ds-sm">
          {collection['Collections.name']}
        </WebUI.Text>
        <WebUI.Text className="text-ds-md">
          {isByAmount
            ? Util.formatAmount(collection['Collections.paymentsTotal'] ?? '0')
            : `${collection['Collections.paymentsCount']} payments`}
        </WebUI.Text>
        <ReportPageTabLink
          tabId="payments"
          onClick={() => {
            if (typeof collection['Collections.id'] === 'number') {
              setSelectedCollectionIds([collection['Collections.id']])
            }
          }}
        >
          View Payments
        </ReportPageTabLink>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – CubeReportTopItems

interface CubeReportTopItemsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  filters: CubeFilter[]
  dateRange: [string, string]
}

export const CubeReportTopItems = ({
  filters,
  dateRange,
  className,
  ...restProps
}: CubeReportTopItemsProps) => {
  const [orderBy, setOrderBy] = useState('PaymentItems.totalSold')
  return (
    <WebUI.VStack
      className={WebUI.cn('gap-4', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.VStack className="gap-2">
        <WebUI.Heading className="text-ds-md" as="h5">
          Top Items
        </WebUI.Heading>
        <WebUI.DropdownSelectText
          className="self-start"
          value={orderBy}
          onValueChange={(newOrderBy) => {
            if (newOrderBy) {
              setOrderBy(newOrderBy)
            }
          }}
        >
          <WebUI.DropdownSelectOption value="PaymentItems.totalSold">
            By total amount
          </WebUI.DropdownSelectOption>
          <WebUI.DropdownSelectOption value="PaymentItems.totalQuantity">
            By total sold
          </WebUI.DropdownSelectOption>
        </WebUI.DropdownSelectText>
      </WebUI.VStack>
      <CubeTopList
        className="gap-4"
        query={{
          dimensions: [
            'Items.id',
            'Items.name',
            'Items.createdAt',
            'Collections.name',
            'Collections.id',
          ],
          measures: ['PaymentItems.totalSold', 'PaymentItems.totalQuantity'],
          limit: 3,
          order: [[orderBy, 'desc']],
          filters: [
            ...filters,
            {
              member: 'Items.id',
              operator: 'gt',
              values: ['0'],
            },
            {
              member: 'PaymentItems.totalSold',
              operator: 'gt',
              values: ['0'],
            },
          ],
          timeDimensions: [{dimension: 'Payments.createdAt', dateRange}],
        }}
        emptyStateView="You have no items with payments during this time period."
        renderItem={(item) => (
          <ItemListItem key={item['Items.id']} item={item} orderBy={orderBy} />
        )}
      />
    </WebUI.VStack>
  )
}

interface ItemListItemProps extends React.ComponentPropsWithoutRef<'div'> {
  item: CubeObject
  orderBy: string
}

const ItemListItem = ({
  item,
  orderBy,
  className,
  ...restProps
}: ItemListItemProps) => {
  const collectionId = item['Collections.id']
  const {data: collection} = api.tabs.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: collectionId!,
    },
  })
  const {data: itemResource} = api.tabItems.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: collectionId!,
      // biome-ignore lint/style/noNonNullAssertion:
      itemId: item['Items.id']!,
    },
  })

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'items-stretch justify-center gap-6 sm:flex-row sm:items-center sm:justify-start',
      )}
      {...restProps}
    >
      {/* TODO: add image path when added to query */}
      <ListItemImage size={110} image={itemResource?.images?.[0]} />
      <WebUI.VStack className="min-w-0 grow gap-2">
        <WebUI.Ellipsis className="text-ds-sm">
          {item['Items.name']}
        </WebUI.Ellipsis>
        <WebUI.Text className="text-ds-md">
          {orderBy === 'PaymentItems.totalSold'
            ? Util.formatAmount(item['PaymentItems.totalSold'] as number)
            : `${item['PaymentItems.totalQuantity'] || 0} Sold`}
        </WebUI.Text>
        {collection && (
          <WebUI.Anchor
            className="max-w-full self-start overflow-hidden text-ellipsis whitespace-nowrap text-ds-sm"
            iconAfter={
              <WebUI.PhosphorIcon className="mb-1" icon="arrow-square-out" />
            }
            rel="noopener noreferrer"
            target="_blank"
            href={`/collection/${collectionId}/manage/items`}
          >
            {item['Collections.name']}
          </WebUI.Anchor>
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – CubeReportTopPayers

interface CubeReportTopPayersProps
  extends React.ComponentPropsWithoutRef<'div'> {
  filters: CubeFilter[]
  timeDimensions: TimeDimension[]
}

export const CubeReportTopPayers = ({
  filters,
  timeDimensions,
  className,
  ...restProps
}: CubeReportTopPayersProps) => {
  const [orderBy, setOrderBy] = useState('Customers.totalPaid')
  return (
    <WebUI.VStack
      className={WebUI.cn('gap-4', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.VStack className="gap-2">
        <WebUI.Heading className="text-ds-md" as="h5">
          Top Payers
        </WebUI.Heading>
        <WebUI.DropdownSelectText
          className="self-start"
          value={orderBy}
          onValueChange={(newOrderBy) => {
            if (newOrderBy) {
              setOrderBy(newOrderBy)
            }
          }}
        >
          <WebUI.DropdownSelectOption value="Customers.totalPaid">
            By total amount
          </WebUI.DropdownSelectOption>
          <WebUI.DropdownSelectOption value="Customers.totalQuantity">
            By total items purchased
          </WebUI.DropdownSelectOption>
        </WebUI.DropdownSelectText>
      </WebUI.VStack>

      <CubeTopList
        query={{
          dimensions: ['Customers.email'],
          measures: [
            'Customers.name',
            'Customers.totalPaid',
            'Customers.paymentCount',
            'Customers.totalQuantity',
          ],
          order: [[orderBy, 'desc']],
          filters,
          timeDimensions,
          limit: 10,
        }}
        emptyStateView="There are no payers during this time period."
        renderItem={(payer) => (
          <WebUI.HStack
            key={payer['Customers.email']}
            className="items-center justify-between gap-4"
          >
            {/* TODO: add customer image when added to query */}
            <WebUI.PhosphorIcon
              className="shrink-0 text-teal-70"
              icon="user-circle"
              width={38}
            />
            <WebUI.VStack className="grow">
              <WebUI.HStack className="justify-between">
                <WebUI.Text className="text-ds-base">
                  {payer['Customers.name']}
                </WebUI.Text>
                <WebUI.Text className="text-right font-light text-ds-base">
                  {orderBy === 'Customers.totalPaid'
                    ? Util.formatAmount(payer['Customers.totalPaid'] ?? '0')
                    : payer['Customers.totalQuantity']}
                </WebUI.Text>
              </WebUI.HStack>
              <WebUI.Text className="text-ds-xs text-gray400">
                {payer['Customers.email']}
              </WebUI.Text>
            </WebUI.VStack>
          </WebUI.HStack>
        )}
        externalLink={
          <ReportPageTabLink tabId="payers">View Payers</ReportPageTabLink>
        }
      />
    </WebUI.VStack>
  )
}

// MARK: – CubeReportWithdrawals

interface CubeReportWithdrawalsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  filters: CubeFilter[]
  dateRange: [string, string]
}

export const CubeReportWithdrawals = ({
  filters,
  dateRange,
  className,
  ...restProps
}: CubeReportWithdrawalsProps) => {
  const [filter, setFilter] = useState('Paid')
  return (
    <WebUI.VStack
      className={WebUI.cn('gap-4', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.VStack className="gap-2">
        <WebUI.Heading className="text-ds-md" as="h5">
          Withdrawals
        </WebUI.Heading>
        <WebUI.DropdownSelectText
          className="self-start"
          value={filter}
          onValueChange={(newFilter) => {
            if (newFilter) {
              setFilter(newFilter)
            }
          }}
        >
          <WebUI.DropdownSelectOption value="Paid">
            Status: Paid
          </WebUI.DropdownSelectOption>
          <WebUI.DropdownSelectOption value="Pending">
            Status: Pending
          </WebUI.DropdownSelectOption>
          <WebUI.DropdownSelectOption value="Failed">
            Status: Failed
          </WebUI.DropdownSelectOption>
        </WebUI.DropdownSelectText>
      </WebUI.VStack>
      <CubeTopList
        query={{
          dimensions: [
            'Withdrawals.description',
            'Withdrawals.amount',
            'Withdrawals.status',
            'Withdrawals.createdAt',
            'Collections.name',
          ],
          order: [['Withdrawals.createdAt', 'desc']],
          limit: 3,
          filters: [
            {
              member: 'Withdrawals.status',
              operator: 'equals',
              values: [filter],
            },
            ...filters,
          ],
          timeDimensions: [{dimension: 'Withdrawals.createdAt', dateRange}],
        }}
        emptyStateView={`You have no ${filter.toLowerCase()} withdrawals during this time period.`}
        renderItem={(withdawal) => (
          <WebUI.VStack key={withdawal['Withdrawals.createdAt']}>
            <WebUI.HStack className="justify-between">
              <WebUI.Text className="text-ds-base">
                {withdawal['Withdrawals.description'] ||
                  withdawal['Collections.name']}
              </WebUI.Text>
              <WebUI.Text className="text-right font-light text-ds-base">
                {Util.formatAmount(withdawal['Withdrawals.amount'] ?? 0)}
              </WebUI.Text>
            </WebUI.HStack>

            <WebUI.Text className="text-ds-xs">
              {Util.formatDateAs(
                withdawal['Withdrawals.createdAt'] ?? '',
                'date_tabular',
              )}
            </WebUI.Text>
          </WebUI.VStack>
        )}
        externalLink={
          <ReportPageTabLink tabId="withdrawals">
            View Withdrawals
          </ReportPageTabLink>
        }
      />
    </WebUI.VStack>
  )
}

// MARK: – CubeTopList

interface CubeTopListProps
  extends WebUI.VStackProps,
    React.ComponentPropsWithoutRef<'div'> {
  query: CubeQuery
  renderItem: (cubeObject: CubeObject) => React.ReactNode
  emptyStateView?: React.ReactNode
  externalLink?: React.ReactNode
}

const CubeTopList = ({
  query: queryDef,
  renderItem,
  emptyStateView = 'Empty',
  externalLink,
  className,
  ...restProps
}: CubeTopListProps) => {
  const query = useCubeQuery(queryDef)
  const data = (query.resultSet?.tablePivot() ?? []) as CubeObject[]

  return (
    <WebUI.VStack className={WebUI.cn('gap-3', className)} {...restProps}>
      {data.length > 0 ? (
        data.map((dataItem) => renderItem(dataItem))
      ) : query.isLoading ? (
        <WebUI.Loader size="3em" />
      ) : (
        emptyStateView
      )}
      {data.length > 0 && !query.isLoading && externalLink}
    </WebUI.VStack>
  )
}

// MARK: – ReportPageTabLink

interface ReportPageTabLinkProps
  extends WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  tabId: string
}

const ReportPageTabLink = ({
  tabId,
  className,
  variant = 'link',
  onClick,
  ...restProps
}: ReportPageTabLinkProps) => {
  const [, setSelectedTabId] = useQueryParam('p')
  return (
    <WebUI.Button
      className={WebUI.cn('text-ds-sm', className)}
      variant={variant}
      onClick={(event) => {
        if (!event.defaultPrevented) {
          window.scrollTo(0, 0)
          setSelectedTabId(tabId)
        }
        onClick?.(event)
      }}
      {...restProps}
    />
  )
}

// MARK: – ListItemImage

interface ListItemImageProps
  extends Omit<SharpImageProps, 'width' | 'height' | 'alt'> {
  size: number
}

const ListItemImage = ({
  size,
  image,
  className,
  style,
  onError,
  ...restProps
}: ListItemImageProps) => (
  <SharpImage
    className={WebUI.cn('shrink-0 rounded object-cover', className)}
    style={style}
    width={size}
    height={size}
    image={image}
    alt="Collection"
    onError={onError}
    errorFallback={
      <WebUI.VStack
        className={WebUI.cn(
          'shrink-0 items-center justify-center rounded bg-teal-80 text-trueWhite',
          className,
        )}
        style={{
          width: size,
          height: size,
          fontSize: size / 2,
          ...style,
        }}
        {...restProps}
      >
        <WebUI.PhosphorIcon className="scale-[(-1,1)]" icon="tag-fill" />
      </WebUI.VStack>
    }
    {...restProps}
  />
)
