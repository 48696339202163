import React, {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'

import {MarketplaceSalesItem, MarketplaceSectionTitle} from '../../components'

const MarketplaceSalesContainer = () => {
  const {
    data: {user: currentUser},
  } = api.auth.session.useSuspenseQuery()
  const {data: collections} = api.tabs.list.useSuspenseQuery()

  const internalMarketplaceEnabledCollections = useMemo(
    () =>
      collections.filter(
        (collection) => collection.options?.internalMarketplace?.enabled,
      ),
    [collections],
  )

  const organizers = useMemo(
    () =>
      internalMarketplaceEnabledCollections.length > 0
        ? ([
            {
              id: currentUser.id,
              created_at: currentUser.created_at,
              name: currentUser.full_name,
              display_name: currentUser.display_name,
              first_name: currentUser.first_name,
              last_name: currentUser.last_name,
              profilePic: currentUser.profile_pic ?? null,
              collections: internalMarketplaceEnabledCollections,
            },
          ] as Array<
            Api.MarketplaceCollectionOrganizer & {collections: Api.Tab[]}
          >)
        : [],
    [currentUser, internalMarketplaceEnabledCollections],
  )

  return (
    <WebUI.VStack className="gap-4">
      <MarketplaceSectionTitle>My Sales</MarketplaceSectionTitle>
      {organizers.length > 0 ? (
        <WebUI.VStack className="gap-4">
          {organizers.map((organizer) => (
            <MarketplaceSalesItem key={organizer.id} organizer={organizer} />
          ))}
        </WebUI.VStack>
      ) : (
        <MarketplaceSalesEmptyStateView />
      )}
    </WebUI.VStack>
  )
}

// MARK: – MarketplaceSalesEmptyStateView

const MarketplaceSalesEmptyStateView = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => (
  <div
    className={WebUI.cn(
      'flex flex-col items-center bg-trueWhite p-8 text-center',
      className,
    )}
    {...restProps}
  >
    <div className="max-w-[34em] font-normal text-ds-lg">
      You haven&apos;t shared any sales yet. If you have an active sale, go to
      the sale settings and toggle on the option to list it in the Internal
      Marketplace.
    </div>
    <LinkButton className="mt-8" size="large" to="/collections">
      See Your Sales
    </LinkButton>
  </div>
)

export default MarketplaceSalesContainer
