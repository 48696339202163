import {useLiveRef} from '@cheddarup/react-util'
import React, {useEffect, useState} from 'react'

import {PhosphorIcon} from '../icons'
import {Ellipsis} from './Ellipsis'
import {
  FileUploader,
  FileUploaderButton,
  FileUploaderDropzone,
  FileUploaderInput,
  FileUploaderInstance,
  FileUploaderProps,
} from './FileUploader'
import {useRemoteFileMetadata} from './RemoteFileOverview'
import {HStack, VStack} from './Stack'
import {IconButton} from './IconButton'
import {Skeleton} from './Skeleton'
import {cn} from '../utils'
import {getFileNameFromUrl} from '@cheddarup/util'

export type FilesManagerValue = File | string // `string` here is remote file's url

export interface FilesManagerProps extends Omit<FileUploaderProps, 'children'> {
  className?: string
  defaultFiles?: FilesManagerValue[]
  onFilesChange?: (files: FilesManagerValue[]) => void
}

export const FilesManager = React.forwardRef<
  FileUploaderInstance,
  FilesManagerProps
>(
  (
    {
      defaultFiles,
      onFilesChange,
      multiple = false,
      noClick = true,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [acceptedFiles, setAcceptedFiles] = useState<FilesManagerValue[]>(
      defaultFiles ?? [],
    )
    const onFilesChangeRef = useLiveRef(onFilesChange)

    useEffect(() => {
      onFilesChangeRef.current?.(acceptedFiles)
    }, [acceptedFiles])

    return (
      <FileUploader
        ref={forwardedRef}
        noClick={noClick}
        onDropAccepted={(newAcceptedFiles: File[]) =>
          setAcceptedFiles((prevAcceptedFiles) => {
            if (!newAcceptedFiles[0]) {
              return prevAcceptedFiles
            }
            return multiple
              ? [...prevAcceptedFiles, ...newAcceptedFiles]
              : [newAcceptedFiles[0]]
          })
        }
        {...restProps}
      >
        <VStack className={cn('FilesManager gap-4', className)}>
          <FileUploaderInput />
          <FileUploaderDropzone className="FilesManager-dropzone">
            <FileUploaderButton
              className={
                'FilesManager-uploadButton h-auto w-full grow rounded border bg-trueWhite'
              }
              orientation="vertical"
              variant="outlined"
              iconBefore={<PhosphorIcon icon="cloud-arrow-up" width={36} />}
            >
              <Ellipsis>Browse Files</Ellipsis>
              <Ellipsis className="text-gray400">
                Drag and drop file{multiple ? 's' : ''} here
              </Ellipsis>
            </FileUploaderButton>
          </FileUploaderDropzone>

          <VStack className="gap-2">
            {acceptedFiles.map((f) => (
              <AcceptedFileListRow
                key={
                  typeof f === 'string'
                    ? f
                    : `${f.name}-${String(f.lastModified)}`
                }
                file={f}
                onRemove={() =>
                  setAcceptedFiles((prevAcceptedFiles) =>
                    prevAcceptedFiles.filter((af) => af !== f),
                  )
                }
              />
            ))}
          </VStack>
        </VStack>
      </FileUploader>
    )
  },
)

// MARK: – AcceptedFileListRow

export interface AcceptedFileListRowProps
  extends React.ComponentPropsWithoutRef<'div'> {
  file: FilesManagerValue
  onRemove: () => void
}

export const AcceptedFileListRow = React.forwardRef<
  HTMLDivElement,
  AcceptedFileListRowProps
>(({file, onRemove, className, ...restProps}, forwardedRef) => {
  const {metadata, isLoading: isFileSizeLoading} =
    typeof file === 'string'
      ? useRemoteFileMetadata(file)
      : {metadata: {size: file.size, contentType: file.type}, isLoading: false}

  return (
    <HStack
      ref={forwardedRef}
      className={cn('gap-3', className)}
      {...restProps}
    >
      <HStack
        className={
          'min-w-0 grow items-center justify-between gap-3 rounded bg-depr-grey-200 px-4 py-3'
        }
      >
        <Ellipsis className="text-ds-sm">
          {typeof file === 'string' ? getFileNameFromUrl(file) : file.name}
        </Ellipsis>
        {isFileSizeLoading ? (
          <Skeleton width={24} height={12} />
        ) : typeof metadata?.size === 'number' ? (
          <Ellipsis className="flex-0 text-right text-ds-sm">
            {(metadata.size * 0.001).toFixed(2)} KB
          </Ellipsis>
        ) : null}
      </HStack>
      <IconButton
        className="text-ds-lg"
        variant="secondary"
        onClick={() => onRemove()}
      >
        <PhosphorIcon icon="trash" />
      </IconButton>
    </HStack>
  )
})
