import * as ProgressPrimitive from '@radix-ui/react-progress'
import {motion} from 'framer-motion'
import React, {useEffect, useState} from 'react'

import {cn} from '../utils'

export interface ProgressProps extends ProgressPrimitive.ProgressProps {}

export const Progress = React.forwardRef<HTMLDivElement, ProgressProps>(
  ({className, value, children, ...restProps}, forwardedRef) => (
    <ProgressPrimitive.Root
      ref={forwardedRef}
      className={cn(
        'Progress',
        'relative h-1 overflow-hidden bg-gray800 [transform:translateZ(0)]',
        className,
      )}
      value={value}
      {...restProps}
    >
      <ProgressPrimitive.Indicator
        className="Progress-indicator h-full w-full bg-trueWhite"
        asChild
      >
        {children}
      </ProgressPrimitive.Indicator>
    </ProgressPrimitive.Root>
  ),
)

export interface TimedProgressProps
  extends Omit<ProgressProps, 'value' | 'children'> {
  initialValue?: number
  transitionDuration?: number
}

export const TimedProgress = React.forwardRef<
  HTMLDivElement,
  TimedProgressProps
>(({transitionDuration, initialValue = 100, ...restProps}, forwardedRef) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setValue((prevValue) => (prevValue > 0 ? prevValue - 1 : prevValue))
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <Progress ref={forwardedRef} value={value} {...restProps}>
      <motion.div
        animate={{
          x: '-100%',
          transition: {
            type: 'tween',
            ease: 'linear',
            duration: transitionDuration,
          },
        }}
      />
    </Progress>
  )
})
