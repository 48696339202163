import React, {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

export interface MarketplaceShopItemsFiltersOverviewProps
  extends React.ComponentPropsWithoutRef<'div'> {
  catalogIds: number[]
  onChangeCatalogIds: (catalogIds: number[]) => void
}

const MarketplaceShopItemsFiltersOverview = ({
  catalogIds,
  onChangeCatalogIds,
  className,
  ...restProps
}: MarketplaceShopItemsFiltersOverviewProps) => {
  const {data: session} = api.auth.session.useSuspenseQuery()
  const {data: marketplaceHome} = api.marketplace.home.useQuery(undefined, {
    enabled: !!session.organization_data?.id,
  })

  const catalogs = useMemo(
    () =>
      marketplaceHome?.catalogs.filter((catalog) =>
        catalogIds.includes(catalog.id),
      ) ?? [],
    [catalogIds, marketplaceHome?.catalogs],
  )

  return catalogs.length > 0 ? (
    <WebUI.HStack
      className={WebUI.cn('inline-flex flex-wrap bg-trueWhite py-2', className)}
      {...restProps}
    >
      {catalogs.map((catalog) => (
        <WebUI.Tag
          key={catalog.id}
          className="!m-1 text-ds-xs"
          onClear={() =>
            onChangeCatalogIds(
              catalogIds.filter((catalogId) => catalogId !== catalog.id),
            )
          }
        >
          {catalog.name}
        </WebUI.Tag>
      ))}
    </WebUI.HStack>
  ) : null
}

export default MarketplaceShopItemsFiltersOverview
