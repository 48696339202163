import {useNavigate} from 'react-router-dom'
import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {useMailedSecurityCodeVerificationMutation} from '@cheddarup/api-client'
import {readApiError} from 'src/helpers/error-formatting'

const MailedSecurityCodeInputPage: React.FC = () => {
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [mailedSecurityCode, setMailedSecurityCode] = useState('')
  const growlActions = WebUI.useGrowlActions()
  const mailedSecurityCodeVerificationMutation =
    useMailedSecurityCodeVerificationMutation()

  return (
    <WebUI.Alert
      aria-label="Reset Two-Factor Authentication with a Mailed Security Code form"
      className="[&_>_.ModalContentView]:max-w-screen-md"
      initialVisible
      onDidHide={() => navigate('../')}
    >
      {(dialog) => (
        <>
          <WebUI.AlertHeader
            className={
              '[&.ModalHeader]:border-b [&.ModalHeader]:border-b-depr-grey-200 [&_>_.ModalHeader-content]:ml-10 [&_>_.ModalHeader-content]:font-normal [&_>_.ModalHeader-content]:text-ds-lg'
            }
          >
            Reset Two-Factor Authentication with a Mailed Security Code
          </WebUI.AlertHeader>
          <WebUI.VStack className="mr-5 ml-5 gap-5 pt-8 pr-15 pb-15 pl-15">
            <WebUI.Text className="text-justify font-light text-gray800">
              <strong className="font-medium">IMPORTANT:</strong> Taking the
              actions below will clear out the two-factor authentication on your
              account, which puts any current or future balance at risk should
              an unauthorized person gain access to your account. If you proceed
              with removing the current device, you are choosing to accept these
              risks. In addition, upon removing the current device, it is
              crucial that you immediately add a new two-factor authentication
              device to keep your account protected.
            </WebUI.Text>
            <div className="mr-10 border-depr-grey-400 border-b" />
            <WebUI.Text className="font-light text-gray800">
              <strong className="font-medium">
                To proceed with your two-factor device reset, enter your account
                password and the mailed security code sent to your physical
                address on file.
              </strong>
            </WebUI.Text>

            <WebUI.FormField className="mt-4 w-3/5" label="Account Password">
              <WebUI.Input
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </WebUI.FormField>
            <WebUI.FormField
              className="my-4 w-3/5"
              label="Mailed Security Code"
            >
              <WebUI.Input
                value={mailedSecurityCode}
                onChange={(event) => setMailedSecurityCode(event.target.value)}
              />
            </WebUI.FormField>

            <WebUI.HStack className="gap-3">
              <WebUI.Button
                variant="primary"
                loading={mailedSecurityCodeVerificationMutation.isPending}
                onClick={async () => {
                  try {
                    await mailedSecurityCodeVerificationMutation.mutateAsync({
                      body: {
                        code: mailedSecurityCode,
                        password,
                      },
                    })
                    growlActions.show('success', {
                      title: 'Success',
                      body: 'Correct code.',
                    })
                    dialog.hide()
                  } catch (error) {
                    const errorMessage = readApiError(error, {
                      security_code_expired:
                        'Your security code expired. Request for new one.',
                      invalid_security_code: 'Invalid security code.',
                      code_not_found:
                        'You need to request a security code before verification.',
                    })
                    growlActions.clear()
                    growlActions.show('error', {
                      title: 'Error!',
                      body: errorMessage,
                    })
                  }
                }}
              >
                Continue
              </WebUI.Button>
              <WebUI.Button variant="secondary" onClick={() => dialog.hide()}>
                Cancel
              </WebUI.Button>
            </WebUI.HStack>
          </WebUI.VStack>
        </>
      )}
    </WebUI.Alert>
  )
}

export default MailedSecurityCodeInputPage
