import {chunk} from '@cheddarup/util'
import {ForwardRefComponent} from '@cheddarup/react-util'
import flattenChildren from 'react-keyed-flatten-children'
import React, {useState} from 'react'

import {Button} from './Button'
import {Menu, MenuButton, MenuItem, MenuList} from './Menu'
import {Stack, StackProps} from './Stack'
import {useGenericMedia} from '../hooks'
import {PhosphorIcon} from '../icons'
import {DeprecatedTooltip} from './_Tooltip'
import {cn} from '../utils'

export interface ActionGroupProps
  extends StackProps,
    React.ComponentPropsWithoutRef<'div'> {
  maxVisible?: number
  maxVisibleCompact?: number
  menuVisible?: boolean
}

export const ActionGroup = React.forwardRef<HTMLDivElement, ActionGroupProps>(
  (
    {
      direction = 'horizontal',
      className,
      maxVisible: maxVisibleProp = 3,
      maxVisibleCompact: maxVisibleCompactProp = 1,
      menuVisible,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const isTouchDevice = useGenericMedia('(hover: none)')
    const maxVisible = isTouchDevice ? maxVisibleCompactProp : maxVisibleProp
    const childrenAsArray = flattenChildren(children) as Array<
      React.ReactElement<
        React.ComponentProps<ForwardRefComponent<typeof Button, ActionProps>>
      >
    >
    const [visibleActions = [], ...restActionChunks] =
      maxVisible > 1
        ? chunk(childrenAsArray, maxVisible - 1)
        : [[], childrenAsArray]
    const hiddenActions = restActionChunks.flat()
    const menuAction =
      hiddenActions.length > 1 ? (
        <Menu placement="bottom-start" visible={menuVisible}>
          <MenuButton
            as={Action}
            icon={<PhosphorIcon icon="dots-three-outline-fill" />}
            tooltipVisible={false}
          >
            More
          </MenuButton>
          <MenuList className="[&_>_.MenuList-inner_>_.MenuList-body]:rounded-[10px]">
            {hiddenActions
              .map((ha) => ha.props)
              .map(
                (
                  {id, icon, labelVisible, execute, onClick, ...actionProps},
                  idx,
                ) => (
                  <MenuItem
                    key={idx}
                    {...actionProps}
                    id={id ?? String(idx)}
                    className="px-4 [&_>_.Button-iconBefore]:mr-2"
                    iconBefore={icon}
                    onClick={(event) => {
                      onClick?.(event)

                      if (execute && !event.defaultPrevented) {
                        return execute(event)
                      }

                      return undefined
                    }}
                  />
                ),
              )}
          </MenuList>
        </Menu>
      ) : (
        hiddenActions
      )

    return (
      <Stack
        ref={forwardedRef}
        className={cn(
          'ActionGroup gap-2 rounded-[8px] border bg-trueWhite p-0_5',
          className,
        )}
        direction={direction}
        {...restProps}
      >
        {visibleActions}
        {menuAction}
      </Stack>
    )
  },
)

// MARK: – Action

export interface ActionProps {
  labelVisible?: boolean
  tooltipVisible?: boolean
  icon?: React.ReactNode
  execute?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any
}

export const Action = React.forwardRef(
  (
    {
      labelVisible = false,
      tooltipVisible = true,
      icon,
      as: Comp = Button,
      size = 'compact',
      variant = 'ghost',
      execute,
      className,
      children: label,
      onClick,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [isLoading, setIsLoading] = useState(false)

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = async (
      event,
    ) => {
      onClick?.(event)

      if (!execute || event.defaultPrevented) {
        return
      }

      try {
        setIsLoading(true)
        await execute(event)
      } finally {
        setIsLoading(false)
      }
    }

    const action = (
      <Comp
        ref={forwardedRef}
        className={cn(
          'Action',
          !labelVisible &&
            'h-[1.33em] w-[1.33em] px-1 py-1 text-ds-lg [&.Action_>_.Button-iconBefore]:mr-0',
          className,
        )}
        size={size}
        variant={variant}
        iconBefore={icon}
        loading={isLoading}
        onClick={handleClick}
        {...restProps}
      />
    )

    return labelVisible || !tooltipVisible ? (
      action
    ) : (
      <DeprecatedTooltip
        className="flex items-center justify-center"
        label={label}
      >
        {action}
      </DeprecatedTooltip>
    )
  },
) as ForwardRefComponent<typeof Button, ActionProps>
