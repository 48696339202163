import React from 'react'

import {HStack, HStackProps} from './Stack'
import {cn} from '../utils'

export interface NavigationBarProps
  extends HStackProps,
    React.ComponentPropsWithoutRef<'div'> {
  left?: React.ReactNode
  center?: React.ReactNode
  right?: React.ReactNode
}

export const NavigationBar = ({
  left,
  center,
  right,
  className,
  ...restProps
}: NavigationBarProps) => (
  <HStack
    className={cn(
      'NavigationBar',
      'relative min-h-16 justify-between gap-2 overflow-hidden bg-trueWhite px-2 shadow-[0_0_0_1px_theme(colors.depr-grey.200)] *:min-w-0 sm:px-4',
      className,
    )}
    {...restProps}
  >
    <HStack className="NavigationBar-leftWrapper min-w-16 items-center gap-3">
      {left}
    </HStack>
    <HStack className="NavigationBar-centerWrapper flex-[1_0_0px] items-center justify-center gap-3">
      {center}
    </HStack>
    <HStack className="NavigationBar-rightWrapper min-w-16 items-center justify-end gap-3">
      {right}
    </HStack>
  </HStack>
)
