import * as WebUI from '@cheddarup/web-ui'
import React, {useContext} from 'react'
import * as Util from '@cheddarup/util'
import {makeFieldGroup, FieldGroupType} from '@cheddarup/core'

import {FieldsEditInnerContext} from './FieldsEdit'

export interface FieldGroupTypePickerProps
  extends React.ComponentPropsWithoutRef<'div'> {
  disableProFields?: boolean
  showBadgeOnProFields?: boolean
  hiddenFieldGroupTypes?: FieldGroupType[]
}

export const FieldGroupTypePicker = ({
  disableProFields = false,
  showBadgeOnProFields = true,
  hiddenFieldGroupTypes = [],
  className,
  ...restProps
}: FieldGroupTypePickerProps) => {
  const proFieldGroupTypes: FieldGroupType[] = ['signature', 'file']
  const fieldGroupTypes: FieldGroupType[] = [
    'full_name',
    'email',
    'address',
    'phone',
    'text',
    'text_multiline',
    'multiple_choice',
    'checkbox',
    'date',
    'time',
    'signature',
    'file',
    'layout_description',
    'layout_divider',
  ]
  const filteredFieldGroupTypes = Util.difference(
    fieldGroupTypes,
    hiddenFieldGroupTypes,
  )

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'FieldGroupTypePicker',
        'min-w-[240px] overflow-y-auto bg-depr-aqua-100 [&_>_.FieldGroupTypePicker-sectionHeader]:min-h-[50px] [&_>_.FieldGroupTypePicker-sectionHeader]:border-trueWhite [&_>_.FieldGroupTypePicker-sectionHeader]:border-b [&_>_.FieldGroupTypePicker-sectionHeader]:px-6 [&_>_.FieldGroupTypePicker-sectionHeader_>_.FieldGroupTypePicker-sectionHeaderTitle]:font-bold [&_>_.FieldGroupTypePicker-sectionHeader_>_.FieldGroupTypePicker-sectionHeaderTitle]:text-ds-sm [&_>_.FieldGroupTypePicker-sectionHeader_>_.FieldGroupTypePicker-sectionHeaderTitle]:uppercase [&_>_.FieldGroupTypePickerItem]:min-h-[50px] [&_>_.FieldGroupTypePickerItem]:border-trueWhite [&_>_.FieldGroupTypePickerItem]:border-b',
        className,
      )}
      {...restProps}
    >
      <WebUI.HStack className="FieldGroupTypePicker-sectionHeader items-center">
        <WebUI.Text className="FieldGroupTypePicker-sectionHeaderTitle">
          Question Types
        </WebUI.Text>
      </WebUI.HStack>
      {filteredFieldGroupTypes.map((fieldGroupType, idx) => (
        <React.Fragment key={fieldGroupType}>
          {idx === filteredFieldGroupTypes.length - 2 &&
            ['layout_description', 'layout_divider'].includes(
              fieldGroupType,
            ) && (
              <WebUI.HStack className="FieldGroupTypePicker-sectionHeader items-center">
                <WebUI.Text className="FieldGroupTypePicker-sectionHeaderTitle">
                  Layout
                </WebUI.Text>
              </WebUI.HStack>
            )}
          <FieldGroupTypePickerItem
            key={fieldGroupType}
            fieldGroupType={fieldGroupType}
            iconAfter={
              showBadgeOnProFields &&
              proFieldGroupTypes.includes(fieldGroupType) && (
                <PaidFieldBadge text={disableProFields ? 'Pro' : 'Paid'} />
              )
            }
            disabled={
              disableProFields && proFieldGroupTypes.includes(fieldGroupType)
            }
          />
        </React.Fragment>
      ))}
    </WebUI.VStack>
  )
}

// MARK: – PaidFieldBadge

export interface PaidFieldBadgeProps
  extends React.ComponentPropsWithoutRef<'div'> {
  text?: 'Pro' | 'Paid'
}

export const PaidFieldBadge: React.FC<PaidFieldBadgeProps> = ({
  text = 'Paid',
  ...restProps
}) => (
  <WebUI.HStack
    className="h-[30px] items-center gap-2 rounded border border-trueWhite px-3 py-2 text-tint"
    {...restProps}
  >
    <WebUI.PhosphorIcon icon="star" />
    <span>{text}</span>
  </WebUI.HStack>
)

// MARK: – FieldGroupTypePickerItem

export interface FieldGroupTypePickerItemProps
  extends WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  fieldGroupType: FieldGroupType
}

export const FieldGroupTypePickerItem = ({
  fieldGroupType,
  className,
  onClick,
  ...restProps
}: FieldGroupTypePickerItemProps) => {
  const media = WebUI.useMedia()
  const contextValue = useContext(FieldsEditInnerContext)
  const content = fieldGroupTypeToContent[fieldGroupType]
  const {label, iconName} = content
  const fieldGroup = makeFieldGroup(fieldGroupType)

  return (
    <WebUI.Draggable
      aria-disabled={false}
      className={WebUI.cn(
        'FieldGroupTypePickerItem',
        `FieldGroupTypePickerItem--${fieldGroupType}`,
        'pr-4 pl-8 text-left [&_>_.Button-iconBefore]:mr-4',
        className,
      )}
      id={fieldGroup.id}
      dragDisabled={!media.sm}
      dragData={fieldGroup}
      as={WebUI.Button}
      variant="ghost"
      iconBefore={
        <WebUI.PhosphorIcon icon={iconName} className="text-[1.5em]" />
      }
      onClick={(event) => {
        onClick?.(event)

        if (event.defaultPrevented) {
          return
        }

        contextValue.setFieldGroups((prevFieldGroups) => [
          ...prevFieldGroups,
          fieldGroup,
        ])
      }}
      {...restProps}
    >
      {label}
    </WebUI.Draggable>
  )
}

// MARK: – Helpers

export const fieldGroupTypeToContent: Record<
  FieldGroupType,
  {
    label: string
    iconName: WebUI.PhosphorIconName
  }
> = {
  full_name: {
    label: 'Full Name',
    iconName: 'user-circle',
  },
  email: {
    label: 'Email',
    iconName: 'envelope',
  },
  address: {
    label: 'Address',
    iconName: 'map-pin',
  },
  phone: {
    label: 'Phone',
    iconName: 'phone',
  },
  text: {
    label: 'Short Text',
    iconName: 'textbox',
  },
  text_multiline: {
    label: 'Multiline Text',
    iconName: 'article',
  },
  multiple_choice: {
    label: 'Dropdown',
    iconName: 'caret-circle-down',
  },
  checkbox: {
    label: 'Checkboxes',
    iconName: 'check-square',
  },
  date: {
    label: 'Date',
    iconName: 'calendar',
  },
  time: {
    label: 'Time',
    iconName: 'clock',
  },
  signature: {
    label: 'Signature or Initials',
    iconName: 'scribble-loop',
  },
  file: {
    label: 'File',
    iconName: 'cloud-arrow-up',
  },
  layout_description: {
    label: 'Description',
    iconName: 'text-aa',
  },
  layout_divider: {
    label: 'Divider',
    iconName: 'arrows-out-line-vertical',
  },
}
