import React, {useRef} from 'react'
import {TimeValue, useTimeField} from 'react-aria'
import {TimeFieldStateOptions, useTimeFieldState} from 'react-stately'
import {SetOptional, SimpleMerge} from '@cheddarup/util'
import {genericForwardRef, useForkRef} from '@cheddarup/react-util'

import {DateSegment, DateSegmentBox, DateSegmentBoxProps} from './DateSegment'
import {cn} from '../utils'

export interface TimeInputProps<T extends TimeValue>
  extends Omit<
    SimpleMerge<
      DateSegmentBoxProps,
      SetOptional<TimeFieldStateOptions<T>, 'locale'>
    >,
    'onChange'
  > {
  onValueChange?: TimeFieldStateOptions<T>['onChange']
}

export const TimeInput = genericForwardRef(
  <T extends TimeValue>(
    {
      variant = 'default',
      size = 'default',
      locale = 'en-US',
      onValueChange,
      className,
      disabled,
      required,
      readOnly,
      ...restProps
    }: TimeInputProps<T>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const state = useTimeFieldState<T>({
      locale,
      isDisabled: disabled,
      isReadOnly: readOnly,
      isRequired: required,
      onChange: onValueChange,
      ...restProps,
    })
    const {fieldProps} = useTimeField(restProps, state, ownRef)

    return (
      <DateSegmentBox
        ref={ref}
        className={cn('TimeInput', className)}
        size={size}
        {...fieldProps}
      >
        {state.segments.map((segment, idx) => (
          <DateSegment key={idx} segment={segment} state={state} />
        ))}
      </DateSegmentBox>
    )
  },
)
