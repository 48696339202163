import React, {useEffect, useMemo, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {SearchForm} from 'src/components'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {TabTemplatesGrid} from 'src/components/TabTemplatesGrid'
import {templateCategoriesPickerOrder} from 'src/views/templates/TemplatesLayout'
import {
  templateCategoryToDescriptionMap,
  templateGroupTypeToDescriptionMap,
} from '@cheddarup/core'
import {NextButton} from '@cheddarup/web-ui/next'

export const TemplatesSuggestionsModal = React.forwardRef<
  WebUI.DialogInstance,
  WebUI.ModalProps
>(({className, ...restProps}, forwardedRef) => {
  const media = WebUI.useMedia()
  const [orderBy, setOrderBy] = useState<
    Api.TabTemplatesOrderBy | 'recommended'
  >('recommended')
  const [templateFilter, setTemplateFilter] = useState({
    type: 'category',
    value: 'Staff Picks',
  })
  const drawerRef = useRef<WebUI.DialogInstance>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const {data: user} = api.auth.session.useQuery(undefined, {
    select: (session) => session.user,
  })
  const templatesQuery = api.templates.list.useQuery(
    {
      queryParams: {
        orderBy: {
          key: orderBy === 'recommended' ? 'recentlyAdded' : orderBy,
        },
      },
    },
    {
      select: (templates) =>
        orderBy === 'recommended'
          ? Util.sort(templates).asc((template) => {
              if (
                !templateFilter.value ||
                (templateFilter.type !== 'category' &&
                  templateFilter.type !== 'groupType')
              ) {
                return 0
              }

              const order = (
                template.options.template[
                  templateFilter.type === 'category' ? 'categories' : 'groups'
                ] as Array<Api.TabTemplateCategory | Api.TabTemplateGroup>
              ).find((catOrGr) =>
                (templateFilter.value === 'For You' && user?.customer_category
                  ? customerCategoryToTemplateGroupTypeMap[
                      user.customer_category
                    ]
                  : [templateFilter.value]
                ).includes(catOrGr.type),
              )?.order

              return order ?? Number.MAX_SAFE_INTEGER
            })
          : templates,
    },
  )

  const templates = templatesQuery.data ?? []

  const recommendedTemplates = templates.filter((template) =>
    template.options.template.groups.some((g) =>
      user?.customer_category
        ? Boolean(
            customerCategoryToTemplateGroupTypeMap[
              user.customer_category
            ]?.includes(g.type),
          )
        : false,
    ),
  )

  const hotCategories = ['Staff Picks', 'Seasonal Collections', 'All']

  // biome-ignore lint/correctness/useExhaustiveDependencies: prevents order changes
  const categories = useMemo(
    () =>
      Util.sort(
        Util.unique(
          templates
            .flatMap((template) =>
              template.options.template.categories.map((c) => c.type),
            )
            .filter((c) => !hotCategories.includes(c)),
        ),
      ).asc((cat) => {
        const index = templateCategoriesPickerOrder.indexOf(cat)
        return index > -1 ? index : Number.MAX_SAFE_INTEGER
      }),
    [templatesQuery.isSuccess],
  )

  const hasRecommendedTemplates = recommendedTemplates.length > 0
  useEffect(() => {
    if (hasRecommendedTemplates) {
      setTemplateFilter({type: 'groupType', value: 'For You'})
    }
  }, [hasRecommendedTemplates])

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    drawerRef.current?.hide()
  }, [templateFilter])

  const filteredTemplates = (
    templateFilter.value === 'For You' ? recommendedTemplates : templates
  )
    .filter(
      Util.fuzzyFilterIterator(searchTerm, {
        iterator: (template) => template.name,
      }),
    )
    .filter(
      (template) =>
        templateFilter.value === 'All' ||
        templateFilter.value === 'For You' ||
        (templateFilter.type === 'groupType'
          ? template.options.template.groups.some(
              (g) => g.type === templateFilter.value,
            )
          : template.options.template.categories.some(
              (c) => c.type === templateFilter.value,
            )),
    )

  const sideNav = (
    <div className="scrollbar-hide flex max-w-[320px] shrink-0 grow basis-auto flex-col gap-6 overflow-y-auto bg-depr-grey-200 px-10 py-16">
      <WebUI.Heading className="font-bold uppercase" as="h4">
        TEMPLATES
      </WebUI.Heading>
      <SearchForm
        containerClassName="w-full"
        className={
          'w-full [&_.Input]:bg-trueWhite [&_.Input]:px-3 [&_.Search-cancelButton]:right-6'
        }
        placeholder="Search by keyword"
        values={{term: searchTerm}}
        onTermChange={(newSearchTerm) => setSearchTerm(newSearchTerm)}
      />
      <div className="flex flex-col items-start gap-4">
        {hasRecommendedTemplates && (
          <SideNavButton
            selected={templateFilter.value === 'For You'}
            onClick={() =>
              setTemplateFilter({type: 'groupType', value: 'For You'})
            }
          >
            For You
          </SideNavButton>
        )}
        {hotCategories.map((category, idx) => (
          <SideNavButton
            key={idx}
            selected={
              templateFilter.type === 'category' &&
              templateFilter.value === category
            }
            onClick={() =>
              setTemplateFilter({type: 'category', value: category})
            }
          >
            {category}
          </SideNavButton>
        ))}
      </div>
      <WebUI.Heading className="font-bold uppercase" as="h4">
        Use Cases
      </WebUI.Heading>
      <div className="flex flex-col items-start gap-4">
        {categories.map((category, idx) => (
          <SideNavButton
            key={idx}
            onClick={() =>
              setTemplateFilter({type: 'category', value: category})
            }
            selected={
              templateFilter.type === 'category' &&
              category === templateFilter.value
            }
          >
            {category}
          </SideNavButton>
        ))}
      </div>
    </div>
  )

  return (
    <WebUI.Modal
      aria-label="Template suggestions"
      ref={forwardedRef}
      className={WebUI.cn(
        '[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:w-full [&_>_.ModalContentView]:max-w-screen-xl sm:[&_>_.ModalContentView]:max-h-[740px] sm:[&_>_.ModalContentView]:rounded-extended',
        className,
      )}
      {...restProps}
    >
      <div className="flex grow flex-col overflow-hidden lg:flex-row">
        {media.lg ? (
          sideNav
        ) : (
          <div className="mx-6 mt-10 flex flex-col border-b py-8">
            <WebUI.Drawer
              ref={drawerRef}
              preventBodyScroll
              disclosure={
                <WebUI.DialogDisclosure as={NextButton} size="md">
                  <div />
                  Browse by Category
                  <WebUI.PhosphorIcon
                    className="-translate-y-1/2 absolute top-1/2 right-5 text-ds-xl"
                    icon="faders-horizontal-bold"
                  />
                </WebUI.DialogDisclosure>
              }
            >
              {sideNav}
            </WebUI.Drawer>
          </div>
        )}
        <div className="flex grow flex-col gap-5 overflow-y-auto px-6 py-8 lg:px-10 lg:py-16">
          <WebUI.Button
            aria-hidden={
              templateFilter.type === 'category' &&
              templateFilter.value === 'All'
            }
            className="visible text-ds-sm aria-hidden:invisible"
            variant="link"
            onClick={() => setTemplateFilter({type: 'category', value: 'All'})}
          >
            View All Templates
          </WebUI.Button>

          <div className="flex flex-col gap-0_5">
            <WebUI.Heading className="font-accentAlt">
              {templateFilter.value}
            </WebUI.Heading>
            <WebUI.Text className="font-light">
              {(templateFilter.type === 'category'
                ? (templateCategoryToDescriptionMap as any)[
                    templateFilter.value
                  ]
                : (templateGroupTypeToDescriptionMap as any)[
                    templateFilter.value
                  ]) ??
                'We’ve hand-selected templates for you based on your profile.'}
            </WebUI.Text>
          </div>
          <div className="flex flex-row justify-end">
            <WebUI.DropdownSelect<Api.TabTemplatesOrderBy | 'recommended'>
              className="[&_>_.Select-select]:px-4 [&_>_.Select-select_>_.DropdownSelectButton-content]:text-ds-sm"
              value={orderBy}
              onValueChange={(newOrderBy) => {
                if (newOrderBy) {
                  setOrderBy(newOrderBy)
                }
              }}
            >
              <WebUI.DropdownSelectOption value="recommended">
                Recommended
              </WebUI.DropdownSelectOption>
              <WebUI.DropdownSelectOption value="recentlyAdded">
                Recently added
              </WebUI.DropdownSelectOption>
              <WebUI.DropdownSelectOption value="mostUsed">
                Most used
              </WebUI.DropdownSelectOption>
            </WebUI.DropdownSelect>
          </div>
          <TabTemplatesGrid
            className="mt-5 sm:grid-cols-[repeat(auto-fill,minmax(246px,1fr))] [&_>_.TemplateCard_>_.TemplateCard-bottom]:h-[120px]"
            loading={templatesQuery.isLoading}
            templates={filteredTemplates}
            templateCardWidth={media.sm ? 246 : undefined}
            templateCardImageHeight={150}
            templateCardButtonSize="default"
          />
        </div>
      </div>

      <WebUI.ModalCloseButton />
    </WebUI.Modal>
  )
})

// MARK: - SideNavButton

interface SideNavButtonProps
  extends WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  selected?: boolean
}

const SideNavButton: React.FC<SideNavButtonProps> = ({
  className,
  variant = 'text',
  selected,
  ...restProps
}) => (
  <WebUI.Button
    aria-selected={selected}
    variant={variant}
    className={WebUI.cn(
      'aria-selected:text-orange-500 [&_>_.Button-iconBefore]:invisible [&_>_.Button-iconBefore]:aria-selected:visible',
      className,
    )}
    iconBefore={
      <WebUI.PhosphorIcon height={20} color="#f36d36" icon="arrow-right-bold" />
    }
    {...restProps}
  />
)

// MARK: – Helpers

const customerCategoryToTemplateGroupTypeMap: Record<
  Api.UserCustomerCategory,
  Api.TabTemplateGroupType[]
> = {
  BUSINESS: ['Small Business', 'Workplace', 'HOAs'],
  CLUB: ['Clubs', 'Greek Life'],
  'FINE ARTS': ['Fine Arts', 'Bands & Choir'],
  FRIENDS: ['Friends & Family', 'Reunions', 'Workplace'],
  'NON-PROFIT': ['Nonprofits'],
  RELIGIOUS: ['Religious'],
  SCHOOL: ['Schools & PTAs'],
  SCOUT: ['Scouts'],
  SPORT: ['Sports', 'Cheer'],
  NONE_OF_THE_ABOVE: [],
  PERSONAL: [],
  DISABLED: [],
  TEST: [],
  MANAGER: [],
  UNKNOWN: [],
}
