import {
  Meter as PrimitiveMeter,
  MeterProps as PrimitiveMeterProps,
} from 'react-aria-components'
import React from 'react'
import {Merge} from '@cheddarup/util'

import {FormField, FormFieldProps} from './Form'
import {cn} from '../utils'

export interface MeterProps
  extends Merge<
      React.ComponentPropsWithoutRef<'div'>,
      Omit<PrimitiveMeterProps, 'className' | 'style'>
    >,
    Pick<FormFieldProps, 'label' | 'error'> {}

export const Meter = React.forwardRef<HTMLDivElement, MeterProps>(
  ({value = 0, maxValue = 100, className, ...restProps}, forwardedRef) => (
    <FormField
      ref={forwardedRef}
      className={cn('Meter', 'gap-2', className)}
      as={PrimitiveMeter}
      {...restProps}
    >
      <MeterBar
        className="Meter-bar"
        value={Math.min((value / maxValue) * 100, 100)}
      />
    </FormField>
  ),
)

// MARK: – MeterBar

export interface MeterBarProps extends React.ComponentPropsWithoutRef<'div'> {
  value: number // 0-100
}

export const MeterBar = React.forwardRef<HTMLDivElement, MeterBarProps>(
  ({value, className, ...restProps}, forwardedRef) => (
    <div
      ref={forwardedRef}
      className={cn(
        'Meter-bar',
        'relative h-[10px] overflow-hidden rounded-[8px] bg-trueWhite [transform:translateZ(0)]',
        className,
      )}
      {...restProps}
    >
      <div
        className={
          'Meter-fill h-full transition-transform duration-200 ease-linear [background:transparent_linear-gradient(90deg,#FBAC28_0%,#F36D36_100%)_0%_0%_no-repeat_padding-box]'
        }
        style={{
          transform: `translateX(-${100 - value}%)`,
        }}
      />
    </div>
  ),
)
