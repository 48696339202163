import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {ForwardRefComponent} from '@cheddarup/react-util'

export interface BankAccountSelectRowProps
  extends React.ComponentPropsWithoutRef<'div'> {
  bankAccount: Api.BankAccount
}

const BankAccountSelectRow = React.forwardRef(
  ({bankAccount, className, ...restProps}, forwardedRef) => (
    <WebUI.HStack
      ref={forwardedRef}
      className={WebUI.cn(
        'BankAccountSelectRow',
        'items-center gap-2 rounded bg-depr-grey-200 px-3 py-2',
        className,
      )}
      {...restProps}
    >
      <div className="grow">
        {bankAccount.nickname || bankAccount.bank_name}
      </div>
      <div>*{bankAccount.last4}</div>
    </WebUI.HStack>
  ),
) as ForwardRefComponent<'div', BankAccountSelectRowProps>

export default BankAccountSelectRow
