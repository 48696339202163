import * as Yup from 'yup'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'
import {
  endpoints,
  getEndpointKey,
  useQueryClient,
  useResetPasswordMutation,
} from '@cheddarup/api-client'
import queryString from 'query-string'
import * as WebUI from '@cheddarup/web-ui'
import {InquireVerificationCode} from 'src/components/InquireVerificationCode'
import {LoginFooter} from 'src/views/onboard/components'
import {AuthLayout} from 'src/components/AuthLayout'
import {read2FAError} from 'src/helpers/error-formatting'
import {useFormik} from '@cheddarup/react-util'

const ResetPasswordPage = () => {
  const location = useLocation()
  const growlActions = WebUI.useGrowlActions()
  const resetPasswordMutation = useResetPasswordMutation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {code, email, view} = queryString.parse(location.search)

  if (typeof code !== 'string' || typeof email !== 'string') {
    return <Navigate replace to="/login" />
  }

  return (
    <AuthLayout>
      <div className="flex flex-col gap-10 p-10">
        <WebUI.LogoIcon />
        <div className="flex flex-col gap-1">
          <WebUI.Heading className="text-center">
            {view === 'signup' ? 'Set Your New' : 'Reset'} Password
          </WebUI.Heading>
          <InquireVerificationCode ignorePhoneNumberExistence>
            {(verificationHelpers) => (
              <ResetPasswordForm
                code={code}
                email={email}
                onVerifyTwoFactor={async ({lastTwoNumbers, ...values}) => {
                  try {
                    const {verificationCode} =
                      await verificationHelpers.verifyPhone({lastTwoNumbers})
                    await resetPasswordMutation.mutateAsync({
                      body: {
                        email,
                        code,
                        security: {token: verificationCode},
                        ...values,
                      },
                    })

                    growlActions.show('success', {
                      title: 'Success',
                      body: 'Your password was successfully updated',
                    })

                    const sessionQueryKey = getEndpointKey(
                      endpoints.auth.session,
                    )
                    await queryClient.invalidateQueries({
                      queryKey: sessionQueryKey,
                    })
                    navigate('/login', {replace: true})
                  } catch (err: any) {
                    growlActions.clear()
                    growlActions.show('error', {
                      title: 'Error',
                      body:
                        read2FAError(err) ||
                        'Something went wrong while resetting your password',
                    })
                  }
                }}
              />
            )}
          </InquireVerificationCode>
        </div>
      </div>
      <LoginFooter />
    </AuthLayout>
  )
}

// MARK: – ResetPasswordForm

interface ResetPasswordFormValues {
  password: string
  password_confirmation: string
}

interface ResetPasswordFormProps {
  className?: string
  code: string
  email: string
  onVerifyTwoFactor: (
    values: ResetPasswordFormValues & {lastTwoNumbers: string},
  ) => void
}

const ResetPasswordForm = ({
  code,
  email,
  onVerifyTwoFactor,
  className,
}: ResetPasswordFormProps) => {
  const resetPasswordMutation = useResetPasswordMutation()
  const navigate = useNavigate()
  const growlActions = WebUI.useGrowlActions()
  const queryClient = useQueryClient()

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required('Required')
        .min(6, 'Include 6 characters')
        .matches(/\d/, 'Include a number'),
      password_confirmation: Yup.string()
        .required('Required')
        .min(6, 'Include 6 characters')
        .matches(/\d/, 'Include a number')
        .oneOf([Yup.ref('password')], 'Must be equal'),
    }),
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    onSubmit: async (values) => {
      try {
        await resetPasswordMutation.mutateAsync({
          body: {
            ...values,
            code,
            email,
          },
        })
        growlActions.show('success', {
          title: 'Success',
          body: 'Your password was successfully updated',
        })

        const sessionQueryKey = getEndpointKey(endpoints.auth.session)
        await queryClient.invalidateQueries({queryKey: sessionQueryKey})
        navigate('/login', {replace: true})
      } catch (err: any) {
        const firstError = err.response?.data?.errors?.[0]
        if (firstError?.error === 'security_token_sent') {
          onVerifyTwoFactor({
            ...values,
            lastTwoNumbers: firstError.details.lastTwoNumbers,
          })
        } else {
          growlActions.show('error', {
            title: 'Error',
            body: 'This reset link has expired. Please click the link in the most recent Reset Password email in your inbox.',
          })
        }
      }
    },
  })

  return (
    <form
      className={WebUI.cn('flex flex-col gap-7', className)}
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
    >
      <div className="flex flex-col gap-4">
        <WebUI.PasswordFormFieldInput
          name="password"
          autoComplete="new-password"
          value={formik.values.password}
          error={formik.errors.password}
          caption="Passwords must contain at least 6 characters and 1 number."
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <WebUI.FormField label="Confirm Password">
          <WebUI.Input
            name="password_confirmation"
            type="password"
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </div>
      <WebUI.Button
        className="mt-8 self-center"
        type="submit"
        size="large"
        variant="primary"
        loading={formik.isSubmitting}
      >
        Reset Password
      </WebUI.Button>
    </form>
  )
}

export default ResetPasswordPage
