import * as WebUI from '@cheddarup/web-ui'
import {useLiveRef} from '@cheddarup/react-util'
import {useMemo} from 'react'
import * as Util from '@cheddarup/util'

export interface RefundsTableProps
  extends Omit<WebUI.TableViewProps<Api.TabPaymentRefund>, 'columns'> {
  selectedRefundId: number | null
  onViewRefund: (refund: Api.TabPaymentRefund) => void
}

const RefundsTable = ({
  selectedRefundId,
  onViewRefund,
  className,
  ...restProps
}: RefundsTableProps) => {
  const onViewRefundRef = useLiveRef(onViewRefund)

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.TabPaymentRefund>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((refund) => new Date(refund.created_at), {
        id: 'created_at',
        sortingFn: 'datetime',
        meta: {
          subtle: false,
        },
        header: 'Refund Issued',
        cell: ({cell}) => Util.formatDateAs(cell.getValue(), 'date_compact'),
      }),
      columnHelper.accessor((refund) => refund.amount, {
        id: 'amount',
        size: 80,
        meta: {
          align: 'right',
        },
        header: 'Amount',
        cell: ({cell}) => Util.formatAmount(cell.getValue()),
      }),
      columnHelper.display({
        id: 'details',
        size: 60,
        meta: {
          align: 'right',
        },
        header: 'Details',
        cell: ({row: {original: r}}) => (
          <WebUI.Button
            variant="link"
            onClick={() => onViewRefundRef.current(r)}
          >
            {selectedRefundId === r.id ? 'hide' : 'view'}
          </WebUI.Button>
        ),
      }),
    ],
    [columnHelper, selectedRefundId],
  )

  return (
    <WebUI.TableView
      className={WebUI.cn(
        '[&_.TableViewCell_>_*]:py-0 [&_.TableViewRow]:text-ds-sm [&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:border-b-0 [&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:bg-depr-grey-200',
        className,
      )}
      sortable
      sortByTogglesVisible
      columns={columns}
      {...restProps}
    />
  )
}

export default RefundsTable
