import {AnimatePresence, motion} from 'framer-motion'
import React from 'react'
import {ForwardRefComponent} from '@cheddarup/react-util'

import {HStack, HStackProps} from './Stack'
import {cn} from '../utils'

export interface FloatingToolbarProps
  extends Omit<HStackProps, 'as'>,
    React.ComponentPropsWithoutRef<'div'> {
  visible: boolean
}

export const FloatingToolbar = React.forwardRef(
  ({visible, className, ...restProps}, forwardedRef) => (
    <AnimatePresence>
      {visible && (
        <HStack
          ref={forwardedRef}
          className={cn(
            'FloatingToolbar',
            'fixed bottom-8 left-1/2 max-w-[calc(100%-theme(spacing.3))] items-center gap-4 overflow-x-auto rounded bg-trueWhite px-4 py-3 shadow-z2',
            className,
          )}
          as={motion.div as any}
          initial={{scale: 0.9, x: '-50%', y: '-1rem', opacity: 0}}
          animate={{
            scale: 1,
            y: 0,
            opacity: 1,
            transition: {duration: 0.1},
          }}
          exit={{
            scale: 0.9,
            y: '-1rem',
            opacity: 0,
            transition: {duration: 0.1},
            transitionEnd: {display: 'none'},
          }}
          {...restProps}
        />
      )}
    </AnimatePresence>
  ),
) as ForwardRefComponent<'div', FloatingToolbarProps>
