// Based on https://github.com/ariakit/ariakit/blob/main/packages/ariakit-react-core/src/utils/hooks.ts#LL117C1-L135C2

import React, {useMemo} from 'react'

/**
 * Merges React Refs into a single memoized function ref so you can pass it to
 * an element.
 * @example
 * const Component = React.forwardRef((props, ref) => {
 *   const internalRef = React.useRef();
 *   return <div {...props} ref={useForkRef(internalRef, ref)} />;
 * });
 */
export function useForkRef(...refs: Array<React.Ref<any> | undefined>) {
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  return useMemo(() => {
    if (!refs.some(Boolean)) {
      return
    }

    return (value: unknown) => {
      refs.forEach((ref) => {
        setRef(ref, value)
      })
    }
  }, refs)
}

/**
 * Sets both a function and object React ref.
 */
function setRef<T>(
  ref: React.RefCallback<T> | React.MutableRefObject<T> | null | undefined,
  value: T,
) {
  if (typeof ref === 'function') {
    ref(value)
  } else if (ref) {
    ref.current = value
  }
}
