import * as Ariakit from '@ariakit/react'
import React from 'react'
import {cva} from 'class-variance-authority'
import type {Merge} from '@cheddarup/util'

import {VariantsProps, cn} from '../../utils'

const inputBase = cva('bg-trueWhite transition-colors', {
  variants: {
    variant: {
      default: 'text-grey-700 ring-1 ring-grey-300 focus-within:ring-teal-600',
      headless: '',
    },
    size: {
      default: 'h-[2.5em] px-[14px] py-2 text-ds-base',
      headless: '',
    },
    roundness: {
      default: 'rounded',
      headless: '',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
    roundness: 'default',
  },
})

export const inputBox = cva(
  'relative inline-flex flex-row items-center transition-colors has-[aria-disabled=true]:cursor-not-allowed',
  {
    variants: {
      variant: {
        default: [
          'has-[read-only]:ring-0',
          'has-[aria-invalid=true]:ring-orange-500',
          'has-[&:hover:not(:focus-within):not([aria-invalid=true]):not(:[aria-disabled=true])]:bg-inputHoverBackground',
        ],
        headless: '',
      },
      size: {
        default: 'gap-[0.5em]',
        headless: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export const input = cva(
  [
    'min-w-[1em] appearance-none font-[inherit] outline-none',
    'placeholder:text-grey-300',
    'aria-disabled:cursor-not-allowed',
  ],
  {
    variants: {
      variant: {
        default: [
          'read-only:ring-0',
          'aria-invalid:ring-orange-500',
          'hover:[&:not(:focus):not([aria-invalid=true]):not(:[aria-disabled=true])]:bg-inputHoverBackground',
        ],
        headless: '',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface InputProps
  extends Merge<Ariakit.RoleProps<'input'>, VariantsProps<typeof inputBase>>,
    VariantsProps<typeof input> {}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      roundness = 'default',
      variant = 'default',
      size = 'default',
      ...restProps
    },
    forwardedRef,
  ) => (
    <Ariakit.Role.input
      ref={forwardedRef}
      className={cn(
        inputBase({variant, size, roundness}),
        input({variant}),
        className,
      )}
      {...restProps}
    />
  ),
)

// MARK: – InputBox

export interface InputBoxProps
  extends Merge<Ariakit.RoleProps<'div'>, VariantsProps<typeof inputBase>>,
    VariantsProps<typeof inputBox> {
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
}

export const InputBox = React.forwardRef<HTMLDivElement, InputBoxProps>(
  (
    {
      disabled,
      required,
      readOnly,
      roundness = 'default',
      variant = 'default',
      size = 'default',
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    return (
      <Ariakit.Role.div
        ref={forwardedRef}
        aria-disabled={disabled}
        aria-required={required}
        aria-readonly={readOnly}
        className={cn(
          inputBase({variant, size, roundness}),
          inputBox({variant, size}),
          className,
        )}
        {...restProps}
      />
    )
  },
)
