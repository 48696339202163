import * as Ariakit from '@ariakit/react'
import React from 'react'

import {cn} from '../utils'

export interface WellProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Ariakit.RoleOptions {}

export const Well = React.forwardRef<HTMLDivElement, WellProps>(
  ({className, ...restProps}, forwardedRef) => (
    <Ariakit.Role
      ref={forwardedRef}
      className={cn(
        'group/well relative rounded bg-depr-grey-100 p-6 shadow-100',
        className,
      )}
      {...restProps}
    />
  ),
)
