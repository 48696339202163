import * as WebUI from '@cheddarup/web-ui'
import CollectionEmptyImage from 'src/images/CollectionEmptyImage.svg'
import * as Util from '@cheddarup/util'

import {SharpImage, SharpImageProps} from './SharpImage'

export interface CollectionListItemImageProps
  extends Util.SetOptional<SharpImageProps, 'alt'> {}

const CollectionListItemImage = ({
  className,
  image,
  width,
  height,
  alt = 'Collection',
  onError,
  ...restProps
}: CollectionListItemImageProps) => {
  return (
    <SharpImage
      className={WebUI.cn('shrink-0 rounded-default object-cover', className)}
      width={width}
      height={height}
      image={image}
      alt={alt}
      onError={onError}
      errorFallback={
        <WebUI.VStack
          className={WebUI.cn(
            'shrink-0 items-center justify-center rounded-default bg-grey-300 text-trueWhite',
            className,
          )}
          style={{
            width,
            height,
          }}
          {...restProps}
        >
          <img
            className="CollectionEmptyImage h-[3em] max-h-[65%] w-auto"
            src={CollectionEmptyImage}
            alt=""
          />
        </WebUI.VStack>
      }
      {...restProps}
    />
  )
}

export default CollectionListItemImage
