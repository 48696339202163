import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

export const Banner = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({className, ...restProps}, forwardedRef) => (
  <div
    ref={forwardedRef}
    className={WebUI.cn(
      'Banner',
      'bg-teal-50 px-8 py-3 text-center font-normal text-trueWhite',
      className,
    )}
    {...restProps}
  />
))
