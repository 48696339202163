import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {getDayOfWeek, getLocalTimeZone, today} from '@internationalized/date'

import MonthDaySelector from './MonthDaySelector'
import OrSeparator from './OrSeparator'
import WeekdaySelector from './WeekdaySelector'
import type {RecurringItemFormValues} from '../../../../containers/RecurringItemForm/RecurringItemForm'

export interface RecurringOptionsStartFieldDropdownProps {
  value: RecurringItemFormValues['options']['recurring']['options']['start']
  repeatPeriod: string
  onChange: (
    newValue: RecurringItemFormValues['options']['recurring']['options']['start'],
  ) => void
  className: string
}

const RecurringOptionsStartFieldDropdown = ({
  value,
  repeatPeriod,
  onChange,
  className,
}: RecurringOptionsStartFieldDropdownProps) => (
  <WebUI.Popover>
    {(popover) => (
      <>
        <WebUI.PopoverDisclosure
          className={WebUI.cn(
            'min-h-10 px-4 py-2 text-ds-base [&_>_.Text]:font-light',
            className,
          )}
          variant="link"
        >
          {(() => {
            if (value.type === 'first_payment') {
              return 'first day of payment'
            }
            return repeatPeriod === 'week'
              ? Util.formatDate(value.date ?? '', 'EEEE')
              : `${Util.formatDate(value.date ?? '', 'do')} day of ${repeatPeriod}`
          })()}
        </WebUI.PopoverDisclosure>

        <WebUI.PopoverContent>
          <WebUI.VStack className="items-stretch gap-3 p-4 sm:flex-row sm:items-start">
            <WebUI.VStack className="grow gap-3">
              <div className="font-normal">
                Start series of payments on a day of the {repeatPeriod}
              </div>
              {repeatPeriod === 'month' ? (
                <MonthDaySelector
                  onSelect={(newDay) => {
                    const nowCalendarDate = today(getLocalTimeZone()).add({
                      days: 1,
                    })
                    let calendarDate = nowCalendarDate.set({day: newDay})

                    if (
                      getDayOfWeek(calendarDate, 'en-US') <
                      getDayOfWeek(nowCalendarDate, 'en-US')
                    ) {
                      calendarDate = calendarDate.add({months: 1})
                    }

                    onChange({type: 'date', date: calendarDate})
                    popover.hide()
                  }}
                />
              ) : (
                <WeekdaySelector
                  weekday={
                    value.date ? Util.formatDate(value.date, 'dddd') : undefined
                  }
                  onSelect={(newWeekday) => {
                    let date = Util.setDay(new Date(), newWeekday)
                    if (Util.getDay(date) <= Util.getDay(new Date())) {
                      date = Util.addDate(date, {weeks: 1})
                    }
                    onChange({
                      type: 'date',
                      // biome-ignore lint/style/noNonNullAssertion:
                      date: Util.parseCalendarDate(date.toISOString())!,
                    })
                    popover.hide()
                  }}
                />
              )}
            </WebUI.VStack>
            <OrSeparator />
            <WebUI.VStack className="grow gap-4">
              <div className="font-normal">
                Start series on first day of payment
              </div>
              <WebUI.HStack>
                <WebUI.Button
                  onClick={(event) => {
                    event.preventDefault()
                    onChange({type: 'first_payment'})
                    popover.hide()
                  }}
                >
                  Immediately
                </WebUI.Button>
              </WebUI.HStack>
            </WebUI.VStack>
          </WebUI.VStack>
        </WebUI.PopoverContent>
      </>
    )}
  </WebUI.Popover>
)

export default RecurringOptionsStartFieldDropdown
