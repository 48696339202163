import * as Util from '@cheddarup/util'
import React, {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {PaymentItemTableView} from 'src/components/PaymentItemTableView'
import {getAttendeeName} from '@cheddarup/core'

import type {RefundFormFormik} from './RefundsManage'

export interface RefundableItemTableProps
  extends React.ComponentPropsWithoutRef<'div'> {
  refundableItems: Api.TabPaymentRefundableItem[]
  selectedQuantities: Record<string, number>
  formik: RefundFormFormik
}

const RefundableItemTable = ({
  refundableItems,
  selectedQuantities,
  formik,
  className,
  ...restProps
}: RefundableItemTableProps) => {
  const hasDiscounts = refundableItems.some((ri) => ri.perItemDiscount)

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.TabPaymentRefundableItem>(),
    [],
  )

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor((ri) => ri.name, {
          id: 'name',
          meta: {
            subtle: false,
          },
          header: 'Available Item(s)',
        }),
        columnHelper.display({
          id: 'type',
          size: 140,
          meta: {
            subtle: false,
          },
          header: 'Type',
          cell: ({row: {original: ri}}) => (
            <WebUI.VStack>
              <WebUI.Text>
                {ri.itemType === 'ticket'
                  ? `Ticket #${Util.encodeToBase36(ri.payment_item_id)}`
                  : 'Item'}
              </WebUI.Text>
              {ri.itemType === 'ticket' && (
                <WebUI.Text>{getAttendeeName(ri.item_field_views)}</WebUI.Text>
              )}
            </WebUI.VStack>
          ),
        }),
        columnHelper.accessor((ri) => ri.refundableQuantity, {
          id: 'refundableQuantity',
          size: 60,
          meta: {
            subtle: false,
          },
          header: 'Qty',
        }),
        columnHelper.accessor((ri) => ri.perItemPrice, {
          id: 'perItemPrice',
          size: 80,
          meta: {
            align: 'right',
          },
          header: 'Price',
          cell: ({cell}) => Util.formatAmount(cell.getValue(), {cents: true}),
        }),
        hasDiscounts
          ? columnHelper.accessor((ri) => ri.perItemDiscount, {
              id: 'perItemDiscount',
              size: 60,
              meta: {
                align: 'right',
              },
              header: 'Discount',
              cell: ({cell}) =>
                `-${Util.formatAmount(cell.getValue(), {cents: true})}`,
            })
          : undefined,
        columnHelper.display({
          id: 'refundQuantity',
          size: 80,
          meta: {
            align: 'right',
          },
          header: 'Qty',
          cell: ({row: {original: ri}}) => (
            <WebUI.Select
              className="w-15 min-w-0 [&_>_.Select-select]:min-w-0"
              name={`quantities.quantity-${ri.payment_item_id}`}
              size="compact"
              value={formik.values.quantities[`quantity-${ri.payment_item_id}`]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {Array.from({length: ri.refundableQuantity + 1}).map(
                (_, option) => (
                  <option key={String(option)} value={option}>
                    {option}
                  </option>
                ),
              )}
            </WebUI.Select>
          ),
        }),
        columnHelper.accessor(
          (ri) => {
            const selectedQuantity =
              selectedQuantities[`quantity-${ri.payment_item_id}`]
            return Number(selectedQuantity || 0) * ri.perItemNet
          },
          {
            id: 'refundAmount',
            size: 88,
            meta: {
              align: 'right',
            },
            header: 'Amount',
            cell: ({cell}) => Util.formatAmount(cell.getValue(), {cents: true}),
          },
        ),
      ].filter((c) => c != null),
    [
      columnHelper,
      hasDiscounts,
      formik.values.quantities,
      formik.handleChange,
      formik.handleBlur,
      selectedQuantities,
    ],
  )

  return refundableItems.length === 0 ? (
    <p className="p-4 text-ds-sm">All available items have been refunded</p>
  ) : (
    <PaymentItemTableView
      className={WebUI.cn(
        '[&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:bg-depr-aqua-100',
        className,
      )}
      columns={columns}
      data={refundableItems}
      {...restProps}
    />
  )
}

export default RefundableItemTable
