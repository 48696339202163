import {
  UseMultipleSelectionGetDropdownPropsOptions,
  UseMultipleSelectionProps,
  UseMultipleSelectionReturnValue,
  UseMultipleSelectionState,
  UseMultipleSelectionStateChange,
  UseMultipleSelectionStateChangeOptions,
  useCombobox,
  useMultipleSelection,
} from 'downshift'
import {Merge, pickBy} from '@cheddarup/util'
import React, {useContext, useEffect, useImperativeHandle, useRef} from 'react'
import {
  ForwardRefComponent,
  useForkRef,
  useStableCallback,
} from '@cheddarup/react-util'

import {
  Combobox,
  ComboboxInput,
  ComboboxInputProps,
  ComboboxInstance,
  ComboboxItem,
  ComboboxProps,
} from './Combobox'
import {Input} from './Input'
import {HStack} from './Stack'
import {Tag, TagProps} from './Tag'
import {cn} from '../utils'

export interface MultiComboboxItem extends ComboboxItem {
  color?: string
}

export interface InternalMultiComboboxContextValue
  extends UseMultipleSelectionReturnValue<MultiComboboxItem> {}

export const InternalMultiComboboxContext = React.createContext(
  {} as InternalMultiComboboxContextValue,
)

// MARK: - MultiCombobox

export interface MultiComboboxInstance
  extends UseMultipleSelectionReturnValue<MultiComboboxItem> {
  comboboxRef: React.RefObject<ComboboxInstance>
}
export interface MultiComboboxProps
  extends Omit<ComboboxProps, 'itemToKey' | 'getA11yStatusMessage'>,
    Omit<
      UseMultipleSelectionProps<MultiComboboxItem>,
      'stateReducer' | 'onStateChange'
    > {
  multipleSelectionStateReducer?: (
    state: UseMultipleSelectionState<MultiComboboxItem>,
    actionAndChanges: UseMultipleSelectionStateChangeOptions<MultiComboboxItem>,
  ) => Partial<UseMultipleSelectionState<MultiComboboxItem>>
  onMultipleSelectionStateChange?: (
    changes: UseMultipleSelectionStateChange<MultiComboboxItem>,
  ) => void
}

export const MultiCombobox = React.forwardRef<
  MultiComboboxInstance,
  MultiComboboxProps
>(
  (
    {
      children,
      className,
      multipleSelectionStateReducer,
      onMultipleSelectionStateChange,
      selectedItems,
      initialSelectedItems,
      defaultSelectedItems,
      itemToKey,
      getA11yStatusMessage,
      activeIndex,
      initialActiveIndex,
      defaultActiveIndex,
      onActiveIndexChange,
      onSelectedItemsChange,
      keyNavigationNext,
      keyNavigationPrevious,
      environment,
      stateReducer: stateReducerProp,
      ...restProps
    },
    forwardedRef,
  ) => {
    const comboboxRef = useRef<ComboboxInstance>(null)

    const multipleSelectionProps: UseMultipleSelectionProps<MultiComboboxItem> =
      {
        selectedItems,
        initialSelectedItems,
        defaultSelectedItems,
        itemToKey,
        getA11yStatusMessage,
        stateReducer: (state, actionAndChanges) =>
          multipleSelectionStateReducer
            ? multipleSelectionStateReducer(state, actionAndChanges)
            : {...state, ...actionAndChanges.changes},
        activeIndex,
        initialActiveIndex,
        defaultActiveIndex,
        onActiveIndexChange,
        onSelectedItemsChange: (change) => {
          onSelectedItemsChange?.(change)
          comboboxRef.current?.closeMenu()
          setTimeout(() => {
            comboboxRef.current?.inputRef.current?.focus()
            setTimeout(() => {
              comboboxRef.current?.openMenu()
            }, 10)
          }, 0)
        },
        onStateChange: onMultipleSelectionStateChange,
        keyNavigationNext,
        keyNavigationPrevious,
        environment,
      }

    const multipleSelection = useMultipleSelection(
      pickBy(
        multipleSelectionProps,
        (val) => val !== undefined,
      ) as UseMultipleSelectionProps<MultiComboboxItem>,
    )

    useImperativeHandle(
      forwardedRef,
      (): MultiComboboxInstance => ({
        ...multipleSelection,
        comboboxRef,
      }),
      [multipleSelection],
    )

    useEffect(() => {
      if (selectedItems && comboboxRef.current?.isOpen) {
        comboboxRef.current?.closeMenu()
        setTimeout(() => {
          comboboxRef.current?.openMenu()
          comboboxRef.current?.setHighlightedIndex(0)
        }, 10)
      }
    }, [selectedItems])

    return (
      <InternalMultiComboboxContext.Provider value={multipleSelection}>
        <Combobox
          ref={comboboxRef}
          className={cn('MultiCombobox', className)}
          stateReducer={useStableCallback((state, actionAndChanges) => {
            let changes = actionAndChanges.changes
            switch (actionAndChanges.type) {
              case useCombobox.stateChangeTypes.ItemClick:
              case useCombobox.stateChangeTypes.InputKeyDownEnter:
              case useCombobox.stateChangeTypes.FunctionSelectItem: {
                const selectedItem = changes.selectedItem
                changes = {inputValue: ''}
                if (selectedItem) {
                  // HACK: Reducer should be free of side effects
                  setTimeout(
                    () => multipleSelection.addSelectedItem(selectedItem),
                    0,
                  )
                }
                break
              }
              case useCombobox.stateChangeTypes.InputChange:
              case useCombobox.stateChangeTypes.FunctionSetInputValue:
              case useCombobox.stateChangeTypes.FunctionReset:
              case useCombobox.stateChangeTypes
                .ControlledPropUpdatedSelectedItem:
                changes = {
                  ...changes,
                  highlightedIndex: 0,
                }
                break
            }
            return stateReducerProp
              ? stateReducerProp(state, {...actionAndChanges, changes})
              : {...state, ...changes}
          })}
          {...restProps}
        >
          {children}
        </Combobox>
      </InternalMultiComboboxContext.Provider>
    )
  },
)

// MARK: - MultiComboboxStatefulInput

export interface MultiComboboxStatefulInputProps
  extends MultiComboboxInputProps {
  itemClassName?: string
  TagComponent?: React.ComponentType<TagProps>
}

export const MultiComboboxStatefulInput = React.forwardRef(
  (
    {
      className,
      itemClassName,
      TagComponent = Tag,
      variant = 'default',
      ...restProps
    },
    forwardedRef,
  ) => {
    const {selectedItems, getSelectedItemProps, removeSelectedItem} =
      useContext(InternalMultiComboboxContext)
    const ownRef = useRef<HTMLInputElement>(null)

    return (
      <HStack
        className={cn(
          'MultiComboboxStatefulInput',
          'cursor-text flex-wrap rounded shadow-[inset_0_0_0_1px_theme(colors.grey.300)] transition-colors duration-100 ease-in-out focus-within:shadow-[inset_0_0_0_1px_theme(colors.teal.50)] hover:[&:not(:focus-within)]:bg-inputHoverBackground',
          className,
        )}
      >
        {selectedItems.map((item, index) => (
          <HStack
            key={index}
            className={cn(
              'MultiComboboxValues-item',
              'flex-0 items-center p-1',
              itemClassName,
            )}
          >
            <TagComponent
              data-index={index}
              className="MultiComboboxValues-itemTag text-ds-sm"
              color={item.color}
              onClear={() => removeSelectedItem(item)}
              {...getSelectedItemProps({selectedItem: item, index})}
            >
              {item.label ?? item.value}
            </TagComponent>
          </HStack>
        ))}

        <MultiComboboxInput
          ref={useForkRef(ownRef, forwardedRef)}
          className={`[&_.Input:hover:not(:focus):not([aria-invalid="true"])]:!bg-transparent min-h-full min-w-[6rem] max-w-full flex-[1] [&_.Input[class]:focus]:shadow-none [&_.Input[class]]:bg-transparent [&_.Input[class]]:shadow-none`}
          variant={variant}
          chevron={false}
          {...restProps}
        />
      </HStack>
    )
  },
) as ForwardRefComponent<typeof Input, MultiComboboxStatefulInputProps>

// MARK: - MultiComboboxInput

export interface MultiComboboxInputProps
  extends Merge<
    ComboboxInputProps,
    Omit<
      UseMultipleSelectionGetDropdownPropsOptions,
      keyof React.HTMLProps<HTMLInputElement>
    >
  > {}

export const MultiComboboxInput = React.forwardRef(
  ({as, className, ...restProps}, forwardedRef) => {
    const {getDropdownProps} = useContext(InternalMultiComboboxContext)
    return (
      <ComboboxInput
        as={as}
        className={cn('MultiComboboxInput', className)}
        {...getDropdownProps({ref: forwardedRef as any, ...(restProps as any)})}
      />
    )
  },
) as ForwardRefComponent<typeof Input, MultiComboboxInputProps>

export {useMultipleSelection}
