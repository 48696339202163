import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import config from 'src/config'
import {Logo} from 'src/components'

import usePublicCollection from '../hooks/usePublicCollection'
import {useNextPayerSegment} from '../utils/payer-navigation-utils'
import {usePayerUIState} from '../PayerUIStateProvider'
import {PayerContinueButton} from './PayerContinueButton'

export interface PayerContinueBandProps
  extends React.ComponentPropsWithoutRef<'div'> {}

export const PayerContinueBand: React.FC<PayerContinueBandProps> = ({
  className,
  ...restProps
}) => {
  const payerUIState = usePayerUIState()
  const nextSegment = useNextPayerSegment()
  const media = WebUI.useMedia()

  if (!media.lg && !nextSegment) {
    return null
  }

  return (
    <div
      data-resting={payerUIState.footerInView}
      className={WebUI.cn(
        'lg:-translate-y-4 -translate-y-[35px] inset-x-0 bottom-0 flex flex-col-reverse items-center justify-between gap-3 bg-trueWhite px-2 py-4 shadow-z3 transition-all data-[resting=true]:shadow-none lg:sticky lg:flex-row lg:rounded-[20px] lg:px-8 lg:data-[resting=true]:translate-y-0',
        payerUIState.cartVisible && 'invisible opacity-0',
        className,
      )}
      {...restProps}
    >
      <PoweredByBadge className="hidden lg:flex" />
      {!!nextSegment && (
        <PayerContinueButton
          className="w-[320px] min-w-[200px] lg:w-auto [&_>_.Button-content]:font-semibold"
          size="large"
        />
      )}
    </div>
  )
}

// MARK: – PoweredByBadge

export const PoweredByBadge: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const {publicCollection} = usePublicCollection()

  const cheddarUpLogo = (
    <a href={config.links.marketingPage}>
      <Logo className="text-ds-sm lg:text-ds-md" forceDisplayText />
    </a>
  )

  return publicCollection.organizer.partner?.slug === 'moneyminder' ? (
    <div
      className={WebUI.cn(
        'inline-flex flex-row flex-wrap items-center justify-center gap-1 text-center lg:gap-2 lg:text-start',
        className,
      )}
      {...restProps}
    >
      <WebUI.Text className="text-ds-sm leading-cosy">
        <WebUI.Anchor
          href="https://moneyminder.com/"
          target="blank"
          rel="noopener noreferrer"
        >
          MoneyMinder
        </WebUI.Anchor>{' '}
        store powered by
      </WebUI.Text>
      {cheddarUpLogo}
    </div>
  ) : !publicCollection.organizer.is_team_complimentary &&
    (publicCollection.is_team || publicCollection.is_pro) ? (
    <div
      className={WebUI.cn('inline-flex flex-row items-center gap-2', className)}
      {...restProps}
    >
      <WebUI.Text className="font-semibold text-ds-sm">Powered by</WebUI.Text>{' '}
      {cheddarUpLogo}
    </div>
  ) : (
    <div
      className={WebUI.cn(
        'inline-flex flex-row items-end gap-2 lg:px-8',
        className,
      )}
      {...restProps}
    >
      {cheddarUpLogo}
      <WebUI.Text className="text-black text-ds-xs lg:text-ds-sm">
        {publicCollection.organizer.is_team_complimentary
          ? 'Payments and forms for groups'
          : 'Free payment pages and forms for groups'}
      </WebUI.Text>
    </div>
  )
}
