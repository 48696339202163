export function getItemPriceWithFee({
  itemBasePrice,
  collection,
  cart,
  paymentMethod = 'card',
}: {
  itemBasePrice: number | null | undefined
  collection: Api.PublicTab
  cart?: Api.Cart
  paymentMethod?: 'card' | 'echeck' | 'cash'
}): {
  subTotal: number
  processingFee: number
  perOrderConvenienceFee: number
  total: number
} {
  const subTotal = itemBasePrice ?? 0

  // Return early with all zeros if subTotal is 0
  if (subTotal === 0) {
    return {
      subTotal: 0,
      processingFee: 0,
      perOrderConvenienceFee: 0,
      total: 0,
    }
  }

  // Convert fixed cents to dollars
  const fixedFeeDollars = collection.fee_structure.fixedCents / 100
  const feePercentage = collection.fee_structure.percentage
  const cartItemCount =
    cart?.items.filter((item) => item.amount > 0).length ?? 0
  const isEmptyCart = cart?.subtotal === 0
  const isFeeTransparencyEnabled =
    collection.fee_transparency && paymentMethod === 'card'

  // Only charge per-order fee for first item when fee transparency is enabled
  const perOrderConvenienceFee =
    isEmptyCart && isFeeTransparencyEnabled ? fixedFeeDollars : 0

  // Calculate per-item convenience fee
  const perItemConvenienceFee =
    (fixedFeeDollars / (1 - feePercentage) - fixedFeeDollars) /
    (cartItemCount || 1)

  // Calculate processing fee when fee transparency enabled
  const processingFee = isFeeTransparencyEnabled
    ? subTotal / (1 - feePercentage) - subTotal + perItemConvenienceFee
    : 0
  return {
    subTotal,
    processingFee,
    perOrderConvenienceFee,
    total: subTotal + processingFee + perOrderConvenienceFee,
  }
}
