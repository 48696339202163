import {useLocation, useNavigate, useParams} from 'react-router-dom'
import React, {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api, useDeletePaymentItemMutation} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {PaymentItemTableView} from 'src/components/PaymentItemTableView'
import {
  getTimeSlotFieldViewsFormatted,
  parseTimeSlotFieldViews,
} from 'src/helpers/parseTimeSlotFieldViews'

const SpotReportPage: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const urlParams = useParams()
  const spotQuery = api.tabSignupSpots.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
        // biome-ignore lint/style/noNonNullAssertion:
        signupId: urlParams.signup!,
        // biome-ignore lint/style/noNonNullAssertion:
        spotId: urlParams.spot!,
      },
    },
    {
      select: (spot) => ({
        ...spot,
        paymentItems: spot.time_slots.flatMap((ts) => ts.payment_items),
      }),
    },
  )

  return (
    <WebUI.Modal
      aria-label="Sign up "
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
      onDidHide={() => navigate({pathname: '..', search: location.search})}
    >
      <WebUI.ModalCloseButton />

      <WebUI.ModalHeader className="border-b-0 pb-0">
        {spotQuery.data?.name}
      </WebUI.ModalHeader>

      {spotQuery.data?.description && (
        <WebUI.MarkdownParagraph
          className="px-8 font-light text-ds-sm italic"
          markdown={spotQuery.data?.description}
        />
      )}

      <WebUI.VStack className="mt-8 gap-4 px-8">
        <WebUI.HStack className="gap-4">
          <WebUI.IconButton
            className="h-auto w-auto px-2 text-ds-lg"
            as={Link}
            size="default_alt"
            variant="secondary"
            to="#"
          >
            <WebUI.PhosphorIcon icon="download-simple" />
          </WebUI.IconButton>

          <WebUI.Button>Print</WebUI.Button>
        </WebUI.HStack>
        <WebUI.Separator />
        <SportReportTable data={spotQuery.data?.paymentItems ?? []} />
      </WebUI.VStack>
    </WebUI.Modal>
  )
}

// MARK: - SpotReportTable

type SportReportTableData =
  Api.SignUpSpot['time_slots'][number]['payment_items'][number]

const SportReportTable: React.FC<
  Omit<WebUI.TableViewProps<SportReportTableData>, 'columns'>
> = ({data, className, ...restProps}) => {
  const urlParams = useParams()
  const commentFieldViewName = parseTimeSlotFieldViews(
    data[0]?.item_field_views ?? [],
  ).commentFieldView?.name
  const deletePaymentItemMutation = useDeletePaymentItemMutation()

  const deletePaymentItemAsync = deletePaymentItemMutation.mutateAsync

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<SportReportTableData>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (pi) => getTimeSlotFieldViewsFormatted(pi.item_field_views).name,
        {
          id: 'displayName',
          header: 'Display Name',
          cell: ({cell, row: {original: pi}}) => (
            <WebUI.VStack>
              <WebUI.Text>{cell.getValue()}</WebUI.Text>
              <WebUI.Button variant="link">
                {pi.payment.tab_member.email}
              </WebUI.Button>
            </WebUI.VStack>
          ),
        },
      ),
      columnHelper.accessor(
        (pi) => getTimeSlotFieldViewsFormatted(pi.item_field_views).comment,
        {
          id: 'comment',
          header: commentFieldViewName ?? 'Comment',
          cell: ({cell}) => (
            <WebUI.Text className="italic">{cell.getValue()}</WebUI.Text>
          ),
        },
      ),
      columnHelper.accessor((pi) => pi.quantity, {
        id: 'quantity',
        header: 'Qty',
      }),
      columnHelper.display({
        id: 'actions',
        size: 80,
        meta: {
          align: 'right',
        },
        cell: ({row: {original: pi}}) => (
          <WebUI.Button
            variant="link"
            loading={deletePaymentItemMutation.isPending}
            onClick={() =>
              deletePaymentItemAsync({
                signupId: Number(urlParams.signup),
                spotId: Number(urlParams.spot),
                pathParams: {
                  // biome-ignore lint/style/noNonNullAssertion:
                  tabId: urlParams.collection!,
                  paymentId: pi.payment.id,
                  paymentItemId: pi.id,
                },
              })
            }
          >
            Remove
          </WebUI.Button>
        ),
      }),
    ],
    [
      columnHelper,
      commentFieldViewName,
      deletePaymentItemAsync,
      deletePaymentItemMutation.isPending,
      urlParams.collection,
      urlParams.signup,
      urlParams.spot,
    ],
  )

  return (
    <PaymentItemTableView
      className={WebUI.cn(
        '[&_>_.TableView-headerGroupList_>_.TableView-headerGroup]:bg-depr-aqua-100',
        className,
      )}
      columns={columns}
      data={data}
      {...restProps}
    />
  )
}

export default SpotReportPage
