import * as WebUI from '@cheddarup/web-ui'
import {Link, useNavigate} from 'react-router-dom'
import * as Util from '@cheddarup/util'
import {forwardRef} from 'react'
import {
  useCloneItemMutation,
  useUpdateItemMutation,
} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {SharpImage} from 'src/components/SharpImage'
import checkDifferentRetailPricesOrAmounts from 'src/helpers/ItemViewPrice'
import ImagesUtils from 'src/helpers/ImagesUtils'
import {PremiumFeatureSideSheetDisclosure} from 'src/components/PremiumFeaturesSideSheet'
import {getItemType} from 'src/helpers/item-helpers'

export interface ItemRowProps extends WebUI.DataRowComponentProps<Api.TabItem> {
  collection: Api.Tab
  inventoryGroups: Api.InventoryGroup[]
  onUpdateInventoryGroups: () => void
  onUpgrade: () => void
  onDelete: () => void
  onMove: () => void
}

const ItemRow = forwardRef<HTMLDivElement, ItemRowProps>(
  (
    {
      collection,
      inventoryGroups,
      onUpdateInventoryGroups,
      onUpgrade,
      onDelete,
      onMove,
      row,
      setSelected,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const updateItemMutation = useUpdateItemMutation()

    const item = row?.original
    const itemSubType = item ? getItemType(item) : 'fixed'
    const lowestAmountListing = Util.firstBy(
      (item?.options.variants?.enabled && item.options.variants.listings) || [],
      (l) => l.amount,
    )
    const withVariantListings =
      item?.options.variants?.enabled &&
      item.options.variants.listings?.length > 1
    const [withDifferentRetailPrices, withDifferentAmounts] = item
      ? checkDifferentRetailPricesOrAmounts(item)
      : [false, false]
    const totalQuantity =
      item?.options.variants?.enabled &&
      item.options.variants.listings?.length > 0
        ? item.options.variants.listings?.every(
            (l) => l.available_quantity !== null,
          )
          ? Util.sumBy(
              item.options.variants.listings,
              (l) => l.available_quantity ?? 0,
            )
          : (item?.inventory_items.find((ii) => ii.variant_uuid !== 'NONE')
              ?.inventory_groups[0]?.available_quantity ?? null)
        : item?.inventory_items.find((ii) => ii.variant_uuid === 'NONE')
            ?.available_quantity
    const reqProButNotPro = item?.requires_pro && !item.is_pro && !item.is_team
    const reqTeamButNotTeam = item?.requires_team && !item.is_team
    const showPaidBadgeOnItem =
      collection.status === 'draft' && (reqProButNotPro || reqTeamButNotTeam)

    return (
      <WebUI.Card
        ref={forwardedRef}
        className={WebUI.cn(
          'flex grow flex-col items-start gap-4 p-3 sm:flex-row sm:items-center sm:p-0',
          className,
        )}
        dragHandleVisible
        accessoryView={
          !!item && (
            <ItemActionGroup
              collection={collection}
              item={item}
              onUpgrade={onUpgrade}
              onDelete={onDelete}
              onMove={onMove}
            />
          )
        }
        {...restProps}
      >
        <Link
          className="relative flex flex-row items-center justify-center *:rounded *:sm:rounded-none"
          to={
            itemSubType === 'ticket'
              ? `ticket/${item?.id}/edit`
              : `item/${item?.id}/edit?type=${itemSubType}`
          }
        >
          <SharpImage
            width={82}
            height={82}
            alt="Item thumbnail"
            image={ImagesUtils.getMainImage(
              item?.images ?? [],
              item?.images
                ? (item.images.find(
                    (image) => image.metadata.thumbnail?.order === 0,
                  )?.id ?? undefined)
                : undefined,
            )}
            errorFallback={
              <div className="flex h-full flex-row items-center justify-center text-teal-80">
                {
                  {
                    fixed: <WebUI.PhosphorIcon icon="tag" width={50} />,
                    donation: (
                      <WebUI.PhosphorIcon icon="hand-heart" width={50} />
                    ),
                    ticket: <WebUI.PhosphorIcon icon="ticket" width={50} />,
                    recurring: (
                      <WebUI.PhosphorIcon icon="arrows-clockwise" width={50} />
                    ),
                  }[itemSubType]
                }
              </div>
            }
          />
          {item?.hidden && (
            <>
              <div className="absolute top-0 left-0 h-full w-full bg-gray600 opacity-50" />
              <div
                className={
                  'pointer-events-none absolute flex h-full flex-col items-center justify-center text-ds-5xl text-trueWhite'
                }
              >
                <WebUI.PhosphorIcon icon="eye-slash" />
              </div>
            </>
          )}
        </Link>
        <div
          data-no-dnd="true"
          className="relative flex min-w-0 grow flex-col items-stretch justify-center gap-2 *:min-w-0 sm:flex-row sm:items-center sm:justify-start"
        >
          <div className="flex flex-col gap-1 sm:flex-[0_0_40%]">
            {item ? (
              <WebUI.InlineEditInput<typeof WebUI.Input>
                className="max-w-[320px] font-normal text-tint"
                InputComponent={WebUI.Input}
                inputClassName="font-normal z-[2]"
                defaultValue={item.name}
                inputSize="compact"
                inputOffset={[-8, -8]}
                onBlur={(event) =>
                  updateItemMutation.mutate({
                    pathParams: {
                      tabId: item.tab_id,
                      itemId: item.id,
                    },
                    body: {name: event.target.value},
                  })
                }
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.currentTarget.blur()
                  }
                }}
              />
            ) : (
              <WebUI.Skeleton width={220} height={12} />
            )}
            <div className="flex flex-row items-baseline gap-2 text-ds-sm">
              {item ? (
                <>
                  <WebUI.Text>
                    {
                      {
                        ticket: 'Ticket',
                        fixed: 'Fixed Price',
                        donation: 'Donation',
                        recurring: 'Recurring Plan',
                      }[itemSubType]
                    }
                  </WebUI.Text>
                  {item.required && (
                    <WebUI.Text className="text-orange-500">
                      Required
                    </WebUI.Text>
                  )}
                </>
              ) : (
                <WebUI.Skeleton width={220} height={12} />
              )}
            </div>
          </div>
          <div className="flex-[0_0_15%] text-ds-sm">
            {item ? (
              <WebUI.InlineEditInput
                inputClassName="font-light z-[1]"
                InputComponent={WebUI.AmountInput}
                DisplayComponent={({DefaultDisplayComponent}) => (
                  <DefaultDisplayComponent className="font-light" />
                )}
                fixedMode={
                  lowestAmountListing || item.amount_type === 'open'
                    ? 'display'
                    : undefined
                }
                defaultValue={
                  item.amount_type === 'open'
                    ? 'Any Ammount'
                    : String(lowestAmountListing?.amount ?? item?.amount ?? '')
                }
                formatValue={(value) =>
                  !lowestAmountListing && item?.amount_type === 'open'
                    ? 'Any Amount'
                    : `${Util.formatAmount(Number(value))}${
                        withDifferentAmounts ? '+' : ''
                      }`
                }
                inputSize="compact"
                inputOffset={[-8, -8]}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                  updateItemMutation.mutate({
                    pathParams: {
                      tabId: item.tab_id,
                      itemId: item.id,
                    },
                    body: {
                      amount: Number(
                        event.target.value.replace('$', '').replaceAll(',', ''),
                      ),
                    },
                  })
                }
                onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
                  if (event.key === 'Enter') {
                    event.currentTarget.blur()
                  }
                }}
              />
            ) : (
              <WebUI.Skeleton width={220} height={12} />
            )}

            {item &&
              (typeof item.options.retailPrice === 'number' ||
                collection.alwaysShowRetailPrice ||
                lowestAmountListing?.retailPrice) && (
                <WebUI.InlineEditInput
                  className="text-gray400"
                  InputComponent={WebUI.AmountInput}
                  fixedMode={lowestAmountListing ? 'display' : undefined}
                  formatValue={(retailPrice) =>
                    ` (Retail: ${Util.formatAmount(retailPrice)}${
                      withDifferentRetailPrices ? '+' : ''
                    })`
                  }
                  defaultValue={String(
                    lowestAmountListing?.retailPrice ??
                      item?.options.retailPrice ??
                      '0',
                  )}
                  inputSize="compact"
                  inputOffset={[-8, -8]}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                    updateItemMutation.mutate({
                      pathParams: {
                        tabId: item.tab_id,
                        itemId: item.id,
                      },
                      body: {
                        options: {
                          retailPrice: Number(
                            event.target.value.replace('$', ''),
                          ),
                        },
                      },
                    })
                  }
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      event.currentTarget.blur()
                    }
                  }}
                />
              )}
          </div>
          <div className="flex flex-col gap-0_5 text-ds-sm sm:flex-[0_0_20%]">
            {typeof totalQuantity === 'number' && (
              <div className="flex items-baseline gap-2 sm:flex-col sm:items-stretch sm:gap-0">
                {totalQuantity > 0 ? (
                  <span>{totalQuantity} Remaining</span>
                ) : (
                  <span className="text-orange-500">Sold Out</span>
                )}
                {withVariantListings && (
                  <span className="text-gray400">
                    Across {item.options.variants?.listings.length ?? 0}{' '}
                    variants
                  </span>
                )}
              </div>
            )}
            {inventoryGroups.map((ig) => {
              const commonProps = {
                className:
                  '[&_>_.Button-content]:overflow-visible [&_>_.Button-content]:whitespace-normal [&_>_.Button-content]:font-light',
                variant: 'link' as const,
                children: `${ig.name || 'Grouped quantity'}: ${ig.available_quantity}`,
              }

              return item?.options.variants?.enabled ? (
                <LinkButton
                  key={ig.id}
                  {...commonProps}
                  to={`item/${item.id}/edit`}
                />
              ) : (
                <WebUI.Button
                  key={ig.id}
                  {...commonProps}
                  onClick={() => onUpdateInventoryGroups()}
                />
              )
            })}
          </div>
          {item ? (
            showPaidBadgeOnItem ? (
              <div className="flex flex-row justify-center sm:flex-[0_0_20%]">
                <PremiumFeatureSideSheetDisclosure tabId={collection.id} />
              </div>
            ) : (
              collection.status !== 'draft' && (
                <WebUI.Ellipsis className="font-light text-ds-sm sm:shrink-0 sm:grow-0 sm:basis-1/4">
                  Collected:{' '}
                  <Link to={`item/${item.id}`}>
                    {Util.formatAmount(item.amount_sold ?? 0)}
                  </Link>
                </WebUI.Ellipsis>
              )
            )
          ) : (
            <WebUI.Skeleton width={220} height={12} />
          )}
        </div>
      </WebUI.Card>
    )
  },
)

// MARK: – ItemActionGroup

interface ItemActionGroupProps {
  collection: Api.Tab
  item: Api.TabItem
  onUpgrade: () => void
  onDelete: () => void
  onMove: () => void
}

const ItemActionGroup = ({
  collection,
  item,
  onUpgrade,
  onDelete,
  onMove,
}: ItemActionGroupProps) => {
  const navigate = useNavigate()
  const updateItemMutation = useUpdateItemMutation()
  const cloneItemMutation = useCloneItemMutation()
  const itemSubType = getItemType(item)
  return (
    <WebUI.ActionGroup>
      <WebUI.Action
        icon={<WebUI.PhosphorIcon icon="pencil" />}
        as={LinkButton}
        to={
          itemSubType === 'ticket'
            ? `ticket/${item.id}/edit`
            : `item/${item.id}/edit?type=${itemSubType}`
        }
      >
        Edit
      </WebUI.Action>
      <WebUI.Action
        icon={<WebUI.PhosphorIcon icon="copy" />}
        execute={async () => {
          if (
            collection &&
            collection.status !== 'draft' &&
            collection.itemLimit != null &&
            !collection.is_pro &&
            collection.reportsAvailable &&
            collection.reportsAvailable.activeItemsCount >= collection.itemLimit
          ) {
            onUpgrade()
          } else {
            await cloneItemMutation.mutateAsync({
              pathParams: {
                tabId: item.tab_id,
                itemId: item.id,
              },
            })
          }
        }}
      >
        Replicate
      </WebUI.Action>
      {(!item.required || item.hidden) && (
        <WebUI.Action
          icon={
            item.hidden ? (
              <WebUI.PhosphorIcon icon="eye" />
            ) : (
              <WebUI.PhosphorIcon icon="eye-slash" />
            )
          }
          execute={() =>
            updateItemMutation.mutateAsync({
              pathParams: {
                tabId: item.tab_id,
                itemId: item.id,
              },
              body: {hidden: !item.hidden},
            })
          }
        >
          {item.hidden ? 'Show' : 'Hide'}
        </WebUI.Action>
      )}

      <WebUI.Action
        icon={<WebUI.PhosphorIcon icon="trash" />}
        onClick={() => onDelete()}
      >
        Delete
      </WebUI.Action>
      <WebUI.Action
        icon={<WebUI.PhosphorIcon icon="scroll" />}
        onClick={() => navigate(`item/${item.id}`)}
      >
        Item Report
      </WebUI.Action>
      <WebUI.Action
        icon={<WebUI.PhosphorIcon icon="arrow-right" />}
        onClick={onMove}
      >
        Move Item
      </WebUI.Action>
    </WebUI.ActionGroup>
  )
}

export default ItemRow
