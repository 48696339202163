import {useMemo, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {api} from '@cheddarup/api-client'

import {PaymentOverviewSideSheet} from '../PayerPage/PaymentOverviewSideSheet'

export interface PaymentsProps {
  collection: Api.TabDetailed
  onShowBalanceSummary: () => void
}

const Payments = ({onShowBalanceSummary, collection}: PaymentsProps) => {
  const [selectedPayment, setSelectedPayment] = useState<Api.TabPayment | null>(
    null,
  )
  return (
    <WebUI.VStack className="gap-4">
      <WebUI.VStack as={WebUI.Panel}>
        <WebUI.HStack className="items-center gap-4 px-6 py-4 text-ds-md">
          <WebUI.PhosphorIcon
            className="rounded bg-teal-50 p-1 text-ds-2xl text-trueWhite"
            icon="currency-dollar"
          />
          <span className="font-normal">Payments</span>
        </WebUI.HStack>
        <WebUI.Separator variant="primary" />
        <WebUI.HStack className="[&_>_.Stack]:flex-[1_0_33.33%] [&_>_.Stack]:p-8">
          <WebUI.VStack>
            <div className="font-semibold text-ds-xs text-gray400">
              TOTAL COLLECTED
            </div>
            <div className="font-normal text-ds-xl text-gray750">
              {Util.formatAmount(collection.payments_total)}
            </div>
          </WebUI.VStack>
          <WebUI.Separator orientation="vertical" variant="primary" />
          <WebUI.VStack>
            <div className="font-semibold text-ds-xs text-gray400">
              ITEMS SOLD
            </div>
            <div className="font-normal text-ds-xl text-gray750">
              {collection.reportsAvailable.soldItemsCount}
            </div>
          </WebUI.VStack>
          <WebUI.Separator orientation="vertical" variant="primary" />
          <WebUI.VStack className="relative">
            <div className="font-semibold text-ds-xs text-gray400">BALANCE</div>
            <div className="font-normal text-ds-xl text-gray750">
              {Util.formatAmount(collection.withdrawal_balance_available)}
            </div>

            <WebUI.IconButton
              className="absolute top-2 right-2 text-ds-lg text-gray400"
              size="default_alt"
              onClick={() => onShowBalanceSummary()}
            >
              <WebUI.PhosphorIcon icon="dots-three-outline-fill" />
            </WebUI.IconButton>
          </WebUI.VStack>
        </WebUI.HStack>
      </WebUI.VStack>

      <WebUI.Panel>
        <PaymentsTable
          userUuid={collection.organizer.uuid ?? ''}
          tabId={collection.id}
          onSelect={(newSelectedPayment) =>
            setSelectedPayment(newSelectedPayment)
          }
        />
      </WebUI.Panel>
      <PaymentOverviewSideSheet
        customerPayment={
          selectedPayment
            ? ({
                id: selectedPayment.id,
                collection: {
                  id: collection.id,
                  name: collection.name,
                },
                organizer: {
                  id: collection.organizer.id,
                  uuid: collection.organizer.uuid,
                  name: collection.organizer.name,
                },
                date: selectedPayment.created_at,
              } as any)
            : null
        }
        visible={!!selectedPayment}
        onDidHide={() => setSelectedPayment(null)}
      />
    </WebUI.VStack>
  )
}

// MARK: – PaymentTable

interface PaymentsTableProps
  extends Omit<
    WebUI.TableViewProps<Api.TabPayment>,
    'columns' | 'data' | 'onSelect'
  > {
  userUuid: string
  tabId: number
  onSelect: (payment: Api.TabPayment) => void
}

const PaymentsTable = ({
  userUuid,
  tabId,
  onSelect,
  ...restProps
}: PaymentsTableProps) => {
  const paymentsPerPage = 25
  const [query, setQuery] = useState({
    page: 0,
    direction: 'desc',
    sort: 'created_at',
    search: '',
  })
  const paymentsQuery = api.tabPayments.list.useQuery(
    {
      pathParams: {
        tabId,
      },
      queryParams: {
        perPage: paymentsPerPage,
        ...(query as any),
      },
      headers: {
        'User-Id': userUuid,
      },
    },
    {
      placeholderData: (prevData) => prevData,
    },
  )

  const columnHelper = useMemo(
    () => WebUI.createColumnHelper<Api.TabPayment>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor((payment) => payment.tab_member.name, {
        id: 'payerOverview',
        header: 'Collection Page',
        cell: ({cell, row: {original: payment}}) => (
          <WebUI.VStack>
            <div className="font-semibold text-ds-sm">{cell.getValue()}</div>
            <div className="font-normal text-ds-xs text-gray400">
              {payment.tab_member.email}
            </div>
          </WebUI.VStack>
        ),
      }),
      columnHelper.accessor((payment) => payment.total, {
        id: 'total',
        meta: {
          align: 'right',
        },
        header: 'Amount',
        cell: ({cell}) => Util.formatAmount(cell.getValue()),
      }),
      columnHelper.accessor((payment) => payment.payment_method, {
        id: 'paymentMethod',
        meta: {
          align: 'right',
        },
        header: 'Method',
        cell: ({cell}) => (
          <WebUI.Tag
            className={WebUI.cn(
              'text-ds-xs capitalize',
              {
                Card: 'bg-teal-70',
                eCheck: 'bg-depr-grey-400',
              }[cell.getValue() as string],
            )}
          >
            {cell.getValue()}
          </WebUI.Tag>
        ),
      }),
      columnHelper.accessor((payment) => payment.status, {
        id: 'status',
        meta: {
          align: 'right',
        },
        header: 'Status',
        cell: ({cell}) => (
          <span
            style={{
              color: cell.getValue() === 'available' ? '#9D9D9D' : '#3E3F42',
            }}
          >
            {cell.getValue() === 'available' ? 'Cleared' : cell.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor((payment) => payment.available_on, {
        id: 'availableOn',
        meta: {
          align: 'right',
        },
        header: 'Date',
        cell: ({cell}) => {
          const value = cell.getValue()
          return <>{value ? Util.formatDateAs(value, 'date_tabular') : ''}</>
        },
      }),
    ],
    [columnHelper],
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const paginationState = useMemo(
    () => ({
      pageSize: paymentsPerPage,
      pageIndex: query.page,
    }),
    [paymentsPerPage, query.page],
  )
  const sortingState = useMemo(
    () => [
      {
        id: query.sort,
        desc: query.direction === 'desc',
      },
    ],
    [query.sort, query.direction],
  )

  const pageCount = Math.ceil(
    (paymentsQuery.data?.pagination.total ?? 0) / paymentsPerPage,
  )

  return (
    <WebUI.TableView<Api.TabPayment>
      className="[&_.TableView-headerGroup]:px-4 [&_.TableViewRow]:px-4"
      loading={paymentsQuery.isPending}
      state={{
        pagination: paginationState,
        sorting: sortingState,
      }}
      pageCount={pageCount}
      manualPagination
      onPaginationChange={(updater) => {
        const newPagination =
          typeof updater === 'function' ? updater(paginationState) : updater

        setQuery((prevQuery) => ({...prevQuery, page: newPagination.pageIndex}))
      }}
      sortable
      sortByTogglesVisible
      manualSortBy
      disableSortRemove
      onSortingChange={(updater) => {
        const [newSorting] =
          typeof updater === 'function' ? updater(sortingState) : updater

        if (newSorting) {
          setQuery((prevQuery) => ({
            ...prevQuery,
            direction: newSorting.desc ? 'desc' : 'asc',
            sort: newSorting.id,
          }))
        }
      }}
      columns={columns}
      data={(paymentsQuery.data?.data ?? []) as any}
      getRowProps={(row) =>
        ({
          as: WebUI.Button,
          variant: 'text',
          onClick: () => onSelect(row.original),
        }) as any
      }
      {...restProps}
    >
      {pageCount > 1 && (
        <WebUI.HStack className="justify-end px-8 py-4">
          <WebUI.TablePaginator />
        </WebUI.HStack>
      )}
    </WebUI.TableView>
  )
}

export default Payments
