// TODO: get rid of this

import {ForwardRefComponent, useLiveRef} from '@cheddarup/react-util'
import {HTMLMotionProps, motion} from 'framer-motion'
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import {
  Tooltip as ReakitTooltip,
  TooltipArrow as ReakitTooltipArrow,
  TooltipInitialState as ReakitTooltipInitialState,
  TooltipOptions as ReakitTooltipOptions,
  TooltipReference as ReakitTooltipReference,
  TooltipStateReturn as ReakitTooltipStateReturn,
  useTooltipState as useReakitTooltipState,
} from 'reakit'
import {cva} from 'class-variance-authority'

import {VariantsProps, cn} from '../utils'

interface InternalTooltipContextValue extends ReakitTooltipStateReturn {}

const InternalTooltipContext = React.createContext(
  {} as InternalTooltipContextValue,
)

export const tooltipInner = cva(
  `max-w-[320px] rounded px-4 py-3 text-center text-ds-sm
  shadow-z16`,
  {
    variants: {
      variant: {
        dark: 'bg-depr-grey-700 text-grey-300',
        light: 'bg-trueWhite text-depr-grey-700',
      },
    },
    defaultVariants: {
      variant: 'dark',
    },
  },
)
export const tooltipArrow = cva(
  'bg-transparent leading-compact [&_.stroke]:fill-transparent',
  {
    variants: {
      variant: {
        dark: '[&_.fill]:fill-depr-grey-700',
        light: '[&_.fill]:fill-trueWhite',
      },
    },
    defaultVariants: {
      variant: 'dark',
    },
  },
)

export interface DeprecatedTooltipProps
  extends ReakitTooltipInitialState,
    ReakitTooltipOptions,
    VariantsProps<typeof tooltipInner>,
    VariantsProps<typeof tooltipArrow> {
  TooltipComponent?: React.ComponentType
  tooltipClassName?: string
  label: React.ReactNode
  arrow?: boolean
  initialVisible?: boolean
  delay?: number
  disabled?: boolean
}

export const DeprecatedTooltip = React.forwardRef(
  (
    {
      as = 'span' as const,
      TooltipComponent = 'div',
      children,
      className,
      tooltipClassName,
      label,
      arrow = true,
      baseId,
      initialVisible,
      visible: visibleProp,
      animated = true,
      placement,
      unstable_fixed,
      unstable_flip,
      unstable_offset,
      gutter,
      unstable_preventOverflow,
      unstable_timeout,
      variant = 'dark',
      delay: delayProp,
      disabled,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [visible, setVisible] = useState(initialVisible ?? false)

    const tooltip = useReakitTooltipState({
      baseId,
      visible: visibleProp ?? visible,
      animated,
      placement,
      unstable_fixed,
      unstable_flip,
      unstable_offset,
      gutter,
      unstable_preventOverflow,
      unstable_timeout,
    })

    const tooltipRef = useLiveRef(tooltip)
    const hideTimeoutsRef = useRef<number[]>([])

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      tooltipRef.current?.hide()
    }, [label])

    useLayoutEffect(() => {
      const hideTimeouts = hideTimeoutsRef.current
      if (visible) {
        hideTimeouts.forEach((ht) => clearTimeout(ht))
        tooltipRef.current?.show()
      } else {
        const delay = delayProp ?? {light: 250, dark: 0}[variant]
        const hideTimeout = setTimeout(
          () => tooltipRef.current?.hide(),
          delay,
        ) as any as number
        hideTimeouts.push(hideTimeout)
      }

      return () => hideTimeouts.forEach((ht) => clearTimeout(ht))
    }, [delayProp, variant, visible])

    return (
      <InternalTooltipContext.Provider value={tooltip}>
        <ReakitTooltipReference
          className={cn(
            'TooltipReference',
            'flex items-center',
            'focus:shadow-none focus:outline-none',
            'focus:focus-visible:shadow-[0_0_0_1px_theme(colors.teal.50)]',
            className,
          )}
          as={as}
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
          {...tooltip}
          {...restProps}
        >
          {children}
        </ReakitTooltipReference>

        <ReakitTooltip
          ref={forwardedRef}
          className={cn(
            'Tooltip',
            '!pointer-events-auto',
            tooltipClassName,
            disabled && 'hidden',
          )}
          {...tooltip}
        >
          {(props: any) =>
            tooltip.visible || tooltip.animating ? (
              <TooltipComponent {...props}>
                <DeprecatedTooltipInnerView
                  className={cn('Tooltip-inner', tooltipInner({variant}))}
                  onMouseEnter={() => setVisible(true)}
                  onMouseLeave={() => setVisible(false)}
                >
                  {arrow && (
                    <ReakitTooltipArrow
                      className={cn('Tooltip-arrow', tooltipArrow({variant}))}
                      {...tooltip}
                    />
                  )}

                  <div>{label}</div>
                </DeprecatedTooltipInnerView>
              </TooltipComponent>
            ) : (
              <>{null}</>
            )
          }
        </ReakitTooltip>
      </InternalTooltipContext.Provider>
    )
  },
) as ForwardRefComponent<'div', DeprecatedTooltipProps>

// MARK: – DeprecatedTooltipInnerView

const DeprecatedTooltipInnerView = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>((props, forwardedRef) => {
  const tooltip = useContext(InternalTooltipContext)
  return tooltip.animated ? (
    <motion.div
      ref={forwardedRef}
      initial={{scale: 0.9, y: '-1rem', opacity: 0}}
      animate={
        tooltip.visible
          ? {
              scale: 1,
              y: 0,
              opacity: 1,
              transition: {duration: 0.15},
            }
          : {
              scale: 0.9,
              y: '-1rem',
              opacity: 0,
              transition: {duration: 0.15},
              transitionEnd: {display: 'none'},
            }
      }
      onAnimationComplete={() => setTimeout(() => tooltip.stopAnimation(), 0)}
      {...(props as HTMLMotionProps<'div'>)}
    />
  ) : (
    <div ref={forwardedRef} {...props} />
  )
})
