import {ForwardRefComponent} from '@cheddarup/react-util'
import {
  cn,
  copyToClipboard,
  FormField,
  PhosphorIcon,
  PhosphorIconName,
} from '@cheddarup/web-ui'
import React, {useState} from 'react'
import {
  InputWithIcon,
  InputWithIconProps,
} from 'src/views/account/settings/BrandKitFooterPage'

export interface CopyToClipboardProps
  extends Omit<InputWithIconProps, 'icon' | 'value'> {
  iconBeforeCopy?: PhosphorIconName
  iconAfterCopy?: PhosphorIconName
  text: string
  label?: string
}

const CopyToClipboard = React.forwardRef(
  (
    {
      iconBeforeCopy = 'copy-simple',
      iconAfterCopy = 'check',
      className,
      position = 'right',
      text,
      label,
      readOnly = true,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [copied, setCopied] = useState(false)
    return (
      <FormField label={label}>
        <InputWithIcon
          ref={forwardedRef}
          className={cn('cursor-pointer', className)}
          value={text}
          icon={
            copied ? (
              <PhosphorIcon
                icon={iconAfterCopy}
                className="text-ds-xl text-teal-40"
              />
            ) : (
              <PhosphorIcon
                icon={iconBeforeCopy}
                className="text-ds-xl text-grey-500"
              />
            )
          }
          position={position}
          readOnly={readOnly}
          onClick={() => {
            copyToClipboard(text)
            setCopied(true)
            setTimeout(() => {
              setCopied(false)
            }, 2000)
          }}
          {...restProps}
        />
      </FormField>
    )
  },
) as ForwardRefComponent<'input', CopyToClipboardProps>

export default CopyToClipboard
