import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {SharpImage} from 'src/components/SharpImage'
import * as Util from '@cheddarup/util'
import {SharpAvatar} from 'src/components/SharpAvatar'
import {BrandKitColors} from '@cheddarup/core'
import {getCUReadableColor} from 'src/helpers/color-utils'

interface SponsorsProps extends React.ComponentPropsWithoutRef<'div'> {
  sponsors: Api.GroupPageSection
  brandKitColors: BrandKitColors
}

const Sponsors: React.FC<SponsorsProps> = ({
  sponsors,
  className,
  style,
  brandKitColors,
  ...restProps
}) => (
  <div
    className={WebUI.cn('flex flex-col px-8 py-18 xl:px-0', className)}
    style={{
      color: getCUReadableColor(brandKitColors[sponsors.background_color], {
        darkColor: '#373737',
      }),
      backgroundColor: brandKitColors[sponsors.background_color],
      ...style,
    }}
    {...restProps}
  >
    <div className="mx-auto flex w-full max-w-[1100px] flex-col gap-4">
      <div className="flex flex-col gap-5 sm:px-6">
        <WebUI.Heading className="font-accentAlt text-h-6 sm:text-h-4">
          {sponsors.headline}
        </WebUI.Heading>
        <WebUI.Text className="font-light text-ds-md">
          {sponsors.description}
        </WebUI.Text>
      </div>
      <div className="mt-4 flex flex-row flex-wrap justify-center gap-8 sm:justify-normal">
        {Util.sort(sponsors.sponsor_items ?? [])
          .asc((si) => si.position)
          .map((member) => (
            <SponsorItemCard key={member.id} sponsor={member} />
          ))}
      </div>
    </div>
  </div>
)

// MARK: - Helpers

interface SponsorItemCardProps {
  sponsor: Api.GroupPageSponsor
}

const SponsorItemCard = ({sponsor}: SponsorItemCardProps) => (
  <div className="flex w-[250px] flex-col items-center gap-5 rounded-[15px] bg-trueWhite px-6 py-4 shadow-z4 sm:h-full">
    <div className="flex h-[220px] flex-col justify-center">
      {sponsor.logo ? (
        <SharpImage image={sponsor.logo} width={170} height="auto" alt="logo" />
      ) : (
        <SharpAvatar
          name={sponsor.name}
          className="[&_>_.Avatar-label]:rounded-none"
          size={170}
          height={120}
        />
      )}
    </div>
    <div className="flex h-[72px]">
      {sponsor.url ? (
        <WebUI.Anchor
          className="whitespace-normal"
          href={sponsor.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {sponsor.name}
        </WebUI.Anchor>
      ) : (
        <span className="flex items-center text-center font-normal text-gray800">
          {sponsor.name}
        </span>
      )}
    </div>
  </div>
)

export default Sponsors
