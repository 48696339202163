import * as WebUI from '@cheddarup/web-ui'
import {ConvertTemplateToTabButton} from './ConvertTemplateToTabButton'

export interface UseTemplateModalProps extends WebUI.ModalProps {
  templateId: number
  hasPremiumFeature?: boolean
}

export const UseTemplateModal: React.FC<UseTemplateModalProps> = ({
  templateId,
  className,
  initialVisible = false,
  hasPremiumFeature,
  ...restProps
}) => (
  <WebUI.Modal
    initialVisible={initialVisible}
    className={WebUI.cn(
      'items-center justify-center bg-transparent [&_>_.ModalContentView]:w-[360px] [&_>_.ModalContentView]:rounded-[20px] [&_>_.ModalContentView]:p-8',
      className,
    )}
    {...restProps}
  >
    <WebUI.ModalCloseButton />
    <div className="flex flex-col gap-6">
      <WebUI.Heading className="font-extrabold" as="h4">
        It’s yours!
      </WebUI.Heading>

      <WebUI.Text>
        We’ll add this template to your existing or new account. Once published,
        you can start collecting payments and information in minutes.
      </WebUI.Text>
      {hasPremiumFeature && (
        <div className="flex gap-2">
          <WebUI.PhosphorIcon icon="star-fill" className="text-yellow-500" />
          <WebUI.Text className="text-ds-xs">
            This template uses premium features that you can remove when you
            customize.
          </WebUI.Text>
        </div>
      )}
      <ConvertTemplateToTabButton
        className="[&_>_.Button-content]:font-extrabold"
        variant="primary"
        templateId={templateId}
        size="large"
      >
        Customize Template
      </ConvertTemplateToTabButton>
    </div>
  </WebUI.Modal>
)
