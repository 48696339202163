import Modal from './Modal'

export interface PlanWarningModalProps {
  handleDismiss?: () => void
  managers: any[]
  onSubmit: () => void
}

const PlanWarningModal = ({
  handleDismiss,
  managers,
  onSubmit,
}: PlanWarningModalProps) => (
  <Modal
    buttons={[
      {
        className: 'text-trueWhite bg-orange-500',
        text: 'Downgrade Now',
        onClick: onSubmit,
      },
      {
        className: 'text-gray600 bg-grey-300',
        text: "Cancel, I'll stay upgraded",
        onClick: handleDismiss,
      },
    ]}
    handleDismiss={handleDismiss}
    heading="Warning: This cannot be undone."
    message={
      <div>
        {managers.length > 0 && (
          <p>
            You have {managers.length}{' '}
            {`manager${managers.length === 1 ? '' : 's'}`} who will no longer
            have access to managing your collections or creating new collections
            under your account, if you downgrade from TEAM.
          </p>
        )}
      </div>
    }
  />
)

export default PlanWarningModal
