import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {Field, Formik} from 'formik'
import {Link, useLocation} from 'react-router-dom'
import query from 'query-string'
import {useState} from 'react'
import {useCheckEmailExistenceMutation} from '@cheddarup/api-client'
import config from 'src/config'
import {useSignup} from 'src/hooks/useAuth'
import {INVALID_EMAIL_STATUSES} from 'src/helpers/email-status-helpers'

export interface SignupAsManagerFormValues {
  first_name: string
  last_name: string
  email: string
  password: string
}

export interface SignupAsManagerFormContainerProps {
  org: string
  inviteCode: string
  memberEmail: string
}

const SignupAsManagerFormContainer: React.FC<
  SignupAsManagerFormContainerProps
> = ({org, inviteCode, memberEmail}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const checkEmailExistenceMutation = useCheckEmailExistenceMutation()
  const [signup] = useSignup({captchaDisabled: true})

  const location = useLocation()
  const [passwordRevealed, setPasswordRevealed] = useState(false)
  return (
    <Formik<SignupAsManagerFormValues>
      initialValues={{
        first_name: '',
        last_name: '',
        email: memberEmail,
        password: '',
      }}
      validationSchema={Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        email: Yup.string()
          .required('Required')
          .email('Email is Invalid')
          .test(
            'matchEmail',
            "Email doesn't match invite",
            (val) => val === memberEmail,
          ),
        password: Yup.string()
          .min(6, 'Password must contain at least 6 characters')
          .matches(/\d/, 'Password must contain at least 1 number')
          .required('Required'),
      })}
      onSubmit={async (values, {setFieldError, setSubmitting}) => {
        const {exists, status} = await checkEmailExistenceMutation.mutateAsync({
          body: {email: values.email},
        })
        if (exists) {
          setSubmitting(false)
          setFieldError(
            'email',
            'Looks like this email is in use. Try logging in.',
          )
        } else if (INVALID_EMAIL_STATUSES.includes(status)) {
          setFieldError(
            'email',
            'The email entered is invalid. Please enter a valid email address.',
          )
        } else {
          try {
            const res = await signup({
              ...values,
              partner: org,
              invite_code: inviteCode,
              profile: {
                referrer: {
                  url: query.parse(window.location.search) as any as string,
                },
              },
            })

            if (!res) {
              return
            }
          } catch (err) {
            if (err instanceof Error) {
              setErrorMessage(err.message)
            }
          }
        }
      }}
      validateOnBlur={false}
    >
      {({isSubmitting, errors, handleSubmit, handleReset}) => (
        <WebUI.Form onSubmit={handleSubmit} onReset={handleReset}>
          <WebUI.FormField label="First name" error={errors.first_name}>
            <Field
              name="first_name"
              as={WebUI.Input}
              placeholder="Hint: Please don't enter a nickname"
            />
          </WebUI.FormField>
          <WebUI.FormField label="Last name" error={errors.last_name}>
            <Field name="last_name" as={WebUI.Input} />
          </WebUI.FormField>
          <WebUI.FormField label="Email address" error={errors.email} disabled>
            <Field name="email" as={WebUI.Input} type="email" />
          </WebUI.FormField>
          <WebUI.FormField
            label={
              <WebUI.HStack className="items-end justify-between gap-4">
                <span>Password</span>
                <WebUI.IconButton
                  className="text-ds-md text-gray400"
                  size="default_alt"
                  tabIndex={-1}
                  onClick={() =>
                    setPasswordRevealed(
                      (prevPasswordRevealed) => !prevPasswordRevealed,
                    )
                  }
                >
                  {passwordRevealed ? (
                    <WebUI.PhosphorIcon icon="eye-slash" />
                  ) : (
                    <WebUI.PhosphorIcon icon="eye" />
                  )}
                </WebUI.IconButton>
              </WebUI.HStack>
            }
            caption="Passwords must contain at least 6 characters and 1 number."
            error={errors.password}
          >
            <Field
              name="password"
              as={WebUI.Input}
              type={passwordRevealed ? 'text' : 'password'}
              autoComplete="new-password"
            />
          </WebUI.FormField>
          <div className="font-normal text-ds-sm">
            By continuing, I agree to the Cheddar Up's{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={config.links.terms}
            >
              Terms of Use
            </a>{' '}
            and{' '}
            <a
              href={config.links.privacyPolicy}
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>

          <WebUI.Button
            className="m-auto mt-7"
            size="large"
            variant="primary"
            type="submit"
            loading={isSubmitting}
          >
            Continue
          </WebUI.Button>

          <Link
            className="mt-2 block text-center text-ds-sm"
            to={{pathname: '/login', search: location.search}}
          >
            Already have an account? Log in
          </Link>

          {errorMessage && (
            <div className="mt-4 flex justify-center text-center text-ds-base text-orange-500">
              {errorMessage}
            </div>
          )}
        </WebUI.Form>
      )}
    </Formik>
  )
}

export default SignupAsManagerFormContainer
