import * as Yup from 'yup'
import {StringParam, useQueryParam} from 'use-query-params'
import {useFormik} from '@cheddarup/react-util'
import React, {useState} from 'react'
import {useSendGuestLoginLinkMutation} from '@cheddarup/api-client'
import * as WebUI from '@cheddarup/web-ui'
import {AuthLayout} from 'src/components/AuthLayout'
import {Link} from 'src/components/Link'
import {Logo} from 'src/components/Logo'
import RightArrowEncircled from 'src/images/RightArrowEncircled.svg'

export const GuestAuthPage: React.FC = () => {
  const [initialEmail] = useQueryParam('email', StringParam)
  const [submittedEmail, setSubmittedEmail] = useState<string | null>(null)

  return (
    <AuthLayout>
      <WebUI.VStack className="gap-5 p-6 sm:gap-10 sm:p-10">
        <Logo />

        {submittedEmail ? (
          <GuestAuthLinkSent
            email={submittedEmail}
            onHide={() => setSubmittedEmail(null)}
          />
        ) : (
          <GuestAuthForm
            initialValues={{email: initialEmail ?? ''}}
            onDidSubmit={(values) => setSubmittedEmail(values.email)}
          />
        )}
      </WebUI.VStack>

      <GuestAuthFooter />
    </AuthLayout>
  )
}

// MARK: – GuestAuthForm

interface GuestAuthFormValues {
  email: string
}

interface GuestAuthFormProps
  extends Omit<React.ComponentPropsWithoutRef<'form'>, 'onReset' | 'onSubmit'> {
  initialValues?: GuestAuthFormValues
  onDidSubmit?: (values: GuestAuthFormValues) => void
}

const GuestAuthForm: React.FC<GuestAuthFormProps> = ({
  initialValues = {
    email: '',
  },
  onDidSubmit,
  className,
  ...restProps
}) => {
  const sendGuestLoginLinkMutation = useSendGuestLoginLinkMutation()
  const formik = useFormik<GuestAuthFormValues>({
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid format').required('Required'),
    }),
    initialValues,
    onSubmit: async (values) => {
      await sendGuestLoginLinkMutation.mutateAsync({
        body: {
          email: values.email,
        },
      })

      onDidSubmit?.(values)
    },
  })

  return (
    <WebUI.Form
      className={WebUI.cn(
        '[&_>_.Form-inner]:gap-5 sm:[&_>_.Form-inner]:gap-10',
        className,
      )}
      onReset={formik.handleReset}
      onSubmit={formik.handleSubmit}
      {...restProps}
    >
      <WebUI.VStack className="gap-3">
        <WebUI.Heading className="text-center leading-compact">
          View order history
        </WebUI.Heading>
        <WebUI.Text className="text-center text-ds-md">
          Don’t have a Cheddar Up account? No problem! Just enter your email and
          we’ll send a link to view your order.
        </WebUI.Text>
      </WebUI.VStack>
      <WebUI.VStack className="gap-4">
        <WebUI.FormField label="Email" required error={formik.errors.email}>
          <WebUI.Input
            name="email"
            type="email"
            autoComplete="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </WebUI.FormField>
      </WebUI.VStack>
      <WebUI.Button
        className="w-[200px] self-center sm:w-[inherit]"
        type="submit"
        size="large"
        variant="primary"
        loading={formik.isSubmitting}
      >
        Send Email
      </WebUI.Button>
    </WebUI.Form>
  )
}

// MARK: – GuestAuthLinkSent

interface GuestAuthLinkSentProps extends React.ComponentPropsWithoutRef<'div'> {
  email: string
  onHide: () => void
}

const GuestAuthLinkSent: React.FC<GuestAuthLinkSentProps> = ({
  email,
  onHide,
  className,
  ...restProps
}) => (
  <WebUI.VStack
    className={WebUI.cn('gap-4 text-center', className)}
    {...restProps}
  >
    <WebUI.Heading className="leading-compact">All done!</WebUI.Heading>
    <WebUI.Text className="text-ds-md">
      Please check your email where we’ve sent you the link to view your order
      history.
    </WebUI.Text>
  </WebUI.VStack>
)

// MARK: – GuestAuthFooter

const GuestAuthFooter: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  ...restProps
}) => {
  const media = WebUI.useMedia()
  return (
    <WebUI.HStack
      className="items-center gap-5 bg-depr-grey-200 p-6 sm:px-10 sm:py-5"
      {...restProps}
    >
      {media.sm && (
        <img
          className="h-[1em] text-ds-2xl text-orange-500"
          src={RightArrowEncircled}
          alt=""
        />
      )}

      <WebUI.Text className="text-ds-md">
        Have an account? {/* TODO: pass query params to prefill login form */}
        <Link
          variant="primary"
          to="/login"
          className="whitespace-[unset] [&_.Anchor-content]:whitespace-[unset]"
        >
          Sign in with your Cheddar Up password
        </Link>
      </WebUI.Text>
    </WebUI.HStack>
  )
}
