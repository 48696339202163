import React from 'react'
import * as WebUI from '@cheddarup/web-ui'
import EasyIcon from 'src/images/EasyIcon.svg'

const LoginFooter = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const media = WebUI.useMedia()

  return media.sm ? (
    <div className={WebUI.cn('bg-depr-grey-200 p-5', className)} {...restProps}>
      <WebUI.HStack className="items-center gap-4 [&_>_svg]:w-10">
        <img
          className="h-[2.5em] w-[2.5em] scale-[0.8]"
          src={EasyIcon}
          alt=""
        />
        <span className="font-normal text-ds-sm text-gray800">
          Your group can pay online without an app or creating an account. It's
          that easy.
        </span>
      </WebUI.HStack>
    </div>
  ) : null
}

export default LoginFooter
