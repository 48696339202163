import * as Ariakit from '@ariakit/react'
import React, {useImperativeHandle} from 'react'
import {cva} from 'class-variance-authority'

import {VariantsProps, cn} from '../utils'
import {getPortalElement} from './Portal'

export type TooltipVariant = 'dark' | 'light'

export interface TooltipProps extends Ariakit.TooltipStoreProps {
  children:
    | React.ReactNode
    | ((tooltipStore: Ariakit.TooltipStore) => React.ReactNode)
}

export const Tooltip = React.forwardRef<Ariakit.TooltipStore, TooltipProps>(
  ({children, ...restProps}, forwardedRef) => {
    const tooltipStore = Ariakit.useTooltipStore({
      showTimeout: 200,
      ...restProps,
    })

    useImperativeHandle(forwardedRef, () => tooltipStore, [tooltipStore])

    return (
      <Ariakit.TooltipProvider store={tooltipStore}>
        {typeof children === 'function' ? children(tooltipStore) : children}
      </Ariakit.TooltipProvider>
    )
  },
)

// MARK: – TooltipAnchor

export interface TooltipAnchorProps extends Ariakit.TooltipAnchorProps {}
export const TooltipAnchor = Ariakit.TooltipAnchor

// MARK: – TooltipContent

export const tooltipContent = cva(
  [
    'max-w-96 rounded px-4 py-3 text-center text-ds-sm shadow-500',
    'scale-90 opacity-0 transition-all data-[enter]:translate-y-0 data-[enter]:scale-100 data-[enter]:opacity-100',
    '-translate-y-4 data-[direction=top]:[&:not([data-enter])]:translate-y-4',
  ],
  {
    variants: {
      variant: {
        dark: 'bg-depr-grey-700 text-depr-grey-50',
        light: 'bg-trueWhite text-depr-grey-700',
        headless: '',
      },
    },
    defaultVariants: {
      variant: 'dark',
    },
  },
)

export interface TooltipContentProps
  extends VariantsProps<typeof tooltipContent>,
    Omit<Ariakit.TooltipProps, 'arrowPadding' | 'backdrop'> {
  arrow?: boolean | React.ReactElement
}

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  TooltipContentProps
>(
  (
    {variant = 'dark', arrow = true, className, children, ...restProps},
    forwardedRef,
  ) => {
    const tooltip = Ariakit.useTooltipContext()
    const direction = Ariakit.useStoreState(
      tooltip,
      (state) => state?.currentPlacement.split('-')[0] ?? 'top',
    )

    return (
      <Ariakit.Tooltip
        ref={forwardedRef}
        data-direction={direction}
        className={cn(tooltipContent({variant}), className)}
        portalElement={getPortalElement}
        {...restProps}
      >
        {arrow && (
          <TooltipArrow
            key={variant} // the colors don't update
            render={arrow === true ? undefined : arrow}
          />
        )}
        <div>{children}</div>
      </Ariakit.Tooltip>
    )
  },
)

// MARK: – TooltipArrow

export interface TooltipArrowProps extends Ariakit.TooltipArrowProps {}
export const TooltipArrow = Ariakit.TooltipArrow
