import * as WebUI from '@cheddarup/web-ui'
import React, {ReactNode} from 'react'
import type {To} from 'react-router-dom'

import {LinkButton} from './LinkButton'

export interface UpsellCardProps extends React.ComponentPropsWithoutRef<'div'> {
  heading: ReactNode
  imgSrc: string
  descriptionText: ReactNode
  tiles?: string[]
  to: To
  ctaText: string
  variant?: 'dark' | 'light'
  linkToLearnMore?: string
}

export const UpsellCard: React.FC<UpsellCardProps> = ({
  heading,
  imgSrc,
  descriptionText,
  tiles = [],
  ctaText,
  variant,
  linkToLearnMore,
  to,
  className,
  ...restProps
}) => (
  <WebUI.Panel
    className={WebUI.cn(
      'UpsellCard',
      'flex flex-col items-start justify-center gap-3 bg-trueWhite',
      variant ? '*:text-trueWhite' : '*:text-gray800',
      className,
    )}
    {...restProps}
  >
    <div className="flex flex-col gap-6 px-7 pt-8">
      <div className="flex flex-col gap-3">
        {typeof heading === 'string' ? (
          <WebUI.Heading
            className="UpsellCard--heading max-w-[224px] font-accent font-bold text-ds-md"
            as="h3"
          >
            {heading}
          </WebUI.Heading>
        ) : (
          heading
        )}
        <WebUI.Text>
          {descriptionText}{' '}
          {linkToLearnMore && (
            <WebUI.Anchor
              data-variant={variant}
              className="data-[variant=light]:text-trueWhite data-[variant=light]:underline"
              target="_blank"
              href={linkToLearnMore}
            >
              Learn more
            </WebUI.Anchor>
          )}
        </WebUI.Text>
        {tiles.length > 0 && (
          <ul className="ml-3 font-normal [list-style:unset]">
            {tiles.map((tile, idx) => (
              <li key={idx}> {tile}</li>
            ))}
          </ul>
        )}
      </div>
      {typeof to === 'string' && to.startsWith('https') ? (
        <WebUI.Button
          className="min-w-[160px] self-start"
          variant="primary"
          as={WebUI.Anchor}
          href={to}
          target="_blank"
        >
          {ctaText}
        </WebUI.Button>
      ) : (
        <LinkButton
          className="min-w-[160px] self-start"
          variant="primary"
          to={to}
        >
          {ctaText}
        </LinkButton>
      )}
    </div>
    <img className="w-full max-w-full" src={imgSrc} alt="" />
  </WebUI.Panel>
)
