import React, {useLayoutEffect, useRef, useState} from 'react'

import {PhosphorIcon} from '../icons'
import {Button} from './Button'
import {Canvas, CanvasInstance} from './Canvas'
import {Text} from './Text'
import {cn} from '../utils'

export interface SignatureCanvasProps
  extends React.ComponentPropsWithoutRef<'div'> {
  disabled?: boolean
  defaultDataURL?: string
  onDataURLChange?: (dataUrl: string | null) => void
}

export const SignatureCanvas = React.forwardRef<
  HTMLDivElement,
  SignatureCanvasProps
>(
  (
    {disabled, defaultDataURL, onDataURLChange, className, ...restProps},
    forwardedRef,
  ) => {
    const canvasContainerRef = useRef<HTMLDivElement>(null)
    const canvasRef = useRef<CanvasInstance>(null)
    const [isEmpty, setIsEmpty] = useState(!defaultDataURL)

    useLayoutEffect(() => {
      if (defaultDataURL) {
        const draw = () => {
          if (canvasContainerRef.current) {
            const cavnasContainerRect =
              canvasContainerRef.current.getBoundingClientRect()

            const hRatio = cavnasContainerRect.width / image.width
            const vRatio = cavnasContainerRect.height / image.height
            const ratio = Math.min(hRatio, vRatio)

            const centerX =
              (cavnasContainerRect.width - image.width * ratio) / 2
            const centerY =
              (cavnasContainerRect.height - image.height * ratio) / 2

            canvasRef.current?.context?.drawImage(
              image,
              0,
              0,
              image.width,
              image.height,
              centerX,
              centerY,
              image.width * ratio,
              image.height * ratio,
            )
          } else {
            canvasRef.current?.context?.drawImage(image, 0, 0)
          }
        }

        const image = new Image()
        image.addEventListener('load', draw)
        image.crossOrigin = 'anonymous'
        image.src = defaultDataURL

        return () => image.removeEventListener('load', draw)
      }

      return () => {}
    }, [defaultDataURL])

    return (
      <div
        ref={forwardedRef}
        className={cn(
          'SignatureCanvas',
          'relative flex min-w-[78px] flex-col gap-3',
          className,
        )}
        {...restProps}
      >
        <div
          ref={canvasContainerRef}
          className={cn(
            'SignatureCanvas-canvasContainer',
            'relative flex flex-col',
          )}
        >
          <Canvas
            ref={canvasRef}
            className={cn(
              'SignatureCanvas-canvas',
              'h-[120px] rounded shadow-[inset_0_0_0_1px_theme(colors.grey.300)]',
              'aria-invalid:shadow-[inset_0_0_0_1px_theme(colors.orange.500)]',
              'focus:shadow-[inset_0_0_0_1px_theme(colors.teal.50)]',
            )}
            disabled={disabled}
            tabIndex={disabled ? -1 : 0}
            onDataURLChange={onDataURLChange}
            onIsEmptyChange={setIsEmpty}
          />

          {isEmpty && (
            <div
              className={
                '-translate-x-1/2 -translate-y-1/2 pointer-events-none absolute top-1/2 left-1/2 flex flex-col items-center justify-center gap-0_5'
              }
            >
              <PhosphorIcon icon="scribble-loop" width={36} />
              <Text className="text-center text-ds-sm">Sign Here</Text>
            </div>
          )}
        </div>

        {!disabled && (
          <Button
            className={cn('SignatureCanvas-clearButton', 'w-[100px] self-end')}
            size="compact"
            variant="secondary"
            disabled={isEmpty}
            onClick={() => canvasRef.current?.clear()}
          >
            Clear
          </Button>
        )}
      </div>
    )
  },
)
