import * as WebUI from '@cheddarup/web-ui'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import React from 'react'
import {
  api,
  useDeleteTabPaymentMutation,
  useSendReceiptTabPaymentMutation,
} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {PayerOverview, PaymentNoteButtonDropdown} from 'src/components'
import {
  ItemVariantOptionValuesList,
  OrderSummaryLayout,
  OrderSummarySidebarHeader,
  PaymentFormTable,
  PaymentItemTable,
  PaymentOverview,
  PaymentSignupTable,
  SignupPaymentSummary,
  useSelectedPaymentObject,
  useSelectedTermAndHistoryTabItemId,
} from 'src/components/OrderSummaryLayout'
import OrderSummaryTermsHistory from 'src/components/OrderSummaryTermsHistory'
import {TicketOverviewCard} from 'src/components/TicketOverviewCard'
import * as Util from '@cheddarup/util'

import {PaymentObjectFieldsEdit} from './PaymentObjectFieldsEdit'
import {normalizeFieldSets} from 'src/components/FieldSetList'

const OrderSummaryPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const urlParams = useParams()

  const collectionId = Number(urlParams.collection)
  const paymentId = Number(urlParams.payment)

  const paymentQuery = api.tabPayments.detail.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
      // biome-ignore lint/style/noNonNullAssertion:
      paymentId: urlParams.payment!,
    },
  })
  const {data: collectionName} = api.tabs.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
    },
    {
      select: (collection) => collection.name,
    },
  )
  const [selectedPaymentObject] = useSelectedPaymentObject()
  const [selectedTermAndHistoryTabItemId] = useSelectedTermAndHistoryTabItemId()

  const pis = paymentQuery.data?.payment_items ?? []
  const paymentItems =
    (pis.filter((pi) => !!pi.tab_item) as unknown as Api.TabItemPayment[]) ?? []
  const paymentForms =
    (pis.filter((pi) => !!pi.tab_form) as Api.TabFormPayment[]) ?? []
  const paymentSignups = Util.sort(
    pis.filter((pi) => !!pi.time_slot) as Api.TabSignupPayment[],
  ).asc((pts) => pts.time_slot.options.startTime)
  const selectedTabObject =
    selectedPaymentObject?.tab_form ?? selectedPaymentObject?.tab_item

  return (
    <OrderSummaryLayout
      onDidHide={() => navigate({pathname: '..', search: location.search})}
      sidebar={
        selectedPaymentObject ? (
          <div
            className={
              'flex flex-col gap-4 *:border-b *:pb-4 [&_>*:last-child]:border-b-0 [&_>_*&:last-child:not(.TicketOverviewCard)]:pb-0'
            }
          >
            <OrderSummarySidebarHeader />
            {selectedPaymentObject.detail &&
            'itemType' in selectedPaymentObject.detail &&
            selectedPaymentObject.detail.itemType === 'ticket' ? (
              <>
                <div className="flex flex-col gap-2">
                  <WebUI.Text className="text-ds-sm">Payment by</WebUI.Text>
                  <PayerOverview
                    avatarHidden
                    tabMember={paymentQuery.data?.tab_member}
                  />
                </div>
                <TicketOverviewCard
                  collectionId={collectionId}
                  paymentObject={
                    selectedPaymentObject as unknown as Api.TabItemPayment
                  }
                />
              </>
            ) : (
              <>
                {!!selectedTabObject?.description && (
                  <WebUI.MarkdownParagraph
                    className="[&_>_.RichTextEditor]:text-ds-sm"
                    markdown={selectedTabObject.description}
                  />
                )}
                {selectedPaymentObject.time_slot && (
                  <SignupPaymentSummary
                    className="text-ds-sm"
                    paymentItem={selectedPaymentObject as Api.TabSignupPayment}
                  />
                )}
                {selectedPaymentObject.is_responses_editable ? (
                  <PaymentObjectFieldsEdit
                    className="text-ds-sm [&_.RichTextEditor]:line-clamp-[8]"
                    collectionId={collectionId}
                    paymentId={paymentId}
                    paymentObject={selectedPaymentObject as Api.PaymentItem}
                  />
                ) : (
                  <PaymentObjectFieldsSimple
                    paymentObject={selectedPaymentObject as Api.PaymentItem}
                  />
                )}
                <ItemVariantOptionValuesList />
              </>
            )}
          </div>
        ) : selectedTermAndHistoryTabItemId ? (
          <OrderSummaryTermsHistory
            payment={paymentQuery.data}
            tabItemId={selectedTermAndHistoryTabItemId}
          />
        ) : undefined
      }
    >
      <div className="flex flex-col gap-4 px-4 py-8 sm:p-8">
        <WebUI.Heading as="h3">Order Summary</WebUI.Heading>
        <div className="flex flex-row gap-3">
          {paymentQuery.data?.can_delete && <DeletePaymentAlert />}
          <LinkButton
            variant="default"
            target="_blank"
            to={`/pdf/collection/${urlParams.collection}/tab-payment/${urlParams.payment}`}
          >
            Print
          </LinkButton>
          {paymentQuery.data && (
            <ResendPaymentReceiptButton payment={paymentQuery.data} />
          )}
        </div>
      </div>

      <WebUI.Separator variant="primary" />

      {!!paymentQuery.data && (
        <>
          <PayerOverview
            className="p-6 sm:p-8"
            payment={paymentQuery.data}
            tabMember={paymentQuery.data.tab_member}
            editable
            footer={<PaymentNote payment={paymentQuery.data} />}
          />

          <WebUI.Separator variant="primary" />

          <PaymentOverview
            className="px-4 py-8 sm:p-8"
            paymentType="tab"
            collectionName={collectionName ?? ''}
            payment={paymentQuery.data}
          />
        </>
      )}

      {!!paymentQuery.data && (
        <div className="flex flex-col gap-8 sm:p-8">
          {paymentItems.length > 0 && (
            <PaymentItemTable
              payment={paymentQuery.data}
              paymentItems={paymentItems}
              shippingInfo={paymentQuery.data.shipping_info}
              refunds={paymentQuery.data.refunds ?? []}
            />
          )}
          {paymentForms.length > 0 && <PaymentFormTable data={paymentForms} />}
          {paymentSignups.length > 0 && (
            <PaymentSignupTable
              collectionId={collectionId}
              data={paymentSignups}
            />
          )}
        </div>
      )}
    </OrderSummaryLayout>
  )
}

// MARK: – ResendPaymentReceiptButton

interface ResendPaymentReceiptButtonContainerProps
  extends React.ComponentPropsWithoutRef<'button'> {
  payment: Api.TabPayment
}

const ResendPaymentReceiptButton = ({
  payment,
  ...restProps
}: ResendPaymentReceiptButtonContainerProps) => {
  const sendReceiptMutation = useSendReceiptTabPaymentMutation()
  const growlActions = WebUI.useGrowlActions()
  return (
    <WebUI.Button
      variant="secondary"
      loading={sendReceiptMutation.isPending}
      onClick={async () => {
        if (payment.tab_member.email) {
          await sendReceiptMutation.mutateAsync({
            pathParams: {
              tabId: payment.tab_id,
              paymentId: payment.id,
            },
            body: {email: payment.tab_member.email},
          })
          growlActions.show('success', {
            title: 'Receipt is sent',
            body: `An email receipt is on its way to ${payment.tab_member.email}.`,
          })
        } else {
          growlActions.show('error', {
            title: 'No email address',
            body: 'No email address has been provided for this payer.',
          })
        }
      }}
      {...restProps}
    >
      Resend Receipt
    </WebUI.Button>
  )
}

// MARK: – DeletePaymentAlert

const DeletePaymentAlert = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'button'>) => {
  const urlParams = useParams<{collection: string; payment: string}>()
  const navigate = useNavigate()
  const collectionId = Number(urlParams.collection)
  const paymentId = Number(urlParams.payment)
  const deletePaymentMutation = useDeleteTabPaymentMutation()
  return (
    <WebUI.Alert
      aria-label="Delete payment confirmation"
      disclosure={
        <WebUI.DeprecatedTooltip label="Delete record">
          <WebUI.IconButton
            className={WebUI.cn('text-ds-xl text-gray400', className)}
            size="default_alt"
            variant="secondary"
            as={WebUI.DialogDisclosure}
            {...restProps}
          >
            <WebUI.PhosphorIcon icon="trash-fill" />
          </WebUI.IconButton>
        </WebUI.DeprecatedTooltip>
      }
    >
      {(dialog) => (
        <>
          <WebUI.AlertHeader>Delete Payment Record</WebUI.AlertHeader>
          <WebUI.AlertContentView
            text="This will permanently delete payment record along with any associated form information. Are you sure?"
            actions={
              <>
                <WebUI.AlertActionButton
                  execute={async () => {
                    await deletePaymentMutation.mutateAsync({
                      pathParams: {
                        tabId: collectionId,
                        paymentId,
                      },
                    })
                    navigate('..')
                    dialog.hide()
                  }}
                >
                  Delete
                </WebUI.AlertActionButton>
                <WebUI.AlertCancelButton />
              </>
            }
          />
        </>
      )}
    </WebUI.Alert>
  )
}

// MARK: – PaymentNote

interface PaymentNoteProps extends React.ComponentPropsWithoutRef<'div'> {
  payment: Api.TabPayment
}

const PaymentNote = ({payment, ...restProps}: PaymentNoteProps) => (
  <div {...restProps}>
    {!!payment.note && <div className="text-ds-sm italic">{payment.note}</div>}
    <PaymentNoteButtonDropdown
      className="text-ds-sm"
      variant="link"
      payment={payment}
    >
      {payment.note ? 'Edit Note' : 'Add Note'}
    </PaymentNoteButtonDropdown>
  </div>
)

// MARK: - PaymentObjectFieldsSimple

export interface PaymentObjectFieldsSimpleProps
  extends React.ComponentPropsWithoutRef<'div'> {
  paymentObject: Api.PaymentItem
}

export const PaymentObjectFieldsSimple: React.FC<
  PaymentObjectFieldsSimpleProps
> = ({paymentObject, className, ...restProps}) => {
  const tabObject =
    paymentObject.tab_item ?? paymentObject.tab_form ?? paymentObject.time_slot

  const sortedFields = normalizeFieldSets({
    fields: paymentObject.item_field_views,
    fieldSets:
      'fieldSets' in tabObject.options
        ? (tabObject.options.fieldSets ?? [])
        : [],
  }).flatMap((fSets) => fSets.fields)

  return (
    <div className={WebUI.cn('flex flex-col gap-8', className)} {...restProps}>
      {sortedFields.map((ifv) => (
        <div className="flex flex-col" key={ifv.id}>
          <WebUI.Text className="text-ds-sm">
            {ifv.name}{' '}
            {ifv.required && <span className="text-orange-500">*</span>}
          </WebUI.Text>
          {(() => {
            switch (ifv.field_type) {
              case 'signature':
              case 'image':
                return (
                  <img
                    src={ifv.value}
                    alt=""
                    className={WebUI.cn(
                      'mt-1',
                      ifv.metadata.fieldTypeMetadata?.fieldIdentifier ===
                        'legal_initials'
                        ? 'max-w-16'
                        : 'max-w-44',
                    )}
                  />
                )
              case 'file':
                return (
                  <WebUI.Tag className="mt-1 text-ds-xs">
                    <WebUI.Anchor
                      className="max-w-[180px] font-light text-ds-xs text-gray800"
                      href={ifv.value}
                      target="_blank"
                    >
                      {Util.getFileNameFromUrl(ifv.value)}
                    </WebUI.Anchor>
                  </WebUI.Tag>
                )
              case 'date':
                return (
                  <WebUI.Text>
                    {new Date(ifv.value).toLocaleDateString()}
                  </WebUI.Text>
                )
              case 'time':
                return (
                  <WebUI.Text>
                    {new Date(ifv.value).toLocaleTimeString()}
                  </WebUI.Text>
                )
              default:
                return <WebUI.Text>{ifv.value}</WebUI.Text>
            }
          })()}
        </div>
      ))}
    </div>
  )
}

export default OrderSummaryPage
