import {
  ArrayParam,
  DelimitedNumericArrayParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import React, {useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import * as NextUI from '@cheddarup/web-ui/next'

export interface CategoryPathProps
  extends React.ComponentPropsWithoutRef<'div'> {
  categories: Api.PublicTabCategory[]
}

const CategoryPath = ({
  categories,
  className,
  ...restProps
}: CategoryPathProps) => {
  const [categoryPath, setCategoryPath] = useQueryParam(
    'categoryPath',
    withDefault(ArrayParam, []),
  )
  const crumbClassName = 'cursor-pointer'
  const [categoryId, subcategoryId] = categoryPath

  const category = categories.find((c) => String(c.id) === categoryId)
  const subcategory = (category?.options.subcategories ?? []).find(
    (sc) => sc.uuid === subcategoryId,
  )
  const crumbs = [
    category ? {key: category.id, title: category.name} : null,
    subcategory ? {key: subcategory.uuid, title: subcategory.name} : null,
  ].filter((c) => !!c)

  return (
    <div
      className={WebUI.cn(
        'flex items-center gap-1 py-4 text-ds-xs sm:gap-2 sm:text-ds-sm',
        className,
      )}
      {...restProps}
    >
      <CategoryFilterDropdown variant="text" categories={categories} />

      {crumbs.map((crumb, idx) => {
        const handleClick = () => {
          if (crumb.key === categoryId) {
            setCategoryPath([String(categoryId)])
          } else {
            setCategoryPath([String(categoryId), subcategoryId ?? null])
          }
        }

        const trailing = idx === crumbs.length - 1

        return (
          <React.Fragment key={crumb.key}>
            {idx === 0 && <span>{'>'}</span>}

            {idx === 0 && category?.options?.subcategories && (
              <WebUI.Menu>
                <WebUI.MenuButton
                  className={WebUI.cn(
                    crumbClassName,
                    trailing && 'font-semibold',
                  )}
                  variant="text"
                >
                  {crumb.title}
                </WebUI.MenuButton>

                <WebUI.MenuList>
                  {category.options.subcategories.map((subCategory) => (
                    <WebUI.MenuItem
                      key={subCategory.uuid}
                      onClick={() =>
                        setCategoryPath([String(categoryId), subCategory.uuid])
                      }
                    >
                      {subCategory.name}
                    </WebUI.MenuItem>
                  ))}
                </WebUI.MenuList>
              </WebUI.Menu>
            )}

            {idx === 0 &&
              category?.options &&
              !category.options.subcategories && (
                <NextUI.Ellipsis
                  className={WebUI.cn(
                    crumbClassName,
                    trailing && 'font-semibold',
                  )}
                >
                  {crumb.title} {crumb.title} {crumb.title}
                </NextUI.Ellipsis>
              )}

            {idx === 1 && (
              <NextUI.Ellipsis
                className={WebUI.cn(
                  crumbClassName,
                  trailing && 'font-semibold',
                )}
                onClick={handleClick}
              >
                {crumb.title}
              </NextUI.Ellipsis>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

// MARK: – CategoryFilterDropdown

interface CategoryFilterDropdownProps
  extends WebUI.MenuButtonProps,
    WebUI.ButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  categories: Api.PublicTabCategory[]
}

const CategoryFilterDropdown: React.FC<CategoryFilterDropdownProps> = ({
  categories,
  className,
  ...restProps
}) => {
  const [, setCategoryPath] = useQueryParam(
    'categoryPath',
    withDefault(DelimitedNumericArrayParam, []),
  )
  const [hoveredCategoryId, setHoveredCategoryId] = useState<number | null>(
    null,
  )

  return (
    <WebUI.Menu aria-label="Item categories">
      <WebUI.MenuButton
        className={WebUI.cn('font-semibold', className)}
        variant="outlined"
        {...restProps}
      >
        Shop All
      </WebUI.MenuButton>

      <WebUI.MenuList>
        <div className="flex">
          <div className="flex flex-col">
            <WebUI.MenuItem
              onMouseEnter={() => setHoveredCategoryId(null)}
              onClick={() => setCategoryPath([])}
            >
              Shop All
            </WebUI.MenuItem>
            {categories?.map((category) => (
              <WebUI.MenuItem
                key={category.id}
                onMouseEnter={() => setHoveredCategoryId(category.id)}
                onClick={() => setCategoryPath([category.id])}
              >
                {category.name}
              </WebUI.MenuItem>
            ))}
          </div>
          <div className="flex flex-col bg-grey-300">
            {hoveredCategoryId !== null &&
              categories
                ?.find(({id}) => id === hoveredCategoryId)
                ?.options.subcategories?.map((subcategory) => (
                  <WebUI.MenuItem
                    key={subcategory.uuid}
                    onClick={() =>
                      setCategoryPath([
                        hoveredCategoryId,
                        subcategory.uuid as any,
                      ])
                    }
                  >
                    {subcategory.name}
                  </WebUI.MenuItem>
                ))}
          </div>
        </div>
      </WebUI.MenuList>
    </WebUI.Menu>
  )
}

export default CategoryPath
