import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import React from 'react'

export interface TabObjectLimitMeterProps
  extends React.ComponentPropsWithoutRef<'div'>,
    Pick<
      Util.SetRequired<WebUI.MeterProps, 'value' | 'maxValue'>,
      'value' | 'maxValue'
    > {
  tabObjectType: 'item' | 'form'
}

export const TabObjectLimitMeter = React.forwardRef<
  HTMLDivElement,
  TabObjectLimitMeterProps
>(({tabObjectType, maxValue, value, ...restProps}, forwardedRef) => (
  <WebUI.Meter
    ref={forwardedRef}
    formatOptions={{style: 'decimal'}}
    label={
      <>
        <span className="font-bold">{value}</span> / {maxValue}{' '}
        {tabObjectType.toUpperCase()}S
      </>
    }
    value={value}
    maxValue={maxValue}
    error={
      value > maxValue && (
        <TabObjectLimitExceededLabel tabObjectType={tabObjectType} />
      )
    }
    {...restProps}
  />
))

// MARK: – TabObjectLimitExceededLabel

export interface TabObjectLimitExceededLabelProps
  extends WebUI.TextProps,
    React.ComponentPropsWithoutRef<'span'>,
    Pick<TabObjectLimitMeterProps, 'tabObjectType'> {}

export const TabObjectLimitExceededLabel = React.forwardRef<
  HTMLSpanElement,
  TabObjectLimitExceededLabelProps
>(({tabObjectType, className, ...restProps}, forwardedRef) => (
  <WebUI.Text
    ref={forwardedRef}
    className={WebUI.cn('text-ds-sm text-orange-500', className)}
    {...restProps}
  >
    {Util.capitalize(tabObjectType)} Limit Exceeded
  </WebUI.Text>
))
