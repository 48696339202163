import * as WebUI from '@cheddarup/web-ui'
import {tailwindConfig} from '@cheddarup/tailwind-config'
import {useParams} from 'react-router-dom'
import Papa from 'papaparse'
import React, {useMemo, useState} from 'react'
import {api} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'
import {Link} from 'src/components/Link'
import {PaymentNoteButtonDropdown, SearchForm} from 'src/components'

const PAYMENTS_PER_PAGE = 25

export interface CollectionManageShippingTableProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
}

const CollectionManageShippingTable = (
  props: React.ComponentPropsWithoutRef<'div'>,
) => {
  const urlParams = useParams()
  const [query, setQuery] = useState({
    direction: 'desc',
    search: '',
    sort: 'created_at',
    page: 0,
  })
  const paymentsQuery = api.tabPayments.list.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        tabId: urlParams.collection!,
      },
      queryParams: {
        ...(query as any),
        perPage: PAYMENTS_PER_PAGE,
        page: query.page + 1,
        shippable: true,
      },
    },
    {
      placeholderData: (prevData) => prevData,
    },
  )

  const {data: payments, pagination} = paymentsQuery.data ?? {
    data: [] as Api.TabPayment[],
    pagination: {},
  }

  const columnHelpers = useMemo(
    () => WebUI.createColumnHelper<Api.TabPayment>(),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelpers.accessor((p) => p.tab_member.name, {
        id: 'tab_members.last_name',
        meta: {
          subtle: false,
        },
        header: 'Name',
        cell: ({cell, row: {original: payment}}) => (
          <WebUI.VStack className="min-w-0">
            <WebUI.Ellipsis>{cell.getValue()}</WebUI.Ellipsis>
            {payment.note && (
              <WebUI.DeprecatedTooltip label={payment.note}>
                <PaymentNoteButtonDropdown variant="text" payment={payment}>
                  <span className="line-clamp-2 font-light text-ds-sm text-gray600 italic">
                    {payment.note}
                  </span>
                </PaymentNoteButtonDropdown>
              </WebUI.DeprecatedTooltip>
            )}
          </WebUI.VStack>
        ),
      }),
      columnHelpers.display({
        id: 'address',
        meta: {
          subtle: false,
        },
        header: 'Address',
        cell: ({row}) => {
          const shipToAddress = row.original.shipment?.shipTo ?? {
            ...row.original.shipping_info?.shipTo,
            street1: row.original.shipping_info?.shipTo?.address ?? '',
          }
          return (
            <WebUI.VStack className="min-w-0">
              {shipToAddress.street1 && (
                <WebUI.Ellipsis className="font-light text-ds-sm">
                  {shipToAddress.street1}
                </WebUI.Ellipsis>
              )}
              <WebUI.Ellipsis className="font-light text-ds-sm">
                {shipToAddress.city ?? ''}
                {', '}
                {shipToAddress.state ?? ''}
                {', '}
                {shipToAddress.zip ?? ''}
              </WebUI.Ellipsis>
              <WebUI.Ellipsis className="font-light text-ds-sm">
                {shipToAddress.country ?? ''}
              </WebUI.Ellipsis>
            </WebUI.VStack>
          )
        },
      }),
      columnHelpers.accessor(
        (p) => (p.shipment?.purchased ? 'Purchased' : 'Not Purchased'),
        {
          id: 'status',
          size: 100,
          header: 'Label Status',
          cell: ({row}) => {
            const shipToCountry =
              row.original.shipment?.shipTo?.country ??
              row.original.shipping_info?.shipTo?.country

            return shipToCountry === 'US' ? (
              <LinkButton
                size="compact"
                variant={
                  row.original.shipment?.purchased ? 'secondary' : 'default'
                }
                to={
                  row.original.shipment?.purchased
                    ? `print-shipping-label-summary/${row.original.id}`
                    : `print-shipping-label/${row.original.id}`
                }
              >
                {row.original.shipment?.purchased
                  ? 'View Label'
                  : 'Print Label'}
              </LinkButton>
            ) : null
          },
        },
      ),
      columnHelpers.display({
        id: 'packingSlipLink',
        size: 100,
        cell: ({row}) => (
          <LinkButton
            size="compact"
            variant="secondary"
            target="_blank"
            to={`/pdf/collection/${row.original.tab_id}/tab-payment/${row.original.id}`}
            iconBefore={
              <WebUI.PhosphorIcon
                className="text-gray800"
                icon="download-simple"
              />
            }
          >
            Packing Slip
          </LinkButton>
        ),
      }),
      columnHelpers.display({
        id: 'note',
        meta: {
          align: 'right',
        },
        cell: ({row: {original: payment}}) => (
          <WebUI.HStack className="min-w-0 gap-3">
            <ShippingActionsDropdown payment={payment} />
          </WebUI.HStack>
        ),
      }),
    ],
    [columnHelpers],
  )

  const paginationState = useMemo(
    () => ({
      pageSize: PAYMENTS_PER_PAGE,
      pageIndex: query.page,
    }),
    [query.page],
  )
  const sortingState = useMemo(
    () => [{id: query.sort, desc: query.direction === 'desc'}],
    [query.direction, query.sort],
  )

  const pageCount = Math.ceil((pagination.total ?? 0) / PAYMENTS_PER_PAGE)

  return (
    <WebUI.Panel as={WebUI.VStack} {...props}>
      {paymentsQuery.isSuccess &&
      payments.length === 0 &&
      query.search.length === 0 ? (
        <NoPayments />
      ) : (
        <WebUI.TableView<Api.TabPayment>
          className="[&_.TableView-headerGroup]:px-8 [&_.TableViewRow]:px-8"
          columns={columns}
          loading={paymentsQuery.isPending}
          data={(payments ?? []) as any[]}
          state={{
            pagination: paginationState,
            sorting: sortingState,
          }}
          pageCount={pageCount}
          manualPagination
          onPaginationChange={(updater) => {
            const newPagination =
              typeof updater === 'function' ? updater(paginationState) : updater

            setQuery((prevQuery) => ({
              ...prevQuery,
              page: newPagination.pageIndex,
            }))
          }}
          sortable
          sortByTogglesVisible
          disableSortRemove
          manualSortBy
          onSortingChange={(updater) => {
            const [newSorting] =
              typeof updater === 'function' ? updater(sortingState) : updater

            if (newSorting) {
              setQuery((prevQuery) => ({
                ...prevQuery,
                direction: newSorting.desc ? 'desc' : 'asc',
                sort: newSorting.id,
              }))
            }
          }}
        >
          <WebUI.VStack
            className={
              '-order-1 items-stretch justify-center gap-4 border-b px-8 py-4 sm:flex-row sm:items-center sm:justify-between'
            }
          >
            <WebUI.HStack className="items-center gap-4">
              <WebUI.Text className="text-ds-sm">
                Shipments: {pagination.total ?? 0}
              </WebUI.Text>
              <SearchForm
                size="compact"
                iconClassName="text-gray400"
                className="min-w-[250px] text-ds-xs placeholder:text-ds-xs"
                initialValues={{term: query.search}}
                onSubmit={(values) => {
                  if (values.term !== query.search) {
                    setQuery((prevQuery) => ({
                      ...prevQuery,
                      search: values.term,
                    }))
                  }
                }}
                noResult={
                  query.search.length > 0 &&
                  paymentsQuery.isSuccess &&
                  payments.length === 0
                }
                placeholder="Search by payer name or email"
              />
            </WebUI.HStack>

            <WebUI.DeprecatedTooltip label="Download Shipping Summary (.csv)">
              <WebUI.IconButton
                className="h-[30px] w-[30px] text-ds-md"
                size="default_alt"
                aria-label="Download Shipping Summary (.csv)"
                variant="secondary"
                onClick={() =>
                  WebUI.downloadFile(
                    new Blob(
                      [makeItemsCSV((paymentsQuery.data?.data ?? []) as any[])],
                      {type: 'data:text/csv;charset=utf-8'},
                    ),
                    'shipping.csv',
                  )
                }
              >
                <WebUI.PhosphorIcon icon="download-simple" />
              </WebUI.IconButton>
            </WebUI.DeprecatedTooltip>
          </WebUI.VStack>

          {pageCount > 1 && (
            <div className="flex justify-end px-8 py-4">
              <WebUI.TablePaginator />
            </div>
          )}
        </WebUI.TableView>
      )}
    </WebUI.Panel>
  )
}

// MARK: – NoPayments

const NoPayments = () => (
  <div className="py-16 text-center">
    <h2 className="font-light text-gray800">
      You don&apos;t have any shipments.
      <br />
      Share your collection!
    </h2>
    <Link className="mt-8" to="../share">
      <WebUI.PlanePlusSolidIcon
        className="h-[100px]"
        fill={tailwindConfig.theme.colors.orange[500]}
      />
    </Link>
  </div>
)

// MARK: – ShippingActionsDropdown

interface ShippingActionsDropdownProps
  extends React.ComponentPropsWithoutRef<'button'> {
  payment: Api.TabPayment
}

const ShippingActionsDropdown = React.forwardRef<
  HTMLButtonElement,
  ShippingActionsDropdownProps
>(({payment, className, ...restProps}, forwardedRef) => {
  const urlParams = useParams()
  return (
    <WebUI.Menu placement="bottom-start">
      <WebUI.MenuButton
        ref={forwardedRef}
        className={WebUI.cn(
          'rounded-full bg-depr-grey-200 text-gray400',
          className,
        )}
        as={WebUI.IconButton}
        size="compact"
        {...restProps}
      >
        <WebUI.PhosphorIcon icon="dots-three-outline-fill" width={25} />
      </WebUI.MenuButton>

      <WebUI.MenuList className="font-light text-ds-sm" shrinkable={false}>
        <WebUI.MenuItem
          as={Link}
          variant="default"
          to={`/collection/${urlParams.collection}/manage/collection/${urlParams.collection}/payment/${payment.id}`}
        >
          Order Summary
        </WebUI.MenuItem>

        <PaymentNoteButtonDropdown
          className="px-[1em] text-left"
          variant="ghost"
          payment={payment}
        >
          Add Note
        </PaymentNoteButtonDropdown>
      </WebUI.MenuList>
    </WebUI.Menu>
  )
})

// MARK: – Helpers

const makeItemsCSV = (payments: Api.TabPayment[]) =>
  Papa.unparse({
    fields: [
      'Name',
      'Street Address',
      'City',
      'State',
      'Zip',
      'Label Status',
      'Notes',
      'Label Cost',
    ],
    data: payments.map((payment) => {
      if (payment.shipment) {
        return [
          payment.shipment.shipTo.name,
          payment.shipment.shipTo.street1,
          payment.shipment.shipTo.city,
          payment.shipment.shipTo.state,
          payment.shipment.shipTo.zip,
          payment.shipment.purchased ? 'Purchased' : 'Not Purchased',
          payment.note ?? '',
          payment.shipment.charge,
        ]
      }

      return [
        payment.shipping_info?.shipTo.name,
        payment.shipping_info?.shipTo.address,
        payment.shipping_info?.shipTo.city,
        payment.shipping_info?.shipTo.state,
        payment.shipping_info?.shipTo.zip,

        'Not Purchased',
        payment.note ?? '',
        '',
      ]
    }),
  })

export default CollectionManageShippingTable
