import * as WebUI from '@cheddarup/web-ui'

const HEIGHT = 36

export interface ProgressBarProps
  extends React.ComponentPropsWithoutRef<'div'> {
  progress: number
}

const ProgressBar = ({
  progress,
  className,
  style,
  ...restProps
}: ProgressBarProps) => (
  <div
    className={WebUI.cn('border bg-trueWhite', className)}
    style={{
      height: HEIGHT,
      ...style,
    }}
    {...restProps}
  >
    <div className="bg-tint" style={{height: HEIGHT, width: `${progress}%`}} />
  </div>
)

export default ProgressBar
