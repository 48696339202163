import 'react-image-crop/dist/ReactCrop.css'
import {useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import {
  PixelCrop,
  ReactCrop,
  ReactCropProps,
  centerCrop,
  defaultCrop,
  makeAspectCrop,
} from 'react-image-crop'
import React, {useRef, useState} from 'react'

import {Image} from './Image'

export interface ImageCropArea {
  x: number
  y: number
  width: number
  height: number
}

export interface ImageCropProps
  extends Omit<ReactCropProps, 'crop' | 'onChange'> {
  src?: string
  width?: number | string | null
  height?: number | string
  initialCrop?: ImageCropArea | null
  onCropChange?: (newCrop: ImageCropArea | null) => void
}

export const ImageCrop = React.forwardRef<ReactCrop, ImageCropProps>(
  (
    {
      keepSelection = true,
      initialCrop,
      onCropChange,
      width = 400,
      height = 260,
      src,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [crop, setCrop] = useState<PixelCrop>(defaultCrop)
    const scaleRef = useRef<{x: number; y: number}>({x: 1, y: 1})
    const onCropChangeRef = useLiveRef(onCropChange)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useUpdateEffect(() => {
      onCropChangeRef.current?.(null)
    }, [src])

    useUpdateEffect(() => {
      onCropChangeRef.current?.({
        x: scaleRef.current.x * (crop?.x ?? 0),
        y: scaleRef.current.y * (crop?.y ?? 0),
        width: scaleRef.current.x * (crop?.width ?? 0),
        height: scaleRef.current.y * (crop?.height ?? 0),
      })
    }, [crop])

    return (
      <ReactCrop
        ref={forwardedRef}
        keepSelection={keepSelection}
        crop={crop}
        aspect={1}
        onChange={(pixelCrop) => setCrop(pixelCrop)}
        {...restProps}
      >
        <Image
          className="[&_>_.Image-image]:!h-auto [&_>_.Image-image]:!w-auto h-auto w-auto bg-trueWhite"
          style={{
            maxWidth: width ?? undefined,
            maxHeight: height,
          }}
          width={width ?? undefined}
          height={height}
          alt="Uploaded"
          src={src}
          onLoad={(event) => {
            const imageEl = event.currentTarget
            scaleRef.current = {
              x: imageEl.naturalWidth / imageEl.width,
              y: imageEl.naturalHeight / imageEl.height,
            }

            setCrop(
              initialCrop
                ? {
                    unit: 'px',
                    ...initialCrop,
                    x: initialCrop.x / scaleRef.current.x,
                    y: initialCrop.y / scaleRef.current.y,
                    width: initialCrop.width / scaleRef.current.x,
                    height: initialCrop.height / scaleRef.current.y,
                  }
                : (centerCrop(
                    makeAspectCrop(
                      {
                        unit: 'px',
                        width: event.currentTarget.width,
                      },
                      1,
                      event.currentTarget.width,
                      event.currentTarget.height,
                    ),
                    event.currentTarget.width,
                    event.currentTarget.height,
                  ) as PixelCrop),
            )
          }}
        />
      </ReactCrop>
    )
  },
)
