import {forwardRef} from 'react'
import ReactPaginate, {ReactPaginateProps} from 'react-paginate'

import {PhosphorIcon} from '../icons'
import {cn} from '../utils'

const CONTROL_SIZE = 16

export interface PaginatorProps
  extends Partial<
    Pick<
      ReactPaginateProps,
      'pageRangeDisplayed' | 'marginPagesDisplayed' | 'onPageChange'
    >
  > {
  page?: ReactPaginateProps['initialPage']
  perPage?: number
  total?: number
  pageCount?: number
}

const Paginator = forwardRef<HTMLDivElement, PaginatorProps>(
  (
    {
      marginPagesDisplayed = 2,
      onPageChange,
      page,
      perPage,
      total,
      pageCount,
      pageRangeDisplayed = 5,
    },
    forwardedRef,
  ) => {
    const linkClassName =
      'flex justify-center items-center h-8 w-8 text-center leading-[32px] rounded outline-none cursor-pointer'

    return (
      <div ref={forwardedRef}>
        <ReactPaginate
          containerClassName="gap-1 flex items-center m-0 [margin-block-start:0px] [margin-block-end:0px] p-1 rounded border"
          activeClassName="rounded bg-depr-aqua-100"
          breakClassName="text-gray800 cursor-default"
          pageLinkClassName="text-ds-sm text-gray800"
          nextLinkClassName={cn(linkClassName, 'text-gray400')}
          previousLinkClassName={cn(linkClassName, 'text-gray400')}
          disableInitialCallback
          initialPage={page}
          marginPagesDisplayed={marginPagesDisplayed}
          nextLabel={
            <PhosphorIcon icon="caret-right-fill" width={CONTROL_SIZE} />
          }
          onPageChange={onPageChange}
          pageCount={
            pageCount ?? (perPage ? Math.ceil(total ?? 0 / perPage) : 1)
          }
          pageRangeDisplayed={pageRangeDisplayed}
          previousLabel={
            <PhosphorIcon icon="caret-left-fill" width={CONTROL_SIZE} />
          }
        />
      </div>
    )
  },
)

export default Paginator
