import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {NumberParam, StringParam, useQueryParam} from 'use-query-params'
import {useParams} from 'react-router-dom'
import React, {useState} from 'react'
import {api} from '@cheddarup/api-client'
import {CollectionCombobox} from 'src/components/CollectionCombobox'
import {ContactsAutosuggestCombobox} from 'src/components/ContactsAutosuggestCombobox'
import {
  useManagerRole,
  useManagerRoleQuery,
} from 'src/components/ManageRoleProvider'

import {MessageHistoryNav} from './MessageHistoryNav'
import {CancelReminderAlert} from './CancelReminderAlert'
import {DeliveryReportView} from './DeliveryReportView'

export interface MessageHistoryViewFilters {
  collectionId: number | null
  messageType: Api.MessageType | null
}

export const MessageHistoryView = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'div'>) => {
  const urlParams = useParams()
  const [view] = useQueryParam('view', StringParam)
  const [filters, setFilters] = useState<MessageHistoryViewFilters>({
    collectionId: urlParams.collection ? Number(urlParams.collection) : null,
    messageType: null,
  })
  const [email, setEmail] = useState('')

  if (view === 'delivery-report') {
    return <DeliveryReportView collectionId={filters.collectionId} />
  }

  return (
    <WebUI.VStack className={WebUI.cn('gap-5', className)} {...restProps}>
      <MessageHistoryViewHeaderPanel
        filters={filters}
        onFiltersChange={(newPartialFilters) =>
          setFilters((prevFilters) => ({
            ...prevFilters,
            ...newPartialFilters,
          }))
        }
        onEmailChange={(newEmail) => setEmail(newEmail)}
      />
      <MessageHistoryViewPanel filters={filters} email={email} />
    </WebUI.VStack>
  )
}

// MARK: – MessageHistoryViewHeaderPanel

interface MessageHistoryViewHeaderPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  filters: MessageHistoryViewFilters
  onFiltersChange: (filters: Partial<MessageHistoryViewFilters>) => void
  onEmailChange: (email: string) => void
}

const MessageHistoryViewHeaderPanel = ({
  filters,
  onFiltersChange,
  onEmailChange,
  className,
  ...restProps
}: MessageHistoryViewHeaderPanelProps) => {
  const collectionsQuery = api.tabs.list.useSuspenseQuery({
    queryParams: {
      filter: 'history',
    },
  })
  const [managerRole] = useManagerRole()
  const hasGroupPageQuery = api.auth.session.useQuery(undefined, {
    select: (session) =>
      session.user.profile.uiClientFlags != null &&
      session.capabilities.subscribed_to_team,
  })

  const hasGroupPage = managerRole
    ? !!managerRole.permissions.group_page
    : !!hasGroupPageQuery.data

  return (
    <WebUI.VStack
      className={WebUI.cn('gap-5 p-7', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <WebUI.Heading className="font-light text-ds-md" as="h2">
        View Message History
      </WebUI.Heading>
      <WebUI.VStack className="gap-3 sm:flex-row sm:gap-6">
        <WebUI.FormField label="Choose a collection">
          <CollectionCombobox
            className="w-[310px]"
            popoverClassName="max-w-[480px]"
            additionalOptions={[
              {
                value: '',
                label: 'All',
              },
              hasGroupPage
                ? {
                    label: 'Group Page',
                    value: '-1',
                  }
                : undefined,
            ].filter((ci) => ci != null)}
            collections={collectionsQuery.data}
            selectedCollectionId={filters.collectionId}
            onSelectedCollectionIdChange={(newSelectedCollectionId) =>
              onFiltersChange({collectionId: newSelectedCollectionId || null})
            }
          />
        </WebUI.FormField>
        <WebUI.FormField label="Message Type" className="grow">
          <WebUI.Select
            name="messageType"
            size="compact"
            value={filters.messageType ?? ''}
            onChange={(event) =>
              onFiltersChange({
                messageType:
                  (event.target.value as Api.MessageType | '') || null,
              })
            }
          >
            <option value="">All</option>
            <option value="message">Message</option>
            <option value="reminder_parent">Automatic Reminder</option>
            <option value="invite">Invitation</option>
          </WebUI.Select>
        </WebUI.FormField>

        <WebUI.FormField
          className="self-center"
          label={<br className="hidden sm:inline" />}
        >
          <WebUI.Text className="text-ds-sm">OR</WebUI.Text>
        </WebUI.FormField>

        <WebUI.FormField label={<br className="hidden sm:inline" />}>
          <ContactsAutosuggestCombobox
            className="h-full sm:w-[310px]"
            size="compact"
            chevronIconName="magnifying-glass"
            field="nameOrEmail"
            placeholder="Search by recipient name or email"
            onSelectedContactChange={(contact) =>
              onEmailChange(contact?.email ?? '')
            }
          />
        </WebUI.FormField>
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

// MARK: – MessageHistoryViewPanel

interface MessageHistoryViewPanelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  filters: MessageHistoryViewFilters
  email: string
}

const MessageHistoryViewPanel = ({
  filters,
  email,
  className,
  ...restProps
}: MessageHistoryViewPanelProps) => {
  const media = WebUI.useMedia()
  return (
    <WebUI.VStack
      className={WebUI.cn('min-h-[400px] sm:flex-row', className)}
      as={WebUI.Panel}
      {...restProps}
    >
      <MessageHistoryNav
        className="max-h-[540px] flex-0 overflow-y-auto sm:w-[min(100%,320px)]"
        filters={filters}
        email={email}
      />
      <WebUI.Separator
        orientation={media.sm ? 'vertical' : 'horizontal'}
        variant="primary"
      />
      <MessageDetailView className="grow" collectionId={filters.collectionId} />
    </WebUI.VStack>
  )
}

// MARK: – MessageDetailView

interface MessageDetailViewProps extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number | null
}

const MessageDetailView = ({
  collectionId,
  ...props
}: MessageDetailViewProps) => {
  const [messageId, setMessageId] = useQueryParam('messageId', NumberParam)
  const [managerRoleQuery] = useManagerRoleQuery()
  const [, setView] = useQueryParam('view', StringParam)
  const [, setPaneKey] = useQueryParam('mcp', StringParam)
  const [, setMessageIdToDuplicate] = useQueryParam(
    'messageIdToDuplicate',
    NumberParam,
  )
  const [, setMessageToDuplicateTabId] = useQueryParam(
    'messageToDuplicateTabId',
    NumberParam,
  )
  const messageQuery = api.messages.detail.useQuery(
    {
      pathParams: {
        // biome-ignore lint/style/noNonNullAssertion:
        messageId: messageId!,
      },
      queryParams: {
        tab_id: collectionId,
      },
    },
    {
      enabled: typeof messageId === 'number',
    },
  )
  const childMessagesQuery = api.messages.list.useQuery(
    {
      queryParams: {
        tab_id: collectionId,
        reminder_id: messageQuery.data?.child_reminder?.id,
      },
    },
    {
      enabled:
        !!messageQuery.data?.child_reminder?.id &&
        !managerRoleQuery.isPending &&
        (!managerRoleQuery.data ||
          !!managerRoleQuery.data.permissions.address_book_and_message_center),
    },
  )
  const [childMessageId, setChildMessageId] = useState<string | null>(null)

  if (!messageQuery.data) {
    return null
  }

  return (
    <div {...props}>
      <WebUI.VStack className="gap-8 p-10">
        <WebUI.VStack className="gap-0_5 text-ds-sm">
          <span>
            <span className="font-medium">Collection:</span>{' '}
            {messageQuery.data.tab?.name ?? 'Group Page'}
          </span>
          {!messageQuery.data.detail.legacy && (
            <>
              {!!messageQuery.data.detail.subject && (
                <span>
                  <span className="font-medium">Subject:</span>{' '}
                  {messageQuery.data.detail.subject}
                </span>
              )}

              {messageQuery.data.message_type !== 'reminder_parent' && (
                <span>
                  <span className="font-medium">Sent:</span>{' '}
                  {messageQuery.data.send_at &&
                    Util.formatDate(
                      new Date(messageQuery.data.send_at),
                      'MM/dd/yy, hh:mm a',
                    )}
                </span>
              )}
            </>
          )}
          {messageQuery.data.message_type !== 'reminder_parent' && (
            <span>
              <span className="font-medium">To:</span>{' '}
              {messageQuery.data.detail.recipients
                .slice(0, 3)
                .map((r) => r.name || r.email)
                .join(', ')}
              {messageQuery.data.detail.recipients.length > 3 && (
                <>
                  {' '}
                  <WebUI.Button
                    variant="link"
                    onClick={() => setView('delivery-report')}
                  >
                    {`& ${messageQuery.data.detail.recipients.length - 3} more`}
                  </WebUI.Button>
                </>
              )}
            </span>
          )}
        </WebUI.VStack>

        {messageQuery.data.message_type === 'reminder_parent' &&
          messageQuery.data.child_reminder && (
            <WebUI.VStack className="gap-0_5 text-ds-sm">
              <span>
                <span className="font-medium">Start Reminder:</span>{' '}
                {messageQuery.data?.child_reminder.start_at &&
                  Util.formatDate(
                    new Date(messageQuery.data.child_reminder.start_at),
                    'MM/dd/yy',
                  )}
              </span>
              <span>
                <span className="font-medium">End Reminder:</span>{' '}
                <span className="pr-2">
                  {messageQuery.data.child_reminder.end_at &&
                    Util.formatDate(
                      new Date(messageQuery.data.child_reminder.end_at),
                      'MM/dd/yy',
                    )}
                </span>
                {messageQuery.data.child_reminder.status === 'active' ? (
                  <CancelReminderAlert
                    disclosure={
                      <WebUI.DialogDisclosure
                        variant="link"
                        className="text-ds-sm"
                      >
                        Cancel Reminder
                      </WebUI.DialogDisclosure>
                    }
                    reminderId={messageQuery.data.child_reminder.id}
                    onCancel={messageQuery.refetch}
                  />
                ) : (
                  <span className="italic">
                    (Reminder cancelled{' '}
                    {Util.formatDate(
                      new Date(messageQuery.data.child_reminder?.next_send_at),
                      'MM/dd/yy',
                    )}
                    )
                  </span>
                )}
              </span>
              {!!messageQuery.data.child_reminder.repeat_seconds && (
                <span className="capitalize">
                  <span className="font-medium">Repeats every:</span>{' '}
                  {
                    {
                      604800: 'weekly',
                      2592000: 'monthly',
                      31536000: 'yearly',
                    }[messageQuery.data.child_reminder.repeat_seconds]
                  }
                </span>
              )}
              <span>
                <span className="font-medium">Remind:</span>{' '}
                {messageQuery.data.child_reminder.recipients ===
                'invited_but_not_paid'
                  ? 'Invited but not paid'
                  : 'Everyone'}
                <WebUI.DeprecatedTooltip
                  className="inline"
                  variant="light"
                  label={
                    messageQuery.data.child_reminder.recipients ===
                    'invited_but_not_paid'
                      ? "This is an option when you use Cheddar Up's Invite feature. Reminders will be sent only to those who were invited and have not paid."
                      : 'Reminders will be sent to everyone (anyone who was invited and/or has made a payment).'
                  }
                >
                  <WebUI.PhosphorIcon
                    className="relative top-1 ml-2 fill-grey-300"
                    icon="question-fill"
                    width={16}
                  />
                </WebUI.DeprecatedTooltip>
              </span>
            </WebUI.VStack>
          )}

        <WebUI.HStack className="gap-4">
          {messageQuery.data.message_type === 'reminder_parent' &&
            (childMessagesQuery.data &&
            childMessagesQuery.data.data.length === 0 ? (
              <WebUI.Text className="self-center text-orange-500">
                No messages sent
              </WebUI.Text>
            ) : (
              <WebUI.Select
                className="w-[200px]"
                name="reminderDate"
                size="compact"
                value={childMessageId ?? ''}
                onChange={(event) => setChildMessageId(event.target.value)}
              >
                <option disabled value="">
                  Select date
                </option>
                {(childMessagesQuery.data?.data || []).map(
                  (message) =>
                    message && (
                      <option
                        key={message.id.toString()}
                        value={message.id.toString()}
                      >
                        Sent:{' '}
                        {Util.formatDate(
                          new Date(message.send_at),
                          'MM/dd/yy, hh:mm a',
                        )}
                      </option>
                    ),
                )}
              </WebUI.Select>
            ))}
          <WebUI.Button
            variant="default"
            disabled={
              messageQuery.data.message_type === 'reminder_parent' &&
              !childMessageId
            }
            onClick={() => {
              setView('delivery-report')
              setMessageId(
                childMessageId ? Number(childMessageId) : messageQuery.data.id,
              )
            }}
          >
            Delivery Report
          </WebUI.Button>
          {!messageQuery.data.detail.legacy &&
            messageQuery.data.message_type !== 'reminder_parent' && (
              <WebUI.Button
                type="button"
                variant="secondary"
                iconBefore={
                  <WebUI.PhosphorIcon icon="copy-simple" width={18} />
                }
                onClick={() => {
                  setPaneKey('send')
                  setMessageIdToDuplicate(messageQuery.data.id)
                  setMessageToDuplicateTabId(messageQuery.data.tab_id)
                }}
              >
                Duplicate
              </WebUI.Button>
            )}
        </WebUI.HStack>
      </WebUI.VStack>

      <WebUI.Separator />

      <div className="p-10 text-ds-sm">
        {messageQuery.data.detail.legacy ? (
          <div className="italic">
            The content of this Invitation is not available as it was sent prior
            to the Message Center release.
          </div>
        ) : messageQuery.data.detail.body ? (
          <>
            <WebUI.MarkdownPreview
              key={messageQuery.data.id}
              markdown={messageQuery.data.detail.body}
            />
            {messageQuery.data.detail.attachments.length > 0 && (
              <span className="italic">
                Attachments:
                {messageQuery.data.detail.attachments
                  .map((a) => a.file_name)
                  .join(', ')}
              </span>
            )}
          </>
        ) : null}
      </div>
    </div>
  )
}
