import * as WebUI from '@cheddarup/web-ui'
import React from 'react'
import GPOverviewSlideLady from 'src/images/GPOverviewSlideLady.png'
import GPTopBannerSlideBanner from 'src/images/GPTopBannerSlideBanner.png'
import GPCollectionsSlideBanner from 'src/images/GPCollectionsSlideBanner.png'
import GPCollectionSpotlightSlideBanner from 'src/images/GPCollectionSpotlightSlideBanner.png'
import GPAboutUsSlideBanner from 'src/images/GPAboutUsSlideBanner.png'
import GPTeamSlideBanner from 'src/images/GPTeamSlideBanner.png'
import GPSponsorsSlideBanner from 'src/images/GPSponsorsSlideBanner.png'
import {useNavigate} from 'react-router-dom'
import {StringParam, useQueryParam, withDefault} from 'use-query-params'

interface GroupPageQuickTourModalProps extends WebUI.ModalProps {}

const GroupPageQuickTourModal: React.FC<GroupPageQuickTourModalProps> = ({
  className,
  ...restProps
}) => {
  const navigate = useNavigate()
  const [slideId] = useQueryParam('slide', withDefault(StringParam, 'overview'))

  return (
    <WebUI.Modal
      className={WebUI.cn(
        '[&_>_.ModalContentView]:max-w-[850px] [&_>_.ModalContentView]:rounded-[16px] [&_>_.ModalContentView]:px-8 [&_>_.ModalContentView]:pt-10 [&_>_.ModalContentView]:pb-13 sm:[&_>_.ModalContentView]:h-[700px] sm:[&_>_.ModalContentView]:max-h-[700px] sm:[&_>_.ModalContentView]:px-12',
        className,
      )}
      onDidHide={() => navigate('..')}
      {...restProps}
    >
      <WebUI.Carousel
        className="flex grow flex-col justify-between"
        options={{
          loop: true,
          startIndex:
            SECTION_ID_TO_SLIDE_INDEX_MAP[slideId as QuickTourSlideId] ?? 0,
        }}
      >
        <WebUI.CarouselContent>
          <WebUI.CarouselItem>
            <GroupPageOverViewSlide />
          </WebUI.CarouselItem>
          {SECTIONS_OVERVIEW.map((section) => (
            <WebUI.CarouselItem key={section.sectionName}>
              <GroupPageSectionSlide {...section} />
            </WebUI.CarouselItem>
          ))}
        </WebUI.CarouselContent>
        <div className="mt-3 flex flex-col items-center gap-6 sm:mt-0">
          <WebUI.CarouselStepper className="relative right-auto bottom-auto left-auto h-3 gap-5 [&_>_.CarouselStep]:min-w-3 [&_>_.CarouselStep]:max-w-3 [&_>_.CarouselStep]:bg-grey-300 [&_>_.CarouselStep]:opacity-100 aria-selected:[&_>_.CarouselStep]:bg-orange-500" />
          <WebUI.Button
            className="top-auto w-[120px] translate-y-0"
            variant="primary"
            as={WebUI.CarouselNext}
          >
            Next
          </WebUI.Button>
        </div>
      </WebUI.Carousel>
      <WebUI.ModalCloseButton className="text-gray400" />
    </WebUI.Modal>
  )
}

// MARK: - GroupPageOverViewSlide

const GroupPageOverViewSlide = () => (
  <div className="flex flex-col gap-7 pt-6">
    <WebUI.Heading as="h1" className="text-ds-3xl">
      All about your Group Page
    </WebUI.Heading>
    <div className="flex flex-col items-center sm:flex-row">
      <div className="flex flex-col gap-7">
        <WebUI.Text className="font-extrabold">
          Showcase your group’s collections, mission, and team members in one
          custom-branded spot.
        </WebUI.Text>
        <WebUI.Text className="font-light text-ds-md">
          Your Group Page gives your community access to all your featured
          collections and so much more. It’s your very own webpage for
          fundraisers, events, and registrations — plus important details about
          your community. Include sections that highlight your organization’s
          mission, team members, sponsors and more.{' '}
          <WebUI.Anchor
            className="[&_>_.Text]:font-light"
            href="https://my.cheddarup.com/me/panoramahspto"
            target="_blank"
            rel="noopener noreferrer"
          >
            See an example Group Page
          </WebUI.Anchor>
        </WebUI.Text>
        <WebUI.Text className="font-light text-ds-md">
          Click “Next” to explore the Group Page sections available to you.
        </WebUI.Text>
      </div>
      <WebUI.Image
        className="shrink-0"
        src={GPOverviewSlideLady}
        alt=""
        height="auto"
        width="auto"
      />
    </div>
  </div>
)

// MARK: - GroupPageSectionSlide

interface SectionSlideProps extends React.ComponentPropsWithoutRef<'div'> {
  sectionName: string
  heading: string
  description: string
  banner: React.ReactNode
}

const GroupPageSectionSlide: React.FC<SectionSlideProps> = ({
  className,
  sectionName,
  heading,
  description,
  banner,
  ...restProps
}) => (
  <div
    className={WebUI.cn('flex flex-col items-center gap-8', className)}
    {...restProps}
  >
    <div className="flex flex-col gap-2">
      <div>
        <WebUI.Text className="text-sm">Group Page Sections</WebUI.Text>
        <WebUI.Heading as="h2">{sectionName}</WebUI.Heading>
      </div>
      <WebUI.Text className="font-light">
        <span className="font-extrabold">{heading}</span>
        <br />
        {description}{' '}
        <WebUI.Anchor
          className="[&_>_.Text]:font-light"
          href="https://my.cheddarup.com/me/panoramahspto"
          target="_blank"
          rel="noopener noreferrer"
        >
          See an example
        </WebUI.Anchor>
      </WebUI.Text>
    </div>
    {banner}
  </div>
)

// MARK: - TotalCollectedSlideBanner

const TotalCollectedSlideBanner = () => (
  <div className="flex w-[530px] max-w-full flex-col gap-1 rounded-[16px] bg-[#F0B831] p-12 text-depr-grey-50">
    <WebUI.Heading className="font-accentAlt text-h-6">
      Thanks to you, we’ve collected
    </WebUI.Heading>
    <WebUI.Heading className="font-body font-extrabold text-h-5">
      $<WebUI.NumberTicker value={300.3} />
    </WebUI.Heading>
    <WebUI.Text className="text-ds-sm">Year-to-Date</WebUI.Text>
  </div>
)

// MARK: – Constants

const SECTION_ID_TO_SLIDE_INDEX_MAP = {
  overview: 0,
  'top-banner': 1,
  collections: 2,
  spotlight: 3,
  'total-collected': 4,
  'about-us': 5,
  team: 6,
  sponsors: 7,
}

export const SECTIONS_OVERVIEW = [
  {
    id: 'top-banner',
    sectionName: 'Top Banner',
    heading: 'Showcase your group with beautiful imagery',
    description: `Your top banner is the place to create visual impact. Here you can
  feature your group’s icon or logo on top of a beautiful background
  image, combined with headline and tagline text of your choosing.`,
    banner: <WebUI.Image src={GPTopBannerSlideBanner} alt="" />,
  },
  {
    id: 'collections',
    sectionName: 'Collections',
    heading: 'Feature all your relevant collections in one spot',
    description:
      'Collect more by adding all of your current and upcoming collections to your Group Page. You decide which collections to put in front of your community. Even control the order of your collections or place them in different categories.',
    banner: <WebUI.Image src={GPCollectionsSlideBanner} alt="" />,
  },
  {
    id: 'spotlight',
    sectionName: 'Collection Spotlight',
    heading: 'Put extra emphasis on specific collections',
    description:
      'Call even more attention to certain collections with our Collection Spotlight. Here you can create a carousel of three different collections that deserve a timely highlighting — allowing you to add a headline, description, feature image and custom button text for each spotlight.',
    banner: <WebUI.Image src={GPCollectionSpotlightSlideBanner} alt="" />,
  },
  {
    id: 'total-collected',
    sectionName: 'Total Collected',
    heading: 'Shine a light on your hard work and progress!',
    description:
      'Let your community know how much you’ve collected. You decide the time frame (year to date, all time, etc.) and we’ll do the math. Customize this section with header and description text and we’ll add the total collected across all your collections.',
    banner: <TotalCollectedSlideBanner />,
  },
  {
    id: 'about-us',
    sectionName: 'About Us',
    heading: 'Give your page depth with a detailed About section',
    description:
      'Adding thoughtful “About Us” or mission statement content will allow your Group Page to serve as a full-fledged home page for your organization. Rally your collecting efforts around who you are and what you do with our customizable About Us section.',
    banner: <WebUI.Image src={GPAboutUsSlideBanner} alt="" />,
  },
  {
    id: 'team',
    sectionName: 'Meet the Team',
    heading: 'Give a shout out to your hard-working team members',
    description:
      'Build out your entire team, including their names, titles, contact information and photos in just a few clicks. Give your teammates the credit they deserve while making it easy for your community to contact them.',
    banner: <WebUI.Image src={GPTeamSlideBanner} alt="" />,
  },
  {
    id: 'sponsors',
    sectionName: 'Sponsors',
    heading: 'Another impactful way to promote your sponsors',
    description:
      'Featuring sponsors on your Group Page is one more reason you can use to encourage brands to support your group. Easily feature sponsor logos, names and website URLs.',
    banner: <WebUI.Image src={GPSponsorsSlideBanner} alt="" />,
  },
]

export type QuickTourSlideId = keyof typeof SECTION_ID_TO_SLIDE_INDEX_MAP

export default GroupPageQuickTourModal
