import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import React from 'react'
import {useParams} from 'react-router-dom'
import {QuestionList, RespondentList} from 'src/components/FieldViewsList'

import {TicketRespondentListRow} from './TicketResponseViewsList'

export interface TicketFieldViewsListProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
}

const TicketFieldViewsList = ({
  collectionId,
  ...restProps
}: TicketFieldViewsListProps) => {
  const urlParams = useParams()
  const [viewBy] = useQueryParam(
    'viewBy',
    withDefault(StringParam, 'attendees'),
  )
  const [itemId] = useQueryParam('itemId', NumberParam)
  const combinedItemId = itemId ?? Number(urlParams.ticket)

  switch (viewBy) {
    case 'attendees':
      return (
        <RespondentList
          collectionId={collectionId}
          tabObjectId={combinedItemId}
          RowComponent={TicketRespondentListRow}
          tabObjectType="item"
          {...restProps}
        />
      )
    case 'questions':
      return (
        <QuestionList
          collectionId={collectionId}
          tabObjectId={combinedItemId}
          tabObjectType="item"
          tabObjectSubtype="ticket"
          {...restProps}
        />
      )
    default:
      return null
  }
}

export default TicketFieldViewsList
