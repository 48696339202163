import {useForkRef, useLiveRef, useUpdateEffect} from '@cheddarup/react-util'
import React, {useImperativeHandle, useMemo, useRef, useState} from 'react'
import {makeShortId} from '@cheddarup/util'

import {PhosphorIcon} from '../icons'
import {CardDragHandle} from './Card'
import {Checkbox} from './Checkbox'
import {IconButton} from './IconButton'
import {Input} from './Input'
import {
  ListInstance,
  ListProps,
  ListRowComponentProps,
  SortableList,
  SortableListRowComponentProps,
  SortableListRowComponentType,
} from './List'
import {cn} from '../utils'

export interface Option {
  id: string
  value: string | '__create__'
}

export interface OptionsBuilderProps
  extends Pick<ListProps<Option>, 'HeaderComponent'>,
    Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {
  optionPlaceholder?: string
  defaultOptions?: Option[]
  options?: Option[]
  onOptionsChange?: (options: Option[]) => void
}

export const OptionsBuilder = React.forwardRef<
  ListInstance,
  OptionsBuilderProps
>(
  (
    {
      optionPlaceholder = 'Add option',
      defaultOptions,
      options: optionsProp,
      onOptionsChange,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const [options, setOptions] = useState<Option[]>([
      ...(defaultOptions ?? optionsProp ?? []),
      {id: makeShortId(), value: '__create__'},
    ])
    const rowRefs = useRef<Array<HTMLDivElement | null>>([])
    const onOptionsChangeRef = useLiveRef(onOptionsChange)

    useUpdateEffect(() => {
      if (optionsProp != null) {
        setOptions(optionsProp)
      }
    }, [optionsProp])

    useUpdateEffect(() => {
      const optionsWithoutCreate = options.filter(
        (o) => o.value !== '__create__',
      )
      onOptionsChangeRef.current?.(optionsWithoutCreate)
    }, [options])

    const OptionBuilderRowWithHandlers = useMemo(
      () =>
        React.memo(
          React.forwardRef<
            HTMLDivElement,
            SortableListRowComponentProps<Option>
          >((rowProps, rowForwardedRef) => (
            <OptionBuilderRow
              ref={useForkRef(rowForwardedRef, (rowRef) => {
                rowRefs.current[rowProps.index] = rowRef
              })}
              {...rowProps}
              placeholder={optionPlaceholder}
              onValueChange={(newValue) =>
                setOptions((prevOptions) => {
                  if (rowProps.data.value === '__create__') {
                    return [
                      ...prevOptions.filter((o) => o.value !== '__create__'),
                      {id: rowProps.data.id, value: newValue},
                      {id: makeShortId(), value: '__create__'},
                    ]
                  }

                  return prevOptions.map((o) =>
                    o.id === rowProps.data.id
                      ? {...rowProps.data, value: newValue}
                      : o,
                  )
                })
              }
              onRemove={() =>
                setOptions((prevOptions) =>
                  prevOptions.filter((o) => o.id !== rowProps.data.id),
                )
              }
              onMoveDown={() => rowRefs.current[rowProps.index + 1]?.focus()}
              onMoveUp={() => rowRefs.current[rowProps.index - 1]?.focus()}
            />
          )),
        ) as SortableListRowComponentType<Option>,
      [optionPlaceholder],
    )

    return (
      <SortableList
        ref={forwardedRef}
        className={cn(
          'OptionsBuilderList',
          'gap-1 rounded bg-trueWhite py-3 shadow-[inset_0_0_0_1px_theme(colors.grey.300)]',
          'transition-colors duration-100 ease-in-out',
          'aria-invalid:shadow-[inset_0_0_0_1px_theme(colors.orange.500)]',
          'focus-within:shadow-[inset_0_0_0_1px_theme(colors.teal.50)] focus:shadow-[inset_0_0_0_1px_theme(colors.teal.50)]',
          'hover:[&:not(:focus-within):not([aria-invalid=true]):not(:disabled):not([aria-disabled=true])]:bg-inputHoverBackground',
          className,
        )}
        touchEnabled
        data={options}
        RowComponent={OptionBuilderRowWithHandlers}
        onOrderChange={(orderedOptionIds) =>
          setOptions((prevOptions) =>
            orderedOptionIds
              .map((oId) => prevOptions.find((o) => o.id === oId))
              .filter((o) => !!o),
          )
        }
        {...restProps}
      />
    )
  },
)

// MARK: – OptionBuilderRow

interface OptionBuilderRowProps extends ListRowComponentProps<Option> {
  placeholder?: string
  onValueChange?: (value: string) => void
  onRemove?: () => void
  onMoveUp?: () => void
  onMoveDown?: () => void
}

const OptionBuilderRow = React.forwardRef<
  HTMLDivElement,
  OptionBuilderRowProps
>(
  (
    {
      placeholder,
      onValueChange,
      onRemove,
      onMoveUp,
      onMoveDown,
      index,
      data: option,
      className,
      onFocus,
      children,
      ...restProps
    },
    forwardedRef,
  ) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(
      forwardedRef,
      () =>
        ({
          ...ownRef.current,
          focus: (options?: FocusOptions) => inputRef.current?.focus(options),
          blur: () => inputRef.current?.blur(),
        }) as any,
      [],
    )

    return (
      <div
        ref={ownRef}
        className={cn(
          'OptionBuilderRow',
          'relative flex flex-row items-center gap-1 bg-inherit px-2',
          className,
        )}
        onFocus={(event) => {
          onFocus?.(event)
          if (!event.defaultPrevented) {
            inputRef.current?.focus()
          }
        }}
        {...restProps}
      >
        <CardDragHandle
          aria-hidden={option.value === '__create__'}
          className={cn(
            'OptionBuilderRow-dragHandle',
            'bg-inherit',
            'aria-hidden:pointer-events-none aria-hidden:invisible',
            '[@media(hover:hover){&}]:invisible [@media(hover:hover){&}]:opacity-0',
            'transition-opacity duration-100 ease-in-out',
            '[@media(hover:hover){.OptionBuilderRow:hover_>_&}]:visible [@media(hover:hover){.OptionBuilderRow:hover_>_&}]:opacity-100',
            '[@media(hover:hover){.OptionBuilderRow:focus-within_>_&}]:visible [@media(hover:hover){.OptionBuilderRow:focus-within_>_&}]:opacity-100',
          )}
        />
        <Checkbox
          className={cn(
            'OptionBuilderRow-checkbox',
            'invisible absolute top-0 left-2 translate-y-1/2 opacity-0',
            '[@media(hover:hover){&}]:visible [@media(hover:hover){&}]:opacity-100',
            'transition-opacity duration-100 ease-in-out',
            '[@media(hover:hover){.OptionBuilderRow:focus-within_>_&}]:invisible [@media(hover:hover){.OptionBuilderRow:focus-within_>_&}]:opacity-0 [@media(hover:hover){.OptionBuilderRow:hover_>_&}]:invisible [@media(hover:hover){.OptionBuilderRow:hover_>_&}]:opacity-0',
          )}
        />
        <Input
          ref={inputRef}
          className="OptionBuilderRow-input grow bg-inherit"
          size="compact"
          variant="headless"
          placeholder={placeholder}
          defaultValue={option.value === '__create__' ? '' : option.value}
          onChange={(event) => {
            if (option.value !== '__create__' && event.target.value === '') {
              onMoveUp?.()
              onRemove?.()
            } else {
              onValueChange?.(event.target.value)
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' || event.key === 'ArrowDown') {
              // Prevent form submit
              if (event.key === 'Enter') {
                event.preventDefault()
              }

              onMoveDown?.()
            } else if (event.key === 'ArrowUp') {
              onMoveUp?.()
            } else if (
              event.key === 'Clear' ||
              event.key === 'Delete' ||
              (event.key === 'Backspace' && option.value === '')
            ) {
              onRemove?.()
            }
          }}
        />
        <IconButton
          aria-hidden={option.value === '__create__'}
          className={cn(
            'OptionBuilderRow-removeButton text-ds-lg text-grey-300 aria-hidden:hidden',
            '[@media(hover:hover){.OptionBuilderRow:hover_>_&}]:visible',
          )}
          size="default_alt"
          variant="ghost"
          onMouseDown={() => {
            onRemove?.()
          }}
        >
          <PhosphorIcon icon="x-circle-fill" />
        </IconButton>
      </div>
    )
  },
)
