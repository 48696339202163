import * as Util from '@cheddarup/util'
import {BooleanParam, useQueryParam} from 'use-query-params'
import {useFormik, WithErrorBoundary} from '@cheddarup/react-util'
import * as Yup from 'yup'
import {useEffect, useMemo, useRef} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useConfirmPhoneNumberMutation,
  useMoveTabsMutation,
  useUpdateUserMutation,
} from '@cheddarup/api-client'
import {useFolderId} from 'src/components/FolderProvider'
import {
  useManagerRole,
  useManagerRoleId,
} from 'src/components/ManageRoleProvider'
import {maskPhoneNumber} from 'src/helpers/phone-helpers'
import {UpsellCard} from 'src/components/UpsellCard'
import {formatUserPlan, getUserSegment} from 'src/helpers/upsell-card-helpers'
import {useLocation} from 'react-router-dom'
import config from 'src/config'

import {
  CreateCollectionButton,
  FinishSetupPanel,
  FoldersNav,
  GetStartedPanel,
  PartnerBenefitsPanel,
  TemplatePostConversionModal,
  WithdrawPanel,
} from './components'
import {AccountProfileItem, ManagerAccounts} from './components/ManagerAccounts'
import CollectionList from './components/CollectionList'
import {LinkButton} from '../../components/LinkButton'
import CollectionsLayout, {
  CollectionsLayoutInstance,
} from '../../components/CollectionsLayout'
import {Logo} from '../../components'
import {SignupFooter} from '../onboard/components'
import JoinTheGroup from '../../images/join-the-group.png'
import WelcomeModal from '../onboard/WelcomeModal'
import {UPSELL_CARDS_CONTENT, VERIFIED_EIN_UPSELL} from './constants'

const CollectionsPage = () => {
  const media = WebUI.useMedia()
  const location = useLocation()
  const [managerRole] = useManagerRole()
  const [folderId, setFolderId] = useFolderId()
  const [managerWelcome, setManagerWelcome] = useQueryParam(
    'managerWelcome',
    BooleanParam,
  )
  const collectionsLayoutRef = useRef<CollectionsLayoutInstance>(null)
  const {data: session} = api.auth.session.useQuery()
  const hasTabsQuery = api.tabs.list.useQuery(undefined, {
    select: (tabs) => tabs.length > 0,
  })
  const moveCollectionsMutation = useMoveTabsMutation()
  const defaultFolderName =
    session?.user.profile?.uiClientFlags?.customDefaultFolderName ||
    'Collections'
  const showFinishSetupPanel =
    !!session?.user.profile?.uiClientFlags?.onboardingChecklist?.show

  const filteredUpsellCards = useMemo(() => {
    const userSegment = session?.user.customer_category
      ? getUserSegment(session?.user.customer_category)
      : 'other'
    const userPlan = formatUserPlan(session?.capabilities.plan ?? 'free')

    return [
      ...UPSELL_CARDS_CONTENT[userSegment][userPlan],
      ...UPSELL_CARDS_CONTENT.plans[userPlan],
      ...(session?.user.verifiedNonProfitStatus === 'verified' &&
      userPlan !== 'team'
        ? [VERIFIED_EIN_UPSELL]
        : []),
    ]
  }, [
    session?.capabilities.plan,
    session?.user.customer_category,
    session?.user.verifiedNonProfitStatus,
  ])

  const randomUpsellCard = Util.sample(filteredUpsellCards, 1)[0]

  return (
    <CollectionsLayout
      ref={collectionsLayoutRef}
      className="max-h-full sm:[&_.CollectionsLayout-contentContainer]:p-0"
    >
      {media.sm ? (
        <WebUI.HStack className="min-h-0 grow">
          <WebUI.HStack className="min-w-0 grow border-r bg-trueWhite">
            <WebUI.DragAndDrop
              keyboardEnabled
              isDroppable={(collection, folder) =>
                collection.user_id === (folder.organizer?.id ?? folder.user_id)
              }
              onDragEnd={(event) => {
                if (event.over?.data.current && event.active.data.current) {
                  moveCollectionsMutation.mutate({
                    body: {
                      collection_ids: [event.active.data.current.id],
                      folder_id:
                        event.over.data.current.id < 0
                          ? null
                          : event.over.data.current.id,
                    },
                  })
                }
              }}
            >
              <WebUI.VStack className="min-w-0 shrink-0 grow-0 basis-[264px]">
                {session && session.manager_roles.length > 0 && (
                  <>
                    <ManagerAccounts disclosureVariant="profile" />
                    <WebUI.Separator variant="primary" />
                  </>
                )}
                <WebUI.HStack className="h-[84px] flex-0 items-center px-4">
                  <CreateCollectionButton className="w-full" />
                </WebUI.HStack>
                <WebUI.Separator variant="primary" />
                <FoldersNav defaultFolderName={defaultFolderName} />
              </WebUI.VStack>
              <WebUI.Separator variant="primary" orientation="vertical" />
              <CollectionList
                className="min-w-0 flex-auto"
                defaultFolderName={defaultFolderName}
              />
            </WebUI.DragAndDrop>
          </WebUI.HStack>
          {media.xl &&
            (!managerRole || managerRole?.permissions?.role === 'admin') && (
              <WebUI.VStack className="max-w-full flex-[0_0_380px] gap-4 overflow-y-auto p-6 *:flex-0">
                <WithdrawPanel />
                {!showFinishSetupPanel && randomUpsellCard && (
                  <UpsellCard
                    {...(randomUpsellCard as any)}
                    to={
                      typeof randomUpsellCard.to === 'string'
                        ? randomUpsellCard.to
                        : {
                            ...randomUpsellCard.to,
                            search: Util.mergeSearchParams(
                              location.search,
                              randomUpsellCard.to.search,
                            ),
                          }
                    }
                  />
                )}
                {!managerRole && (
                  <>
                    <PartnerBenefitsPanel />
                    {!!session?.user.profile?.uiClientFlags?.onboardingChecklist
                      ?.show && <FinishSetupPanel />}
                    {!!session && !session.capabilities.subscribed_to_team && (
                      <CollectionsUpsellCard />
                    )}
                  </>
                )}
              </WebUI.VStack>
            )}
        </WebUI.HStack>
      ) : (
        <WebUI.VStack className="gap-4">
          <WebUI.VStack className="gap-2 px-8">
            <WebUI.Heading as="h2">Collections</WebUI.Heading>
            {!!hasTabsQuery.data && (
              <WebUI.Text>
                Want to create a collection?{' '}
                <WebUI.Anchor
                  href={
                    WebUI.isAppleDevice()
                      ? config.appleStoreLink
                      : config.googlePlayLink
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Download our app
                </WebUI.Anchor>{' '}
                or click below to get started.
              </WebUI.Text>
            )}
          </WebUI.VStack>
          {hasTabsQuery.data ? (
            <WebUI.Panel>
              {session && session.manager_roles.length > 0 && (
                <>
                  <ManagerAccounts disclosureVariant="profile" />
                  <WebUI.Separator variant="primary" />
                </>
              )}
              <WebUI.HStack className="items-center p-4">
                <CreateCollectionButton />
              </WebUI.HStack>
            </WebUI.Panel>
          ) : (
            <GetStartedPanel />
          )}
          {showFinishSetupPanel && (
            <FinishSetupPanel
              onShowFinishSetup={() =>
                collectionsLayoutRef.current?.showFinishSetup()
              }
            />
          )}
          {!!hasTabsQuery.data && (
            <WebUI.Panel
              as={FoldersNav}
              defaultFolderName={defaultFolderName}
            />
          )}
          {!managerRole ||
            (managerRole.permissions.role === 'admin' && <WithdrawPanel />)}
          {!!session && !session.capabilities.subscribed_to_team && (
            <CollectionsUpsellCard />
          )}
          <WebUI.Modal
            aria-label="Collection list"
            className="[&_>_.ModalContentView]:overflow-hidden"
            preventBodyScroll={false}
            visible={folderId !== undefined}
            onDidHide={() => setFolderId(undefined)}
          >
            <WebUI.ModalCloseButton />
            <CollectionList defaultFolderName={defaultFolderName} />
          </WebUI.Modal>
        </WebUI.VStack>
      )}
      <WithErrorBoundary>
        <SelectProfileModal />
        <InquireTwoFactorAuthModal />
        <VerifyPoneNumberModal />
      </WithErrorBoundary>
      <WelcomeModal />
      <TemplatePostConversionModal />
      <WithErrorBoundary>
        {managerWelcome ? (
          <ManagerWelcomeModal onDidHide={() => setManagerWelcome(undefined)} />
        ) : (
          <CustomerCategoryModal />
        )}
      </WithErrorBoundary>
    </CollectionsLayout>
  )
}

// MARK: – CollectionsUpsellCard

const CollectionsUpsellCard = () => {
  const {data: showFinishSetup} = api.auth.session.useSuspenseQuery(undefined, {
    select: (session) => ({
      showFinishSetup:
        !!session.user.profile.uiClientFlags?.onboardingChecklist?.show,
    }),
  })

  if (showFinishSetup) {
    return null
  }

  return (
    <WebUI.Panel
      className={
        'bg-no-repeat px-8 py-6 [background-position-x:right] [background-position-y:16px]'
      }
      style={{
        backgroundImage: `url(${JoinTheGroup})`,
      }}
    >
      <div className="mr-32">
        <div className="mb-2 py-2 font-accent font-bold text-ds-md">
          Activate your Group Page
        </div>
        <div className="py-2 font-light text-ds-sm">
          <span className="font-bold">Don't miss out!</span> Create a one-stop,
          always-there URL for your community.
        </div>
      </div>
      <div className="mb-2 py-2">
        <WebUI.RadioGroup
          className={
            '[&_>_.Radio_>_.Radio-icon]:h-[1em] [&_>_.Radio_>_.Radio-icon]:w-[1em] [&_>_.Radio_>_.Radio-icon_>_.Radio-checkIcon]:text-[0.75em]'
          }
          size="compact"
        >
          <WebUI.Radio className="items-start" checked>
            Feature multiple collections
          </WebUI.Radio>
          <WebUI.Radio className="items-start" checked>
            Add your own image or logo
          </WebUI.Radio>
          <WebUI.Radio className="items-start" checked>
            Decide which collections to include
          </WebUI.Radio>
          <WebUI.Radio className="items-start" checked>
            Organize with categories
          </WebUI.Radio>
        </WebUI.RadioGroup>
      </div>
      <LinkButton variant="primary" to="/group">
        Customize Your Group Page
      </LinkButton>
    </WebUI.Panel>
  )
}

// MARK: – CustomerCategoryModal

const CustomerCategoryModal = () => {
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const growlActions = WebUI.useGrowlActions()
  const sessionQuery = api.auth.session.useSuspenseQuery()
  const updateUserMutation = useUpdateUserMutation()

  const formik = useFormik<{
    customerCategory: Api.UserCustomerCategory | ''
  }>({
    initialValues: {
      customerCategory: '',
    },
    validationSchema: Yup.object().shape({
      customerCategory: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      if (values.customerCategory) {
        try {
          await updateUserMutation.mutateAsync({
            body: {
              customer_category: values.customerCategory ?? undefined,
            },
          })
          modalRef.current?.hide()
        } catch {
          growlActions.show('error', {
            title: 'Error',
            body: 'Cannot update the category. Please try again.',
          })
        }
      }
    },
  })

  return (
    <WebUI.Modal
      aria-label="Customer category form"
      ref={modalRef}
      className="sm:[&_>_.ModalContentView]:max-w-screen-sm"
      initialVisible={
        !sessionQuery.data.organization &&
        sessionQuery.data.user.customer_category === 'UNKNOWN'
      }
      hideOnClickOutside={false}
      hideOnEsc={false}
    >
      <WebUI.Form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
        <WebUI.VStack className="gap-12 p-10">
          <WebUI.VStack className="mt-4 gap-5">
            <Logo />
            <WebUI.Heading className="text-center">
              Help us customize your experience
            </WebUI.Heading>
            <WebUI.FormField
              label="Who are you primarily collecting for?"
              error={formik.errors.customerCategory}
            >
              <WebUI.DropdownSelect<Api.UserCustomerCategory | ''>
                name="customerCategory"
                placeholder="Select One"
                value={formik.values.customerCategory}
                onValueChange={(newCustomerCategory) =>
                  formik.setFieldValue('customerCategory', newCustomerCategory)
                }
              >
                <WebUI.DropdownSelectOption value="SCHOOL">
                  School
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="SPORT">
                  Sports Team
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="SCOUT">
                  Scouts
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="FINE ARTS">
                  Music or Fine Arts
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="CLUB">
                  Club
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="BUSINESS">
                  Business
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="RELIGIOUS">
                  Religious Organization
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="NON-PROFIT">
                  Other Non-Profit
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="FRIENDS">
                  Friend or Family
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="PERSONAL">
                  Myself
                </WebUI.DropdownSelectOption>
                <WebUI.DropdownSelectOption value="NONE_OF_THE_ABOVE">
                  None of the Above
                </WebUI.DropdownSelectOption>
              </WebUI.DropdownSelect>
            </WebUI.FormField>
          </WebUI.VStack>
          <WebUI.Button
            className="w-[240px] self-center"
            type="submit"
            variant="primary"
            size="large"
          >
            Get Started
          </WebUI.Button>
        </WebUI.VStack>
        <SignupFooter />
      </WebUI.Form>
    </WebUI.Modal>
  )
}

// MARK: – InquireTwoFactorAuthModal

const InquireTwoFactorAuthModal = () => {
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const sessionQuery = api.auth.session.useSuspenseQuery()

  const userIsNotImpersonated =
    !sessionQuery?.data?.auth?.impersonation?.userIsImpersonated
  const isTwoFactorAuthRequired =
    sessionQuery.data.user.withdrawal_data.total_available_balance > 0 &&
    !sessionQuery.data.user.profile.phone.verified &&
    userIsNotImpersonated

  useEffect(() => {
    if (!isTwoFactorAuthRequired) {
      modalRef.current?.hide()
    }
  }, [isTwoFactorAuthRequired])

  return (
    <WebUI.Modal
      aria-label="Two-factor auth required"
      ref={modalRef}
      className="sm:[&_>_.ModalContentView]:max-w-screen-sm"
      role="alertdialog"
      initialVisible={isTwoFactorAuthRequired}
      hideOnClickOutside={false}
      hideOnEsc={false}
    >
      <WebUI.VStack className="gap-4 p-16">
        <WebUI.Heading className="text-center">Action Required</WebUI.Heading>
        <WebUI.Text className="text-center font-light">
          Two-factor authentication is not currently set on your account, which
          can put your account balance at risk. To proceed, please enable
          two-factor authentication now.
        </WebUI.Text>
        <LinkButton
          className="w-[240px] self-center"
          variant="primary"
          size="large"
          to="my-account/security"
        >
          Set Authentication
        </LinkButton>
      </WebUI.VStack>
    </WebUI.Modal>
  )
}

// MARK: – SelectProfileModal

const SelectProfileModal: React.FC<WebUI.ModalProps> = ({
  className,
  ...restProps
}) => {
  const modalRef = useRef<WebUI.DialogInstance>(null)
  const sessionQuery = api.auth.session.useSuspenseQuery()
  const [managerRoleId, setManagerRoleId] = useManagerRoleId()
  const [disableSelectProfileModal, setDisableSelectProfileModal] =
    WebUI.useLocalStorage(
      `disable_select_profile_modal-${sessionQuery.data.user.id}`,
      false,
    )

  return (
    <WebUI.Modal
      aria-label="Select profile"
      ref={modalRef}
      className={WebUI.cn(
        '[&_>_.ModalContentView]:p-10 sm:[&_>_.ModalContentView]:max-w-screen-sm',
        className,
      )}
      initialVisible={
        !disableSelectProfileModal &&
        managerRoleId === undefined &&
        sessionQuery.data.manager_roles.length > 0
      }
      hideOnClickOutside={false}
      hideOnEsc={false}
      {...restProps}
    >
      {(dialog) => (
        <WebUI.VStack className="items-center gap-6">
          <WebUI.VStack className="gap-4 text-center">
            <WebUI.Heading>Select an account profile</WebUI.Heading>
            <WebUI.Text className="font-light text-ds-md">
              As a manager, you can choose which profile you'd like to use.
            </WebUI.Text>
          </WebUI.VStack>
          <WebUI.VStack
            className={
              'w-[230px] items-center gap-4 [&_>_.AccountProfileItem]:w-full [&_>_.AccountProfileItem]:cursor-pointer [&_>_.AccountProfileItem]:rounded [&_>_.AccountProfileItem]:p-3 [&_>_.AccountProfileItem]:shadow-z4'
            }
          >
            {sessionQuery.data.manager_roles.map((mr) => (
              <AccountProfileItem
                key={mr.id}
                managerRole={mr}
                onClick={() => {
                  setManagerRoleId(mr.id)
                  dialog.hide()
                }}
              />
            ))}
            <AccountProfileItem
              isPersonal
              managerRole={{
                id: sessionQuery.data.user.id,
                name: sessionQuery.data.user.display_name ?? '',
                profile_pic: sessionQuery.data.user.profile_pic ?? null,
              }}
              onClick={() => {
                setManagerRoleId(null)
                dialog.hide()
              }}
            />
          </WebUI.VStack>
          <WebUI.Checkbox
            name="include_categories"
            size="compact"
            checked={!disableSelectProfileModal}
            onChange={(event) =>
              setDisableSelectProfileModal(!event.target.checked)
            }
          >
            Ask when I open Cheddar Up
          </WebUI.Checkbox>
        </WebUI.VStack>
      )}
    </WebUI.Modal>
  )
}

// MARK: ManagerWelcomeModal

const ManagerWelcomeModal = ({className, ...restProps}: WebUI.ModalProps) => {
  const customerCategoryQuery = api.auth.session.useSuspenseQuery(undefined, {
    select: (session) => session.user.customer_category,
  })
  const updateUserMutation = useUpdateUserMutation()

  const updateUserMutate = updateUserMutation.mutate
  useEffect(() => {
    if (updateUserMutation.isIdle && customerCategoryQuery.data === 'UNKNOWN') {
      updateUserMutate({
        body: {
          customer_category: 'MANAGER',
        },
      })
    }
  }, [customerCategoryQuery.data, updateUserMutate, updateUserMutation.isIdle])

  return (
    <WebUI.Modal
      aria-label="Manager welcome"
      className={WebUI.cn(
        'sm:[&_>_.ModalContentView]:max-w-screen-sm',
        className,
      )}
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <WebUI.VStack className="items-center gap-4 p-10 pt-4 text-center">
            <WebUI.Heading>You’re in!</WebUI.Heading>
            <WebUI.Text className="font-light text-ds-base">
              As an account manager, you have access to both your manager
              profile and your personal profile. Use the account profile
              drop-down menu to select your preferred profile each time you log
              in.
            </WebUI.Text>
            <WebUI.Button
              size="large"
              variant="default"
              onClick={() => dialog.hide()}
            >
              Explore Cheddar Up
            </WebUI.Button>
          </WebUI.VStack>
        </>
      )}
    </WebUI.Modal>
  )
}

// MARK: – VerifyPoneNumberModal

const VerifyPoneNumberModal: React.FC<WebUI.ModalProps> = ({
  className,
  ...restProps
}) => {
  const {data: session} = api.auth.session.useSuspenseQuery()
  const confirmPhonNumberMutation = useConfirmPhoneNumberMutation()
  const userIsNotImpersonated =
    !!session && !session?.auth?.impersonation?.userIsImpersonated
  const phoneVerficationRequired =
    userIsNotImpersonated && !!session?.user.requirePhoneVerification
  const maskedPhoneNumber = maskPhoneNumber(
    WebUI.parsePhoneNumber(session?.user.profile.phone.fullNumber ?? '', {
      defaultCallingCode: session?.user.profile.phone.country_code ?? undefined,
    })?.formatNational(),
  )

  return (
    <WebUI.Modal
      aria-label="Phone Verification Required"
      className={WebUI.cn(
        '[&_>_.ModalContentView]:max-w-screen-md [&_>_.ModalContentView]:p-12',
        className,
      )}
      initialVisible={phoneVerficationRequired}
      hideOnClickOutside={false}
      hideOnEsc={false}
      {...restProps}
    >
      {(dialog) => (
        <WebUI.VStack className="items-center gap-6">
          <WebUI.VStack className="gap-4 text-center">
            <WebUI.Heading>Confirm your two-factor phone number</WebUI.Heading>
            <WebUI.Text className="font-light text-ds-md">
              Your two-factor phone number can be used to verify account login
              and to confirm certain actions. Let's make sure it's up to date:
            </WebUI.Text>
            <WebUI.Text>
              Phone number currently on file <br /> {maskedPhoneNumber}
            </WebUI.Text>
            <WebUI.Text>Is this still correct?</WebUI.Text>
          </WebUI.VStack>
          <WebUI.HStack className="items-center gap-4">
            <WebUI.Button
              size="large"
              variant="primary"
              onClick={() => {
                confirmPhonNumberMutation.mutateAsync()
                dialog.hide()
              }}
            >
              Confirm
            </WebUI.Button>
            <WebUI.Button as={LinkButton} to="my-account/security" size="large">
              Update Phone Number
            </WebUI.Button>
          </WebUI.HStack>
        </WebUI.VStack>
      )}
    </WebUI.Modal>
  )
}

export default CollectionsPage
