import {api} from '@cheddarup/api-client'
import {resolveSignatureFieldValue} from '@cheddarup/core'
import * as Util from '@cheddarup/util'

export function useResponsesCsvDataQuery(
  tabId: number,
  tabObjectId: number,
  fieldSets?: Api.FieldSet[] | null,
) {
  return api.tabMembers.list.useQuery(
    {
      pathParams: {
        tabId,
      },
      queryParams: {
        include_refunds: true,
      },
    },
    {
      select: (members) => {
        const respondents = members
          .map((m) => ({
            ...m,
            payments: m.payments.map((p) => ({
              ...p,
              payment_items: p.payment_items
                .filter((pi) => pi.tab_object_id === tabObjectId)
                .filter(
                  (pi) =>
                    pi.total === 0 ||
                    pi.amount_after_refund == null ||
                    pi.amount_after_refund > 0,
                ),
            })),
          }))
          .filter((m) =>
            m.payments.some((p) =>
              p.payment_items.some(
                (pi) =>
                  pi.item_field_views.length > 0 ||
                  (pi.detail &&
                    'variant' in pi.detail &&
                    pi.detail.variant != null),
              ),
            ),
          )

        const possibleItemFieldViews = Util.sort(
          Util.uniqueBy(
            respondents
              .flatMap((r) => r.payments)
              .flatMap((p) => p.payment_items)
              .flatMap((pi) => pi.item_field_views),
            (ifv) => ifv.item_field_id,
          ),
        ).asc((ifv) =>
          fieldSets
            ? (fieldSets.findIndex(
                (fs) => fs.uuid === ifv.metadata.fieldSetId,
              ) ?? Number.MAX_SAFE_INTEGER)
            : (ifv.item_field_id ?? ifv.position),
        )

        const possibleFieldNames = [
          ...possibleItemFieldViews.map((pifv) => pifv.name),
          'Document Number',
        ]

        const csvData = respondents.flatMap((r) =>
          r.payments.flatMap((p) =>
            p.payment_items.map((pi) => [
              r.name,
              r.email,
              Util.formatDateAs(p.created_at) ?? '',
              ...possibleItemFieldViews.map((pifv) => {
                const ifv = pi.item_field_views.find(
                  (ifv) => ifv.item_field_id === pifv.item_field_id,
                )
                return ifv?.field_type === 'signature'
                  ? resolveSignatureFieldValue(ifv, p.e_signatures)
                  : (ifv?.value ?? '')
              }),
              Util.encodeToBase36(pi.id),
            ]),
          ),
        )

        return {
          possibleFieldNames,
          csvData,
        }
      },
    },
  )
}
