import * as Util from '@cheddarup/util'
import React, {useMemo, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import * as WebUI from '@cheddarup/web-ui'
import {tailwindConfig} from '@cheddarup/tailwind-config'
import {
  InferResponse,
  api,
  endpoints,
  useUpdateTabMemberMutation,
} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {SearchForm} from 'src/components'
import {
  useManagerRole,
  useManagerRoleId,
} from 'src/components/ManageRoleProvider'

const ITEMS_PER_PAGE = 25

const CollectionManageVisitorsTable: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const urlParams = useParams()
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [managerRoleId] = useManagerRoleId()
  const growlActions = WebUI.useGrowlActions()

  const membersQuery = api.tabMembers.list.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
    },
  })

  type Datum = InferResponse<typeof endpoints.tabMembers.list>[number]
  const columnHelper = useMemo(() => WebUI.createColumnHelper<Datum>(), [])

  const columns = useMemo(
    () => [
      columnHelper.accessor((item) => [item.name, item.email], {
        id: 'name',
        meta: {
          subtle: false,
        },
        header: 'Visitor Name',
        cell: ({cell, row}) => (
          <WebUI.VStack className="min-w-0">
            <WebUI.Ellipsis>{cell.getValue()[0]}</WebUI.Ellipsis>
            <WebUI.Anchor
              className="font-light text-ds-sm"
              rel="noopener noreferrer"
              target="_blank"
              href={`mailto:${row.original.email}`}
            >
              {row.original.email}
            </WebUI.Anchor>
          </WebUI.VStack>
        ),
      }),
      columnHelper.accessor(
        (item) =>
          Util.sumBy(
            (item.payments ?? []).filter((payment) => payment.paid),
            (payment) => payment.total,
          ),
        {
          id: 'total',
          meta: {
            align: 'left',
          },
          header: 'Payments Total',
          cell: ({cell}) => (
            <WebUI.Text className="font-normal">
              {Util.formatAmount(cell.getValue())}
            </WebUI.Text>
          ),
        },
      ),
      columnHelper.accessor((item) => item.identify_count, {
        id: 'identify_count',
        size: 88,
        header: '# of Visits',
        cell: ({cell}) => (
          <WebUI.VStack className="min-w-0">
            {cell.getValue() ? (
              <WebUI.Ellipsis className="font-light">
                {cell.getValue()}
              </WebUI.Ellipsis>
            ) : (
              <WebUI.DeprecatedTooltip label="Visit recorded by organizer.">
                <span className="font-light">-</span>
              </WebUI.DeprecatedTooltip>
            )}
          </WebUI.VStack>
        ),
      }),
      columnHelper.accessor((item) => new Date(item.created_at), {
        id: 'created_at',
        size: 88,
        sortingFn: 'datetime',
        header: 'First Visit',
        cell: ({cell}) => (
          <WebUI.Text className="font-light">
            {Util.formatDateAs(cell.getValue(), 'date_compact')}
          </WebUI.Text>
        ),
      }),
      columnHelper.accessor((item) => new Date(item.updated_at), {
        id: 'updated_at',
        size: 88,
        sortingFn: (rowA, rowB) =>
          new Date(rowA.original.updated_at) >
          new Date(rowB.original.updated_at)
            ? 1
            : -1,
        header: 'Last Visit',
        cell: ({cell}) => (
          <WebUI.Text className="font-light">
            {Util.formatDateAs(cell.getValue(), 'date_compact')}
          </WebUI.Text>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        minSize: 88,
        size: 88,
        meta: {
          align: 'right',
        },
        cell: ({
          cell: {
            row: {original: tabMember},
          },
        }) => <VisitorActionsDropdown tabMember={tabMember as any} />,
      }),
    ],
    [columnHelper],
  )

  const filteredData = useMemo(() => {
    if (!searchTerm) return membersQuery.data ?? []

    return (membersQuery.data ?? []).filter((member) => {
      const searchLower = searchTerm.toLowerCase()
      return (
        member.name?.toLowerCase().includes(searchLower) ||
        member.email?.toLowerCase().includes(searchLower)
      )
    })
  }, [membersQuery.data, searchTerm])

  const paginatedData = useMemo(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE
    const endIndex = startIndex + ITEMS_PER_PAGE
    return filteredData.slice(startIndex, endIndex)
  }, [filteredData, page])

  const initialPaginationState = useMemo(
    () => ({
      pageIndex: page - 1,
      pageSize: ITEMS_PER_PAGE,
    }),
    [page],
  )

  const pageCount = Math.ceil(filteredData.length / ITEMS_PER_PAGE)

  return (
    <WebUI.Panel
      className={WebUI.cn('flex flex-col', className)}
      {...restProps}
    >
      {!!membersQuery.data && membersQuery.data.length === 0 ? (
        <NoVisitors />
      ) : (
        <WebUI.TableView<Datum>
          className="[&_.TableView-headerGroup]:px-8 [&_.TableViewRow]:px-8"
          columns={columns}
          data={paginatedData}
          loading={membersQuery.isPending}
          initialState={{pagination: initialPaginationState}}
          pageCount={pageCount}
          sortable
          sortByTogglesVisible
          disableSortRemove
          manualPagination={true}
          onPaginationChange={(updater) => {
            if (typeof updater === 'function') {
              const newState = updater(initialPaginationState)
              setPage(newState.pageIndex + 1)
            } else {
              setPage(updater.pageIndex + 1)
            }
          }}
        >
          <>
            <div
              className={
                '-order-1 flex items-center justify-between border-b px-8 py-4'
              }
            >
              <div className="flex items-center gap-4">
                <WebUI.Text className="text-ds-sm">
                  Visitors: {filteredData.length}
                </WebUI.Text>

                <SearchForm
                  className="min-w-[250px] text-ds-xs placeholder:text-ds-xs"
                  iconClassName="text-gray400"
                  size="compact"
                  placeholder="Search by payer name or email"
                  onTermChange={(term) => {
                    setSearchTerm(term)
                    setPage(1) // Reset to first page when searching
                  }}
                />
              </div>

              <WebUI.IconButton
                className="h-[30px] w-[30px] text-ds-md"
                variant="secondary"
                onClick={async () => {
                  const res = await api.exports.visitorsXlsx.fetch({
                    pathParams: {
                      // biome-ignore lint/style/noNonNullAssertion:
                      tabId: urlParams.collection!,
                    },
                    headers: {
                      'X-Manager-Account-Id': managerRoleId,
                    },
                  })
                  if (res.file_url) {
                    window.location.href = res.file_url
                  }

                  growlActions.show('success', {
                    title: 'Your report is in progress',
                    body:
                      res.message ||
                      'Your report was downloaded to your device.',
                  })
                }}
              >
                <WebUI.PhosphorIcon icon="download-simple" />
              </WebUI.IconButton>
            </div>
            {pageCount > 1 && (
              <div className="flex justify-end px-8 py-4">
                <WebUI.TablePaginator />
              </div>
            )}
          </>
        </WebUI.TableView>
      )}
    </WebUI.Panel>
  )
}

// MARK: – VisitorActionsDropdown

interface VisitorActionsDropdownProps
  extends WebUI.MenuButtonProps,
    React.ComponentPropsWithoutRef<'button'> {
  tabMember: Api.TabMember
}

const VisitorActionsDropdown = React.forwardRef<
  HTMLButtonElement,
  VisitorActionsDropdownProps
>(({tabMember, className, ...restProps}, forwardedRef) => {
  const [managerRole] = useManagerRole()
  const [doNotShowForUnsubscribeVisitor, setDoNotShowForUnsubscribeVisitor] =
    WebUI.useSessionStorage<boolean | null>(
      `DO_NOT_SHOW_UNSUBSCRIBE_VISITOR_${tabMember.id}`,
      null,
    )
  const removeFromVisitorListAlertRef = useRef<WebUI.DialogInstance>(null)
  const updateTabMemberMutation = useUpdateTabMemberMutation()

  const subscribeOrUnsunscribe = async () => {
    await updateTabMemberMutation.mutateAsync({
      pathParams: {
        tabId: tabMember.tab_id,
        memberId: tabMember.id,
      },
      body: {
        unsubscribed_at: tabMember.unsubscribed_at
          ? null
          : new Date().toISOString(),
      },
    })
  }

  return (
    <WebUI.Menu placement="bottom-start">
      <WebUI.MenuButton
        ref={forwardedRef}
        className={WebUI.cn(
          'rounded-full bg-depr-grey-200 text-gray400',
          className,
        )}
        as={WebUI.IconButton}
        size="compact"
        {...restProps}
      >
        <WebUI.PhosphorIcon icon="dots-three-outline-fill" width={25} />
      </WebUI.MenuButton>

      <WebUI.MenuList className="font-light text-ds-sm" shrinkable={false}>
        {!!tabMember.email && managerRole?.permissions?.role !== 'viewer' && (
          <WebUI.MenuItem
            as={Link}
            variant="default"
            to={`message-center?to=${tabMember.email}-${tabMember.name}`}
          >
            Send Message
          </WebUI.MenuItem>
        )}
        <WebUI.MenuItem
          onClick={() => {
            if (tabMember.unsubscribed_at || doNotShowForUnsubscribeVisitor) {
              subscribeOrUnsunscribe()
            } else {
              removeFromVisitorListAlertRef.current?.show()
            }
          }}
        >
          {tabMember.unsubscribed_at
            ? 'Subscribe to Messages'
            : 'Unsubscribe from Messages'}
        </WebUI.MenuItem>
      </WebUI.MenuList>
      <RemoveFromVisitorListAlert
        ref={removeFromVisitorListAlertRef}
        tabMember={tabMember}
        onConfirmedDoNotShowAgain={() =>
          setDoNotShowForUnsubscribeVisitor(true)
        }
        onConfirmedRemove={subscribeOrUnsunscribe}
      />
    </WebUI.Menu>
  )
})

// MARK: – NoVisitors

const NoVisitors: React.FC = () => (
  <div className="py-16 text-center">
    <h2 className="font-light text-gray800">
      You don&apos;t have any visitors.
      <br />
      Share your collection!
    </h2>
    <Link to="../share">
      <WebUI.PlanePlusSolidIcon
        className="mt-8 w-[6.25rem]"
        fill={tailwindConfig.theme.colors.orange[500]}
      />
    </Link>
  </div>
)

// MARK: – RemoveFromVisitorListAlert

interface RemoveFromVisitorListAlertProps extends WebUI.AlertProps {
  tabMember: Api.TabMember
  onConfirmedRemove: () => Promise<void>
  onConfirmedDoNotShowAgain: () => void
}

const RemoveFromVisitorListAlert = React.forwardRef<
  WebUI.DialogInstance,
  RemoveFromVisitorListAlertProps
>(
  (
    {tabMember, onConfirmedRemove, onConfirmedDoNotShowAgain, ...restProps},
    forwardedRef,
  ) => {
    const [doNotShowAgain, setDoNotShowAgain] = useState(false)

    return (
      <WebUI.Alert
        aria-label="Unsubscribe Visitor"
        ref={forwardedRef}
        {...restProps}
      >
        <WebUI.AlertHeader>Unsubscribe Visitor</WebUI.AlertHeader>
        <WebUI.AlertContentView
          text={
            <WebUI.VStack className="gap-4">
              <span>
                This visitor will be unsubscribed from your message center. They
                will still receive transactional messages (e.g. receipts).
              </span>
              <WebUI.Checkbox
                size="compact"
                state={doNotShowAgain}
                onChange={(event) => setDoNotShowAgain(event.target.checked)}
              >
                Do not show again
              </WebUI.Checkbox>
            </WebUI.VStack>
          }
          actions={
            <>
              <WebUI.AlertActionButton
                execute={async () => {
                  await onConfirmedRemove()
                  if (doNotShowAgain) {
                    onConfirmedDoNotShowAgain()
                  }
                }}
              >
                Unsubscribe
              </WebUI.AlertActionButton>
              <WebUI.AlertCancelButton />
            </>
          }
        />
      </WebUI.Alert>
    )
  },
)

export default CollectionManageVisitorsTable
