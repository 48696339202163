import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import React, {useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import {Link} from 'src/components/Link'
import {apiClient} from 'src/helpers/client'
import {readApiError} from 'src/helpers/error-formatting'
import {useFormik} from '@cheddarup/react-util'

const RequestMailedSecurityCodePage: React.FC = () => {
  const navigate = useNavigate()
  const growlActions = WebUI.useGrowlActions()
  const {data: session} = api.auth.session.useSuspenseQuery()
  const companyLine1Address = session.user.business_address.line1 ?? ''
  const city = session.user.business_address.city ?? ''
  const postalCode = session.user.business_address.postal_code ?? ''
  const state = session.user.business_address.state ?? ''
  const organizationName =
    session.user.profile.uiClientFlags?.underwritingOrganizationName ||
    session.user.display_name ||
    ''

  const [loading, setLoading] = useState(false)
  const [companyLine2Address, setCompanyLine2Address] = useState(
    session?.user?.business_address.line2 ?? '',
  )
  const organizationAddressAlertRef = useRef<WebUI.DialogInstance>(null)

  return (
    <WebUI.Alert
      aria-label="Request a Mailed Security Code"
      className="[&_>_.ModalContentView]:max-w-screen-sm"
      initialVisible
      onDidHide={() => navigate('../two-factor-authentication')}
    >
      {(dialog) => (
        <>
          <WebUI.AlertHeader
            className={
              '[&.ModalHeader]:border-depr-grey-200 [&.ModalHeader]:border-b [&_>_.ModalHeader-content]:ml-10 [&_>_.ModalHeader-content]:font-2xl [&_>_.ModalHeader-content]:font-normal'
            }
          >
            Request a Mailed Security Code
          </WebUI.AlertHeader>
          <WebUI.VStack className="gap-5 pt-8 pr-16 pb-16 pl-16">
            <WebUI.Text className="text-justify font-light text-gray800">
              To learn more about mailed security codes, go{' '}
              <Link
                className="underline"
                variant="primary"
                replace
                to="../about-mailed-security-code"
              >
                here
              </Link>
              .<br />
              <br />
              Your mailed security code will be sent to:
              <br />
              <br />
              {organizationName}
              <br />
              {companyLine1Address} {companyLine2Address}, {city}, {state}{' '}
              {postalCode}
              <br />
              <br />
              <strong>NOTE: </strong>For security purposes, it is not possible
              to change this mailing address. Upon receipt of the mailed
              security code, come back to{' '}
              <strong>My Account {'>'} Security</strong> to enter the code.
              <br />
              <br />
            </WebUI.Text>
            <WebUI.HStack className="gap-3">
              <WebUI.Button
                variant="primary"
                type="button"
                loading={loading}
                onClick={async () => {
                  try {
                    setLoading(true)
                    await apiClient.post('/users/mailed_security_codes', {
                      companyLine2Address,
                    })
                    growlActions.clear()
                    growlActions.show('success', {
                      title: 'Success',
                      body: 'Your mailed security code has been requested. You should expect to receive it within 3-5 business days.',
                    })
                    dialog.hide()
                  } catch (error) {
                    const errorMessage = readApiError(error, {
                      address_not_verified: 'Your address is not verified.',
                      code_already_requested:
                        'Mailed security code already requested.',
                      letter_not_sent: 'Letter not sent.',
                      unable_to_sent_letter:
                        'Organization Address is Unverifiable',
                    })
                    growlActions.clear()
                    growlActions.show('error', {
                      title: 'Error!',
                      body: errorMessage,
                    })
                    if (
                      errorMessage === 'Organization Address is Unverifiable'
                    ) {
                      organizationAddressAlertRef.current?.show()
                    }
                  } finally {
                    setLoading(false)
                  }
                }}
              >
                Request Mailed Security Code
              </WebUI.Button>
              <WebUI.Button variant="secondary" onClick={() => dialog.hide()}>
                Cancel
              </WebUI.Button>
            </WebUI.HStack>
          </WebUI.VStack>
          <OrganizationAddressLineTwoAlert
            setCompanyLine2Address={setCompanyLine2Address}
            companyLine2Address={companyLine2Address}
            ref={organizationAddressAlertRef}
          />
        </>
      )}
    </WebUI.Alert>
  )
}

// MARK: – OrganizationAddressLineTwoAlert

interface OrganizationAddressAlertProps extends WebUI.AlertProps {
  setCompanyLine2Address: (value: string) => void
  companyLine2Address: string
}

const OrganizationAddressLineTwoAlert = React.forwardRef<
  WebUI.DialogInstance,
  OrganizationAddressAlertProps
>(
  (
    {setCompanyLine2Address, companyLine2Address, ...restProps},
    forwardedRef,
  ) => {
    const formik = useFormik({
      validationSchema: () => {
        const schema = Yup.object().shape({
          companyLine2Address: Yup.string().required(),
        })
        return schema
      },
      initialValues: {
        companyLine2Address,
      },
      onSubmit: (values) => {
        setCompanyLine2Address(`#${values.companyLine2Address}`)
      },
    })

    return (
      <WebUI.Alert
        ref={forwardedRef}
        aria-label="Organization Address is unverifiable"
        className="[&_>_.ModalContentView]:max-w-screen-sm"
        {...restProps}
      >
        {(dialog) => (
          <>
            <WebUI.AlertHeader>
              Error: Organization Address is unverifiable
            </WebUI.AlertHeader>
            <WebUI.VStack className="gap-5 p-8">
              <WebUI.Form
                onSubmit={(values) => {
                  dialog.hide()
                  formik.handleSubmit(values)
                }}
              >
                <WebUI.FormField
                  label="Provide suite or unit number:"
                  className="mt-5"
                  error={formik.errors.companyLine2Address}
                >
                  <WebUI.Input
                    className="mb-3 max-w-[240px]"
                    name="companyLine2Address"
                    placeholder="123"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </WebUI.FormField>
                <WebUI.Button
                  variant="primary"
                  type="submit"
                  size="large"
                  className="self-center"
                >
                  Save
                </WebUI.Button>
              </WebUI.Form>
            </WebUI.VStack>
          </>
        )}
      </WebUI.Alert>
    )
  },
)

export default RequestMailedSecurityCodePage
