import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import React, {useMemo} from 'react'
import {InferResponse, api, endpoints} from '@cheddarup/api-client'
import {LinkButton} from 'src/components/LinkButton'

export interface VisitorsProps extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.TabDetailed
}

const Visitors = ({collection, className, ...restProps}: VisitorsProps) => {
  const visitorsQuery = api.tabMembers.list.useQuery({
    pathParams: {
      tabId: collection.id,
    },
    headers: {
      'User-Id': collection.organizer.uuid ?? '',
    },
  })

  const totalVisits = Util.sumBy(
    visitorsQuery.data ?? [],
    (v) => v.identify_count,
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.VStack as={WebUI.Panel}>
        <WebUI.HStack className="items-center gap-4 px-6 py-4">
          <WebUI.PhosphorIcon
            className="rounded bg-teal-70 p-1 text-ds-2xl text-trueWhite"
            icon="eye"
          />
          <span className="font-normal text-ds-md">Visitors</span>
        </WebUI.HStack>
        <WebUI.Separator variant="primary" />
        <WebUI.HStack className="[&_>_.Stack]:flex-[0_0_50%] [&_>_.Stack]:p-8">
          <WebUI.VStack>
            <div className="font-semibold text-ds-xs text-gray400">
              TOTAL VISITORS
            </div>
            <div className="font-normal text-ds-xl">
              {visitorsQuery.data?.length ?? 0}
            </div>
          </WebUI.VStack>
          <WebUI.Separator orientation="vertical" variant="primary" />
          <WebUI.VStack>
            <div className="font-semibold text-ds-xs text-gray400">
              TOTAL VISITS
            </div>
            <div className="font-normal text-ds-xl">{totalVisits}</div>
          </WebUI.VStack>
        </WebUI.HStack>
      </WebUI.VStack>

      <WebUI.Panel>
        <VisitorsTable
          userUuid={collection.organizer.uuid ?? ''}
          tabId={collection.id}
        />
      </WebUI.Panel>
    </WebUI.VStack>
  )
}

// MARK: – VisitorsTable

type Visitor = InferResponse<typeof endpoints.tabMembers.list>[number]

interface VisitorsTableProps
  extends Omit<WebUI.TableViewProps<Visitor>, 'columns' | 'data'> {
  userUuid: string
  tabId: number
}

const VisitorsTable = ({userUuid, tabId, ...restProps}: VisitorsTableProps) => {
  const visitorsQuery = api.tabMembers.list.useQuery({
    pathParams: {
      tabId,
    },
    headers: {
      'User-Id': userUuid,
    },
  })

  const columnHelper = useMemo(() => WebUI.createColumnHelper<Visitor>(), [])

  const columns = useMemo(
    () => [
      columnHelper.accessor((visitor) => visitor.name, {
        id: 'visitorOverview',
        header: 'Visitor',
        cell: ({cell, row: {original: visitor}}) => (
          <WebUI.VStack>
            <div className="font-semibold text-ds-sm">{cell.getValue()}</div>
            <div className="font-normal text-ds-xs text-gray400">
              {visitor.email}
            </div>
          </WebUI.VStack>
        ),
      }),
      columnHelper.accessor((visitor) => visitor.created_at, {
        id: 'firstVisit',
        meta: {
          align: 'right',
        },
        header: 'First Visit',
        cell: ({cell}) => Util.formatDateAs(cell.getValue(), 'date_tabular'),
      }),
      columnHelper.accessor((visitor) => visitor.identify_count, {
        id: 'identifyCount',
        meta: {
          align: 'right',
        },
        header: '# of Visits',
        cell: ({cell}) => (
          <WebUI.Tag className="text-ds-xs">{cell.getValue()}</WebUI.Tag>
        ),
      }),
      columnHelper.accessor(
        (visitor) => Util.sumBy(visitor.payments ?? [], (p) => p.total),
        {
          id: 'total',
          meta: {
            align: 'right',
          },
          header: 'Payments',
          cell: ({cell}) => Util.formatAmount(cell.getValue()),
        },
      ),
    ],
    [columnHelper],
  )

  return (
    <WebUI.TableView
      className="[&_.TableView-headerGroup]:px-4 [&_.TableViewRow]:px-4"
      sortable
      sortByTogglesVisible
      loading={visitorsQuery.isPending}
      columns={columns}
      data={visitorsQuery.data ?? []}
      getRowProps={(row) =>
        ({
          as: LinkButton,
          className: 'px-0 h-auto [font-size:inherit] text-left',
          to: `/reporting/payers/${row.original.id}`,
        }) as any
      }
      {...restProps}
    />
  )
}

export default Visitors
