import {ForwardRefComponent} from '@cheddarup/react-util'
import * as WebUI from '@cheddarup/web-ui'
import {forwardRef} from 'react'

export interface CreditCardSelectRowProps extends WebUI.HStackProps {
  creditCard: Api.CreditCard
}

const CreditCardSelectRow = forwardRef(
  ({creditCard, className, ...restProps}, forwardedRef) => (
    <WebUI.HStack
      ref={forwardedRef}
      className={WebUI.cn(
        'CreditCardSelectRow',
        'items-center gap-2 rounded bg-depr-grey-200 px-3 py-2',
        className,
      )}
      {...restProps}
    >
      <WebUI.CreditCardBrandLogo
        className="CreditCardSelectRow-brandLogo"
        brand={creditCard.brand}
      />
      <WebUI.Ellipsis className="CreditCardSelectRow-brandTitle hidden grow font-light md:inline">
        {creditCard.brand}
      </WebUI.Ellipsis>
      {creditCard.nickname && (
        <WebUI.Ellipsis className="CreditCardSelectRow-nickname grow font-light">
          {creditCard.nickname}
        </WebUI.Ellipsis>
      )}
      <div className="CreditCardSelectRow-last4">*{creditCard.last4}</div>
    </WebUI.HStack>
  ),
) as ForwardRefComponent<'div', CreditCardSelectRowProps>

export default CreditCardSelectRow
