import React, {useEffect, useRef, useState} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import useScrollToHash from 'src/hooks/useScrollToHash'
import {api, useUpdateTabMutation} from '@cheddarup/api-client'
import {bannedWordIncludedIn} from 'src/helpers/inputHelpers'
import {useDebounceCallback, useFormik} from '@cheddarup/react-util'
import {STATEMENT_DESCRIPTOR_MAX_LEN} from 'src/helpers/user-helpers'

import {CollectionSettingsPanel} from './CollectionSettingsPanel'
import {CollectionSettingsUpgradePlanButton} from './CollectionSettingsUpgradePlanButton'
import {TaxFormModal, TaxItem} from './Taxes'
import {SettingDisclosureSwitch} from './SettingDisclosureSwitch'

// MARK: – PaymentMethodsSettings

export interface PaymentMethodsSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
  collectionHasRecurringItems: boolean
}

export const PaymentMethodsSettings = ({
  collection,
  collectionHasRecurringItems,
  ...restProps
}: PaymentMethodsSettingsProps) => {
  const {data: userCurrency} = api.auth.session.useQuery(undefined, {
    select: (session) => session.user.currency,
  })
  const {data: userProfile} = api.auth.session.useQuery(undefined, {
    select: (session) => session.user.profile,
  })
  const updateCollectionMutation = useUpdateTabMutation()
  const debouncedUpdateTab = useDebounceUpdateTab()

  const offlinePaymentInstructionsWarning = (() => {
    if (bannedWordIncludedIn(collection.offline_payment_instructions)) {
      return 'Your instructions contain restricted language (mention of another payment platform) and will not appear at checkout. This field is closely monitored and if a workaround is entered, your collection may be at risk of being shut down, with payments auto refunded.'
    }
    if (collectionHasRecurringItems) {
      return 'Note: This setting is not available on carts with recurring items.'
    }
    return null
  })()

  const allowPaymentsLabel = 'Allow payments by eCheck'

  return (
    <CollectionSettingsPanel
      heading="Payment methods"
      settings={[
        <WebUI.Disclosure key="disclosure" className="gap-4" visible>
          <WebUI.DisclosureSwitch disabled>Credit Cards</WebUI.DisclosureSwitch>
          <WebUI.DisclosureContent className="text-ds-sm">
            Payment by credit card is always offered by default and can not be
            disabled.
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
        userCurrency === 'usd' ? (
          <WebUI.Disclosure
            key="allow_echeck_payments"
            className="gap-4"
            visible={collection.allow_echeck_payments}
            onVisibleChange={(newAllowEcheckPayments) =>
              debouncedUpdateTab({
                pathParams: {
                  tabId: collection.id,
                },
                body: {allow_echeck_payments: newAllowEcheckPayments},
              })
            }
          >
            {collection.status === 'draft' ||
            collection.is_pro ||
            userProfile?.features?.hasTeamEcheckFees ? (
              <SettingDisclosureSwitch
                featureKey={
                  !collection.is_pro &&
                  !userProfile?.features?.hasTeamEcheckFees
                    ? 'paymentByEcheck'
                    : undefined
                }
              >
                {allowPaymentsLabel}
              </SettingDisclosureSwitch>
            ) : (
              <CollectionSettingsUpgradePlanButton plan="pro">
                {allowPaymentsLabel}
              </CollectionSettingsUpgradePlanButton>
            )}
            <WebUI.DisclosureContent className="text-ds-sm">
              EChecks (electronic checks) take up to 5 business days to process.
            </WebUI.DisclosureContent>
          </WebUI.Disclosure>
        ) : null,
        <WebUI.Disclosure
          key="allow_offline_payments"
          className="gap-4"
          visible={!!collection.allow_offline_payments}
          onVisibleChange={(newAllowEcheckPayments) => {
            debouncedUpdateTab({
              pathParams: {
                tabId: collection.id,
              },
              body: {allow_offline_payments: newAllowEcheckPayments},
            })
          }}
        >
          <WebUI.DisclosureSwitch>
            Allow payments by cash or check
          </WebUI.DisclosureSwitch>
          {offlinePaymentInstructionsWarning && (
            <div className="max-w-[70%] text-orange-500">
              {offlinePaymentInstructionsWarning}
            </div>
          )}
          <WebUI.DisclosureContent>
            <p className="mx-0 my-[10px] text-ds-sm">
              <strong>Important note:</strong> If your collection contains
              tickets, your customers will receive their QR code ticket(s)
              immediately after checkout, likely prior to them submitting their
              cash or check payment.
            </p>
            <WebUI.DeferredRichTextMarkdown
              className="min-h-[120px] max-w-[550px] text-ds-sm"
              placeholder="Optional: Provide mailing or delivery details for those who choose to pay by cash or check"
              initialMarkdownValue={
                collection.offline_payment_instructions ?? ''
              }
              commitValue={(newOfflinePaymentInstructions) =>
                updateCollectionMutation.mutate({
                  pathParams: {
                    tabId: collection.id,
                  },
                  body: {
                    offline_payment_instructions: newOfflinePaymentInstructions,
                  },
                })
              }
            />
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
      ]}
      {...restProps}
    />
  )
}

// MARK: - CreditCartStatementSettings

export interface CreditCartStatementSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const CreditCartStatementSettings = ({
  collection,
  ...restProps
}: CreditCartStatementSettingsProps) => {
  const updateCollectionMutation = useUpdateTabMutation()
  const debouncedUpdateTab = useDebounceUpdateTab()
  useScrollToHash()

  return (
    <CollectionSettingsPanel
      id="statement_descriptor"
      heading="Credit Card Statement"
      settings={[
        <WebUI.Disclosure
          key="customCardDescriptor"
          className="gap-4"
          visible={collection.options?.customCardDescriptor?.enabled ?? false}
          onVisibleChange={(newCustomCardDescriptorEnabled) =>
            debouncedUpdateTab({
              pathParams: {
                tabId: collection.id,
              },
              body: {
                options: {
                  customCardDescriptor: {
                    enabled: newCustomCardDescriptorEnabled,
                  },
                },
              },
            })
          }
        >
          <WebUI.DisclosureSwitch>
            <span className="flex flex-col sm:flex-row">
              Customize what payers see on their credit card statement:&nbsp;
              <span className="text-orange-500">
                {collection.statement_descriptor}
              </span>
            </span>
          </WebUI.DisclosureSwitch>
          <WebUI.DisclosureContent>
            <WebUI.VStack className="gap-4">
              <WebUI.Text className="font-light text-ds-sm">
                Choose something that your payers will recognize to avoid
                disputes (numbers and special characters not allowed).
              </WebUI.Text>
              <WebUI.HStack className="items-center gap-4">
                <WebUI.DeferredInput
                  size="compact"
                  placeholder="Credit card descriptor"
                  maxLength={STATEMENT_DESCRIPTOR_MAX_LEN}
                  defaultValue={collection.statement_descriptor ?? ''}
                  commitValue={(newValue) =>
                    updateCollectionMutation.mutate({
                      pathParams: {
                        tabId: collection.id,
                      },
                      body: {
                        options: {
                          customCardDescriptor: {
                            enabled: true,
                            value: newValue,
                          },
                        },
                      },
                    })
                  }
                  {...restProps}
                />
                <WebUI.Text className="font-light text-ds-sm text-gray400">
                  You are limited to {STATEMENT_DESCRIPTOR_MAX_LEN} characters.
                </WebUI.Text>
              </WebUI.HStack>
            </WebUI.VStack>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
      ]}
      {...restProps}
    />
  )
}

// MARK: – FeesSettings

export interface FeesSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const FeesSettings = ({collection, ...restProps}: FeesSettingsProps) => {
  const {data: userCurrency} = api.auth.session.useQuery(undefined, {
    select: (session) => session.user.currency,
  })
  const debouncedUpdateTab = useDebounceUpdateTab()
  const feesSettingsModalRef = useRef<WebUI.DialogInstance>(null)
  const isUserBasic = !collection.is_pro

  return (
    <CollectionSettingsPanel
      heading="Fees"
      settings={[
        <WebUI.Disclosure
          key="processing_preference"
          className="gap-2"
          visible={
            collection.processing_preference === 'user' ||
            !!collection.options?.payerCanCoverFees
          }
          onVisibleChange={(newProcessingPreferenceEnabled) => {
            if (!newProcessingPreferenceEnabled) {
              debouncedUpdateTab({
                pathParams: {
                  tabId: collection.id,
                },
                body: {
                  processing_preference: 'member',
                  options: {payerCanCoverFees: false},
                },
              })
            }
          }}
        >
          <WebUI.DisclosureSwitch>
            {{
              usd: isUserBasic
                ? 'Cover fees for your payers'
                : 'Cover all or some fees for your payers',
              cad: 'Cover fees for your payers',
            }[userCurrency ?? 'usd'] ?? ''}
          </WebUI.DisclosureSwitch>
          <WebUI.DisclosureContent>
            <WebUI.VStack className="gap-4">
              <WebUI.Text className="font-light text-ds-sm">
                Give your payers the option to cover fees at checkout and
                include a custom message.
              </WebUI.Text>
              <WebUI.Button
                size="compact"
                className="self-start"
                onClick={() => feesSettingsModalRef.current?.show()}
              >
                View Options
              </WebUI.Button>
            </WebUI.VStack>
            <FeesSettingsModal
              ref={feesSettingsModalRef}
              collection={collection}
              onSuccess={() => feesSettingsModalRef.current?.hide()}
            />
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
      ]}
      {...restProps}
    />
  )
}

// MARK: PhoneResetAlert

interface FeesSettingsModalProps extends WebUI.DialogProps {
  collection: Api.Tab
  onSuccess: () => void
}

const FeesSettingsModal = React.forwardRef<
  WebUI.DialogInstance,
  FeesSettingsModalProps
>(({collection, onSuccess, ...restProps}, forwardedRef) => {
  const {data: userCurrency} = api.auth.session.useQuery(undefined, {
    select: (session) => session.user.currency,
  })
  const updateCollectionMutation = useUpdateTabMutation()
  const isUserBasic = !collection.is_pro

  const formik = useFormik({
    initialValues: {
      processing_preference: collection.processing_preference,
      options: {
        payerCanCoverFees: collection.options?.payerCanCoverFees,
        payerCanCoverFeesCustomMessage:
          collection.options?.payerCanCoverFeesCustomMessage ??
          'This will add a small amount to your total to cover convenience fees for the organizer.',
        coverOnlyEcheckFees: collection.options?.coverOnlyEcheckFees ?? false,
      },
    },
    onSubmit: async (values) => {
      await updateCollectionMutation.mutateAsync({
        pathParams: {
          tabId: collection.id,
        },
        body: values,
      })
      onSuccess()
    },
  })
  return (
    <WebUI.Modal
      ref={forwardedRef}
      aria-label="Fee Settings Modal"
      initialVisible={false}
      className="[&_>_.ModalContentView]:h-full [&_>_.ModalContentView]:max-w-screen-lg"
      {...restProps}
    >
      <WebUI.ModalHeader className="border-b-0">
        <WebUI.PageHeader>Fee Options</WebUI.PageHeader>
      </WebUI.ModalHeader>
      <WebUI.ModalCloseButton />
      <WebUI.VStack className="grow gap-4 px-9">
        <WebUI.Disclosure
          className="gap-3"
          visible={formik.values.processing_preference === 'user'}
          onVisibleChange={(newProcessingPreferenceEnabled) => {
            formik.setFieldValue(
              'processing_preference',
              newProcessingPreferenceEnabled ? 'user' : 'member',
            )
            if (!newProcessingPreferenceEnabled) {
              formik.setFieldValue('options.payerCanCoverFees', false)
            }
          }}
        >
          <WebUI.DisclosureSwitch>
            {{
              usd: isUserBasic
                ? 'Cover fees for your payers'
                : 'Cover all or some fees for your payers',
              cad: 'Cover fees for your payers',
            }[userCurrency ?? 'usd'] ?? ''}
          </WebUI.DisclosureSwitch>
          <WebUI.DisclosureContent>
            {userCurrency === 'usd' && !isUserBasic && (
              <WebUI.VStack className="gap-2">
                <WebUI.RadioGroup
                  name="coverOnlyEcheckFees"
                  aria-label="Discount Applies To"
                  state={formik.values.options.coverOnlyEcheckFees.toString()}
                  onChange={(e) =>
                    formik.setFieldValue(
                      'options.coverOnlyEcheckFees',
                      e.target.value === 'true',
                    )
                  }
                >
                  <WebUI.Radio value="false">Cover all fees</WebUI.Radio>
                  <WebUI.Radio value="true">
                    Cover only eCheck fees (if included as payment method)
                  </WebUI.Radio>
                </WebUI.RadioGroup>
              </WebUI.VStack>
            )}
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>
        <WebUI.Separator />
        <WebUI.Disclosure
          className="gap-3"
          visible={!!formik.values.options.payerCanCoverFees}
          onVisibleChange={(newPayerCanCoverFees) =>
            formik.setFieldValue(
              'options.payerCanCoverFees',
              newPayerCanCoverFees,
            )
          }
        >
          <WebUI.DisclosureSwitch
            disabled={formik.values.processing_preference !== 'user'}
          >
            Give payers the option to cover fees
          </WebUI.DisclosureSwitch>
          <WebUI.DisclosureContent className="text-ds-sm">
            <WebUI.VStack className="gap-2">
              <WebUI.Text>Include a custom message for payers</WebUI.Text>
              <WebUI.DeferredRichTextMarkdown
                className="min-h-[100px] max-w-[480px] text-ds-sm"
                placeholder="This will add a small amount to your total to cover convenience fees for the organizer."
                initialMarkdownValue={
                  formik.values.options.payerCanCoverFeesCustomMessage
                }
                commitValue={(newCustomMessage) =>
                  formik.setFieldValue(
                    'options.payerCanCoverFeesCustomMessage',
                    newCustomMessage,
                  )
                }
              />
            </WebUI.VStack>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>
        <WebUI.Separator />
      </WebUI.VStack>
      <WebUI.PageToolbar>
        <WebUI.PageToolbarSubmitButton
          arrow={false}
          disabled={!formik.values.processing_preference || formik.isSubmitting}
          loading={formik.isSubmitting}
          onClick={formik.submitForm}
        >
          Save Fee Options
        </WebUI.PageToolbarSubmitButton>
      </WebUI.PageToolbar>
    </WebUI.Modal>
  )
})

// MARK: – ReceiptsSettings

export interface ReceiptsSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const ReceiptsSettings = ({
  collection,
  ...restProps
}: ReceiptsSettingsProps) => {
  const [isReceiptContentBanned, setIsReceiptContentBanned] = useState(false)
  const updateCollectionMutation = useUpdateTabMutation()
  const debouncedUpdateTab = useDebounceUpdateTab()
  const isBasicCollection = !collection.is_pro && !collection.is_team
  const isCollectionPublished = collection.status !== 'draft'
  const enforceAddlGatFeatures = !collection.options?.doNotEnforceAddlGated
  const label = 'Customize payer receipt'

  return (
    <CollectionSettingsPanel
      heading="Receipts"
      settings={[
        <WebUI.Disclosure
          key="custom_receipt_enabled"
          className="gap-4"
          visible={!!collection.custom_receipt_enabled}
          onVisibleChange={(newCustomReceiptEnabled) =>
            debouncedUpdateTab({
              pathParams: {
                tabId: collection.id,
              },
              body: {custom_receipt_enabled: newCustomReceiptEnabled},
            })
          }
        >
          {isBasicCollection &&
          isCollectionPublished &&
          enforceAddlGatFeatures ? (
            <CollectionSettingsUpgradePlanButton plan="pro">
              {label}
            </CollectionSettingsUpgradePlanButton>
          ) : (
            <SettingDisclosureSwitch
              featureKey={
                isBasicCollection && enforceAddlGatFeatures
                  ? 'customReceipt'
                  : undefined
              }
            >
              {label}
            </SettingDisclosureSwitch>
          )}

          {isReceiptContentBanned && (
            <WebUI.Text className="text-ds-sm text-orange-500">
              Your message contains restricted language (mention of another
              payment platform). If it is not removed, your receipts will not be
              customized. This field is closely monitored and if a workaround is
              entered, your collection may be at risk of being shut down, with
              payments auto refunded.
            </WebUI.Text>
          )}
          <WebUI.DisclosureContent>
            <WebUI.DeferredRichTextMarkdown
              className="min-h-[200px] max-w-[550px] text-ds-sm"
              placeholder="Enter message to appear at top of the receipt"
              initialMarkdownValue={collection.custom_receipt_content ?? ''}
              commitValue={(newCustomReceiptContent) => {
                if (bannedWordIncludedIn(newCustomReceiptContent)) {
                  setIsReceiptContentBanned(true)
                } else {
                  setIsReceiptContentBanned(false)
                  updateCollectionMutation.mutate({
                    pathParams: {
                      tabId: collection.id,
                    },
                    body: {
                      custom_receipt_content: newCustomReceiptContent,
                    },
                  })
                }
              }}
              {...restProps}
            >
              <WebUI.RichTextEditorToolbar
                rootClassName="-order-1"
                pick={['h1', 'bold', 'a', 'ol', 'ul', 'hr']}
              />
            </WebUI.DeferredRichTextMarkdown>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
      ]}
      {...restProps}
    />
  )
}

// MARK: – CollectionPageSettings

export interface CollectionPageSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
}

export const CollectionPageSettings = ({
  collection,
  ...restProps
}: CollectionPageSettingsProps) => {
  const debouncedUpdateTab = useDebounceUpdateTab()
  const {data: collectionItems} = api.tabItems.list.useQuery({
    pathParams: {
      tabId: collection.id,
    },
  })

  const hasItemsWithNonZeroAmount = collectionItems?.some(
    ({amount, amount_type, options}) =>
      (amount == null &&
        (amount_type === 'open' || options?.variants?.enabled)) ||
      (amount != null && amount > 0),
  )

  return (
    <CollectionSettingsPanel
      heading="Collection page"
      settings={[
        <WebUI.Disclosure
          key="requirePayment"
          className="gap-4"
          visible={!!collection.options?.requirePayment}
          onVisibleChange={(newRequirePayment) =>
            debouncedUpdateTab({
              pathParams: {
                tabId: collection.id,
              },
              body: {options: {requirePayment: newRequirePayment}},
            })
          }
        >
          <WebUI.DisclosureSwitch
            disabled={
              !collection.options?.requirePayment && !hasItemsWithNonZeroAmount
            }
          >
            Require payment for at least one item
          </WebUI.DisclosureSwitch>
          <WebUI.DisclosureContent className="text-ds-sm">
            <WebUI.Text className="font-bold">
              Only toggle this on if everyone who visits your page is making a
              payment.
            </WebUI.Text>{' '}
            Visitors will not be allowed to proceed to checkout until they add
            at least one item that requires payment to their cart.
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
      ]}
      {...restProps}
    />
  )
}

// MARK: – TaxesSettings

export interface TaxesSettingsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collection: Api.Tab
  collectionHasRecurringItems: boolean
}

export const TaxesSettings = ({
  collection,
  collectionHasRecurringItems,
  ...restProps
}: TaxesSettingsProps) => {
  const updateCollectionMutation = useUpdateTabMutation()
  const debouncedUpdateTab = useDebounceUpdateTab()

  const taxes = Array.isArray(collection.taxes) ? collection.taxes : []

  const updateCollection = updateCollectionMutation.mutate
  useEffect(() => {
    if (!collection.is_pro && collection.status !== 'draft') {
      updateCollection({
        pathParams: {
          tabId: collection.id,
        },
        body: {taxes_enabled: false},
      })
    }
  }, [collection.id, collection.is_pro, collection.status, updateCollection])

  const label = 'Include tax at checkout'

  return (
    <CollectionSettingsPanel
      heading="Taxes"
      settings={[
        <WebUI.Disclosure
          key="taxes_enabled"
          className="gap-4"
          visible={collection.taxes_enabled}
          onVisibleChange={(newTaxesEnabled) =>
            debouncedUpdateTab({
              pathParams: {
                tabId: collection.id,
              },
              body: {taxes_enabled: newTaxesEnabled},
            })
          }
        >
          {collection.status === 'draft' || collection.is_pro ? (
            <SettingDisclosureSwitch
              featureKey={collection.is_pro ? undefined : 'taxes'}
            >
              {label}
            </SettingDisclosureSwitch>
          ) : (
            <CollectionSettingsUpgradePlanButton plan="pro">
              {label}
            </CollectionSettingsUpgradePlanButton>
          )}
          {collection.taxes_enabled && collectionHasRecurringItems && (
            <WebUI.Text className="max-w-[70%] text-ds-sm text-orange-500">
              Note: Taxes will only apply to recurring payment items set to
              occur immediately (not scheduled for a future day of the month)
              and will only apply to the first payment.
            </WebUI.Text>
          )}
          <WebUI.DisclosureContent>
            <WebUI.VStack className="w-3/4 gap-4">
              <div className="text-ds-sm">
                Apply taxes to your entire collection or specific items
              </div>
              {taxes.map((tax, index) => (
                <TaxItem
                  key={index}
                  collectionId={collection.id}
                  index={index}
                  onDelete={(deletedTaxIdx) =>
                    updateCollectionMutation.mutate({
                      pathParams: {
                        tabId: collection.id,
                      },
                      body: {
                        taxes: taxes.filter(
                          (_tax, idx) => idx !== deletedTaxIdx,
                        ),
                      },
                    })
                  }
                  tax={tax}
                />
              ))}
              <WebUI.HStack>
                <TaxFormModal
                  disclosure={
                    <WebUI.DialogDisclosure variant="secondary">
                      Add New Tax
                    </WebUI.DialogDisclosure>
                  }
                  collectionId={collection.id}
                />
              </WebUI.HStack>
            </WebUI.VStack>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>,
      ]}
      {...restProps}
    />
  )
}

// MARK: – Helpers

export function useDebounceUpdateTab(wait = 500) {
  const updateCollectionMutation = useUpdateTabMutation()

  return useDebounceCallback(
    (...args: Parameters<(typeof updateCollectionMutation)['mutate']>) => {
      updateCollectionMutation.mutate(...args)
    },
    wait,
    true,
  )
}
