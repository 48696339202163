import * as Ariakit from '@ariakit/react'
import React, {useImperativeHandle} from 'react'

import {NextButton} from './Button'
import {Heading} from '../Heading'
import {PhosphorIcon} from '../../icons'
import {cn} from '../../utils'

export interface NextPopoverInstance extends Ariakit.PopoverStore {}

export interface NextPopoverProps extends Ariakit.PopoverStoreProps {
  children:
    | React.ReactNode
    | ((popover: NextPopoverInstance) => React.ReactNode)
}

export const NextPopover = React.forwardRef<
  NextPopoverInstance,
  NextPopoverProps
>(({children, ...restProps}, forwardedRef) => {
  const popoverStore = Ariakit.usePopoverStore(restProps)

  useImperativeHandle(forwardedRef, () => popoverStore, [popoverStore])

  return (
    <Ariakit.PopoverProvider store={popoverStore}>
      {typeof children === 'function' ? children(popoverStore) : children}
    </Ariakit.PopoverProvider>
  )
})

// MARK: - PopoverDisclosure

export interface NextPopoverDisclosureProps
  extends Ariakit.PopoverDisclosureProps {}

export const NextPopoverDisclosure = React.forwardRef<
  HTMLButtonElement,
  NextPopoverDisclosureProps
>((props, forwardedRef) => (
  <Ariakit.PopoverDisclosure
    ref={forwardedRef}
    render={<NextButton />}
    {...props}
  />
))

// MARK: - PopoverDisclosure

export interface NextPopoverDismissProps
  extends Ariakit.PopoverDisclosureProps {}

export const NextPopoverDismiss = React.forwardRef<
  HTMLButtonElement,
  NextPopoverDismissProps
>(({children, className, ...restProps}, forwardedRef) => (
  <Ariakit.PopoverDismiss
    ref={forwardedRef}
    className={cn('absolute top-4 right-4 text-lg', className)}
    render={<NextButton size="xs" variant="outlined" />}
    {...restProps}
  >
    {children ?? <PhosphorIcon icon="x" />}
  </Ariakit.PopoverDismiss>
))

// MARK: - PopoverAnchor

export interface NextPopoverAnchorProps extends Ariakit.PopoverAnchorProps {}
export const NextPopoverAnchor = Ariakit.PopoverAnchor

// MARK: - PopoverContent

export interface NextPopoverContentProps extends Ariakit.PopoverProps {}

export const NextPopoverContent = React.forwardRef<
  HTMLDivElement,
  NextPopoverContentProps
>(({className, ...restProps}, forwardedRef) => {
  return (
    <Ariakit.Popover
      ref={forwardedRef}
      className={cn(
        'flex scale-90 flex-col overflow-auto rounded-default bg-trueWhite opacity-0 shadow-z16 transition-all data-[enter]:scale-100 data-[enter]:opacity-100',
        className,
      )}
      fitViewport
      gutter={8}
      {...restProps}
    />
  )
})

// MARK: - PopoverHeading

export interface NextPopoverHeadingProps extends Ariakit.PopoverAnchorProps {}

export const NextPopoverHeading = React.forwardRef<
  HTMLHeadingElement,
  NextPopoverHeadingProps
>((props, forwardedRef) => (
  <Ariakit.PopoverHeading ref={forwardedRef} render={<Heading />} {...props} />
))

// MARK: - PopoverDescription

export interface NextPopoverDescriptionProps
  extends Ariakit.PopoverDescriptionProps {}

export const NextPopoverDescription = Ariakit.PopoverDescription
