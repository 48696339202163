import {parseAbsolute} from '@internationalized/date'
import type {NonEmptyObject} from 'type-fest'

import {pick} from './object-utils'
import {firstBy, keys} from './array-utils'

export function getCurrentTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function isCuSupportedTimeZone(
  tzId: string,
): tzId is (typeof cuSupportedTimeZoneIds)[number] {
  return (cuSupportedTimeZoneIds as any as string[]).includes(tzId)
}

export function getDefaultTimeZone(
  tzMap: NonEmptyObject<Partial<TimezoneMap>> = timeZoneIdToNameMap,
) {
  const currentTzId = getCurrentTimeZone()

  if (isCuSupportedTimeZone(currentTzId)) {
    return currentTzId
  }

  const compareDate = new Date()
  const currentTzOffset = -compareDate.getTimezoneOffset() * 60 * 1000
  const tzOffsets = keys(tzMap).map(
    (tzId) =>
      [tzId, parseAbsolute(compareDate.toISOString(), tzId).offset] as const,
  )

  const tzOffsetDeltas = tzOffsets.map(
    ([tzId, tzOffset]) => [tzId, Math.abs(tzOffset - currentTzOffset)] as const,
  )

  return firstBy(tzOffsetDeltas, ([_tzId, tzOffset]) => tzOffset)?.[0]
}

export function getDefaultCuTimeZone() {
  return getDefaultTimeZone(cuSupportedTimeZonesIdToNameMap)
}

export const timeZoneIdToNameMap = {
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'America/Juneau': 'Alaska',
  'America/Boise': 'Mountain Time',
  'America/Dawson': 'Dawson, Yukon',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Phoenix': 'Arizona',
  'America/Chicago': 'Central Time',
  'America/Regina': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Belize': 'Central America',
  'America/Detroit': 'Eastern Time',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Tijuana': 'Tijuana',
  'America/Montevideo': 'Montevideo',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'America/Los_Angeles': 'Pacific Time',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  GMT: 'UTC',
  'Europe/London': 'Edinburgh, London',
  'Europe/Dublin': 'Dublin',
  'Europe/Lisbon': 'Lisbon',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Atlantic/Canary': 'Canary Islands',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': 'West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens, Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Istanbul, Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Kamchatka': 'Kamchatka, Marshall Islands',
  'Pacific/Fiji': 'Fiji Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa",
  'America/Puerto_Rico': 'Puerto Rico',
  'America/St_Thomas': 'U.S. Virgin Islands',
  'America/Denver': 'Denver',
}

export type TimezoneMap = typeof timeZoneIdToNameMap

export const cuSupportedTimeZoneIds = [
  'America/Los_Angeles',
  'America/Boise',
  'America/Chicago',
  'America/Detroit',
  'America/Juneau',
  'Pacific/Honolulu',
  'America/St_Thomas',
] as const

export const cuSupportedTimeZonesIdToNameMap = pick(
  timeZoneIdToNameMap,
  cuSupportedTimeZoneIds,
)
