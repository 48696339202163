import * as WebUI from '@cheddarup/web-ui'
import React, {useImperativeHandle, useRef} from 'react'

import {
  CollectionsHeaderMobile,
  CollectionsHeaderMobileInstance,
} from './CollectionsMobileLayout/CollectionsMobileLayout'
import {
  SlideOutNav,
  SlideOutNavLinkButtonProps,
  SlideOutNavProps,
} from './SlideOutNav'
import {AppHeader} from './AppHeader'
import CollectionAppBanner from './CollectionAppBanner'
import {useManagerRole, useManagerRoleId} from './ManageRoleProvider'
import {NavLinkButton} from './LinkButton'

export interface CollectionsLayoutInstance {
  showFinishSetup: () => void
}

export interface CollectionsLayoutProps
  extends React.ComponentPropsWithoutRef<'div'> {
  banner?: React.ReactNode
}

const CollectionsLayout = React.forwardRef<
  CollectionsLayoutInstance,
  CollectionsLayoutProps
>(({banner, className, children, ...restProps}, forwardedRef) => {
  const appHeaderMobileRef = useRef<CollectionsHeaderMobileInstance>(null)
  const media = WebUI.useMedia()

  useImperativeHandle(forwardedRef, () => ({
    showFinishSetup: () => appHeaderMobileRef.current?.showFinishSetup(),
  }))

  return (
    <WebUI.VStack
      className={WebUI.cn(
        'CollectionsLayout',
        'max-h-full shrink-0 grow basis-auto',
        className,
      )}
      {...restProps}
    >
      <WebUI.VStack>
        {media.sm ? (
          <AppHeader />
        ) : (
          <CollectionsHeaderMobile ref={appHeaderMobileRef} />
        )}
        <CollectionAppBanner />
      </WebUI.VStack>

      {banner}

      {media.sm ? (
        <div className="relative flex min-h-0 grow">
          <CollectionsNav />
          <div className="CollectionsLayout-contentContainer flex min-w-0 grow flex-col px-10 py-8">
            {children}
          </div>
        </div>
      ) : (
        <WebUI.VStack className="CollectionsLayout-contentContainer grow py-6">
          {children}
        </WebUI.VStack>
      )}
    </WebUI.VStack>
  )
})

// MARK: – CollectionsNav

const CollectionsNav: React.FC<SlideOutNavProps> = (props) => {
  const [managerRoleId] = useManagerRoleId()
  const [managerRole] = useManagerRole()
  const [isCollapsed, setIsCollapsed] = WebUI.useLocalStorage(
    'collections_nav',
    false,
  )

  const isManager = managerRoleId != null

  return (
    <SlideOutNav
      variant={isManager ? 'default' : 'secondary'}
      isCollapsed={isCollapsed}
      {...props}
    >
      <div
        className={WebUI.cn(
          'flex px-2 py-3',
          isCollapsed ? 'justify-center' : 'justify-end',
        )}
      >
        <WebUI.DeprecatedTooltip
          variant="light"
          placement="right"
          label={isCollapsed ? 'Show Menu' : 'Hide Menu'}
        >
          <WebUI.IconButton
            onClick={() =>
              setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed)
            }
          >
            <WebUI.PhosphorIcon
              icon={
                isCollapsed
                  ? 'arrow-circle-right-fill'
                  : 'arrow-circle-left-fill'
              }
              color="white"
              width={24}
            />
          </WebUI.IconButton>
        </WebUI.DeprecatedTooltip>
      </div>
      <SlideOutNavLinkButtonWithToolTip
        iconBefore={<WebUI.PhosphorIcon icon="briefcase" />}
        to="/collections"
        tooltipHeading="Collections Dashboard"
        tooltipText="Manage and create collections from your collections dashboard"
      >
        Collections
      </SlideOutNavLinkButtonWithToolTip>
      {(!isManager || managerRole?.permissions.role === 'admin') && (
        <SlideOutNavLinkButtonWithToolTip
          iconBefore={<WebUI.PhosphorIcon icon="user-circle-plus" />}
          to="/managers"
          tooltipHeading="Managers"
          tooltipText="Give managers access to collection-specific reporting with ease"
        >
          Managers
        </SlideOutNavLinkButtonWithToolTip>
      )}
      {(!isManager || managerRole?.permissions?.group_page) && (
        <SlideOutNavLinkButtonWithToolTip
          iconBefore={<WebUI.PhosphorIcon icon="link" />}
          to="/group"
          tooltipHeading="Group Homepage"
          tooltipText="Create a custom-branded webpage for your group"
        >
          Group Page
        </SlideOutNavLinkButtonWithToolTip>
      )}
      {(!isManager || managerRole?.permissions.type === 'all') && (
        <SlideOutNavLinkButtonWithToolTip
          iconBefore={<WebUI.PhosphorIcon icon="chart-bar" />}
          to="/reports"
          tooltipHeading="Report Center"
          tooltipText="Tap into collecting trends with reporting across your entire account"
        >
          Reports
        </SlideOutNavLinkButtonWithToolTip>
      )}
    </SlideOutNav>
  )
}

// MARK: – SlideOutNavLinkButtonWithToolTip

export interface SlideOutNavLinkButtonWithToolTipProps
  extends SlideOutNavLinkButtonProps {
  tooltipHeading: string
  tooltipText: string
}

export const SlideOutNavLinkButtonWithToolTip: React.FC<
  SlideOutNavLinkButtonWithToolTipProps
> = ({className, tooltipHeading, tooltipText, ...restProps}) => (
  <WebUI.Tooltip placement="bottom-start">
    <NavLinkButton
      className={WebUI.cn('SlideOutNavLinkButton', className)}
      as={WebUI.TooltipAnchor}
      {...restProps}
    />
    <WebUI.TooltipContent className="max-w-[300px]" variant="light">
      <div className="flex flex-col gap-1 text-start">
        <WebUI.Text className="font-extrabold">{tooltipHeading}</WebUI.Text>
        <WebUI.Text>{tooltipText}</WebUI.Text>
      </div>
    </WebUI.TooltipContent>
  </WebUI.Tooltip>
)

export default CollectionsLayout
