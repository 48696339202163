import * as WebUI from '@cheddarup/web-ui'
import {useEffect} from 'react'
import {LinkButton} from 'src/components/LinkButton'
import {Logo} from 'src/components'

const ThankYouPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="flex grow flex-col">
      <div className="flex min-h-17 flex-row items-center bg-depr-grey-50 px-3 shadow-[0_1px_3px_#0000000a]">
        <LinkButton to="/">
          <Logo />
        </LinkButton>
      </div>

      <div className="flex grow flex-col items-center justify-center gap-6">
        <WebUI.Heading className="text-center font-accent text-gray400 italic">
          Thank you for using Cheddar Up
        </WebUI.Heading>
        <WebUI.Text className="max-w-[420px] text-center font-light text-black">
          Your account has been deleted. We’re sorry to see you go, but we’re
          here should you need us in the future.
        </WebUI.Text>
      </div>
    </div>
  )
}

export default ThankYouPage
