import {StringParam, useQueryParam, withDefault} from 'use-query-params'
import React from 'react'
import {useParams} from 'react-router-dom'
import Papa from 'papaparse'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'
import * as Util from '@cheddarup/util'
import {Link} from 'src/components/Link'
import {LinkButton} from 'src/components/LinkButton'
import {SearchForm} from 'src/components'
import {useResponsesCsvDataQuery} from 'src/hooks/useResponsesCsvDataQuery'

import TicketFieldViewsList from './TicketFieldViewsList'
import TicketPaymentsTable from './TicketPaymentsTable'

const TicketSpecificPage = () => {
  const urlParams = useParams()
  const [selectedTab, setSelectedTab] = useQueryParam(
    't',
    withDefault(StringParam, 'attendees-table'),
  )
  const collectionId = Number(urlParams.collection)
  const ticketId = Number(urlParams.ticket)
  const [viewBy] = useQueryParam(
    'viewBy',
    withDefault(StringParam, 'attendees'),
  )
  const itemReportQuery = api.tabItems.report.useQuery({
    pathParams: {
      // biome-ignore lint/style/noNonNullAssertion:
      tabId: urlParams.collection!,
      // biome-ignore lint/style/noNonNullAssertion:
      itemId: urlParams.ticket!,
    },
  })
  const responsesCsvDataQuery = useResponsesCsvDataQuery(
    collectionId,
    ticketId,
    itemReportQuery.data?.options.fieldSets,
  )

  return (
    <WebUI.SegmentedTabs
      className="min-w-[min(100%,640px)] grow"
      initialSelectedId="attendees-table"
      currentId={selectedTab}
      selectedId={selectedTab}
      onChangeCurrentId={(newPaneKey) => setSelectedTab(newPaneKey)}
    >
      <WebUI.VStack className="gap-5">
        {itemReportQuery.data && (
          <WebUI.Panel className="gap-4 py-6" as={WebUI.VStack}>
            <WebUI.VStack className="gap-2 px-6">
              <Link
                className="text-ds-sm"
                variant="primary"
                iconBefore={<WebUI.PhosphorIcon icon="arrow-left" />}
                to="../items"
              >
                Back to all items
              </Link>

              <WebUI.VStack className="gap-1">
                <WebUI.HStack className="items-center gap-3">
                  <WebUI.PhosphorIcon icon="ticket" />
                  <WebUI.Text>{itemReportQuery.data.name}</WebUI.Text>
                </WebUI.HStack>
                <WebUI.VStack className="ml-7">
                  <WebUI.Text className="text-ds-sm">
                    Collected:{' '}
                    {Util.formatAmount(itemReportQuery.data.amount_sold ?? 0)}
                  </WebUI.Text>
                  <WebUI.Text className="text-ds-sm">
                    Tickets Sold: {itemReportQuery.data.quantity_sold}
                  </WebUI.Text>
                </WebUI.VStack>
              </WebUI.VStack>
            </WebUI.VStack>

            {responsesCsvDataQuery.data &&
              responsesCsvDataQuery.data.csvData.length > 0 && (
                <>
                  <WebUI.VStack className="items-stretch justify-center gap-4 px-6 md:flex-row md:items-center md:justify-between">
                    <WebUI.SegmentedTabList
                      className={`min-w-[240px] bg-teal-90 [&_>_.SegmentedTab[aria-selected="true"]]:text-orange-500 [&_>_.SegmentedTab]:text-teal-50`}
                    >
                      {itemReportQuery.data.fields.length > 0 && (
                        <>
                          <WebUI.SegmentedTab id="attendees-table">
                            Attendees
                          </WebUI.SegmentedTab>
                          <WebUI.SegmentedTab id="responses-table">
                            Responses
                          </WebUI.SegmentedTab>
                        </>
                      )}
                    </WebUI.SegmentedTabList>
                    {selectedTab === 'responses-table' && (
                      <WebUI.VStack className="gap-4 sm:flex-row">
                        <WebUI.DeprecatedTooltip label="CSV file of all responses">
                          <WebUI.Button
                            variant="secondary"
                            iconAfter={
                              <WebUI.PhosphorIcon
                                icon="download-simple"
                                width={20}
                              />
                            }
                            onClick={() => {
                              const csv = Papa.unparse({
                                fields: [
                                  'Payer',
                                  'Email',
                                  'Date Completed',
                                  ...(responsesCsvDataQuery.data
                                    ?.possibleFieldNames ?? []),
                                ],
                                data: responsesCsvDataQuery.data?.csvData ?? [],
                              })

                              WebUI.downloadFile(
                                new Blob([csv], {
                                  type: 'data:text/csv;charset=utf-8',
                                }),
                                `${itemReportQuery.data?.name}.csv`,
                              )
                            }}
                          >
                            Download Responses
                          </WebUI.Button>
                        </WebUI.DeprecatedTooltip>
                        <WebUI.DeprecatedTooltip label="PDF file of all responses">
                          <LinkButton
                            variant="secondary"
                            iconAfter={
                              <WebUI.PhosphorIcon icon="printer" width={20} />
                            }
                            target="_blank"
                            to={
                              viewBy === 'attendees'
                                ? `/pdf/collection/${collectionId}/item/${ticketId}/field-views-report`
                                : `/pdf/collection/${collectionId}/tab-object/${ticketId}/fields`
                            }
                          >
                            Print Responses
                          </LinkButton>
                        </WebUI.DeprecatedTooltip>
                      </WebUI.VStack>
                    )}
                  </WebUI.VStack>
                  <WebUI.TabPanel tabId="responses-table">
                    <ItemResponsesDisplayOptions
                      collectionId={collectionId}
                      itemId={ticketId}
                    />
                  </WebUI.TabPanel>
                </>
              )}
          </WebUI.Panel>
        )}
        <WebUI.TabPanel tabId="attendees-table">
          <TicketPaymentsTable
            collectionId={collectionId}
            data={itemReportQuery.data?.payment_items}
          />
        </WebUI.TabPanel>
        <WebUI.TabPanel tabId="responses-table">
          <TicketFieldViewsList collectionId={collectionId} />
        </WebUI.TabPanel>
      </WebUI.VStack>
    </WebUI.SegmentedTabs>
  )
}

// MARK: – ItemResponsesDisplayOptions

interface ItemResponsesDisplayOptionsProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  itemId: number
}

const ItemResponsesDisplayOptions: React.FC<
  ItemResponsesDisplayOptionsProps
> = ({collectionId, itemId, className, ...restProps}) => {
  const [viewBy, setViewBy] = useQueryParam(
    'viewBy',
    withDefault(StringParam, 'attendees'),
  )
  const [searchKeyword, setSearchKeyword] = useQueryParam(
    'search',
    withDefault(StringParam, ''),
  )

  return (
    <WebUI.VStack className={WebUI.cn('gap-4', className)} {...restProps}>
      <WebUI.Separator variant="primary" />
      <WebUI.VStack className="items-stretch justify-end gap-4 px-6 md:flex-row md:items-end">
        <WebUI.FormField className="max-w-[240px] grow">
          <WebUI.DropdownSelect<string | null>
            size="compact"
            placeholder="Select View"
            value={viewBy ?? null}
            onValueChange={(newViewBy) => setViewBy(newViewBy)}
          >
            <WebUI.DropdownSelectOption value="attendees">
              View responses by attendee
            </WebUI.DropdownSelectOption>
            <WebUI.DropdownSelectOption value="questions">
              View responses by question
            </WebUI.DropdownSelectOption>
          </WebUI.DropdownSelect>
        </WebUI.FormField>
        {viewBy === 'attendees' && (
          <SearchForm
            className="min-w-[250px] text-ds-xs placeholder:text-ds-xs"
            size="compact"
            placeholder="Search by attendee or payer name"
            initialValues={{term: searchKeyword}}
            onSubmit={({term: newSearchKeyword}) =>
              setSearchKeyword(newSearchKeyword)
            }
          />
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  )
}

export default TicketSpecificPage
