import {
  Dialog as ReakitDialog,
  DialogBackdrop as ReakitDialogBackdrop,
  DialogBackdropOptions as ReakitDialogBackdropOptions,
  DialogDisclosure as ReakitDialogDisclosure,
  DialogDisclosureOptions as ReakitDialogDisclosureOptions,
  DialogInitialState as ReakitDialogInitialState,
  DialogOptions as ReakitDialogOptions,
  DialogStateReturn as ReakitDialogStateReturn,
  useDialogState as useReakitDialogState,
} from 'reakit'
import {
  ForwardRefComponent,
  useForkRef,
  useLiveRef,
  useUpdateEffect,
} from '@cheddarup/react-util'
import React, {useContext, useId, useImperativeHandle, useState} from 'react'

import {usePreventBodyScroll} from '../hooks/usePreventBodyScroll'
import {Button} from './Button'
import {cn} from '../utils'

interface InternalDialogContextValue extends ReakitDialogStateReturn {}

const InternalDialogContext = React.createContext(
  {} as InternalDialogContextValue,
)

// MARK: - Dialog

export interface DialogInstance extends ReakitDialogStateReturn {}
export interface DialogProps extends ReakitDialogInitialState {
  children?: React.ReactNode | ((dialog: DialogInstance) => React.ReactNode)
  initialVisible?: boolean
  onVisibleChange?: (newVisible: boolean) => void
  onDidShow?: () => void
  onDidHide?: () => void
}

export const Dialog = React.forwardRef<DialogInstance, DialogProps>(
  (
    {
      baseId,
      initialVisible,
      visible,
      animated,
      modal,
      onVisibleChange,
      onDidShow,
      onDidHide,
      children,
    },
    forwardedRef,
  ) => {
    const dialog = useReakitDialogState({
      baseId,
      visible: visible ?? initialVisible,
      animated,
      modal,
    })
    const dialogRef = useLiveRef(dialog)
    const onVisibleChangeRef = useLiveRef(onVisibleChange)
    const onDidShowRef = useLiveRef(onDidShow)
    const onDidHideRef = useLiveRef(onDidHide)

    useImperativeHandle(forwardedRef, () => dialog, [dialog])

    useUpdateEffect(() => {
      if (visible != null) {
        dialogRef.current.setVisible(visible)
      }
    }, [visible])

    useUpdateEffect(() => {
      if (!dialog.animating) {
        onVisibleChangeRef.current?.(dialog.visible)
        if (dialog.visible) {
          onDidShowRef.current?.()
        }
      }
      if (!dialog.visible) {
        onDidHideRef.current?.()
      }
    }, [dialog.visible, dialog.animating])

    return (
      <InternalDialogContext.Provider value={dialog}>
        {typeof children === 'function' ? children(dialog) : children}
      </InternalDialogContext.Provider>
    )
  },
)

// MARK: - DialogDisclosure

export interface DialogDisclosureProps
  extends Omit<ReakitDialogDisclosureOptions, keyof ReakitDialogStateReturn> {}

export const DialogDisclosure = React.forwardRef(
  ({as = Button, className, style, ...restProps}, forwardedRef) => {
    const dialog = useContext(InternalDialogContext)
    return (
      <ReakitDialogDisclosure
        ref={forwardedRef}
        className={cn('DialogDisclosure', className)}
        as={as}
        style={{
          pointerEvents: 'initial',
          ...style,
        }}
        {...dialog}
        {...restProps}
      />
    )
  },
) as ForwardRefComponent<typeof Button, DialogDisclosureProps>

// MARK: - DialogBackdrop

export interface DialogBackdropProps
  extends Omit<ReakitDialogBackdropOptions, keyof ReakitDialogStateReturn> {
  children?: React.ReactNode | ((dialog: DialogInstance) => React.ReactNode)
}

export const DialogBackdrop = React.forwardRef(
  ({as: Comp = 'div', children, className, ...restProps}, forwardedRef) => {
    const dialog = useContext(InternalDialogContext)
    return (
      <ReakitDialogBackdrop
        ref={forwardedRef}
        className={cn('DialogBackdrop', className)}
        {...dialog}
        {...(restProps as any)}
      >
        {(props: any) => {
          if (!dialog.visible && !dialog.animating) {
            return <>{null}</>
          }
          return (
            <Comp {...props}>
              {typeof children === 'function' ? children(dialog) : children}
            </Comp>
          )
        }}
      </ReakitDialogBackdrop>
    )
  },
) as ForwardRefComponent<'div', DialogBackdropProps>

// MARK: - DialogContent

export interface DialogContentProps
  extends Omit<ReakitDialogOptions, keyof ReakitDialogStateReturn> {
  children?: React.ReactNode | ((dialog: DialogInstance) => React.ReactNode)
}

export const DialogContent = React.forwardRef(
  (
    {
      as: Comp = 'div',
      preventBodyScroll,
      children,
      id: idProp,
      className,
      ...restProps
    },
    forwardedRef,
  ) => {
    const dialog = useContext(InternalDialogContext)
    const [ownElement, setOwnElement] = useState<HTMLElement | null>(null)
    const ref = useForkRef(forwardedRef, setOwnElement)

    const id = idProp ?? useId()

    usePreventBodyScroll(ownElement, id, preventBodyScroll && dialog.visible)

    return (
      <ReakitDialog
        ref={ref}
        id={id}
        className={cn('DialogContent', className)}
        preventBodyScroll={false}
        {...dialog}
        {...(restProps as any)}
      >
        {(props: any) => {
          if (!dialog.visible && !dialog.animating) {
            return <>{null}</>
          }
          return (
            <Comp {...props}>
              {typeof children === 'function' ? children(dialog) : children}
            </Comp>
          )
        }}
      </ReakitDialog>
    )
  },
) as ForwardRefComponent<'div', DialogContentProps>
