import React from 'react'
import {
  DelimitedNumericArrayParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'
import * as WebUI from '@cheddarup/web-ui'
import {MatchQueryParams} from 'src/components/WithQueryParams'
import {RoutableTab} from 'src/components/RoutableTabs'
import * as Util from '@cheddarup/util'

import CategoryPath from '../PayerPage/components/PayerPageToolbar/CategoryPath'
import VariantsFilterDropdown from '../PayerPage/components/PayerPageToolbar/VariantsFilterDropdown'
import usePublicCollection from '../hooks/usePublicCollection'
import {
  getPayerSegments,
  isPayerSegmentDisabled,
  useCurrentPayerSegment,
  usePayerNavigateToSegment,
} from '../utils/payer-navigation-utils'
import useCart from '../hooks/useCart'
import {PayerCartPopover} from '../PayerPage/components/PayerCartPopover'
import {FeeTransparencyIcon} from 'src/components/FeeTransparencyIcon'

export interface PayerToolbarProps
  extends Omit<React.ComponentPropsWithoutRef<'div'>, 'children'> {}

export const PayerToolbar: React.FC<PayerToolbarProps> = ({
  className,
  ...restProps
}) => {
  const media = WebUI.useMedia()
  const currentSegment = useCurrentPayerSegment()
  const {publicCollection} = usePublicCollection()

  return (
    <div
      className={WebUI.cn(
        'relative flex flex-col justify-center overflow-hidden bg-trueWhite shadow-10 sm:flex-row sm:border-b sm:shadow-none',
        className,
      )}
      {...restProps}
    >
      {!media.sm && (
        <div className="border-b px-4">
          <PayerTabList />
        </div>
      )}
      <div
        className={WebUI.cn(
          'mx-4 flex h-[54px] max-w-screen-xl flex-[1_0_auto] flex-row items-center justify-between gap-3 *:min-w-0 *:flex-[1_1_0px] sm:mx-8',
        )}
      >
        {publicCollection.fee_transparency &&
          publicCollection.items.length > 0 && (
            <FeeTransparencyModal className="[&]:flex-0" />
          )}
        <div className="flex flex-row items-center">
          <PayerFitlers />
        </div>
        {media.sm && (
          <div className="!flex-0 sm:!flex-auto flex flex-row items-center justify-center">
            <PayerTabList />
          </div>
        )}

        <div className="flex flex-row items-center justify-end">
          {(currentSegment !== 'checkout' || !media.sm) && (
            <MatchQueryParams queryParams={{preview: true}}>
              {(isMatched) =>
                isMatched ? (
                  <WebUI.Text className="font-semibold text-ds-sm uppercase">
                    Preview Only
                  </WebUI.Text>
                ) : (
                  <PayerCartPopover />
                )
              }
            </MatchQueryParams>
          )}
        </div>
      </div>
    </div>
  )
}

// MARK: – PayerTabList

const PayerTabList: React.FC<
  WebUI.TabListProps & React.ComponentPropsWithoutRef<'div'>
> = ({className, ...restProps}) => {
  const {publicCollection} = usePublicCollection()
  const {cart} = useCart()
  const navigateToSegment = usePayerNavigateToSegment()

  const segments = getPayerSegments({publicCollection})

  return (
    <WebUI.TabList
      aria-label="Navigation"
      className={WebUI.cn(
        'border-b-0 sm:aria-orientation-horizontal:overflow-x-hidden [&_.Tab]:text-ds-sm [&_.Tab_>.Anchor-content]:font-semibold',
        '[&_.TabList-underline]:bg-[var(--payer-secondary-bg-color)]',
        className,
      )}
      {...restProps}
    >
      {segments.map((s) => {
        const isDisabled = isPayerSegmentDisabled({
          publicCollection,
          cart,
          segment: s,
        })

        return (
          <RoutableTab
            key={s}
            aria-disabled={isDisabled}
            className="aria-disabled:pointer-events-none aria-disabled:opacity-50"
            to={s}
            onClick={(event) => {
              event.preventDefault()
              navigateToSegment(s)
            }}
          >
            {
              {
                items: 'Items',
                forms: 'Forms',
                checkout: segments.includes('items') ? 'Checkout' : 'Submit',
              }[s]
            }
          </RoutableTab>
        )
      })}
    </WebUI.TabList>
  )
}

// MARK: – PayerFitlers

interface PayerFitlersProps extends React.ComponentPropsWithoutRef<'div'> {}

const PayerFitlers: React.FC<PayerFitlersProps> = ({
  className,
  ...restProps
}) => {
  const [_categoryPath] = useQueryParam(
    'categoryPath',
    withDefault(DelimitedNumericArrayParam, []),
  )
  const {publicCollection} = usePublicCollection()
  const currentSegment = useCurrentPayerSegment()

  const categoryPath = _categoryPath as [number?, string?]

  const anchoredCategories = publicCollection.categories.filter(
    (category) =>
      category.anchor &&
      publicCollection.items.some((i) => i.category?.id === category.id),
  )

  const order = Object.fromEntries(
    publicCollection.filterOrder?.map((x) => [x.key, x.order]) ?? [],
  )

  const categoryPathFilters =
    publicCollection.filters
      ?.filter(
        (i) =>
          i.categoryId === categoryPath[0] &&
          (!categoryPath[1] || i.subcategoryId === categoryPath[1]),
      )
      ?.map((v) => v.filters) ?? []

  const visibleFiltersCombined: Record<string, string[]> =
    categoryPathFilters &&
    Util.mergeWith(
      {},
      ...categoryPathFilters,
      (a: string[], b: string[]) =>
        (Array.isArray(a) && a.concat(b)) || undefined,
    )

  const visibleFilters = Object.fromEntries(
    Object.entries(visibleFiltersCombined).map(([k, v]) => [
      k,
      Util.sort(Util.unique(v)).asc((x) => (order[k] || []).indexOf(x)),
    ]),
  )

  return (
    <div
      className={WebUI.cn('flex items-center gap-3 overflow-hidden', className)}
      {...restProps}
    >
      {anchoredCategories.length > 0 && currentSegment !== 'checkout' && (
        <>
          <CategoryPath categories={anchoredCategories} />
          {Object.keys(visibleFilters ?? {}).length > 0 && (
            <VariantsFilterDropdown filters={visibleFilters} />
          )}
        </>
      )}
    </div>
  )
}

// MARK: - FeeTransparencyModal

const FeeTransparencyModal = ({
  className,
  ...restProps
}: React.ComponentPropsWithoutRef<'button'>) => {
  return (
    <WebUI.Modal
      className={WebUI.cn(
        'items-center justify-center bg-transparent',
        '[&_>_.ModalContentView]:h-auto [&_>_.ModalContentView]:max-w-md [&_>_.ModalContentView]:items-center',
        '[&_>_.ModalContentView]:gap-4 [&_>_.ModalContentView]:rounded-extended [&_>_.ModalContentView]:p-8',
      )}
      disclosure={
        <WebUI.DialogDisclosure
          className={WebUI.cn(
            '[&_>_.Button-iconBefore]:!mr-1 text-ds-sm sm:text-ds-base',
            className,
          )}
          iconBefore={<FeeTransparencyIcon className="text-ds-xl" />}
          variant="link"
          {...restProps}
        >
          Transparent Pricing
        </WebUI.DialogDisclosure>
      }
      initialVisible={false}
    >
      {(disclosure) => (
        <>
          <FeeTransparencyIcon className="text-ds-5xl" />
          <WebUI.Text className="font-extrabold text-ds-xl">
            Transparent Pricing
          </WebUI.Text>
          <WebUI.Text className="text-center font-light text-ds-lg">
            All prices include platform fees, ensuring no surprises at checkout.
          </WebUI.Text>
          <WebUI.Button
            variant="secondary"
            size="large"
            onClick={disclosure.hide}
          >
            Close
          </WebUI.Button>
        </>
      )}
    </WebUI.Modal>
  )
}
