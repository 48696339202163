import * as Yup from 'yup'
import {useParams} from 'react-router-dom'
import React, {useMemo, useRef} from 'react'
import * as Util from '@cheddarup/util'
import * as WebUI from '@cheddarup/web-ui'
import {
  api,
  useCreateSpotMutation,
  useCreateTimeSlotsBatchMutation,
  useDeleteTimeSlotsBatchMutation,
  useUpdateSpotMutation,
  useUpdateTimeSlotsBatchMutation,
} from '@cheddarup/api-client'
import {useForkRef, useFormik} from '@cheddarup/react-util'
import {getLocalTimeZone, toCalendarDateTime} from '@internationalized/date'
import {yupTimeSchema} from 'src/helpers/YupInternationalizedDateSchema'

import {
  TimeSlotsForm,
  TimeSlotsFormValues,
  TimeSlotsRepeatType,
  makeTransientTimeSlot,
} from './TimeSlotsForm'
import {TimeSlotsEditForm, TimeSlotsEditFormValues} from './TimeSlotsEditForm'

interface SpotFormValues<TMode extends 'create' | 'edit'> {
  name: string
  description: string
  available_quantity: string
  time_slots: TMode extends 'create'
    ? TimeSlotsFormValues[]
    : TMode extends 'edit'
      ? TimeSlotsEditFormValues[]
      : never
}

export type SpotFormFormik = ReturnType<
  typeof useFormik<SpotFormValues<'create' | 'edit'>>
>

export interface SpotFormModalProps extends WebUI.ModalProps {
  signUp: Api.SignUp | null
  spot: Api.SignUpSpot | null
}

export const SpotFormModal = React.forwardRef<
  WebUI.DialogInstance,
  SpotFormModalProps
>(
  (
    {signUp, spot: spotProp, initialVisible = false, className, ...restProps},
    forwardedRef,
  ) => {
    type TValues = SpotFormValues<'edit' | 'create'>

    const ownRef = useRef<WebUI.DialogInstance>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const richTextEditorRef = useRef<WebUI.RichTextEditorInstance>(null)
    const isAddingAnotherRef = useRef(false)
    const urlParams = useParams()
    const spotQuery = api.tabSignupSpots.detail.useQuery(
      {
        pathParams: {
          // biome-ignore lint/style/noNonNullAssertion:
          tabId: signUp?.tab_id!,
          // biome-ignore lint/style/noNonNullAssertion:
          signupId: signUp?.id!,
          // biome-ignore lint/style/noNonNullAssertion:
          spotId: spotProp?.id!,
        },
      },
      {
        enabled: signUp != null && spotProp != null,
      },
    )
    const createSpotMutation = useCreateSpotMutation()
    const updateSpotMutation = useUpdateSpotMutation()
    const createTimeSlotsBatchMutation = useCreateTimeSlotsBatchMutation()
    const updateTimeSlotsBatchMutation = useUpdateTimeSlotsBatchMutation()
    const deleteTimeSlotsBatchMutation = useDeleteTimeSlotsBatchMutation()
    const growlActions = WebUI.useGrowlActions()

    const tabId = Number(urlParams.collection)
    const spot = spotQuery.data
    const timeZone = signUp?.options.timeZone || getLocalTimeZone()

    const initialValues = useMemo<TValues>(() => {
      let timeSlots: TimeSlotsFormValues[] | TimeSlotsEditFormValues[] = [
        {
          uuid: Util.makeUuid(),
          repeatType: 'none' as const,
          date: null,
          endDate: null,
          times: [
            {
              start: null,
              end: null,
              available_quantity: '',
            },
          ],
        },
      ]

      if (spot) {
        const timeSlotsGroupedByStartTime = Util.groupBy(
          Util.sort(spot.time_slots.filter((ts) => !!ts.options.startTime)).asc(
            (ts) => ts.options.startTime ?? -1,
          ),
          (ts) =>
            Util.parseDateTime(ts.options.startTime ?? '')?.toAbsoluteString(),
        )
        timeSlots = Util.arrayFromObject(
          timeSlotsGroupedByStartTime,
          (date, tss) => ({
            date: Util.parseCalendarDate(date, timeZone),
            times: Util.sort(
              tss.map((ts) => ({
                id: ts.id,
                start: ts.options.startTime
                  ? Util.parseTime(ts.options.startTime, timeZone)
                  : null,
                end: ts.options.endTime
                  ? Util.parseTime(ts.options.endTime, timeZone)
                  : null,
                available_quantity: String(ts.available_quantity ?? ''),
              })),
            ).asc((ts) => ts.start?.toString()),
          }),
        )
      }

      return {
        name: spot?.name ?? '',
        description: spot?.description ?? '',
        available_quantity:
          signUp?.options.signupType === 'list'
            ? String(spot?.available_quantity ?? '')
            : '',
        time_slots: timeSlots,
      }
    }, [signUp?.options.signupType, spot, timeZone])

    const formik = useFormik<TValues>({
      enableReinitialize: true,
      validationSchema: Yup.object().shape({
        name: Yup.string().required('Required'),
        available_quantity: Yup.lazy((value) =>
          value === '' ? Yup.string() : Yup.number(),
        ),
        time_slots:
          signUp?.options.signupType === 'schedule'
            ? Yup.array().of(
                Yup.object().shape({
                  repeatType: spot ? Yup.mixed() : Yup.string(),
                  date: spot
                    ? Yup.date().typeError('Required').required('Required')
                    : Yup.date()
                        .typeError('Required')
                        .required('Required')
                        .min(
                          Util.subDays(new Date(), 1),
                          `Can't be earlier than today`,
                        ),
                  endDate: spot
                    ? Yup.mixed().nullable()
                    : Yup.date()
                        .typeError('Required')
                        .when(
                          'repeatType',
                          ([repeatType]: TimeSlotsRepeatType[], schema) =>
                            repeatType && repeatType !== 'none'
                              ? schema.when('date', ([date]) =>
                                  date && date.toString() !== 'Invalid Date'
                                    ? schema
                                        .min(
                                          date,
                                          `Can't be earlier than start date`,
                                        )
                                        .typeError('Required')
                                        .required('Required')
                                    : schema,
                                )
                              : schema.nullable(),
                        ),
                  times: Yup.array()
                    .of(
                      Yup.object().shape({
                        start: yupTimeSchema().required('Required'),
                        end: yupTimeSchema()
                          .required('Required')
                          .when('start', ([start], schema) => {
                            if (start) {
                              return schema.min(
                                start,
                                `Can't be earlier than start time`,
                              )
                            }
                            return schema
                          }),
                        available_quantity: Yup.lazy((value) =>
                          value === '' ? Yup.string() : Yup.number(),
                        ),
                      }),
                    )
                    .min(1, "Start and end times can't be empty"),
                }),
              )
            : Yup.mixed(),
      }),
      initialValues,
      onSubmit: async (values) => {
        if (!signUp) {
          return
        }

        try {
          const commonBody = {
            name: values.name,
            description: values.description,
            hidden: false,
          }

          if (spot) {
            const updatedSpot = await updateSpotMutation.mutateAsync({
              pathParams: {
                tabId,
                signupId: signUp.id,
                spotId: spot.id,
              },
              body: commonBody,
            })

            if (signUp.options.signupType === 'list') {
              const availableQuantity =
                values.available_quantity === ''
                  ? null
                  : Number(values.available_quantity)
              const defaultTimeSlot = updatedSpot.time_slots.find(
                (ts) => ts.options.isSignupListDefaultSlot,
              )

              if (
                defaultTimeSlot &&
                availableQuantity !== defaultTimeSlot.available_quantity
              ) {
                await updateTimeSlotsBatchMutation(
                  {
                    tabId,
                    signUpId: signUp.id,
                    spotId: updatedSpot.id,
                  },
                  [
                    {
                      ...defaultTimeSlot,
                      available_quantity: availableQuantity,
                    },
                  ],
                )
              }
            } else if (signUp.options.signupType === 'schedule') {
              const times = (
                values.time_slots as TimeSlotsEditFormValues[]
              ).flatMap((ts) => ts.times.map((t) => ({...t, date: ts.date})))

              const timeSlotsToCreate = times
                .filter((t): t is Omit<typeof t, 'id'> => t.id == null)
                .filter(
                  (
                    t,
                  ): t is Util.SetNonNullable<
                    typeof t,
                    'date' | 'start' | 'end'
                  > => !!t.date && !!t.start && !!t.end,
                )
                .map((t) => ({
                  options: {
                    startTime: toCalendarDateTime(t.date, t.start)
                      .toDate(timeZone)
                      .toISOString(),
                    endTime: toCalendarDateTime(t.date, t.end)
                      .toDate(timeZone)
                      .toISOString(),
                  },
                  available_quantity:
                    t.available_quantity === ''
                      ? null
                      : Number(t.available_quantity),
                }))
              const timeSlotsToUpdate = times
                .filter(
                  (t): t is Util.SetRequired<typeof t, 'id'> => t.id != null,
                )
                .filter(
                  (
                    t,
                  ): t is Util.SetNonNullable<
                    typeof t,
                    'date' | 'start' | 'end'
                  > => !!t.date && !!t.start && !!t.end,
                )
                .map((t) => ({
                  id: t.id,
                  options: {
                    startTime: toCalendarDateTime(t.date, t.start)
                      .toDate(timeZone)
                      .toISOString(),
                    endTime: toCalendarDateTime(t.date, t.end)
                      .toDate(timeZone)
                      .toISOString(),
                  },
                  available_quantity:
                    t.available_quantity === ''
                      ? null
                      : Number(t.available_quantity),
                }))
              const timeSlotIdsToDelete = spot.time_slots
                .filter((ts) => !!ts.id && !times.some((t) => t.id === ts.id))
                .map((ts) => ts.id)

              await Promise.all([
                timeSlotsToCreate.length > 0
                  ? createTimeSlotsBatchMutation(
                      {
                        tabId,
                        signUpId: signUp.id,
                        spotId: updatedSpot.id,
                      },
                      timeSlotsToCreate,
                    )
                  : Promise.resolve(null),
                timeSlotsToUpdate.length > 0
                  ? updateTimeSlotsBatchMutation(
                      {
                        tabId,
                        signUpId: signUp.id,
                        spotId: updatedSpot.id,
                      },
                      timeSlotsToUpdate,
                    )
                  : Promise.resolve(null),
                timeSlotIdsToDelete.length > 0
                  ? deleteTimeSlotsBatchMutation(
                      {
                        tabId,
                        signUpId: signUp.id,
                        spotId: updatedSpot.id,
                      },
                      timeSlotIdsToDelete,
                    )
                  : Promise.resolve(null),
              ])
            }
          } else {
            const newSpot = await createSpotMutation.mutateAsync({
              pathParams: {
                tabId,
                signupId: signUp.id,
              },
              body: {
                ...commonBody,
                available_quantity:
                  values.available_quantity === ''
                    ? null
                    : Number(values.available_quantity),
              },
            })

            if (signUp.options.signupType === 'schedule') {
              const timeSlots = values.time_slots as TimeSlotsFormValues[]
              const timeSlotsPayloads = timeSlots.flatMap((ts) =>
                makeTransientTimeSlot(ts, timeZone),
              )
              if (timeSlotsPayloads.length > 0) {
                await createTimeSlotsBatchMutation(
                  {
                    tabId,
                    signUpId: signUp.id,
                    spotId: newSpot.id,
                  },
                  timeSlotsPayloads,
                )
              }
            }
          }

          formik.resetForm()
          formik.setFieldValue('time_slots', [
            {
              uuid: Util.makeUuid(),
              repeatType: 'none' as const,
              date: null,
              endDate: null,
              times: [
                {
                  start: null,
                  end: null,
                  available_quantity: '',
                },
              ],
            },
          ])
          richTextEditorRef.current?.setMarkdownValue('')
          if (!isAddingAnotherRef.current) {
            ownRef.current?.hide()
          }
        } catch {
          growlActions.clear()
          growlActions.show('error', {
            title: spot ? "Couldn't update the spot." : "Couldn't add a spot.",
          })
        } finally {
          isAddingAnotherRef.current = false
        }
      },
    })

    return (
      <WebUI.Modal
        aria-label="Sign up spot form"
        ref={ref}
        className={WebUI.cn(
          '[&_>_.ModalContentView]:max-w-screen-sm [&_>_.ModalContentView]:overflow-y-auto [&_>_.ModalContentView]:pb-8',
          className,
        )}
        initialVisible={initialVisible}
        preventBodyScroll={false}
        {...restProps}
      >
        <WebUI.ModalCloseButton />
        <WebUI.ModalHeader variant="compact">
          {spot ? 'Edit Spot' : 'Add Spot'}
        </WebUI.ModalHeader>

        <form
          className={
            'flex flex-col gap-6 px-8 [&_>_*:not(.TimeSlotsForm)]:max-w-[340px]'
          }
          onReset={formik.handleReset}
          onSubmit={formik.handleSubmit}
        >
          <WebUI.FormField
            size="compact"
            required
            label="Spot Title"
            error={formik.errors.name}
          >
            <WebUI.Input
              name="name"
              size="compact"
              placeholder="Give your spot a title"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
          <WebUI.FormField
            size="compact"
            label="Description"
            error={formik.errors.description}
          >
            <WebUI.RichTextEditor
              key={formik.initialValues.description}
              ref={richTextEditorRef}
              className="text-ds-sm"
              name="description"
              placeholder="Describe your spot (optional)"
              initialMarkdownValue={formik.values.description}
              onMarkdownValueChange={(newDescription) =>
                formik.setFieldValue('description', newDescription)
              }
              onBlur={formik.handleBlur}
            />
          </WebUI.FormField>
          {signUp?.options.signupType === 'list' && (
            <div className="flex flex-col gap-3">
              <WebUI.FormField
                className="[&_>_.FormField-caption]:inline"
                size="compact"
                label="Available Spots"
                caption="“Full” will show when all available spots have been filled"
                error={formik.errors.available_quantity}
              >
                <WebUI.NumberInput
                  className="max-w-[120px]"
                  name="available_quantity"
                  placeholder="Qty."
                  value={formik.values.available_quantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </WebUI.FormField>
            </div>
          )}

          {signUp?.options.signupType === 'schedule' &&
            (spotProp || spot ? (
              <>
                {(formik.values.time_slots as TimeSlotsEditFormValues[]).map(
                  (tv, idx) => {
                    const timeSlotError = Array.isArray(
                      formik.errors.time_slots,
                    )
                      ? formik.errors.time_slots[idx]
                      : null
                    const timesError =
                      typeof timeSlotError === 'string'
                        ? null
                        : timeSlotError?.times

                    return (
                      <React.Fragment key={idx}>
                        <TimeSlotsEditForm
                          className="TimeSlotsForm"
                          formik={formik}
                          collectionId={signUp.tab_id}
                          signUpId={signUp.id}
                          // biome-ignore lint/style/noNonNullAssertion:
                          spot={(spot ?? spotProp)!}
                          timeValues={tv}
                        />
                        {typeof timesError === 'string' && (
                          <WebUI.Text
                            className="FormField-error text-ds-xs text-orange-500"
                            variant="danger"
                          >
                            {timesError}
                          </WebUI.Text>
                        )}
                      </React.Fragment>
                    )
                  },
                )}
                <WebUI.Button
                  className="text-ds-sm"
                  variant="link"
                  iconBefore={
                    <WebUI.PhosphorIcon icon="plus-circle-fill" width={20} />
                  }
                  onClick={() =>
                    formik.setFieldValue('time_slots', [
                      ...formik.values.time_slots,
                      {
                        uuid: Util.makeUuid(),
                        repeatType: 'none',
                        date: null,
                        endDate: null,
                        times: [
                          {
                            start: null,
                            end: null,
                            available_quantity: '',
                          },
                        ],
                        available_quantity: '',
                      },
                    ])
                  }
                >
                  Add Date
                </WebUI.Button>
              </>
            ) : (
              (formik.values.time_slots as TimeSlotsFormValues[]).map(
                (timeSlot, idx) => {
                  const timeSlotError = Array.isArray(formik.errors.time_slots)
                    ? formik.errors.time_slots[idx]
                    : null
                  const timesError =
                    typeof timeSlotError === 'string'
                      ? null
                      : timeSlotError?.times

                  return (
                    <React.Fragment key={timeSlot.uuid}>
                      <TimeSlotsForm
                        className="TimeSlotsForm"
                        timeZone={timeZone}
                        formik={formik}
                        timeSlot={timeSlot as TimeSlotsFormValues}
                      />
                      {typeof timesError === 'string' && (
                        <WebUI.Text
                          className="FormField-error text-ds-xs text-orange-500"
                          variant="danger"
                        >
                          {timesError}
                        </WebUI.Text>
                      )}
                      <WebUI.Button
                        className="text-ds-sm"
                        variant="link"
                        iconBefore={
                          <WebUI.PhosphorIcon
                            icon="plus-circle-fill"
                            width={20}
                          />
                        }
                        onClick={() =>
                          formik.setFieldValue('time_slots', [
                            ...formik.values.time_slots,
                            {
                              uuid: Util.makeUuid(),
                              repeatType: 'none',
                              date: null,
                              endDate: null,
                              times: [
                                {
                                  start: null,
                                  end: null,
                                  available_quantity: '',
                                },
                              ],
                              available_quantity: '',
                            },
                          ])
                        }
                      >
                        Add Date
                      </WebUI.Button>
                    </React.Fragment>
                  )
                },
              )
            ))}

          <WebUI.HStack className="gap-3">
            <WebUI.Button type="submit" loading={formik.isSubmitting}>
              Save
            </WebUI.Button>
            {!spot && (
              <WebUI.Button
                type="button"
                loading={formik.isSubmitting}
                onClick={() => {
                  isAddingAnotherRef.current = true
                  formik.submitForm()
                }}
              >
                Save and Add Another
              </WebUI.Button>
            )}
          </WebUI.HStack>
        </form>
      </WebUI.Modal>
    )
  },
)
