import * as WebUI from '@cheddarup/web-ui'
import * as Util from '@cheddarup/util'
import {Link} from 'react-router-dom'
import Carousel from 'nuka-carousel'
import React, {useCallback, useMemo, useState} from 'react'
import CollectionImageIcon from 'src/images/CollectionImageIcon.svg'
import ImagesUtils from 'src/helpers/ImagesUtils'

import {MarketplaceSectionTitle} from '../../components'

export interface CatalogGallerySectionProps
  extends React.ComponentPropsWithoutRef<'div'> {
  catalogs: Api.MarketplaceCatalog[]
}

const CatalogGallerySection = React.memo(
  ({catalogs, ...restProps}: CatalogGallerySectionProps) => {
    const [page, setPage] = useState(0)

    const sortedCatalogs = useMemo(
      () =>
        Util.sort(catalogs).by([
          {asc: (c) => c.display_order, desc: (c) => c.id},
        ]),
      [catalogs],
    )

    const handleGoForward = useCallback(() => {
      setPage((prevPage) => prevPage + 1)
    }, [])

    const handleGoBack = useCallback(() => {
      setPage((prevPage) => prevPage - 1)
    }, [])

    return (
      <div {...restProps}>
        <header className="flex items-center justify-between">
          <MarketplaceSectionTitle>
            Browse Latest Catalogs
          </MarketplaceSectionTitle>
          <GalleryArrowNavigation
            className="sm:mr-4"
            forwardActive={sortedCatalogs.length - (page + 1) * 2 > 0}
            backActive={page !== 0}
            onGoForward={handleGoForward}
            onGoBack={handleGoBack}
          />
        </header>
        <CatalogGallery
          catalogs={sortedCatalogs}
          page={page}
          onChangePage={setPage}
        />
      </div>
    )
  },
)

// MARK: – CatalogGallery

interface CatalogGalleryProps extends React.ComponentPropsWithoutRef<'div'> {
  catalogs: Api.MarketplaceCatalog[]
  page: number
  onChangePage: (nextPage: number) => void
}

const CatalogGallery = ({
  catalogs,
  page,
  onChangePage,
  className,
  ...restProps
}: CatalogGalleryProps) => {
  const chunkedCatalogs = useMemo(() => Util.chunk(catalogs, 2), [catalogs])

  return (
    <div
      className={WebUI.cn('mt-4 max-h-[12rem] *:max-h-[12rem]', className)}
      {...restProps}
    >
      {chunkedCatalogs.length > 0 && (
        <Carousel
          withoutControls
          slideIndex={page}
          heightMode="current"
          beforeSlide={(_prevSlide, nextSlide) => onChangePage(nextSlide)}
        >
          {chunkedCatalogs.map(([firstCatalog, secondCatalog]) =>
            firstCatalog ? (
              <React.Fragment
                key={
                  secondCatalog
                    ? `${firstCatalog.id}-${secondCatalog.id}`
                    : firstCatalog.id
                }
              >
                <CatalogGalleryItem
                  key={firstCatalog.id}
                  className="inline-block h-[12rem] w-full pt-4 lg:w-1/2 lg:pt-0 lg:pr-4"
                  catalog={firstCatalog}
                />
                {secondCatalog && (
                  <CatalogGalleryItem
                    key={secondCatalog.id}
                    className="inline-block h-[12rem] w-full pt-4 lg:w-1/2 lg:pt-0 lg:pr-4"
                    catalog={secondCatalog}
                  />
                )}
              </React.Fragment>
            ) : null,
          )}
        </Carousel>
      )}
    </div>
  )
}

// MARK: – CatalogGalleryItem

interface CatalogGalleryItemProps extends React.ComponentPropsWithoutRef<'a'> {
  catalog: Api.MarketplaceCatalog
}

const CatalogGalleryItem = React.memo(
  ({className, catalog, ...restProps}: CatalogGalleryItemProps) => {
    const imageSrc = useMemo(
      () =>
        catalog.logo
          ? ImagesUtils.getCroppedImageUrl(catalog.logo)
          : CollectionImageIcon,
      [catalog.logo],
    )

    return (
      <Link
        className={WebUI.cn('h-full', className)}
        to="/marketplace/shop-items"
        state={{catalogIds: [catalog.id]}}
        {...restProps}
      >
        <div
          className={
            'float-left flex h-full w-1/2 flex-col justify-center whitespace-normal bg-trueWhite'
          }
        >
          <h5 className="mx-4 font-normal text-[1.3125rem] text-gray800">
            {catalog.name}
          </h5>
        </div>
        <div
          className="float-left inline h-full w-1/2 bg-center bg-cover bg-trueWhite bg-no-repeat"
          style={{backgroundImage: `url('${imageSrc}')`}}
        />
      </Link>
    )
  },
)

// MARK: – GalleryArrowNavigation

interface GalleryArrowNavigationProps
  extends React.ComponentPropsWithoutRef<'div'> {
  forwardActive?: boolean
  backActive?: boolean
  onGoForward: () => void
  onGoBack: () => void
}

const GalleryArrowNavigation = ({
  forwardActive,
  backActive,
  onGoForward,
  onGoBack,
  className,
  ...restProps
}: GalleryArrowNavigationProps) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const handleClickBack = useCallback(
    Util.debounce(() => {
      if (backActive) {
        onGoBack()
      }
    }, 200),
    [backActive, onGoBack],
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const handleClickForward = useCallback(
    Util.debounce(() => {
      if (forwardActive) {
        onGoForward()
      }
    }, 200),
    [forwardActive, onGoForward],
  )

  return (
    <div className={WebUI.cn('whitespace-nowrap', className)} {...restProps}>
      <WebUI.IconButton
        className="text-ds-2xl"
        size="default_alt"
        disabled={!backActive}
        onClick={handleClickBack}
      >
        <WebUI.PhosphorIcon icon="arrow-circle-left" />
      </WebUI.IconButton>
      <WebUI.IconButton
        className="text-ds-2xl"
        size="default_alt"
        disabled={!backActive}
        onClick={handleClickForward}
      >
        <WebUI.PhosphorIcon icon="arrow-circle-right" />
      </WebUI.IconButton>
    </div>
  )
}

declare module 'nuka-carousel' {
  interface CarouselProps {
    children?: React.ReactNode
  }
}

export default CatalogGallerySection
