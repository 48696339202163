import React from 'react'
import * as WebUI from '@cheddarup/web-ui'

import {TicketFormImagesField} from './TicketFormImagesField'
import type {TicketFormFormik} from './TicketForm'
import {getLocalTimeZone} from '@internationalized/date'

export interface TicketFormImagesAndDescriptionProps
  extends React.ComponentPropsWithoutRef<'div'> {
  collectionId: number
  formik: TicketFormFormik
}

export const TicketFormImagesAndDescription: React.FC<
  TicketFormImagesAndDescriptionProps
> = ({collectionId, formik, className, ...restProps}) => (
  <WebUI.VStack
    className={WebUI.cn(
      '[&_.Disclosure]:border-depr-grey-200 [&_.Disclosure]:border-b [&_.Disclosure_.DisclosureContent]:pb-8',
      className,
    )}
    {...restProps}
  >
    <WebUI.Disclosure initialVisible>
      <WebUI.AccordionHeader>Description</WebUI.AccordionHeader>
      <WebUI.DisclosureContent>
        <WebUI.FormField
          label="Add a description of your ticket or event:"
          error={formik.errors.description}
        >
          <WebUI.RichTextEditor
            key={formik.initialValues.description}
            className="min-h-[200px] max-w-[512px]"
            name="description"
            placeholder="Description"
            initialMarkdownValue={formik.values.description}
            onMarkdownValueChange={(newDescription) =>
              formik.setFieldValue('description', newDescription)
            }
          >
            <WebUI.RichTextEditorToolbar
              className={
                '!justify-end [&_>_.RichTextEditorToolbar-contentMain]:flex-0 [&_>_.RichTextEditorToolbar-extra]:flex-[0_0_0px]'
              }
              rootClassName="-order-1"
              pick={['bold', 'italic', 'a', 'ul', 'ol']}
            />
          </WebUI.RichTextEditor>
        </WebUI.FormField>
      </WebUI.DisclosureContent>
    </WebUI.Disclosure>

    <WebUI.Disclosure>
      <WebUI.AccordionHeader>Image</WebUI.AccordionHeader>
      <WebUI.DisclosureContent>
        <WebUI.Text className="font-light text-ds-sm">
          By uploading an image, you agree that your images do not violate
          copyright permissions.
        </WebUI.Text>
        <TicketFormImagesField
          images={formik.values.images}
          onChange={(newImages) => formik.setFieldValue('images', newImages)}
        />
      </WebUI.DisclosureContent>
    </WebUI.Disclosure>

    <WebUI.Disclosure
      initialVisible={
        !!formik.values.options.time?.startTime ||
        !!formik.values.options.time?.endTime
      }
    >
      <WebUI.AccordionHeader>Event time and location</WebUI.AccordionHeader>
      <WebUI.DisclosureContent>
        <WebUI.VStack className="max-w-[380px] gap-4">
          <WebUI.FormField
            label="Start Date and Time"
            error={(formik.errors.options?.time as any)?.startTime}
          >
            <WebUI.DatePicker
              granularity="minute"
              size="compact"
              value={formik.values.options.time?.startTime}
              onValueChange={(newOpenDatetime) => {
                formik.setFieldValue('options.time.startTime', newOpenDatetime)
                if (!formik.values.options.time?.timeZone) {
                  formik.setFieldValue(
                    'options.time.timeZone',
                    getLocalTimeZone(),
                  )
                }
              }}
            />
          </WebUI.FormField>
          <WebUI.FormField
            label="End Date and Time"
            error={(formik.errors.options?.time as any)?.endTime}
          >
            <WebUI.DatePicker
              granularity="minute"
              size="compact"
              value={formik.values.options.time?.endTime}
              onValueChange={(newOpenDatetime) =>
                formik.setFieldValue('options.time.endTime', newOpenDatetime)
              }
            />
          </WebUI.FormField>

          {(formik.values.options.time?.startTime ||
            formik.values.options.time?.endTime) && (
            <WebUI.FormField
              label="Time Zone"
              error={(formik.errors.options?.time as any)?.timeZone}
            >
              <WebUI.SupportedTimeZoneCombobox
                className="max-w-[400px]"
                inputSize="compact"
                defaultValue={getLocalTimeZone()}
                value={formik.values.options.time?.timeZone}
                onValueChange={(newTimeZoneValue) =>
                  formik.setFieldValue(
                    'options.time.timeZone',
                    newTimeZoneValue,
                  )
                }
                onBlur={formik.handleBlur}
              />
            </WebUI.FormField>
          )}
          <WebUI.FormField label="Location (optional)">
            <WebUI.AddressCombobox
              className="max-w-[400px]"
              placement="top"
              inputSize="compact"
              defaultAddress={formik.values.options.location?.address}
              creatable
              onAddressChange={({address}) =>
                formik.setFieldValue('options.location.address', address)
              }
            />
          </WebUI.FormField>
        </WebUI.VStack>
      </WebUI.DisclosureContent>
    </WebUI.Disclosure>
  </WebUI.VStack>
)
