import React, {useRef} from 'react'
import {
  AriaCalendarCellProps,
  AriaCalendarGridProps,
  DateValue,
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
  useDatePicker,
} from 'react-aria'
import {
  CalendarState,
  CalendarStateOptions,
  DatePickerStateOptions,
  RangeCalendarState,
  useCalendarState,
  useDatePickerState,
} from 'react-stately'
import {SetOptional, SimpleMerge} from '@cheddarup/util'
import {genericForwardRef, useForkRef} from '@cheddarup/react-util'
import {getLocalTimeZone, getWeeksInMonth, today} from '@internationalized/date'

import {DateSegmentBoxProps} from './DateSegment'
import {Popover, PopoverContent, PopoverDisclosure} from './Popover'
import {DateInput, createCalendar} from './DateInput'
import {PhosphorIcon} from '../icons'
import {HStack, VStack} from './Stack'
import {IconButton} from './IconButton'
import {Text} from './Text'
import {Button} from './Button'
import {cn} from '../utils'

export interface DatePickerProps<T extends DateValue>
  extends Omit<
    SimpleMerge<DateSegmentBoxProps, DatePickerStateOptions<T>>,
    'createCalendar' | 'onChange'
  > {
  contentClassName?: string
  onValueChange?: DatePickerStateOptions<T>['onChange']
}

export const DatePicker = genericForwardRef(
  <T extends DateValue>(
    {
      size,
      variant,
      onValueChange,
      contentClassName,
      className,
      ...restProps
    }: DatePickerProps<T>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const state = useDatePickerState({
      onChange: onValueChange,
      ...restProps,
    })
    const {fieldProps, buttonProps, dialogProps, calendarProps} = useDatePicker(
      restProps,
      state,
      ownRef,
    )

    return (
      <Popover
        visible={state.isOpen}
        onVisibleChange={state.setOpen}
        placement="bottom-start"
      >
        <div
          ref={ref}
          className={cn('DatePicker-content relative max-w-full', className)}
        >
          <DateInput
            className={
              'DatePicker-dateInput w-full pr-9 [&.DatePicker-dateInput]:bg-trueWhite [&.DatePicker-dateInput]:text-left'
            }
            size={size}
            variant={variant}
            {...fieldProps}
          />

          <PopoverDisclosure
            className="DatePicker-disclosure -translate-y-1/2 absolute top-1/2 right-2 text-ds-lg"
            as={IconButton}
            size="default_alt"
            {...buttonProps}
            onClick={(event) => {
              event.preventDefault()
              state.toggle()
            }}
          >
            <PhosphorIcon icon="calendar-fill" />
          </PopoverDisclosure>
        </div>

        <PopoverContent
          className={cn(
            'DatePicker-content',
            '[&_>_.PopoverContent-inner_>_.PopoverContent-body]:!min-w-[240px] [&_>_.PopoverContent-inner_>_.PopoverContent-body]:!max-w-[240px]',
            contentClassName,
          )}
          {...dialogProps}
        >
          <Calendar
            {...calendarProps}
            onValueChange={(newValue) => {
              calendarProps.onChange?.(newValue)
              state.close()
            }}
          />
        </PopoverContent>
      </Popover>
    )
  },
)

// MARK: – Calendar

export interface CalendarProps<T extends DateValue>
  extends Omit<
    SimpleMerge<
      React.ComponentPropsWithoutRef<'div'>,
      SetOptional<CalendarStateOptions<T>, 'locale'>
    >,
    'createCalendar' | 'onChange'
  > {
  onValueChange?: (value: DateValue) => void
}

export const Calendar = genericForwardRef(
  <T extends DateValue>(
    {
      locale = 'en-US',
      onValueChange,
      className,
      ...restProps
    }: CalendarProps<T>,
    forwardedRef: React.Ref<HTMLDivElement>,
  ) => {
    const state = useCalendarState({
      locale,
      createCalendar,
      ...restProps,
      onChange: onValueChange,
    })
    const {calendarProps, prevButtonProps, nextButtonProps, title} =
      useCalendar(restProps, state)

    return (
      <VStack
        ref={forwardedRef}
        className={cn('Calendar', className)}
        {...calendarProps}
      >
        <HStack className="Calendar-navContainer items-center justify-between bg-teal-80 p-2">
          <IconButton
            className="Calendar-navPrev text-tint"
            size="default_alt"
            disabled={state.isPreviousVisibleRangeInvalid()}
            onClick={() => state.focusPreviousPage()}
            {...prevButtonProps}
          >
            <PhosphorIcon icon="caret-left" />
          </IconButton>

          <Text className="Calendar-navDisplayDate">{title}</Text>

          <IconButton
            className="Calendar-navNext text-tint"
            size="default_alt"
            disabled={state.isNextVisibleRangeInvalid()}
            onClick={() => state.focusNextPage()}
            {...nextButtonProps}
          >
            <PhosphorIcon icon="caret-right" />
          </IconButton>
        </HStack>
        <CalendarGrid state={state} />
      </VStack>
    )
  },
)

// MARK: – CalendarGrid

export interface CalendarGridProps
  extends SimpleMerge<
    React.ComponentPropsWithoutRef<'div'>,
    AriaCalendarGridProps
  > {
  locale?: string
  state: CalendarState | RangeCalendarState
}

export const CalendarGrid = React.forwardRef<HTMLDivElement, CalendarGridProps>(
  ({locale = 'en-US', state, className, ...restProps}, forwardedRef) => {
    const {gridProps, headerProps, weekDays} = useCalendarGrid(restProps, state)
    return (
      <VStack
        ref={forwardedRef}
        className={cn('CalendarGrid', className)}
        {...gridProps}
      >
        <HStack
          className="CalendarGrid-weekdaysContainer bg-teal-80 py-1 *:flex-[1_0_0px]"
          {...headerProps}
        >
          {weekDays.map((weekDayName, idx) => (
            <Text
              key={idx}
              className="Calendar-weekDay text-center font-light text-ds-xs"
            >
              {weekDayName}
            </Text>
          ))}
        </HStack>

        <VStack className="CalendarGrid-content gap-0_5 bg-trueWhite py-1">
          {[
            ...Array.from({
              length: getWeeksInMonth(state.visibleRange.start, locale),
            }).keys(),
          ].map((weekIdx) => (
            <HStack
              key={weekIdx}
              className="CalendarGrid-weekContainer gap-0_5 *:flex-[1_0_0px]"
            >
              {state
                .getDatesInWeek(weekIdx)
                .map((date, idx) =>
                  date ? (
                    <CalendarCell key={idx} state={state} date={date} />
                  ) : (
                    <div key={idx} />
                  ),
                )}
            </HStack>
          ))}
        </VStack>
      </VStack>
    )
  },
)

// MARK: – CalendarCell

export interface CalendarCellProps
  extends SimpleMerge<
    React.ComponentPropsWithoutRef<'div'>,
    AriaCalendarCellProps
  > {
  state: CalendarState | RangeCalendarState
}

export const CalendarCell = React.forwardRef<HTMLDivElement, CalendarCellProps>(
  ({date, state, className, ...restProps}, forwardedRef) => {
    const ownRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)
    const {
      cellProps,
      buttonProps,
      isOutsideVisibleRange,
      isDisabled,
      isUnavailable,
      formattedDate,
    } = useCalendarCell({...restProps, date}, state, ownRef)

    return (
      <div
        data-today={date.compare(today(getLocalTimeZone())) === 0}
        className={cn('CalendarCell group', className)}
        {...cellProps}
      >
        <Button
          ref={ref}
          className={`CalendarCell-button w-full p-0 group-aria-selected:bg-teal-80 group-aria-selected:group-focus:bg-teal-80 group-data-[today="true"]:bg-gray100`}
          hidden={isOutsideVisibleRange}
          size="compact"
          variant="ghost"
          disabled={isUnavailable || isDisabled}
          {...buttonProps}
        >
          {formattedDate}
        </Button>
      </div>
    )
  },
)
