import * as WebUI from '@cheddarup/web-ui'
import React, {useEffect, useState} from 'react'
import {api} from '@cheddarup/api-client'
import AddBannerImageIcon from 'src/images/AddBannerImageIcon.svg'
import {HeaderImagePickerModal} from 'src/components/HeaderImagePicker'
import CollectionBannerPlaceholder from 'src/images/CollectionBannerPlaceholder.jpg'

import type {CollectionFormFormik} from './CollectionDetailsPage'
import {SharpImage} from 'src/components/SharpImage'
import * as Util from '@cheddarup/util'
import {
  PlanUpgradeButton,
  PremiumFeatureSideSheetDisclosure,
  PremiumFeaturesSideSheet,
} from 'src/components/PremiumFeaturesSideSheet'
import {useManagerRoleId} from 'src/components/ManageRoleProvider'

export interface ImagePanelProps extends React.ComponentPropsWithoutRef<'div'> {
  formik: CollectionFormFormik
  collection?: Api.Tab
  header?: Api.Header
}

export const ImagePanel = ({
  formik,
  collection,
  className,
  ...restProps
}: ImagePanelProps) => {
  const [mrId] = useManagerRoleId()
  const isSubscribedToProQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_pro,
  })

  const isSubscribedToPro = isSubscribedToProQuery.data !== false || !!mrId

  return (
    <PremiumFeaturesSideSheet
      modal
      tabId={collection?.id}
      enforcedPremiumMeta={{
        pro: {
          multipleBannerImages: true,
          videoLink: true,
        },
      }}
    >
      <div className={WebUI.cn('group relative', className)} {...restProps}>
        <ImageSelectOverlay
          tab={collection}
          formik={formik}
          disclosure={
            !!formik.values.videoLink ||
            formik.values.headerImages.length > 0 ? (
              <WebUI.Carousel className="w-full" options={{loop: true}}>
                <WebUI.CarouselContent className="min-h-32">
                  {formik.values.headerImages.map((headerImage, idx) => (
                    <WebUI.CarouselItem
                      key={headerImage.id}
                      className="flex items-center justify-center bg-trueBlack"
                    >
                      <SharpImage
                        alt="Collection banner"
                        width="100%"
                        image={headerImage}
                      />

                      <div className="absolute top-4 right-4 flex flex-row gap-3">
                        {!isSubscribedToPro && idx > 0 && (
                          <PremiumFeatureSideSheetDisclosure />
                        )}
                        <WebUI.IconButton
                          className="text-ds-lg"
                          size="default_alt"
                          variant="secondary"
                          as={WebUI.DialogDisclosure}
                        >
                          <WebUI.PhosphorIcon icon="pencil" />
                        </WebUI.IconButton>
                        <WebUI.IconButton
                          className="text-ds-lg"
                          size="default_alt"
                          variant="secondary"
                          onClick={() =>
                            formik.setFieldValue(
                              'headerImages',
                              formik.values.headerImages.filter(
                                (hi) => hi !== headerImage,
                              ),
                            )
                          }
                        >
                          <WebUI.PhosphorIcon icon="x" />
                        </WebUI.IconButton>
                      </div>
                    </WebUI.CarouselItem>
                  ))}

                  {formik.values.videoLink.length > 0 && (
                    <WebUI.CarouselItem className="flex items-center justify-center bg-trueBlack">
                      <WebUI.VideoPlayer
                        className="[&_>_div]:!flex [&_>_div]:!items-center [&_>_div]:!justify-center"
                        width="100%"
                        height="100%"
                        url={formik.values.videoLink}
                      />

                      <div className="absolute top-4 right-4 flex flex-row gap-3">
                        {!isSubscribedToPro && (
                          <PremiumFeatureSideSheetDisclosure />
                        )}

                        <WebUI.IconButton
                          className="text-ds-lg"
                          size="default_alt"
                          variant="secondary"
                          as={WebUI.DialogDisclosure}
                        >
                          <WebUI.PhosphorIcon icon="pencil" />
                        </WebUI.IconButton>
                        <WebUI.IconButton
                          className="text-ds-lg"
                          size="default_alt"
                          variant="secondary"
                          onClick={() => formik.setFieldValue('videoLink', '')}
                        >
                          <WebUI.PhosphorIcon icon="x" />
                        </WebUI.IconButton>
                      </div>
                    </WebUI.CarouselItem>
                  )}
                </WebUI.CarouselContent>

                {(formik.values.videoLink
                  ? [...formik.values.headerImages, formik.values.videoLink]
                  : formik.values.headerImages
                ).length > 1 && <WebUI.CarouselStepper />}
              </WebUI.Carousel>
            ) : (
              <WebUI.DialogDisclosure
                className="[&_>_.Button-iconBefore]:!mr-5 [&_>_.Button-iconBefore]:-mt-1 h-auto w-full justify-start bg-gray100 px-12 py-7 [&_>_.Button-content]:flex-0"
                variant="secondary"
                iconBefore={
                  <img className="h-[3.5em]" src={AddBannerImageIcon} alt="" />
                }
              >
                Create a banner
              </WebUI.DialogDisclosure>
            )
          }
        />
      </div>
    </PremiumFeaturesSideSheet>
  )
}

// MARK: – ImageSelectOverlay

interface ImageSelectOverlayProps extends WebUI.ModalProps {
  tab?: Api.Tab
  formik: CollectionFormFormik
}

const ImageSelectOverlay = React.forwardRef<
  WebUI.DialogInstance,
  ImageSelectOverlayProps
>(
  (
    {
      tab,
      formik,
      initialVisible = false,
      contentViewAppearance = 'overlay',
      ...restProps
    },
    forwardedRef,
  ) => (
    <WebUI.Modal
      ref={forwardedRef}
      initialVisible={initialVisible}
      contentViewAppearance={contentViewAppearance}
      {...restProps}
    >
      {(dialog) => (
        <>
          <WebUI.ModalCloseButton />
          <PremiumFeaturesSideSheet
            tabId={tab?.id}
            enforcedPremiumMeta={{
              pro: {
                multipleBannerImages: true,
                videoLink: true,
              },
            }}
          >
            <WebUI.ModalHeader variant="compact">
              Create a Banner
            </WebUI.ModalHeader>

            <WebUI.Tabs className="grow" variant="underlined">
              {(tabs) => (
                <>
                  <WebUI.TabList className="px-13 [&_>_.TabList-underline]:bg-orange-500">
                    <WebUI.Tab id="images">Image(s)</WebUI.Tab>
                    <WebUI.Tab id="video">Video</WebUI.Tab>
                  </WebUI.TabList>

                  <WebUI.TabPanel
                    className="flex flex-col gap-4 px-12 py-6"
                    tabId="images"
                  >
                    <span>
                      Add a single image or encourage interaction with an image
                      carousel of up to 4 images.
                    </span>

                    <ImagesStackBuilder
                      active={tabs.selectedId === 'images'}
                      tab={tab}
                      formik={formik}
                      onDismiss={() => dialog.hide()}
                    />
                  </WebUI.TabPanel>

                  <WebUI.TabPanel className="px-12 py-6" tabId="video">
                    <TabVideoSubform
                      className="max-w-[480px]"
                      active={tabs.selectedId === 'video'}
                      tab={tab}
                      formik={formik}
                      onDismiss={() => dialog.hide()}
                    />
                  </WebUI.TabPanel>
                </>
              )}
            </WebUI.Tabs>

            {/** HACK: this is an element for portals to attach to */}
            <div id="toolbar" />
          </PremiumFeaturesSideSheet>
        </>
      )}
    </WebUI.Modal>
  ),
)

// MARK: – ImagesStackBuilder

interface ImagesStackBuilderProps
  extends React.ComponentPropsWithoutRef<'div'> {
  active: boolean
  tab?: Api.Tab
  formik: CollectionFormFormik
  onDismiss: () => void
}

const ImagesStackBuilder = ({
  active,
  tab,
  formik,
  onDismiss,
  className,
  ...restProps
}: ImagesStackBuilderProps) => {
  const [selectedImageIdx, setSelectedImageIdx] = useState<number>(0)
  const [editingImageIdx, setEditingImageIdx] = useState<number | null>(null)

  const toolbarEl = document.getElementById('toolbar')
  const selectedHeaderImage = formik.values.headerImages[selectedImageIdx]

  return (
    <>
      <div
        className={WebUI.cn(
          'relative flex w-full max-w-screen-sm flex-col gap-4',
          className,
        )}
        {...restProps}
      >
        {selectedHeaderImage ? (
          <WithButtonOverlay
            className="rounded-default"
            overlay={
              <div className="absolute inset-x-6 top-6 flex flex-row justify-between gap-3">
                {selectedImageIdx === 0 &&
                formik.values.headerImages.length > 1 ? (
                  <WebUI.Tag className="bg-trueWhite text-ds-xs text-gray800">
                    Main Image
                  </WebUI.Tag>
                ) : (
                  <div />
                )}
                <WebUI.IconButton
                  className="text-ds-lg"
                  size="default_alt"
                  variant="secondary"
                  onClick={() =>
                    formik.setFieldValue(
                      'headerImages',
                      formik.values.headerImages.filter(
                        (image) => image !== selectedHeaderImage,
                      ),
                    )
                  }
                >
                  <WebUI.PhosphorIcon icon="x" />
                </WebUI.IconButton>
              </div>
            }
            onClick={() => setEditingImageIdx(selectedImageIdx)}
          >
            <SharpImage
              key={selectedHeaderImage.id}
              alt="Main"
              width="100%"
              image={selectedHeaderImage}
            />
          </WithButtonOverlay>
        ) : (
          <WebUI.Button
            className="aspect-video w-full bg-gray100 [&_>_.Button-content]:flex-0"
            orientation="vertical"
            variant="secondary"
            iconBefore={
              <WebUI.Image height="3.5em" alt="" src={AddBannerImageIcon} />
            }
            onClick={() => setEditingImageIdx(0)}
          >
            Select Image
          </WebUI.Button>
        )}

        {formik.values.headerImages.length > 0 && (
          <ImagesStack
            tab={tab}
            selectedImageId={selectedHeaderImage?.id}
            images={formik.values.headerImages}
            onSelectImageId={(newSelectedImageId) =>
              setSelectedImageIdx(
                formik.values.headerImages.findIndex(
                  (i) => i.id === newSelectedImageId,
                ),
              )
            }
            onOrderChange={(newOrder) =>
              formik.setFieldValue(
                'headerImages',
                Util.sort(formik.values.headerImages).asc((i) =>
                  newOrder.indexOf(i.id),
                ),
              )
            }
            onAddImage={() =>
              setEditingImageIdx(formik.values.headerImages.length)
            }
          />
        )}

        {active && (
          <WebUI.Portal portalElement={toolbarEl}>
            <WebUI.PageToolbar className="w-full">
              <WebUI.PageToolbarSubmitButton
                type="button"
                arrow={false}
                onClick={() => onDismiss()}
              >
                Save
              </WebUI.PageToolbarSubmitButton>
            </WebUI.PageToolbar>
          </WebUI.Portal>
        )}
      </div>

      <HeaderImagePickerModal
        key={editingImageIdx}
        visible={editingImageIdx != null}
        imageCropAspectRatio={16 / 9}
        imageCropLimitToImage={false}
        placeholderSrc={CollectionBannerPlaceholder}
        initialImage={formik.values.headerImages[editingImageIdx ?? -1]}
        onImageSubmit={(newImage) => {
          if (editingImageIdx != null) {
            const newHeaderImages = Util.splice(
              formik.values.headerImages,
              editingImageIdx,
              1,
              [newImage],
            )
            formik.setFieldValue('headerImages', newHeaderImages)
          }
        }}
        onDidHide={() => setEditingImageIdx(null)}
      />
    </>
  )
}

// MARK: – ImagesStack

interface ImagesStackProps extends React.ComponentPropsWithoutRef<'div'> {
  maxImagesCount?: number
  tab?: Api.Tab
  images: Api.S3Image[]
  selectedImageId?: number | null
  onAddImage: () => void
  onSelectImageId: (imageId: number) => void
  onOrderChange: (imageIds: number[]) => void
}

const ImagesStack = ({
  maxImagesCount = 4,
  tab,
  images,
  selectedImageId,
  onAddImage,
  onSelectImageId,
  onOrderChange,
  className,
  ...restProps
}: ImagesStackProps) => {
  const [mrId] = useManagerRoleId()
  const isSubscribedToProQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_pro,
  })

  const isSubscribedToPro = isSubscribedToProQuery.data !== false || !!mrId

  const planBadge = isSubscribedToPro ? null : !tab ||
    tab.status === 'draft' ? (
    <PremiumFeatureSideSheetDisclosure key="premium-feature-side-sheet-disclosure" />
  ) : (
    <PlanUpgradeButton key="plan-upgrade-button" upgradeTo="pro" />
  )

  return (
    <div className={WebUI.cn('flex flex-row gap-3', className)} {...restProps}>
      <WebUI.DragAndDrop
        dragOverlayPortal={false}
        onDragEnd={(event) => {
          if (event.over) {
            const currentOrder = images.map((i) => i.id)
            const activeIdx = currentOrder.indexOf(Number(event.active.id))
            const overIdx = currentOrder.indexOf(Number(event.over.id))

            onOrderChange(Util.swapIndices(currentOrder, activeIdx, overIdx))
          }
        }}
      >
        <WebUI.SortableContext
          strategy={WebUI.rectSortingStrategy}
          items={images}
        >
          {({items: imageIds}) => (
            <>
              {imageIds.map((imageId, idx) => {
                const image = images.find((image) => imageId === image.id)

                return image ? (
                  <WebUI.Sortable
                    key={image.id}
                    id={imageId}
                    className="flex-1"
                  >
                    <WithButtonOverlay
                      aria-selected={selectedImageId === image.id}
                      className="rounded-default ring-offset-1 transition-shadow aria-selected:ring-2"
                      overlay={
                        planBadge &&
                        idx > 0 && (
                          <div className="absolute top-2 right-2">
                            {planBadge}
                          </div>
                        )
                      }
                      // biome-ignore lint/style/noNonNullAssertion:
                      onClick={() => onSelectImageId(image.id!)}
                    >
                      <SharpImage alt="" image={image} />
                    </WithButtonOverlay>
                  </WebUI.Sortable>
                ) : null
              })}
              {imageIds.length < maxImagesCount && (
                <div className="relative flex-1">
                  <WebUI.Button
                    className="!p-0 !h-full w-full [&_>_.Button-content]:flex-none"
                    orientation="vertical"
                    variant="secondary"
                    iconBefore={
                      <WebUI.PhosphorIcon
                        className="text-ds-lg"
                        icon="plus-bold"
                      />
                    }
                    onClick={() => {
                      if (
                        isSubscribedToPro ||
                        !tab ||
                        tab.status === 'draft' ||
                        imageIds.length === 0
                      ) {
                        onAddImage()
                      }
                    }}
                  >
                    Add Image
                  </WebUI.Button>
                  {planBadge && imageIds.length > 0 && (
                    <div className="absolute top-2 right-2">{planBadge}</div>
                  )}
                </div>
              )}
              {Array.from({length: maxImagesCount - images.length - 1}).map(
                (_, idx) => (
                  <div key={idx} className="flex-1" />
                ),
              )}
            </>
          )}
        </WebUI.SortableContext>
      </WebUI.DragAndDrop>
    </div>
  )
}

// MARK: – WithButtonOverlay

interface WithButtonOverlayProps
  extends Util.Merge<
    React.ComponentPropsWithoutRef<'div'>,
    Pick<React.ComponentPropsWithoutRef<'button'>, 'onClick'>
  > {
  overlay?: React.ReactNode
}

const WithButtonOverlay = ({
  overlay,
  className,
  children,
  onClick,
  ...restProps
}: WithButtonOverlayProps) => {
  return (
    <div
      className={WebUI.cn('relative overflow-hidden', className)}
      {...restProps}
    >
      {children}
      <div className="absolute inset-0">
        <button
          className="absolute inset-0 cursor-pointer bg-transparent"
          type="button"
          onClick={onClick}
        />
        {overlay}
      </div>
    </div>
  )
}

interface TabVideoSubformProps extends React.ComponentPropsWithoutRef<'div'> {
  active: boolean
  tab?: Api.Tab
  formik: CollectionFormFormik
  onDismiss: () => void
}

const TabVideoSubform = ({
  active,
  tab,
  formik,
  onDismiss,
  className,
  ...restProps
}: TabVideoSubformProps) => {
  const [mrId] = useManagerRoleId()
  const isSubscribedToProQuery = api.auth.session.useQuery(undefined, {
    select: (session) => session.capabilities.subscribed_to_pro,
  })
  const [videoPlatform, setVideoPlatform] =
    useState<WebUI.SupportedVideoPlatform>('youtube')

  const toolbarEl = document.getElementById('toolbar')
  const isSubscribedToPro = isSubscribedToProQuery.data !== false || !!mrId
  const isDisabled = !isSubscribedToPro && !!tab && tab.status !== 'draft'

  useEffect(() => {
    const linkVideoPlatform = WebUI.getVideoPlatformForUrl(
      formik.values.videoLink,
    )
    if (linkVideoPlatform) {
      setVideoPlatform(linkVideoPlatform)
    }
  }, [formik.values.videoLink])

  return (
    <div className={WebUI.cn('flex flex-col gap-4', className)} {...restProps}>
      {!isSubscribedToPro &&
        (!tab || tab.status === 'draft' ? (
          <PremiumFeatureSideSheetDisclosure />
        ) : (
          <PlanUpgradeButton key="plan-upgrade-button" upgradeTo="pro" />
        ))}
      <span className="text-ds-base">
        Your video will be embedded and playable directly from your page.
      </span>
      <WebUI.FormField required label="Video platform">
        <WebUI.DropdownSelect
          disabled={isDisabled}
          value={videoPlatform}
          onValueChange={(newVideoPlatform) =>
            setVideoPlatform(newVideoPlatform as WebUI.SupportedVideoPlatform)
          }
        >
          {WebUI.supportedVideoPlatforms.map((vp) => (
            <WebUI.DropdownSelectOption key={vp} value={vp}>
              {WebUI.formattedVideoPlatforms[vp]}
            </WebUI.DropdownSelectOption>
          ))}
        </WebUI.DropdownSelect>
      </WebUI.FormField>

      <WebUI.FormField
        required
        label="Video URL"
        error={formik.errors.videoLink}
      >
        <WebUI.Input
          name="videoLink"
          type="url"
          disabled={isDisabled}
          value={formik.values.videoLink}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </WebUI.FormField>

      {active && (
        <WebUI.Portal portalElement={toolbarEl}>
          <WebUI.PageToolbar className="w-full">
            <WebUI.PageToolbarSubmitButton
              type="button"
              arrow={false}
              onClick={() => {
                const linkVideoPlatform = WebUI.getVideoPlatformForUrl(
                  formik.values.videoLink,
                )

                if (formik.values.videoLink.length === 0 || linkVideoPlatform) {
                  onDismiss()
                } else {
                  formik.setFieldError(
                    'videoLink',
                    'Unsupported video platform',
                  )
                }
              }}
            >
              Save
            </WebUI.PageToolbarSubmitButton>
          </WebUI.PageToolbar>
        </WebUI.Portal>
      )}
    </div>
  )
}
