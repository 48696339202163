import {ForwardRefComponent, useForkRef} from '@cheddarup/react-util'
import {tokens} from '@cheddarup/tokens'
import React, {useId, useRef} from 'react'

import {Text} from './Text'
import {cn} from '../utils'

export interface FloatingLabelFormTextFieldProps {
  label?: React.ReactNode
  error?: React.ReactNode
  suffix?: React.ReactNode
  caption?: React.ReactNode
  id?: string
  required?: boolean
}

export const FloatingLabelFormTextField = React.forwardRef(
  (
    {
      as: Comp = 'fieldset',
      children: childrenProp,
      className,
      label,
      error,
      suffix,
      caption,
      id: idProp,
      required,
      ...restProps
    },
    forwardedRef,
  ) => {
    const child = React.isValidElement(childrenProp)
      ? (childrenProp as React.ReactElement<any>)
      : null
    const suffixRef = useRef<HTMLDivElement>(null)
    const ref = useForkRef((child as any)?.ref, forwardedRef)

    const inputId = idProp ?? useId()
    const labelId = `${inputId}-label`
    const captionId = `${inputId}-caption`
    const errorId = `${inputId}-error`

    const suffixWidth = suffixRef.current?.getBoundingClientRect().width ?? 0

    return (
      <Comp
        className={cn(
          'FloatingLabelFormTextField',
          'relative flex min-w-0 flex-col gap-2 border-none bg-trueWhite',
          className,
        )}
        {...restProps}
      >
        {child && (
          <div className="FormField-inputWrapper peer/inputWrapper relative w-full grow bg-inherit">
            {React.cloneElement(child, {
              ref,
              className: cn(
                'FloatingLabelFormTextField-input',
                `peer/input w-full grow placeholder:text-transparent hover:!bg-inherit
                autofill:!bg-clip-text`,
                child.props.className,
              ),
              style:
                suffixWidth > 0
                  ? {
                      paddingRight: `calc(${suffixWidth}px + ${tokens.space[7]} * 2)`,
                      ...child.props.style,
                    }
                  : child.props.style,
              id: inputId,
              required: child.props.required ?? required,
              placeholder: child.props.placeholder ?? ' ',
              ...(label && {'aria-labelledby': labelId}),
              ...(caption && {'aria-describedby': captionId}),
              ...(error && {
                'aria-invalid': true,
                'aria-errormessage': errorId,
              }),
            })}

            {label && (
              <Text
                as="label"
                className={cn(
                  'FloatingLabelFormTextField-label',
                  '-translate-y-5 peer-placeholder-shown/input:-translate-y-1/2 peer-autofill/input:-translate-y-5 peer-focus/input:-translate-y-5 pointer-events-none absolute start-4 end-4 top-2 w-fit origin-[0] scale-75 transform select-none overflow-hidden text-ellipsis whitespace-nowrap bg-inherit px-3 text-ds-base text-gray600 duration-300 peer-placeholder-shown/input:top-1/2 peer-placeholder-shown/input:scale-100 peer-placeholder-shown/input:bg-transparent peer-autofill/input:top-2 peer-autofill/input:scale-75 peer-autofill/input:bg-inherit peer-focus/input:top-2 peer-focus/input:scale-75 peer-focus/input:bg-inherit',
                )}
                id={labelId}
                htmlFor={inputId}
              >
                {label} {required && <span className="text-orange-500">*</span>}
              </Text>
            )}
            {!!suffix && (
              <div
                ref={suffixRef}
                className="FormField-suffix absolute inset-y-0 right-4 flex min-h-0 flex-col justify-center"
              >
                {suffix}
              </div>
            )}
          </div>
        )}

        {!!caption && (
          <Text
            className="FormField-caption hidden text-ds-xs peer-focus-within/inputWrapper:inline"
            id={captionId}
            variant="subtle"
          >
            {caption}
          </Text>
        )}

        {!!error && error !== true && (
          <Text
            className="FloatingLabelFormTextField-error border-l-0 bg-[unset] px-3 text-ds-xs text-orange-500"
            variant="danger"
            id={errorId}
          >
            {error}
          </Text>
        )}
      </Comp>
    )
  },
) as ForwardRefComponent<'fieldset', FloatingLabelFormTextFieldProps>
