// Inspired by https://github.com/adobe/react-spectrum/blob/main/packages/react-aria-components/src/ColorSwatchPicker.tsx

import React, {useContext, useRef} from 'react'
import {Radio as ReakitRadio, RadioOptions as ReakitRadioOptions} from 'reakit'
import {cn} from '../../utils'
import {InternalRadioGroupContext, RadioGroup, RadioGroupProps} from '../Radio'
import {getReadableColorIsBlack, Merge, toHex} from '@cheddarup/util'
import {useColorPicker} from './ColorPicker'
import {useForkRef} from 'reakit-utils'

export interface ColorSwatchPickerProps
  extends Merge<
    React.ComponentPropsWithoutRef<'div'>,
    Omit<RadioGroupProps, 'defaultState' | 'state' | 'size' | 'variant'>
  > {
  defaultValue?: string
  value?: string
}

export const ColorSwatchPicker = React.forwardRef<
  HTMLDivElement,
  ColorSwatchPickerProps
>(
  (
    {className, defaultValue, value: valueProp, onChange, ...restProps},
    forwardedRef,
  ) => {
    const colorPicker = useColorPicker()

    const _value = valueProp ?? colorPicker?.value
    const value = _value ? toHex(_value) : _value

    return (
      <RadioGroup
        ref={forwardedRef}
        className={cn('flex flex-row flex-wrap gap-2', className)}
        state={value}
        defaultState={defaultValue ? toHex(defaultValue) : defaultValue}
        onChange={(event) => {
          onChange?.(event)
          if (event.defaultPrevented) {
            return
          }

          colorPicker?.setValue(event.target.value)
        }}
        {...restProps}
      />
    )
  },
)

// MARK: – ColorSwatchPickerItem

export interface ColorSwatchPickerItemProps
  extends Merge<
    Omit<
      Merge<
        React.ComponentPropsWithoutRef<'input'>,
        Partial<ReakitRadioOptions>
      >,
      'children'
    >,
    Pick<ColorSwatchProps, 'color' | 'colorName'>
  > {}

export const ColorSwatchPickerItem = React.forwardRef<
  HTMLButtonElement,
  ColorSwatchPickerItemProps
>(
  (
    {
      color,
      colorName,
      className,
      disabled: disabledProp,
      onMouseDown,
      ...restProps
    },
    forwardedRef,
  ) => {
    const {name, disabled, size, variant, ...radioState} = useContext(
      InternalRadioGroupContext,
    )
    const ownRef = useRef<HTMLInputElement>(null)
    const ref = useForkRef(ownRef, forwardedRef)

    return (
      <div
        className={cn('relative cursor-pointer rounded-default', className)}
        onMouseDown={onMouseDown}
        onClick={() => ownRef.current?.click()}
      >
        <ReakitRadio
          ref={ref}
          className={cn(
            'peer -z-[1] absolute h-px w-px overflow-hidden opacity-0',
            className,
          )}
          name={name}
          disabled={disabledProp ?? disabled}
          value={toHex(color)}
          {...radioState}
          {...restProps}
        />
        <ColorSwatch
          className="!rounded-[inherit] h-full w-full outline-teal-50 outline-offset-2 peer-aria-checked:outline peer-aria-checked:outline-2"
          color={color}
          colorName={colorName}
        />
      </div>
    )
  },
)

// MARK: – ColorSwatch

export interface ColorSwatchProps
  extends React.ComponentPropsWithoutRef<'div'> {
  color: string
  colorName?: string
}

export const ColorSwatch = React.forwardRef<HTMLDivElement, ColorSwatchProps>(
  (
    {
      color,
      colorName,
      role = 'img',
      'aria-label': ariaLabel,
      'aria-roledescription': ariaRoledescription = 'color swatch',
      className,
      style,
      ...restProps
    },
    forwardedRef,
  ) => (
    <div
      ref={forwardedRef}
      role={role}
      aria-roledescription={ariaRoledescription}
      // TODO: generate a default color name from the `color` prop
      aria-label={ariaLabel ?? colorName}
      className={cn(
        'min-h-4 min-w-4 rounded-default forced-color-adjust-none',
        getReadableColorIsBlack(color, '#FFFFFF') && 'border-grey-300',
        className,
      )}
      style={{
        backgroundColor: color,
        ...style,
      }}
      {...restProps}
    />
  ),
)
