import * as Util from '@cheddarup/util'
import {NumberParam, useQueryParam} from 'use-query-params'
import React, {useMemo} from 'react'
import * as WebUI from '@cheddarup/web-ui'
import {api} from '@cheddarup/api-client'

import type {MessageHistoryViewFilters} from './MessageHistoryView'
import {useManagerRoleQuery} from 'src/components/ManageRoleProvider'

export interface MessageHistoryNavProps
  extends React.ComponentPropsWithoutRef<'div'> {
  filters: MessageHistoryViewFilters
  email: string
}

export const MessageHistoryNav = ({
  filters,
  email,
  className,
  ...restProps
}: MessageHistoryNavProps) => {
  const [managerRoleQuery] = useManagerRoleQuery()
  const [messageId, setMessageId] = useQueryParam('messageId', NumberParam)
  const messagesQuery = api.messages.list.useInfiniteQuery(
    {
      queryParams: {
        tab_id:
          email.length > 0 || filters.collectionId === -1
            ? null
            : filters.collectionId,
        group_page:
          (email.length === 0 && filters.collectionId === -1) || undefined,
        message_type: email.length > 0 ? null : filters.messageType,
        recipient: email || undefined,
        page: 1,
        per_page: 20,
      },
    },
    {
      enabled:
        !managerRoleQuery.isPending &&
        (!managerRoleQuery.data ||
          !!managerRoleQuery.data.permissions.address_book_and_message_center),
      initialPageParam: 1,
      getNextPageParam: ({pagination}) =>
        pagination.page * pagination.perPage < pagination.total
          ? pagination.page + 1
          : undefined,
    },
  )

  const messages = useMemo(
    () => messagesQuery.data?.pages.flatMap((p) => p.data) ?? [],
    [messagesQuery.data?.pages],
  )

  return (
    <WebUI.VStack
      className={WebUI.cn(
        '[&_>_.MessageHistoryNavItem:not(:last-child)]:border-b',
        className,
      )}
      {...restProps}
    >
      {messagesQuery.isSuccess &&
        messagesQuery.data.pages[0]?.pagination.total === 0 && (
          <WebUI.Text className="p-8 text-gray600">
            {{
              message: 'You have not yet sent any messages on this collection.',
              invite:
                'You have not yet sent any invitations on this collection.',
              reminder_parent:
                'You have no automatic reminders set up on this collection.',
              // @ts-expect-error
            }[filters.messageType] ?? 'No messages have been sent.'}
          </WebUI.Text>
        )}
      {messages.map((message) => (
        <MessageHistoryNavItem
          key={message.id}
          aria-selected={messageId === message.id}
          message={message}
          onClick={() => setMessageId(message.id)}
        />
      ))}
      {messagesQuery.hasNextPage && (
        <div className="p-4">
          <WebUI.Button
            className="w-full"
            variant="secondary"
            loading={messagesQuery.isFetchingNextPage}
            onClick={() => messagesQuery.fetchNextPage()}
          >
            Load More
          </WebUI.Button>
        </div>
      )}
    </WebUI.VStack>
  )
}

// MARK: – MessageHistoryNavItem

interface MessageHistoryNavItemProps
  extends React.ComponentPropsWithoutRef<'button'> {
  message: Api.Message
}

const MessageHistoryNavItem = ({
  message,
  className,
  ...restProps
}: MessageHistoryNavItemProps) => (
  <WebUI.Button
    className={
      'MessageHistoryNavItem block rounded-none bg-trueWhite p-4 aria-selected:bg-teal-90'
    }
    variant="text"
    {...restProps}
  >
    <WebUI.VStack className="gap-2">
      <WebUI.HStack className="justify-between gap-3 text-ds-xs">
        <span>
          {
            {
              message: 'Message',
              invite: 'Invitation',
              reminder_parent: 'Reminder',
            }[message.message_type]
          }
          {message.detail.legacy && ' (Legacy)'}
        </span>
        <span>
          {message.send_at &&
            Util.formatDate(new Date(message.send_at), 'MM/dd/yy')}
        </span>
      </WebUI.HStack>

      <WebUI.VStack className="gap-1">
        <WebUI.Heading
          className="overflow-hidden text-ellipsis whitespace-nowrap"
          as="h4"
        >
          {message.detail.subject}
        </WebUI.Heading>
        <WebUI.Ellipsis className="font-light text-ds-sm">
          {message.tab?.name ?? 'Group Page'}
        </WebUI.Ellipsis>
        {message.message_type === 'reminder_parent' ? (
          <span className="text-ds-xs text-linkPrimaryText">View Settings</span>
        ) : (
          <span className="text-ds-xs">
            {message.detail.recipients.length} Recipients
          </span>
        )}
      </WebUI.VStack>
    </WebUI.VStack>
  </WebUI.Button>
)
