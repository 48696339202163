// @ts-ignore

import {z} from '@cheddarup/util'

export const subscriptionPlanTypeSchema = z.enum([
  'free',
  'pro',
  'pause',
  'team',
])

export const billingHistoryItemSchema = z.object({
  charge: z
    .object({
      amount: z.number(),
      amount_refunded: z.number(),
      status: z.string(),
    })
    .optional(),
  created: z.number().nullish(),
  hosted_invoice_url: z.string(),
})

export const subscriptionPlanSchema = z.object({
  identifier: z.string(),
  name: z.string(),
  pending_changes: z.object({
    identifier: z.literal('no_change'),
    new_plan: z.string(),
    starting: z.string(),
  }),
})

export const planTypeSchema = z.enum([
  'pro_monthly',
  'pro_annual',
  'team_monthly',
  'team_annual',
])

export const billingDetailsSchema = z.object({
  plan: z.string(),
  plan_cost: z.number(),
  coupon_discount: z.number(),
  tax_breakdown: z.array(z.any()), // You can replace `z.any()` with a more specific schema if needed
  total_tax: z.number(),
  subtotal: z.number(),
  total: z.number(),
  proration_details: z.object({
    current_plan: planTypeSchema.optional(),
    new_plan: planTypeSchema.optional(),
    new_plan_amount: z.number().optional(),
    next_billing_date: z.string().optional(),
  }),
})

export const partnerPromoCodeSchema = z.object({
  code: z.string(),
  validPlans: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      stripe_plan_id: planTypeSchema,
    }),
  ),
  discount: z.number(),
  valid: z.boolean(),
  duration: z.enum(['forever', 'once']),
  label: z.string(),
  description: z.string(),
  partnerId: z.string(),
  percentage: z.string(),
})

declare global {
  namespace Api {
    // @ts-ignore
    type SubscriptionPlan = z.infer<typeof subscriptionPlanSchema>
    // @ts-ignore
    type BillingHistoryItem = z.infer<typeof billingHistoryItemSchema>
    type PartnerPromoCodeSchema = z.infer<typeof partnerPromoCodeSchema>
    type PlanType = z.infer<typeof planTypeSchema>
    type BillingDetails = z.infer<typeof billingDetailsSchema>
  }
}
